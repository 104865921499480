import React from "react";
import { Modal, Button, OverlayTrigger, Popover } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "../router";
import { ToastContainer, toast } from 'react-toastify';
import * as commanActionCreator from '../reducers/commanReduces'

import { MdResetTv } from 'react-icons/md';
import { FaRegCopy, FaTrashAlt } from 'react-icons/fa';

import SearchWithButton from "./utilComponents/searchWithButton";
import { fetchScreenDetails, setToScreenService } from "../services/screenService";

import { fetchAllPlayList, addPlayList, attachedDataList, deletePlayList } from "../services/playListServices";

import { DataIsEmpty, IconButton } from "./utilComponents/inputFieldAndButtons";
import { bindActionCreators } from "@reduxjs/toolkit";
import Sidebar from "./subComponents/Sidebar";
import Navbar from "./subComponents/Navbar";
import Playlist from "../appIcons/Playlist";
import Playee from "../appIcons/Playee";
import SetToScreenModel from "./utilComponents/setToScreenModel";

class PlayListComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      addPlaylistModel: false,
      deletePlaylistModel: false,
      totalDuration: "00:00:00",
      selectedPlayListObject: {},
      playList: [],
      selectedPlayList: [],
      selectAll: false,
      screenList: [],
      playListName: "New PlayList",
      attachedList: {},
      playListId: "",
    }
  }
  async componentDidMount() {
    this.props.counterSlice.startLoading();
    let playListRes = await fetchAllPlayList();
    if (playListRes.success)
      this.setState({ playList: playListRes.body, playListTemp: playListRes.body, });
    this.props.counterSlice.stopLoading();
  }
  searchName = (e) => {
    if (e.target.value != "") {
      this.setState({ playList: this.state.playListTemp.filter((s) => s.name.toUpperCase().includes(e.target.value.toUpperCase())) })
    } else
      this.setState({ playList: this.state.playListTemp })
  }
  closeAll = () => {
    this.setState({
      deletePlaylistModel: false, setToScreenModel: false, addPlaylistModel: false, totalDuration: "00:00:00", selectedPlayList: [],
      selectedPlayListObject: {}, screenList: []
    });
  }
  savePlayList = async () => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.props.counterSlice.startLoading();

    let playListDTO = {
      name: this.state.playListName,
      totalDuration: this.state.totalDuration,
      orgId: userInfo.orgId
    }
    let response = await addPlayList(playListDTO);
    if (response.success) {
      let dasboardResponse = await fetchAllPlayList();
      this.setState({ playList: dasboardResponse.body });
      this.props.router.navigate("/playlist/" + response.body._id);
    } else {
      toast.error('Error while adding playlist.', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        theme: "colored",
        progress: undefined,
      });
    }
    this.props.counterSlice.stopLoading();
  }
  copyPlayList = async (copyData) => {
    copyData._id = undefined;
    copyData.status = "DRAFT";
    copyData.name = copyData.name + "-(copy)";
    this.props.counterSlice.startLoading();

    let response = await addPlayList(copyData);
    if (response.success) {
      let dasboardResponse = await fetchAllPlayList();
      this.setState({ playList: dasboardResponse.body });
      toast.success('Playlist created successfully.', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        theme: "colored",
        progress: undefined,
      });
    } else {
      toast.error('Error while adding playlist.', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        theme: "colored",
        progress: undefined,
      });
    }
    this.props.counterSlice.stopLoading();

  }
  deletePlayList = async () => {
    this.props.counterSlice.startLoading();

    let response = await deletePlayList(this.state.playListId);
    if (response.success) {
      toast.success('PlayList deleted successfully.', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        theme: "colored",
        progress: undefined,
      });
      let playListRes = await fetchAllPlayList();
      this.setState({ playList: playListRes.body });
    } else {
      toast.error('Error while deleting screen.', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        theme: "colored",
        progress: undefined,
      });
    }
    this.props.counterSlice.stopLoading();

    this.setState({ playListId: "", deletePlaylistModel: false })
  };
  onClickDeletePlayList = async (p) => {
    this.props.counterSlice.startLoading();
    let attachedData = await attachedDataList(p._id);
    this.props.counterSlice.stopLoading();
    this.setState({ deletePlaylistModel: true, attachedList: attachedData.body, playListId: p._id })
  }
  onSetToScreenClick = async (p) => {
    this.props.counterSlice.startLoading();
    let screenDetails = await fetchScreenDetails();
    let attachedData = await attachedDataList(p._id);

    this.props.counterSlice.stopLoading();
    this.setState({ setToScreenModel: true, selectedPlayListObject: p });
    if (screenDetails.success) {
      let screenList = screenDetails.body;
      if (attachedData.success && attachedData.body.screenList.length > 0) {
        let attachedScreenInfo = attachedData.body;
        screenList.forEach((s) => {
          s.isSelected = false;
          for (let i = 0; i < attachedScreenInfo.screenList.length; i++) {
            if (s._id === attachedScreenInfo.screenList[i]._id)
              s.isSelected = true;
          }
        });
        if (attachedScreenInfo.screenList.length === screenList)
          this.setState({ selectAll: true })

      } else
        screenList.forEach((s) => s.isSelected = false);
      this.setState({ screenList: screenList, screenTempList: screenList });
    }
  }
  setToScreenService = async () => {
    let screenList = [];
    this.setState({ setToScreenModel: false });
    this.props.counterSlice.startLoading();

    this.state.screenList.forEach((s) => {
      if (s.isSelected)
        screenList.push(s._id);
    });

    let screenDTO = {
      type: "PLAYLIST",
      refrenceId: this.state.selectedPlayListObject._id,
      screenIdList: screenList
    };
    let screenDetails = await setToScreenService(screenDTO);
    if (screenDetails.success) {
      toast.success('Screens has been updated.', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        theme: "colored",
        progress: undefined,
      });
    } else {
      toast.error(screenDetails.message, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        theme: "colored",
        progress: undefined,
      });
    }
    this.props.counterSlice.stopLoading();

    this.closeAll();
  }
  searchScreenName = (e) => {
    if ((e.target.value != "")) {
      console.log(e.target.value.length);

      let screenList = [];
      for (let i = 0; i < this.state.screenTempList.length; i++) {
        let s = this.state.screenTempList[i]
        for (let j = 0; j < s.tags.length; j++) {
          if (s.tags[j] && s.tags[j].tag.toUpperCase().includes(e.target.value.toUpperCase())) {
            screenList.push(s)
            break;
          }
        }
      }
      let screenNameSort = this.state.screenTempList.filter((s) => s.name.toUpperCase().includes(e.target.value.toUpperCase()));
      let screenNameList = screenList.concat(screenNameSort);
      this.setState({ screenList: this.removeDuplicates(screenNameList) });
    } else
      this.setState({ screenList: this.state.screenTempList })
  }
  removeDuplicates = (arr) => {
    const uniqueArray = [];
    const idTracker = {};

    for (let obj of arr) {
      const id = obj._id;
      if (!idTracker[id]) {
        uniqueArray.push(obj);
        idTracker[id] = true;
      }
    }
    return uniqueArray;
  }
  render() {
    const userRole = JSON.parse(localStorage.getItem("roles"));

    return (
      <div className="flex">
        <Sidebar type={"playlist"} />
        <div className="h-screen grow md:overflow-hidden overflow-auto md:hover:overflow-auto">
          <Navbar router={this.props.router} counterSlice={this.props.counterSlice} />
          <div>
            <SearchWithButton
              title="Playlist"
              icon={<Playee style="self-center h-8 w-8" />}
              placeholder="Search playlist"
              onChangeSearch={(e) => this.searchName(e)}
              buttonName="ADD PLAYLIST"
              isRead={userRole.playList["addPlayList"].read}
              onButtonClick={async () => {
                userRole.playList["addPlayList"].write && this.savePlayList();
              }} />
            <br />
            {this.state.playList.length === 0 && <DataIsEmpty url={process.env.PUBLIC_URL + "/emptyPlaylist.svg"}
              title="Create new your playlist"
              body1="Playlists help you organize the order of the content that you want to show."
              body2="It's as easy as drag-and-drop. Fill your Playlist with your uploaded media, then add 
              your Playlist/s to your Channel."
              customFunction={() => userRole.playList["addPlayList"].write && this.savePlayList()}
              buttonName="Create Playlist"
            />}
            <div className="mx-16">
              {this.state.playList?.map((p, i) => {
                return (
                  <div className="flex px-6 py-2 bg-white hover:shadow-md my-2 rounded-lg	group" key={p._id}>
                    <div className={"rounded-lg cursor-pointer self-center border-solid border-1 p-2 bg-dark-team"} onClick={() => this.props.router.navigate("/playlist/" + p._id)}>
                      <Playlist style="self-center h-8 w-8" />
                    </div>
                    <div className="grow px-3 cursor-pointer self-center" onClick={() => this.props.router.navigate("/playlist/" + p._id)}>
                      <div className="xl:w-36 lg:w-24 w-10 md:text-start inline-block text-gray-600 hover:text-gray-800 truncate cursor-pointer "
                        title={p.name}
                      ><span className="truncate">{p.name}</span></div>
                      <p className={(p.status === "DRAFT") ? "text-currentSecondaryColor text-sm italic" : "text-gray-400 lowercase text-sm italic"}>{(p.status === "DRAFT") ? "Draft Saved. Publish required." : p.status}</p>
                    </div>
                    <div className="basis-1/2	self-center -space-x-2 overflow-x-scroll overflow-hidden">
                      {
                        p.playlist?.map((ml, i) => {
                          if (ml.mediaId && ml.mediaId.type === "MEDIA" && !ml.mediaId.contentType.includes("video"))
                            return (
                              <OverlayTrigger trigger={['hover', 'focus']} rootClose placement="top"
                                overlay={
                                  <Popover>
                                    <Popover.Body className="p-0 m-0 text-center">
                                      <img className="inline-block h-22 w-24 rounded-lg border-solid " src={ml.mediaId.url} alt="" />
                                    </Popover.Body>
                                  </Popover>}>
                                <img className="hover:-translate-y-1 inline-block h-12 w-12 rounded-lg border-solid border-1 " src={ml.mediaId.url} alt="Lodding..." key={i} />
                              </OverlayTrigger>
                            )
                        })
                      }
                    </div>
                    <div className="self-center flex basis-1/5 justify-around	 mt-2 z-10 ">
                      {userRole.playList["duplicatePlayList"].read && <IconButton icon={<FaRegCopy size={18} className="text-gray-500" />} toolTip="Duplicate" customFunc={() => userRole.playList["duplicatePlayList"].write && this.copyPlayList(p)} />}
                      {userRole.playList["setToScreen"].read && <IconButton icon={<MdResetTv size={18} className="text-gray-500" />} toolTip="Set To Screen" customFunc={() => userRole.playList["setToScreen"].write && this.onSetToScreenClick(p)} />}
                      {userRole.playList["deletePlayList"].read && <IconButton icon={<FaTrashAlt size={18} className="text-gray-500" />} toolTip="Delete" customFunc={() => userRole.playList["deletePlayList"].write && this.onClickDeletePlayList(p)} />}
                    </div>
                  </div>);
              })}
            </div>
          </div>
        </div>
        <Modal show={this.state.deletePlaylistModel} onHide={this.closeAll}>
          <Modal.Header closeButton closeVariant={"#ffff"}>
            <Modal.Title>Delete Playlist</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>Are you sure, you would like to delete this playlist permanently?</div>
            <br />
            {this.state.attachedList && this.state.attachedList.screenList && this.state.attachedList.screenList.length > 0 && <div>
              <span className="text-gray font-bold text-sm">Attached to screen</span>
              {this.state.attachedList.screenList?.map((s) => (
                <div className="text-xs">- {s.name}</div>
              ))}
            </div>}
            {this.state.attachedList && this.state.attachedList.channelList && this.state.attachedList.channelList.length > 0 && <div>
              <span className="text-gray-500 font-bold text-sm">Attached to channel</span>
              {this.state.attachedList.channelList?.map((c) => (
                <div className="text-xs">- {c.name}</div>
              ))}
            </div>}
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn btn-pr" onClick={this.closeAll}>
              Cancel
            </Button>
            <Button className="btn btn-prime" onClick={this.deletePlayList}>
              Delete
            </Button>

          </Modal.Footer>
        </Modal>
        <SetToScreenModel
          setToScreenModel={this.state.setToScreenModel}
          closeAll={this.closeAll}
          searchScreenName={this.searchScreenName}
          selectAllScreen={() => {
            let isSelected = !this.state.selectAll ? true : false;
            this.state.screenList.forEach((sl) => sl.isSelected = isSelected);
            this.setState({ screenList: this.state.screenList, selectAll: !this.state.selectAll })
          }}
          isSelectAll={this.state.selectAll}
          screenList={this.state.screenList}
          routeTo={() => this.props.router.navigate("/screens")}
          onScreenSelected={(s, i) => {
            this.state.screenList[i].isSelected = !this.state.screenList[i].isSelected;
            let isAllSelected = true;
            this.state.screenList.forEach((si) => {
              if (!si.isSelected)
                isAllSelected = false;
            })
            this.setState({ screenList: this.state.screenList, selectAll: isAllSelected });
          }}
          setToScreenService={this.setToScreenService}
        />
        <ToastContainer />
      </div>
    )
  };
}

const mapStateToProps = (state) => ({
  isLoading: state.counter.isLoading,
  userRole: state.counter.userRole
});

const mapDispatchToProps = (dispatch) => ({
  counterSlice: bindActionCreators(commanActionCreator, dispatch)

})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PlayListComponent));
