import React from "react";
import { connect } from "react-redux";

import { withRouter } from "../../router";
import * as commanActionCreator from '../../reducers/commanReduces'
import { bindActionCreators } from "@reduxjs/toolkit";
import { ToastContainer } from 'react-toastify';

import { fetchMediaLogs } from "../../services/screenService";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import ScreenMediaLog from "./screenMediaLog";
import Link from "../../appIcons/Link";
import { AppIcon } from "../utilComponents/inputFieldAndButtons";


class MediaLogsComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            mediaLogs: [],
            isLogs: true,
            isMediaLogs: false,
            page: 1,
            limit: 10,
            totalCount: 0,
            selectedMedia: ""
        }
    }
    async componentDidMount() {
        this.props.counterSlice.startLoading();
        let mediaLogsDetails = await fetchMediaLogs(this.state.page, this.state.limit);
        this.setState({ mediaLogs: mediaLogsDetails.body, totalCount: Math.ceil(mediaLogsDetails.totalCount / this.state.limit) });
        this.props.counterSlice.stopLoading();
    }
    logDetails = async (m) => {
        this.state.selectedMedia = m;
        this.setState({ selectedMedia: this.state.selectedMedia, isMediaLogs: true, isLogs: false })
    }
    convertToTimeFormat = (str) => {
        let count = str.split(":").length - 1;
        if (count == 0) {
            return "00:00:" + str;
        } else if (count == 1) {
            return "00:" + str;
        }
        return str;
    }
    onLimitChange = async (e) => {
        this.props.counterSlice.startLoading();
        this.setState({ limit: e.target.value, page: 1 });
        let mediaLogsDetails = await fetchMediaLogs(1, e.target.value);
        this.setState({ mediaLogs: mediaLogsDetails.body, totalCount: Math.ceil(mediaLogsDetails.totalCount / this.state.limit) });
        this.props.counterSlice.stopLoading();

    }
    onPageChange = async (page) => {

        if (page > 0 && page <= this.state.totalCount) {
            this.props.counterSlice.startLoading();
            this.setState({ page });
            let mediaLogsDetails = await fetchMediaLogs(page, this.state.limit);
            this.setState({ mediaLogs: mediaLogsDetails.body, totalCount: Math.ceil(mediaLogsDetails.totalCount / this.state.limit) });
            this.props.counterSlice.stopLoading();
        }
    }
    render() {
        const userRole = JSON.parse(localStorage.getItem("roles"));

        return (
            <div>
                {this.state.isLogs &&
                    <div className="shadow mt-8 rounded-lg">
                        <div className="flex rounded-t-lg bg-white p-3 text-gray-450 text-center text-sm font-medium border-b-1">
                            <div className="flex-1">Media</div>
                            <div className="flex-1">Screen Count</div>
                            <div className="flex-1">Play Count</div>
                            <div className="flex-1">Play Time(hr:min:sec)</div>
                        </div>
                        {this.state.mediaLogs?.map((m, i) => (
                            <div key={i} className="flex bg-white p-2 text-gray-450 text-xs text-center text-black border-b-1">
                                <div className="flex flex-1 gap-4">
                                    <div className="flex">
                                        {m.media.type && m.media.type === "LINK" ?
                                            <div className="rounded border-solid border-1 w-8 h-8 p-1 flex self-center"><Link className="self-center p-1" /></div> :
                                            m.media.type === "APP" ? <div className="bg-dark-team p-1 rounded"><AppIcon style={"self-center fill-white w-6 h-6"} type={m.media.appType} /></div> :
                                                m.media.contentType.includes("video") ?
                                                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M9.08203 6.18945V13.3575H18.938V6.18945H9.08203ZM9.52999 6.63741H18.49V12.9094H9.52999V6.63741Z" fill="black" />
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M9.08203 14.668V21.836H18.938V14.668H9.08203ZM9.52999 15.1159H18.49V21.3879H9.52999V15.1159Z" fill="black" />
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M4.60297 4.38086C4.47922 4.38086 4.37891 4.48102 4.37891 4.60477V23.4208C4.37891 23.5444 4.47922 23.6447 4.60297 23.6447H23.419C23.5427 23.6447 23.643 23.5444 23.643 23.4208V4.60477C23.643 4.48102 23.5427 4.38086 23.419 4.38086H4.60297ZM4.82703 4.82882H23.195V23.1968H4.82703V4.82882Z" fill="black" />
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M6.18305 6.20703C6.0593 6.20719 5.95898 6.30734 5.95898 6.4311V8.1111C5.95898 8.23485 6.0593 8.33516 6.18305 8.33516H7.86305C7.98664 8.33516 8.08696 8.23485 8.08696 8.1111V6.4311C8.08696 6.30734 7.98664 6.20719 7.86305 6.20703H6.18305ZM6.40711 6.65499H7.63915V7.88703H6.40711V6.65499Z" fill="black" />
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M6.18305 9.57812C6.0593 9.57812 5.95898 9.67844 5.95898 9.80219V11.4822C5.95898 11.6058 6.0593 11.7061 6.18305 11.7061H7.86305C7.9868 11.7061 8.08696 11.6058 8.08696 11.4822V9.80219C8.08696 9.67844 7.9868 9.57812 7.86305 9.57812H6.18305ZM6.40711 10.0261H7.63915V11.2581H6.40711V10.0261Z" fill="black" />
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M6.18305 12.9492C6.0593 12.9492 5.95898 13.0495 5.95898 13.1731V14.8531C5.95898 14.9769 6.0593 15.0772 6.18305 15.0772H7.86305C7.9868 15.0772 8.08696 14.9769 8.08696 14.8531V13.1731C8.08696 13.0495 7.9868 12.9492 7.86305 12.9492H6.18305ZM6.40711 13.3972H7.63915V14.6292H6.40711V13.3972Z" fill="black" />
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M6.18305 16.3203C6.0593 16.3203 5.95898 16.4205 5.95898 16.5442V18.2242C5.95898 18.348 6.0593 18.4483 6.18305 18.4483H7.86305C7.9868 18.4483 8.08696 18.348 8.08696 18.2242V16.5442C8.08696 16.4205 7.9868 16.3203 7.86305 16.3203H6.18305ZM6.40711 16.7683H7.63915V18.0003H6.40711V16.7683Z" fill="black" />
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M6.18305 19.6934C6.0593 19.6934 5.95898 19.7935 5.95898 19.9173V21.5973C5.95898 21.721 6.0593 21.8213 6.18305 21.8213H7.86305C7.98664 21.8213 8.08696 21.721 8.08696 21.5973V19.9173C8.08696 19.7935 7.98664 19.6934 7.86305 19.6934H6.18305ZM6.40711 20.1413H7.63915V21.3734H6.40711V20.1413Z" fill="black" />
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M20.1595 6.20703C20.0359 6.20719 19.9355 6.30734 19.9355 6.4311V8.1111C19.9355 8.23485 20.0359 8.33516 20.1595 8.33516H21.8395C21.9632 8.33516 22.0635 8.23485 22.0635 8.1111V6.4311C22.0635 6.30734 21.9632 6.20719 21.8395 6.20703H20.1595ZM20.3835 6.65499H21.6156V7.88703H20.3835V6.65499Z" fill="black" />
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M20.1595 9.57812C20.0359 9.57812 19.9355 9.67844 19.9355 9.80219V11.4822C19.9355 11.6058 20.0359 11.7061 20.1595 11.7061H21.8395C21.9632 11.7061 22.0635 11.6058 22.0635 11.4822V9.80219C22.0635 9.67844 21.9632 9.57812 21.8395 9.57812H20.1595ZM20.3835 10.0261H21.6156V11.2581H20.3835V10.0261Z" fill="black" />
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M20.1595 12.9492C20.0359 12.9492 19.9355 13.0495 19.9355 13.1731V14.8531C19.9355 14.9769 20.0359 15.0772 20.1595 15.0772H21.8395C21.9632 15.0772 22.0635 14.9769 22.0635 14.8531V13.1731C22.0635 13.0495 21.9632 12.9492 21.8395 12.9492H20.1595ZM20.3835 13.3972H21.6156V14.6292H20.3835V13.3972Z" fill="black" />
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M20.1595 16.3203C20.0359 16.3203 19.9355 16.4205 19.9355 16.5442V18.2242C19.9355 18.348 20.0359 18.4483 20.1595 18.4483H21.8395C21.9632 18.4483 22.0635 18.348 22.0635 18.2242V16.5442C22.0635 16.4205 21.9632 16.3203 21.8395 16.3203H20.1595ZM20.3835 16.7683H21.6156V18.0003H20.3835V16.7683Z" fill="black" />
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M20.1595 19.6934C20.0359 19.6934 19.9355 19.7935 19.9355 19.9173V21.5973C19.9355 21.721 20.0359 21.8213 20.1595 21.8213H21.8395C21.9632 21.8213 22.0635 21.721 22.0635 21.5973V19.9173C22.0635 19.7935 21.9632 19.6934 21.8395 19.6934H20.1595ZM20.3835 20.1413H21.6156V21.3734H20.3835V20.1413Z" fill="black" />
                                                    </svg> :
                                                    <img className="rounded border-solid border-1 w-8 h-8" src={m.media.url} alt="loading..." />}
                                    </div>
                                    <div className="xl:w-36 lg:w-24 w-10 lg:text-center md:text-start inline-block truncate cursor-pointer self-center" onClick={() => userRole.monitoring["detaiPOPLogs"].read && this.logDetails(m)}
                                    ><span className="font-medium truncate">{m.media.name}</span></div>
                                </div>
                                <div className="flex-1 self-center">
                                    <span className="self-center">{m.screenCount}</span>
                                </div>
                                <div className="flex-1 self-center">
                                    <span className="self-center">{m.mediaCount}</span>
                                </div>
                                <div className="flex-1 self-center">
                                    {this.convertToTimeFormat(m.totalDuration)}
                                </div>
                            </div>
                        ))}
                        <div className="flex justify-end gap-4 rounded-b-lg bg-white p-3 text-gray-450 text-center text-sm font-medium border-b-1">
                            <span className="self-center" >Row per page:</span>
                            <select className="bg-white border-b-1 self-center border-b-black" onChange={(e) => this.onLimitChange(e)} >
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                                <option value="40">40</option>
                                <option value="50">50</option>
                            </select>
                            <span>{this.state.page} of {this.state.totalCount}</span>
                            <IoIosArrowBack className="self-center" onClick={() => this.onPageChange(this.state.page - 1)} />
                            <IoIosArrowForward className="self-center" onClick={() => this.onPageChange(this.state.page + 1)} />
                        </div>
                    </div>
                }
                {this.state.isMediaLogs && <ScreenMediaLog media={this.state.selectedMedia} onBack={() => this.setState({ isMediaLogs: false, isLogs: true })} />
                }

                <ToastContainer />
            </div>
        );
    }
};

const mapStateToProps = (state) => ({
    isLoading: state.counter.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
    counterSlice: bindActionCreators(commanActionCreator, dispatch)
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MediaLogsComponent));
