import React from "react";
import { connect } from "react-redux";
import { withRouter } from "../../router";
import { format } from 'date-fns'
import { loadImageFromDrive } from "../../services/appServices";

class CurrencyPreview extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currenciesList: this.props.currenciesSlotList[0],
            index: 0,
            timer: "",
        }
    }
    async componentDidMount() {
        this.timerList();
    }

    componentWillUnmount() {
        clearTimeout(this.state.timer);
    }

    timerList = async () => {
        this.setState({ currenciesList: this.props.currenciesSlotList[this.state.index] })
        this.state.timer = setTimeout(function () {
            if ((this.props.currenciesSlotList.length - 1) === this.state.index)
                this.state.index = 0
            else this.state.index += 1;
            this.timerList();
        }.bind(this), this.props.duration * 1000);
    }
    roundDecimal = (number, n) => {
        const multiplier = Math.pow(10, n);
        const roundedNumber = Math.round(number * multiplier) / multiplier;
        return roundedNumber;
    }
    render() {
        console.log(this.state.currenciesList);
        return (<div className="flex w-full h-full flex-col items-center bg-gray-900 justify-center gap-2">
            <span className="text-white text-2xl">{this.props.title} {this.props.baseCurrency !== "" && <span className="text-lg font-medium">(1 {this.props.baseCurrency})</span>}</span>
            <div className={this.props.isLandscape ? "grid grid-rows-4 sm: grid-flow-col gap-2" : "grid grid-col-1 gap-2"}>
                {this.state.currenciesList?.map((c) => (
                    <div key={c.id} className={"mx-2 flex self-center items-center flex-1 w-80 h-14	text-white justify-between rounded bg-gradient-to-b from-currentSecondaryColor to-currentSecondaryColor-opacity-10 p-2"}>
                        <div className="flex flex-1 flex-col">
                            <span className="text-lg font-medium">{c.id}</span>
                            <div className=""><span className=" text-sm font-normal">{c.name}</span></div>
                        </div>
                        <div className="self-center font-medium text-lg	">
                            <span>{parseFloat(c.rate).toFixed(this.props.decimal)}</span>
                        </div>
                    </div>
                ))}
            </div>
        </div>)
    };
}


const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CurrencyPreview));
