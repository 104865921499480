import React from 'react';

const HorizontalType = () => {
  return (
    <svg width="44" height="44" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M31.5 11.4231L7.5 11.4231C6.66003 11.4231 6 12.1112 6 12.987L6 25.4361C6 26.3118 6.66003 27 7.5 27H31.5C32.34 27 33 26.3118 33 25.4361V12.987C33 12.1112 32.34 11.4231 31.5 11.4231ZM31.2837 12.643C31.6137 12.643 31.8837 12.9244 31.8837 13.2685V25.1232C31.8837 25.4673 31.6138 25.7488 31.2837 25.7488H7.71322C7.38322 25.7488 7.11325 25.4673 7.11325 25.1232L7.11325 13.2685C7.11325 12.9244 7.3832 12.643 7.71322 12.643L31.2837 12.643Z" stroke="white" strokeWidth="0.608" strokeMiterlimit="10"/>
    </svg>

  )
}

export default HorizontalType;

