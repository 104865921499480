import React from "react";
import { connect } from "react-redux";
import { withRouter } from "../router";
import { ToastContainer, toast } from 'react-toastify';
import { bindActionCreators } from "@reduxjs/toolkit";
import * as commanActionCreator from '../reducers/commanReduces'
import Navbar from "./subComponents/Navbar";
import { format } from 'date-fns'
import { InputFieldWithEdit } from "./utilComponents/inputFieldAndButtons";
import { MdArrowBackIos, MdStayCurrentLandscape, MdStayCurrentPortrait } from "react-icons/md";
import { Button } from "react-bootstrap";
import { updateMedia } from "../services/mediaService";
import { createApp, fetchMeetTheTeamAppData, getGoogleSheetDataService, getGoogleSheetListService } from "../services/appServices";
import debounce from 'lodash.debounce';
import MTTIcon from "../appIcons/MTTIcon";
import { FaRegCheckCircle } from "react-icons/fa";
import MeetTeamPreview from "./subComponents/meetTeamPreview";

class MeetTheTeamAppComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isNameEdit: false,
            isLandscape: true,
            appName: "Meet The Team App",
            idDisabled: true,
            isUpdate: false,
            duration: 10,
            barerToken: "",
            googleSheetURL: "",
            isValidURL: false,
            fileId: "",
            sheetId: "",
            sheetData: [],
            appInfo: "",
            isSessionExpired: true
        }
    }
    async componentDidMount() {
        this.props.counterSlice.startLoading();
        const queryParams = new URLSearchParams(window.location.hash.substring(1));
        const accessToken = queryParams.get('access_token');
        if (accessToken)
            this.setState({ barerToken: accessToken, sheetData: [], isSessionExpired: false });

        if (this.props.router.params.appId) {
            let appInfo = await fetchMeetTheTeamAppData(this.props.router.params.appId);
            if (appInfo.success)
                this.setState({
                    appInfo: appInfo.body,
                    appName: appInfo.body.name,
                    duration: appInfo.body.timer,
                    isUpdate: true,
                    barerToken: appInfo.body.userToken,
                    googleSheetURL: appInfo.body.link,
                    sheetData: appInfo.data,
                    idDisabled: false,
                    isValidURL: true,
                    isSessionExpired: appInfo.isSessionExpired
                });
            else
                toast.error(appInfo.message, {
                    position: "top-right",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    theme: "colored",
                    progress: undefined,
                });
        }
        this.props.counterSlice.stopLoading();
    }

    updateSoccerApp = async () => {
        if (this.state.appName.trim() == "") {
            toast.error('App name should not be empty.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
            return;
        }
        this.props.counterSlice.startLoading();
        let appDTO = {
            _id: this.state.appInfo._id,
            name: this.state.appName.trim(),
            countryCode: this.state.selectedCountry,
            leagueCode: this.state.selectedLeague,
            soccerSlideduration: this.state.duration
        }
        let response = await updateMedia(appDTO);
        if (response.success) {
            toast.success('App updated successfully.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        } else {
            toast.error(response.message, {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        }
        this.props.counterSlice.stopLoading();
    }

    createMeetTheTeamApp = async () => {

        this.props.counterSlice.startLoading();
        let appDTO = {
            name: this.state.appName.trim(),
            timer: this.state.duration,
            userToken: this.state.barerToken,
            link: this.state.googleSheetURL,
            sheetId: this.state.sheetId,
            fileId: this.state.fileId,
            appType: "MEET_TEAM",
            duration: "00:00:50"
        }
        let response = await createApp(appDTO);
        if (response.success) {
            toast.success('App saved successfully.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
            this.props.router.navigate("/apps");

        } else {
            toast.error(response.message, {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        }
        this.props.counterSlice.stopLoading();
    }
    handleGoogleLogin = () => {
        const redirectUri = 'https://login.signaxe.com/app/meet_team'; // Your authorized redirect URI
        const c_ID = '387768878139-jfgcaslumld429hd3v28b1ambpgs3utp.apps.googleusercontent.com';
        const authUrl = `https://accounts.google.com/o/oauth2/auth?response_type=token&client_id=${c_ID}&redirect_uri=${redirectUri}&scope=https://www.googleapis.com/auth/spreadsheets https://www.googleapis.com/auth/drive`;

        window.location.href = authUrl;
    };
    getPathParameter = (url) => {
        var startIndex = url.indexOf('/d/') + 3; // Find the index after '/d/'
        var endIndex = url.indexOf('/', startIndex); // Find the next '/' after '/d/'
        return url.substring(startIndex, endIndex); // Assuming the path parameter is always before 'edit'
    }
    searchSheetData = debounce((sheetUrl) => {
        this.fetchSheetDataFromGoogleAPI(sheetUrl.trim())
    }, 500);

    fetchSheetDataFromGoogleAPI = async (sheetUrl) => {
        if (sheetUrl)
            this.setState({ googleSheetURL: sheetUrl });

        let fileId = this.getPathParameter(sheetUrl);
        let sheetListData = await getGoogleSheetListService(fileId, this.state.barerToken);
        if (sheetListData.error) {
            this.setState({ isValidURL: false, sheetData: [], sheetId: "", fileId: "" })
            return;
        }
        let sheetId = sheetListData.sheets[0].properties.title;
        let sheetData = await getGoogleSheetDataService(fileId, sheetId, this.state.barerToken);
        if (sheetListData.error) {
            this.setState({ isValidURL: false, sheetData: [], sheetId: "", fileId: "" })
            return;
        }
        this.setState({ isValidURL: true, sheetData: sheetData.values.slice(1), sheetId, fileId, isSessionExpired: false });
    }
    render() {
        const userRole = JSON.parse(localStorage.getItem("roles"));

        let screenSize = { height: "410px", width: "717px" };
        if (!this.state.isLandscape) {
            screenSize = { width: "55vh", height: "68vh" };
        }
        return (
            <div>
                <Navbar router={this.props.router} counterSlice={this.props.counterSlice} />
                <div className="flex bg-white border-b-1 border-slate-300 w-full min-h-min	">
                    <div className="flex-none p-4 border-1"
                        onClick={() => this.props.router.navigate("/apps")}>
                        <MdArrowBackIos size={20} />
                    </div>
                    <div className="self-center bg-dark-team rounded p-2 m-2">
                        <MTTIcon />
                    </div>
                    <div className="px-2 py-3 flex flex-col self-center grow">
                        <InputFieldWithEdit
                            isEdit={this.state.isNameEdit}
                            isRead={userRole.app["editName"].read}
                            onKeyDown={(e) => {
                                if (this.state.appName.trim() !== "" && (e.key === "Enter" || e.key === "Tab" || e.key === undefined)) {
                                    this.setState({ isNameEdit: false });
                                } else this.setState({ isNameEdit: true });
                            }}
                            inputValue={this.state.appName}
                            placeholder="Enter App Name"
                            onInputchange={(e) => { userRole.app["editName"].write && this.setState({ appName: e.target.value }); }}
                            onEditClick={() => { userRole.app["editName"].write && this.setState({ isNameEdit: !this.state.isNameEdit }) }} />
                        {this.state.isUpdate ? <span className="order-last text-sm text-gray-450">{format(new Date(), 'dd/MM/yyyy HH:mm')}, modified by {this.state.appInfo != "" && this.state.appInfo.modifiedBy && <span>{this.state.appInfo.modifiedBy.firstName} {this.state.appInfo.modifiedBy.lastName}</span>} </span> :
                            <span className="order-last text-sm text-gray-450">{format(new Date(), 'dd/MM/yyyy HH:mm')}</span>}
                    </div>
                    <div className="flex gap-4 self-center" >
                        <Button className="bg-darkBlue text-white rounded p-2 mr-4"
                            disabled={!this.state.isUpdate ? this.state.sheetData.length > 0 ? false : true : true}
                            onClick={this.state.isUpdate ? this.updateSoccerApp : this.createMeetTheTeamApp}>{this.state.isUpdate ? "Update" : "Save"}</Button>
                    </div>
                </div>
                <div className="flex" style={{ height: "82vh" }}>
                    <div className="bg-white h-full w-1/3 px-12 pt-2 gap-4 flex flex-col">
                        <div className="flex flex mr-4 ">
                            <label className="self-center flex-1">{"Login with Google *"}</label>
                            <button onClick={this.handleGoogleLogin} className="border flex gap-2 justify-center p-2 w-36 rounded" >Login{this.state.barerToken != "" && !this.state.isSessionExpired && <FaRegCheckCircle className="self-center text-green-200" />}</button>
                        </div>
                        {this.state.isSessionExpired && this.state.isUpdate && <span className="text-sm text-red-600 -mt-3">Please sign in with google session is expired</span>}
                        <div className="flex flex-col mr-4">
                            <label>{"Google Sheets URL*"}</label>
                            <div className="flex flex-1 gap-2">
                                <input type="url" id="inputo"
                                    className="self-center border-1 border-gray-300 rounded p-2 text-black my-select"
                                    value={this.state.googleSheetURL}
                                    onChange={(e) => this.setState({ googleSheetURL: e.target.value })}
                                    onBlur={(e) => this.searchSheetData(e.target.value.trim())}
                                    style={{ backgroundColor: "inherit", color: "white" }}
                                    placeholder="URL" />
                            </div>
                        </div>
                        <div className="flex flex-col gap-1 mr-4">
                            <label>{"Duration"}</label>
                            <div className="flex gap-2">
                                <input type="number" id="inputo"
                                    max={20}
                                    min={5}
                                    className="border-1 border-gray-300 rounded p-2 text-black my-select"
                                    value={this.state.duration} onChange={(e) => (e.target.value >= 5 && e.target.value < 21) && this.setState({ duration: e.target.value })}
                                    style={{ backgroundColor: "inherit", color: "white" }}
                                    placeholder="Enter duration" />
                            </div>
                        </div>
                        {/* <div className="flex justify-between mr-4">
                            <label>{"Birthday Celebrations *"}</label>
                            <div className="flex">
                                <ToggalSetButton value={this.state.is12Format} customFunction={() => this.setState({ is12Format: !this.state.is12Format })} />
                            </div>
                        </div>
                        <div className="flex justify-between mr-4">
                            <label>{"Work Anniversary Celebration *"}</label>
                            <div className="flex">
                                <ToggalSetButton value={this.state.is12Format} customFunction={() => this.setState({ is12Format: !this.state.is12Format })} />
                            </div>
                        </div> */}
                    </div>
                    <div className="flex-1">
                        <div className="flex content-end m-4">
                            <button className="p-2 flex bg-black text-white rounded-lg font-bold border-solid border-1" onClick={() => {
                                this.setState({ isLandscape: !this.state.isLandscape })
                            }}>
                                {this.state.isLandscape ? <MdStayCurrentLandscape className="self-center font-bold mr-1 " size={25} /> :
                                    <MdStayCurrentPortrait className="self-center font-bold mr-1 " size={25} />}
                            </button>
                        </div>
                        <div className="flex justify-center">
                            <div className="bg-cover flex bg-white items-center justify-center object-cover border-black border-4 shadow overflow-hidden" style={screenSize}>
                                {this.state.barerToken != "" ? this.state.googleSheetURL == "" ?
                                    <span className="text-2xl">Searching...</span> :
                                    (this.state.isValidURL && this.state.sheetData.length > 0) ? <MeetTeamPreview sheetData={this.state.sheetData} duration={this.state.duration}
                                        accessToken={this.state.barerToken} isLandscape={this.state.isLandscape} /> :
                                        <span className="text-2xl">Loading...</span> :
                                    <span className="font-semibold text-2xl">Login with Google...</span>}
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </div >)
    };
}

const mapStateToProps = (state) => ({
    isLoading: state.counter.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
    counterSlice: bindActionCreators(commanActionCreator, dispatch)
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MeetTheTeamAppComponent));
