import * as Constants from '../constant';

let fetchPlans = async (screenInfo) => {
    const token = localStorage.getItem("accessToken");

    return fetch(Constants.API_URL + "/user/plan", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            authorization: "Barear " + token
        },
    }).then((data) => data.json())
        .catch((error) => {
            return {};
        });
};

let fetchMyPlans = async (page,limit) => {
    const token = localStorage.getItem("accessToken");

    return fetch(Constants.API_URL + "/user/myplan?limit="+limit+"&page="+page, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            authorization: "Barear " + token
        },
    }).then((data) => data.json())
        .catch((error) => {
            return {};
        });
};

let fetchMyActivePlans = async () => {
    const token = localStorage.getItem("accessToken");

    return fetch(Constants.API_URL + "/user/myplan/activeLicence", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            authorization: "Barear " + token
        },
    }).then((data) => data.json())
        .catch((error) => {
            return {};
        });
};

let buyNewPlan = async (planInfo) => {
    const token = localStorage.getItem("accessToken");

    return fetch(Constants.API_URL + "/user/plan", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            authorization: "Barear " + token
        },
        body: JSON.stringify(planInfo),
    }).then((data) => data.json())
        .catch((error) => {
            return {};
        });
};

let createPaymentOrder = async (orderInfo) => {
    const token = localStorage.getItem("accessToken");

    return fetch(Constants.API_URL + "/user/payment/createOrder", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            authorization: "Barear " + token
        },
        body: JSON.stringify(orderInfo),
    }).then((data) => data.json())
        .catch((error) => {
            return {};
        });
};
export {
    fetchPlans,
    fetchMyPlans,
    buyNewPlan,
    fetchMyActivePlans,
    createPaymentOrder
};