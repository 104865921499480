import * as Constants from '../constant';

let addNewScreen = async (screenInfo) => {
    const token = localStorage.getItem("accessToken");

    return fetch(Constants.API_URL + "/screen", {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            authorization: "Barear " + token
        },
        body: JSON.stringify(screenInfo),
    }).then((data) => data.json())
        .catch((error) => {
            return {};
        });
};
let repairingService = async (screenInfo) => {
    const token = localStorage.getItem("accessToken");

    return fetch(Constants.API_URL + "/screen/repair", {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            authorization: "Barear " + token
        },
        body: JSON.stringify(screenInfo),
    }).then((data) => data.json())
        .catch((error) => {
            return {};
        });
};
let skipScreenService = async (screenInfo) => {
    const token = localStorage.getItem("accessToken");

    return fetch(Constants.API_URL + "/screen", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            authorization: "Barear " + token
        },
        body: JSON.stringify(screenInfo),
    }).then((data) => data.json())
        .catch((error) => {
            return {};
        });
};

let updateScreenData = async (screenDTO) => {
    const token = localStorage.getItem("accessToken");

    return fetch(Constants.API_URL + "/screen/update", {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            authorization: "Barear " + token
        },
        body: JSON.stringify(screenDTO),
    }).then((data) => data.json())
        .catch((error) => {
            return {};
        });
};
let setToScreenService = async (screenDTO) => {
    const token = localStorage.getItem("accessToken");

    return fetch(Constants.API_URL + "/screen/update/multiplescreen", {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            authorization: "Barear " + token
        },
        body: JSON.stringify(screenDTO),
    }).then((data) => data.json())
        .catch((error) => {
            return {};
        });
};

let fetchScreenDetails = async () => {
    const token = localStorage.getItem("accessToken");

    return fetch(Constants.API_URL + "/screen", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            authorization: "Barear " + token
        },
    }).then((data) => data.json())
        .catch((error) => {
            return {};
        });
};

let fetchScreenDetailsWithID = async (id) => {
    const token = localStorage.getItem("accessToken");

    return fetch(Constants.API_URL + "/screen/info/" + id, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            authorization: "Barear " + token
        },
    }).then((data) => data.json())
        .catch((error) => {
            return {};
        });
};
let validationTokenService = async (orgId,validationToken) => {
    const token = localStorage.getItem("accessToken");

    return fetch(Constants.API_URL + `/screen/validateToken/${orgId}/${validationToken}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            authorization: "Barear " + token
        },
    }).then((data) => data.json())
        .catch((error) => {
            return {};
        });
};
let fetchScreenLogs = async (page, limit) => {
    const token = localStorage.getItem("accessToken");

    return fetch(Constants.API_URL + "/screen/logs?limit=" + limit + "&page=" + page, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            authorization: "Barear " + token
        },
    }).then((data) => data.json())
        .catch((error) => {
            return {};
        });
};
let fetchUserActivityLogs = async (page, limit, isAcending, startDate, endDate) => {
    const token = localStorage.getItem("accessToken");

    return fetch(Constants.API_URL + "/screen/user/logs?limit=" + limit + "&page=" + page + "&isAcending=" + isAcending +
        "&startDate=" + startDate + "&endDate=" + endDate, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            authorization: "Barear " + token
        },
    }).then((data) => data.json())
        .catch((error) => {
            return {};
        });
};
let fetchMediaLogs = async (page, limit) => {
    const token = localStorage.getItem("accessToken");

    return fetch(Constants.API_URL + "/screen/mediaLogsAll?page=" + page + "&limit=" + limit, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            authorization: "Barear " + token
        },
    }).then((data) => data.json())
        .catch((error) => {
            return {};
        });
};

let updateScreenNameService = async (screenDTO) => {
    const token = localStorage.getItem("accessToken");

    return fetch(Constants.API_URL + "/screen/update/name", {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            authorization: "Barear " + token
        },
        body: JSON.stringify(screenDTO),
    }).then((data) => data.json())
        .catch((error) => {
            return {};
        });
};
let deleteScreenService = async (screenId) => {
    const token = localStorage.getItem("accessToken");

    return fetch(Constants.API_URL + "/screen?screenId=" + screenId, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            authorization: "Barear " + token
        },
    }).then((data) => data.json())
        .catch((error) => {
            return {};
        });
};

let fetchCurrentLogService = async (selectedStatus, page, limit) => {
    const token = localStorage.getItem("accessToken");
    let url = `/screen/logs/onlineScreens?status=${selectedStatus}&page=${page}&limit=${limit}`
    return fetch(Constants.API_URL + url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            authorization: "Barear " + token
        },
    }).then((data) => data.json())
        .catch((error) => {
            return {};
        });
};

let fetchScreenMediaDetailsLogs = async (id, screenId, page, limit, duration, startDate, endDate) => {
    const token = localStorage.getItem("accessToken");
    let url = `/screen/mediaLogs/${id}/screen/${screenId}?limit=${limit}&page=${page}&isToday=${duration}`
    if (duration == 2)
        url = `${url}&startDate=${startDate}&endDate=${endDate}`
    return fetch(Constants.API_URL + url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            authorization: "Barear " + token
        },
    }).then((data) => data.json())
        .catch((error) => {
            return {};
        });
};

let fetchMediaDetailsLogs = async (id, page, limit, duration, startDate, endDate) => {
    const token = localStorage.getItem("accessToken");
    let url = `/screen/mediaLogs/${id}?limit=${limit}&page=${page}&isToday=${duration}`
    if (duration == 2)
        url = `${url}&startDate=${startDate}&endDate=${endDate}`
    return fetch(Constants.API_URL + url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            authorization: "Barear " + token
        },
    }).then((data) => data.json())
        .catch((error) => {
            return {};
        });
};

let downloadMediaDetailsLogs = async (id, page, limit, duration, startDate, endDate) => {
    const token = localStorage.getItem("accessToken");
    let url = `/screen/mediaLogs/download/${id}?limit=${limit}&page=${page}&isToday=${duration}`
    if (duration == 2)
        url = `${url}&startDate=${startDate}&endDate=${endDate}`
    return fetch(Constants.API_URL + url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            authorization: "Barear " + token
        },
    }).then((response) => response.blob())
        .then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `ScreenMediaLog.xlsx`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        }).catch((error) => {
            return {};
        });
};

let fetchScreenDateLogs = async (screenId, year, month, date) => {
    const token = localStorage.getItem("accessToken");

    return fetch(Constants.API_URL + `/screen/logs/anyDate/${screenId}/${year}/${month}/${date}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            authorization: "Barear " + token
        },
    }).then((data) => data.json())
        .catch((error) => {
            return {};
        });
};

let downaloadFileService = async () => {
    const token = localStorage.getItem("accessToken");

    return fetch(Constants.API_URL + `/screen/logs/download`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            authorization: "Barear " + token
        },
    }).then((response) => response.blob())
        .then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `screenlog.xlsx`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        }).catch((error) => {
            return {};
        });
};

let downaloadFileCurrentDateService = async (type) => {
    const token = localStorage.getItem("accessToken");

    return fetch(Constants.API_URL + `/screen/logs/download/current?status=` + type, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            authorization: "Barear " + token
        },
    }).then((response) => response.blob())
        .then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            let date = new Date();
            link.setAttribute('download', `screenlog_${date}.xlsx`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        }).catch((error) => {
            return {};
        });
};

let dowanlodScreenDateLogs = async (screenName, screenId, startDate, endDate) => {
    const token = localStorage.getItem("accessToken");

    return fetch(Constants.API_URL + `/screen/logs/download/withDates/${screenId}?startDate=${startDate}&endDate=${endDate}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            authorization: "Barear " + token
        },
    }).then((response) => response.blob())
        .then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${screenName}_${startDate}_To_${endDate}.xlsx`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        }).catch((error) => {
            return {};
        });
};

let screenLogDataWithDates = async (screenId, startDate, endDate) => {
    const token = localStorage.getItem("accessToken");

    return fetch(Constants.API_URL + `/screen/logs/withDates/${screenId}?startDate=${startDate}&endDate=${endDate}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            authorization: "Barear " + token
        },
    }).then((data) => data.json())
        .catch((error) => {
            return {};
        });
};

let addScreenLicenseService = async (screenDTO) => {
    const token = localStorage.getItem("accessToken");

    return fetch(Constants.API_URL + "/screen/addLicense", {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            authorization: "Barear " + token
        },
        body: JSON.stringify(screenDTO),
    }).then((data) => data.json())
        .catch((error) => {
            return {};
        });
};

let revokeScreenLicenseService = async (screenDTO) => {
    const token = localStorage.getItem("accessToken");

    return fetch(Constants.API_URL + "/screen/revokeLicense", {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            authorization: "Barear " + token
        },
        body: JSON.stringify(screenDTO),
    }).then((data) => data.json())
        .catch((error) => {
            return {};
        });
};

let fetchUnliceScreenDetails = async () => {
    const token = localStorage.getItem("accessToken");

    return fetch(Constants.API_URL + "/screen/unlicensed", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            authorization: "Barear " + token
        },
    }).then((data) => data.json())
        .catch((error) => {
            return {};
        });
};
let downloadUserActivityLog = async (startDate, endDate) => {
    const token = localStorage.getItem("accessToken");

    return fetch(Constants.API_URL + `/screen/logs/user/activity?startDate=` + startDate + "&endDate=" + endDate, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            authorization: "Barear " + token
        },
    }).then((response) => response.blob())
        .then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            let date = new Date();
            link.setAttribute('download', `userActivity_${date}.xlsx`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        }).catch((error) => {
            return {};
        });
};
export {
    addNewScreen,
    skipScreenService,
    fetchScreenDetails,
    updateScreenData,
    fetchScreenDetailsWithID,
    fetchScreenLogs,
    updateScreenNameService,
    deleteScreenService,
    fetchCurrentLogService,
    fetchMediaLogs,
    fetchMediaDetailsLogs,
    fetchScreenDateLogs,
    repairingService,
    setToScreenService,
    fetchUserActivityLogs,
    downaloadFileService,
    dowanlodScreenDateLogs,
    downaloadFileCurrentDateService,
    screenLogDataWithDates,
    addScreenLicenseService,
    revokeScreenLicenseService,
    downloadMediaDetailsLogs,
    fetchScreenMediaDetailsLogs,
    fetchUnliceScreenDetails,
    downloadUserActivityLog,
    validationTokenService
};