import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

const FileSelector = (props) => {
  let { onFileSelect } = props;

  const onDrop = useCallback(acceptedFiles => {
    onFileSelect(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <>
      <div
        {...getRootProps()}
        style={{
          padding: '20px',
          textAlign: 'center',
          cursor: 'pointer',
          backgroundColor: isDragActive ? '#f8f8f8' : 'transparent',
        }}>
        <input id="fileInput" accept="image/*" {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the files here ...</p>
        ) : (
          <p>Drag and drop files here, or click to select files</p>
        )}
           
      </div>
      <div><button className='bg-darkBlue rounded text-white p-2' onClick={() => document.getElementById('fileInput').click()}>Select Files</button></div>
    </>
  );
};

export default FileSelector;
