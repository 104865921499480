import React from "react";
import { connect } from "react-redux";
import { withRouter } from "../router";
import { ToastContainer, toast } from 'react-toastify';
import { bindActionCreators } from "@reduxjs/toolkit";
import * as commanActionCreator from '../reducers/commanReduces'
import Navbar from "./subComponents/Navbar";
import { format, parseISO } from 'date-fns'
import { DataIsEmptyWithoutButton, InputFieldWithEdit, InputFiledWithSearch, LatLongInputFiled } from "./utilComponents/inputFieldAndButtons";
import { MdArrowBackIos, MdStayCurrentLandscape, MdStayCurrentPortrait } from "react-icons/md";
import WeatherIcon from "../appIcons/WeatherIcon";
import { FaDotCircle, FaRegCircle, FaSearch } from "react-icons/fa";
import { createApp, fetchAppData, fetchWeatherInfo } from "../services/appServices";
import { Button } from "react-bootstrap";
import { updateMedia } from "../services/mediaService";

class WeatherAppDetailsComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isNameEdit: false,
            appName: "WeatherApp",
            isCity: true,
            isLandscape: true,
            city1: "",
            city2: "",
            city3: "",
            city1Info: "",
            city2Info: "",
            city3Info: "",
            selectedTemp: "C",
            lat1: "",
            long1: "",
            lat2: "",
            long2: "",
            lat3: "",
            long3: "",
            appInfo: "",
            isUpdate: false
        }
    }
    async componentDidMount() {
        if (this.props.router.params.appId) {
            this.props.counterSlice.startLoading();
            let appInfo = await fetchAppData(this.props.router.params.appId);
            this.populateInfo(appInfo.body);
            this.props.counterSlice.stopLoading();
        }
    }

    populateInfo = (data) => {
        this.setState({
            appInfo: data,
            appName: data.name,
            selectedTemp: data.tempUnit,
            isUpdate: true,
            isCity: data.withName
        });
        if (data.withName) {
            this.setState({
                city1: data.weatherInfo.city1,
                city2: data.weatherInfo.city2,
                city3: data.weatherInfo.city3
            });
            this.searchCity("city1Info", data.weatherInfo.city1);
            this.searchCity("city2Info", data.weatherInfo.city2);
            this.searchCity("city3Info", data.weatherInfo.city3);

        } else {
            this.setState({
                lat1: data.weatherInfo.lat1,
                long1: data.weatherInfo.lat1,
                lat2: data.weatherInfo.lat2,
                long2: data.weatherInfo.lat2,
                lat3: data.weatherInfo.lat3,
                long3: data.weatherInfo.lat3,
            })
            if (data.weatherInfo.lat1)
                this.searchCity("city1Info", data.weatherInfo.lat1 + "," + data.weatherInfo.lat1);
            if (data.weatherInfo.lat2)
                this.searchCity("city2Info", data.weatherInfo.lat2 + "," + data.weatherInfo.lat2);
            if (data.weatherInfo.lat3)
                this.searchCity("city3Info", data.weatherInfo.lat3 + "," + data.weatherInfo.lat3);
        }
    }

    onInputChange = (type, value) => {
        this.setState({ [type]: value });
    }
    searchCity = async (type, cityName) => {
        if (cityName && cityName != "") {
            this.props.counterSlice.startLoading();
            let cityWeather = await fetchWeatherInfo(cityName);
            this.props.counterSlice.stopLoading();

            if (cityWeather.error) {
                toast.error(cityWeather.error.message, {
                    position: "top-right",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    theme: "colored",
                    progress: undefined,
                });
                return;
            }
            this.setState({ [type]: cityWeather });
        }
    }

    createWeatherApp = async () => {

        let weatherInfo = {}
        if (this.state.isCity) {
            if (this.state.city1.trim() == "") {
                toast.error('City 1 should not be empty.', {
                    position: "top-right",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    theme: "colored",
                    progress: undefined,
                });
                return;
            }
            weatherInfo.city1 = this.state.city1
            weatherInfo.city2 = this.state.city2.trim() != "" ? this.state.city2 : undefined;
            weatherInfo.city3 = this.state.city3.trim() != "" ? this.state.city3 : undefined;
        } else {
            if (this.state.lat1.trim() == "" || this.state.long1.trim() == "") {
                toast.error('Latitute Longitude should not be empty.', {
                    position: "top-right",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    theme: "colored",
                    progress: undefined,
                });
                return;
            }
            weatherInfo.lat1 = this.state.lat1;
            weatherInfo.long1 = this.state.long1;
            weatherInfo.lat2 = this.state.lat2.trim() != "" ? this.state.lat2 : undefined;
            weatherInfo.long2 = this.state.long2.trim() != "" ? this.state.long2 : undefined;
            weatherInfo.lat3 = this.state.lat3.trim() != "" ? this.state.lat3 : undefined;
            weatherInfo.long3 = this.state.long3.trim() != "" ? this.state.long3 : undefined;
        }
        this.props.counterSlice.startLoading();

        let appDTO = {
            name: this.state.appName.trim(),
            withName: this.state.isCity,
            weatherInfo: weatherInfo,
            tempUnit: this.state.selectedTemp,
            appType: "WEATHER"
        }
        let response = await createApp(appDTO);
        if (response.success) {

            toast.success('Weather app saved successfully.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
            this.props.router.navigate("/apps");

        } else {
            toast.error('Error while saving weather app.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        }
        this.props.counterSlice.stopLoading();
    }

    updateWeatherApp = async () => {
        if (this.state.appName.trim() == "") {
            toast.error('App name should not be empty.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
            return;
        }
        let weatherInfo = {}
        if (this.state.isCity) {
            if (this.state.city1.trim() == "") {
                toast.error('City 1 should not be empty.', {
                    position: "top-right",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    theme: "colored",
                    progress: undefined,
                });
                return;
            }
            weatherInfo.city1 = this.state.city1
            weatherInfo.city2 = this.state.city2 && this.state.city2.trim() != "" ? this.state.city2 : undefined;
            weatherInfo.city3 = this.state.city3 && this.state.city3.trim() != "" ? this.state.city3 : undefined;
        } else {
            if (this.state.lat1.trim() == "" || this.state.long1.trim() == "") {
                toast.error('City 1 Latitute Longitude should not be empty.', {
                    position: "top-right",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    theme: "colored",
                    progress: undefined,
                });
                return;
            }
            weatherInfo.lat1 = this.state.lat1;
            weatherInfo.long1 = this.state.long1;
            weatherInfo.lat2 = this.state.lat2 && this.state.lat2.trim() != "" ? this.state.lat2 : undefined;
            weatherInfo.long2 = this.state.long2 && this.state.long2.trim() != "" ? this.state.long2 : undefined;
            weatherInfo.lat3 = this.state.lat3 && this.state.lat3.trim() != "" ? this.state.lat3 : undefined;
            weatherInfo.long3 = this.state.long3 && this.state.long3.trim() != "" ? this.state.long3 : undefined;
        }
        this.props.counterSlice.startLoading();
        let appDTO = {
            _id: this.state.appInfo._id,
            name: this.state.appName.trim(),
            withName: this.state.isCity,
            weatherInfo: weatherInfo,
            tempUnit: this.state.selectedTemp
        }
        let response = await updateMedia(appDTO);
        if (response.success) {

            toast.success('weather app updated successfully.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        } else {
            toast.error('Error while updating weather app.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        }
        this.props.counterSlice.stopLoading();
    }
    SingleCity = () => (<div className="text-white flex flex-1 w-20 items-center  bg-gradient-to-b justify-center from-blueG-100 via-blueG-200 to-blueG-300 px-4 py-8" >
        <div className="basis-1/3 flex flex-col gap-4 items-center">
            <span className="font-bold text-lg">{this.state.city1Info.location.name}, {this.state.city1Info.location.country}</span>
            <div><img src={this.state.city1Info.current.condition.icon} alt="Loading..."></img></div>
        </div>
        <div className="flex flex-col gap-4" >
            <div className="flex-1 flex gap-4">
                <div className="flex-1 flex"><span className="font-bold text-7xl">{this.state.selectedTemp === "C" ? this.state.city1Info.current.temp_c : this.state.city1Info.current.temp_f}</span>
                    {this.state.selectedTemp === "C" ? <span className="text-lg">&#8451;</span> : <span className="text-lg">&#8457;</span>}
                </div>
                <div className="">
                    <span>
                        {format(parseISO(this.state.city1Info.current.last_updated), "d, EEEE yyyy, h:mm a")}
                    </span>
                    <br />
                    <span>
                        {this.state.city1Info.current.condition.text}
                    </span>
                </div>
            </div>
            <div className="flex font-light	text-light-gold text-base text-center">
                <div className="flex-1">Percipation <br /> {this.state.city1Info.current.precip_mm} mm</div>
                <div className="flex-1">Humidity <br />{this.state.city1Info.current.humidity} %</div>
                <div className="flex-1">Wind <br /> {this.state.city1Info.current.wind_kph} km/h
                </div>
            </div>
            <div className="flex text-base font-normal gap-3 text-gray-20">
                {this.state.city1Info.forecast.forecastday?.map((f) =>
                (<div className="flex-1 text-center">
                    <img src={f.day.condition.icon} alt="Loading..." />
                    <span className="flex justify-center">{format(parseISO(f.date), "E")}</span>
                    {this.state.selectedTemp === "C" ? <span className="flex item-start">{f.day.mintemp_c}<span className="text-xs">&#8451;</span>&nbsp;&nbsp;{f.day.maxtemp_c}<span className="text-xs">&#8451;</span></span> :
                        <span className="flex item-start">{f.day.mintemp_f}<span className="text-xs">&#8457;</span>&nbsp;{f.day.maxtemp_f}<span className="text-xs">&#8457;</span></span>}
                </div>))
                }
            </div>
        </div>
    </div>);
    render() {

        let screenSize = { height: "410px", width: "717px" }
        if (!this.state.isLandscape)
            screenSize = { width: "45vh", height: "70vh", }

        let LandscapeCity = ({ cityInfo, gradient }) => (
            <div className={"text-white flex-1 items-center flex flex-col justify-center gap-4 " + gradient}>
                <span className="font-bold text-xl">{cityInfo.location.name}, {cityInfo.location.country}</span>
                <img src={cityInfo.current.condition.icon} className="h-20 w-20 item-center" alt="Loading..." />
                <div className="flex justify-around	gap-3">
                    <div className="flex-1 flex"><span className="font-bold text-lg">{this.state.selectedTemp === "C" ? cityInfo.current.temp_c : cityInfo.current.temp_f}</span>
                        {this.state.selectedTemp === "C" ? <span className="text-sm">&#8451;</span> : <span className="text-sm">&#8457;</span>}
                    </div>
                    <div className="text-xs">
                        <span>
                            {format(parseISO(cityInfo.current.last_updated), "d, EEEE yyyy, h:mm a")}
                        </span>
                        <br />
                        <span>
                            {cityInfo.current.condition.text}
                        </span>
                    </div>
                </div>
                <div className="flex font-light	text-light-gold text-xs text-center gap-2">
                    <div>Percipation <br /> {cityInfo.current.precip_mm} mm</div>
                    <div>Humidity <br />{cityInfo.current.humidity} %</div>
                    <div>Wind <br /> {cityInfo.current.wind_kph} km/h
                    </div>
                </div>
                <div className="flex text-xs font-normal gap-2 text-gray-20">
                    {cityInfo.forecast.forecastday?.map((f, i) => {
                        if (i >= 1)
                            return (<div className="flex-1 flex-col flex items-center text-center text-xs gap-2" >
                                <img src={f.day.condition.icon} className="w-8 h-8" alt="Loading..." />
                                <span className="flex ">{format(parseISO(f.date), "E")}</span>
                                {<span className="flex item-start">{this.state.selectedTemp === "C" ? f.day.mintemp_c : f.day.mintemp_f}<span className="-mt-2" style={{ fontSize: "8px" }}>o</span>&nbsp;{this.state.selectedTemp === "C" ? f.day.maxtemp_c : f.day.maxtemp_f}<span className="-mt-2" style={{ fontSize: "8px" }}>o</span></span>}
                            </div>)
                    })
                    }
                </div>
            </div>
        );

        let VerticalView = ({ cityInfo }) => (
            <>
                <span className="font-bold ">{cityInfo.location.name}, {cityInfo.location.country}</span>
                <div className="flex justify-center"><img src={cityInfo.current.condition.icon} alt="Loading..."></img></div>
                <div className="flex gap-4 justify-center">
                    <div className="justify-center self-center flex"><span className="font-bold text-2xl">{this.state.selectedTemp === "C" ? cityInfo.current.temp_c : cityInfo.current.temp_f}</span>
                        {this.state.selectedTemp === "C" ? <span className="text-sm">&#8451;</span> : <span className="text-sm">&#8457;</span>}
                    </div>
                    <div className="text-xs">
                        <span>
                            {format(parseISO(cityInfo.current.last_updated), "d, EEEE yyyy, h:mm a")}
                        </span>
                        <br />
                        <span>
                            {cityInfo.current.condition.text}
                        </span>
                    </div>
                </div>
            </>
        );
        const userRole = JSON.parse(localStorage.getItem("roles"));

        return (
            <div>
                <Navbar router={this.props.router} counterSlice={this.props.counterSlice} />
                {/* HeaderInfo Bar Start */}
                <div className="flex bg-white border-b-1 border-slate-300 w-full min-h-min	">
                    <div className="flex-none p-4 border-1"
                        onClick={() => { this.props.router.navigate(-1) }}>
                        <MdArrowBackIos size={20} />
                    </div>
                    <div className="self-center bg-dark-team rounded p-2 m-2">
                        <WeatherIcon style="fill-white " />
                    </div>
                    <div className="px-2 py-3 flex flex-col self-center grow">
                        <InputFieldWithEdit
                            isEdit={this.state.isNameEdit}
                            isRead={userRole.app["editName"].read}
                            onKeyDown={(e) => {
                                if (this.state.appName.trim() !== "" && (e.key === "Enter" || e.key === "Tab" || e.key === undefined)) {
                                    this.setState({ isNameEdit: false });
                                } else this.setState({ isNameEdit: true });
                            }}
                            inputValue={this.state.appName}
                            placeholder="Enter Weather App Name"
                            onInputchange={(e) => { userRole.app["editName"].write && this.setState({ appName: e.target.value }); }}
                            onEditClick={() => { userRole.app["editName"].write && this.setState({ isNameEdit: !this.state.isNameEdit }) }} />
                        {this.state.isUpdate ? <span className="order-last text-sm text-gray-450">{format(new Date(), 'dd/MM/yyyy HH:mm')}, modified by {this.state.appInfo != "" && this.state.appInfo.modifiedBy && <span>{this.state.appInfo.modifiedBy.firstName} {this.state.appInfo.modifiedBy.lastName}</span>} </span> :
                            <span className="order-last text-sm text-gray-450">{format(new Date(), 'dd/MM/yyyy HH:mm')}</span>}
                    </div>
                    <div className="flex gap-4 self-center" >
                        <Button className="bg-darkBlue text-white rounded p-2 mr-4" disabled={this.state.city1Info === ""} onClick={this.state.isUpdate ? this.updateWeatherApp : this.createWeatherApp}>{this.state.isUpdate ? "Update" : "Save"}</Button>
                    </div>
                </div>
                {/* HeaderInfo Bar End */}
                <div className="flex" style={{ height: "83vh" }}>
                    {/* weatherInfo Bar Start */}
                    <div className="bg-white h-full w-1/3 px-12 pt-5 gap-4 flex flex-col">
                        <div onChange={this.onChangeValue} className="flex gap-4">
                            <button className="flex gap-3" onClick={() => this.setState({
                                isCity: true, city1: "",
                                city2: "",
                                city3: "",
                                city1Info: "",
                                city2Info: "",
                                city3Info: "",
                            })}>{this.state.isCity ? <FaDotCircle className="self-center text-currentSecondaryColor" /> : <FaRegCircle className="self-center" />}City Name</button>
                            <button className="flex gap-3" onClick={() => this.setState({
                                isCity: false,
                                lat1: "",
                                long1: "",
                                lat2: "",
                                long2: "",
                                lat3: "",
                                long3: "", city1Info: "",
                                city2Info: "",
                                city3Info: "",
                            })}>{!this.state.isCity ? <FaDotCircle className="self-center text-currentSecondaryColor" /> : <FaRegCircle className="self-center" />} Coordinate</button>
                        </div>
                        <div className="flex justify-between">
                            <label className="self-center text-black font-bolt">Temperature Unit *</label>
                            <select className="border-1 basis-40  p-2 rounded mr-4" value={this.state.selectedTemp} onChange={(e) =>
                                this.setState({ selectedTemp: e.target.value })}>
                                <option value="C">Celsius</option>
                                <option value="F">Fahrenheit</option>
                            </select>
                        </div>
                        {this.state.isCity ? <>
                            <InputFiledWithSearch lable={"City 1 *"}
                                value={this.state.city1}
                                isDisabled={false}
                                onChnage={(e) => this.onInputChange("city1", e.target.value)}
                                onClickSearch={() => this.state.city1.trim() !== "" && this.searchCity("city1Info", this.state.city1)} />
                            <InputFiledWithSearch lable={"City 2"}
                                value={this.state.city2}
                                isOptional={true}
                                isDisabled={this.state.city1.trim() == ""}
                                onChnage={(e) => this.onInputChange("city2", e.target.value)}
                                onClickSearch={() => this.state.city2.trim() !== "" && this.searchCity("city2Info", this.state.city2)}
                                onClickClear={() => this.setState({ city2: "", city2Info: "" })} />
                            <InputFiledWithSearch lable={"City 3"}
                                value={this.state.city3}
                                isOptional={true}
                                isDisabled={this.state.city1.trim() == ""}
                                onChnage={(e) => this.onInputChange("city3", e.target.value)}
                                onClickSearch={() => this.state.city3.trim() !== "" && this.searchCity("city3Info", this.state.city3)}
                                onClickClear={() => this.setState({ city3: "", city3Info: "" })} />
                        </> : <>
                            <LatLongInputFiled lable={"City 1*"}
                                latvalue={this.state.lat1}
                                longvalue={this.state.long1}
                                onLatChnage={(e) => this.onInputChange("lat1", e.target.value)}
                                onLongChnage={(e) => this.onInputChange("long1", e.target.value)}
                                isOptional={false}
                                onClickSearch={() => this.state.lat1.trim() !== "" && this.state.long1.trim() !== "" && this.searchCity("city1Info", this.state.lat1 + "," + this.state.long1)}
                                onClickClear={() => this.setState({ city1: "", lat1: "", long1: "", city3Info: "" })} />
                            <LatLongInputFiled lable={"City 2"}
                                latvalue={this.state.lat2}
                                longvalue={this.state.long2}
                                isOptional={true}

                                onLatChnage={(e) => this.onInputChange("lat2", e.target.value)}
                                onLongChnage={(e) => this.onInputChange("long2", e.target.value)}
                                onClickSearch={() => this.state.lat2.trim() !== "" && this.state.long2.trim() !== "" &&
                                    this.searchCity("city2Info", this.state.lat2 + "," + this.state.long2)}
                                onClickClear={() => this.setState({ city2: "", lat2: "", long2: "", city2Info: "" })}
                            />
                            <LatLongInputFiled lable={"City 3"}
                                latvalue={this.state.lat3}
                                longvalue={this.state.long3}
                                isOptional={true}

                                onLatChnage={(e) => this.onInputChange("lat3", e.target.value)}
                                onLongChnage={(e) => this.onInputChange("long3", e.target.value)}
                                onClickSearch={() => this.state.lat3.trim() !== "" && this.state.long3.trim() !== "" && this.searchCity("city3Info", this.state.lat3 + "," + this.state.long3)}
                                onClickClear={() => this.setState({ city3: "", lat3: "", long3: "", city3Info: "" })}
                            />
                        </>}
                    </div>
                    {/* weatherInfo Bar End */}
                    <div className="flex-1">
                        <div className="flex content-end">
                            <button className="p-2 flex bg-black text-white rounded-lg font-bold border-solid border-1" onClick={() => this.setState({ isLandscape: !this.state.isLandscape })}>
                                {this.state.isLandscape ? <MdStayCurrentLandscape className="self-center font-bold mr-1 " size={25} /> :
                                    <MdStayCurrentPortrait className="self-center font-bold mr-1 " size={25} />}
                            </button>
                        </div>
                        <div className="flex justify-center">
                            <div className="bg-cover bg-center object-cover border-black border-4 shadow overflow-hidden" style={screenSize}>
                                {(this.state.city1Info === "" && this.state.city2Info === "" && this.state.city3Info === "") ? <DataIsEmptyWithoutButton url={process.env.PUBLIC_URL + "/blankScreenIcon.svg"}
                                    title="App instance preview"
                                    body1="Edit and save the configuration to preview this app instance" /> :
                                    <>{this.state.isLandscape ?
                                        <div className="flex " style={screenSize}>
                                            {this.state.city1Info !== "" && this.state.city2Info === "" && this.state.city3Info === "" &&
                                                (<div className="text-white flex flex-1 items-center  bg-gradient-to-b justify-center from-blueG-100 via-blueG-200 to-blueG-300 px-4 py-8" >
                                                    <div className="basis-1/3 flex flex-col gap-4 items-center">
                                                        <span className="font-bold text-lg">{this.state.city1Info.location.name}, {this.state.city1Info.location.country}</span>
                                                        <div><img src={this.state.city1Info.current.condition.icon} alt="Loading..."></img></div>
                                                    </div>
                                                    <div className="flex flex-col gap-4" >
                                                        <div className="flex-1 flex gap-4">
                                                            <div className="flex-1 flex"><span className="font-bold text-7xl">{this.state.selectedTemp === "C" ? this.state.city1Info.current.temp_c : this.state.city1Info.current.temp_f}</span>
                                                                {this.state.selectedTemp === "C" ? <span className="text-lg">&#8451;</span> : <span className="text-lg">&#8457;</span>}
                                                            </div>
                                                            <div className="">
                                                                <span>
                                                                    {format(parseISO(this.state.city1Info.current.last_updated), "d, EEEE yyyy, h:mm a")}
                                                                </span>
                                                                <br />
                                                                <span>
                                                                    {this.state.city1Info.current.condition.text}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="flex font-light	text-light-gold text-base text-center">
                                                            <div className="flex-1">Percipation <br /> {this.state.city1Info.current.precip_mm} mm</div>
                                                            <div className="flex-1">Humidity <br />{this.state.city1Info.current.humidity} %</div>
                                                            <div className="flex-1">Wind <br /> {this.state.city1Info.current.wind_kph} km/h
                                                            </div>
                                                        </div>
                                                        <div className="flex text-base font-normal gap-3 text-gray-20">
                                                            {this.state.city1Info.forecast.forecastday?.map((f, i) =>
                                                            (<div className="flex-1 text-center" key={i}>
                                                                <img src={f.day.condition.icon} alt="Loading..." />
                                                                <span className="flex justify-center">{format(parseISO(f.date), "E")}</span>
                                                                {this.state.selectedTemp === "C" ? <span className="flex item-start">{f.day.mintemp_c}<span className="text-xs">&#8451;</span>&nbsp;&nbsp;{f.day.maxtemp_c}<span className="text-xs">&#8451;</span></span> :
                                                                    <span className="flex item-start">{f.day.mintemp_f}<span className="text-xs">&#8457;</span>&nbsp;{f.day.maxtemp_f}<span className="text-xs">&#8457;</span></span>}
                                                            </div>))
                                                            }
                                                        </div>
                                                    </div>
                                                </div>)
                                            }
                                            {(this.state.city1Info !== "" && (this.state.city2Info !== "" || this.state.city3Info !== "")) &&
                                                <LandscapeCity cityInfo={this.state.city1Info} gradient={"bg-gradient-to-b from-blueG-100 via-blueG-200 to-blueG-300"} />
                                            }
                                            {(this.state.city2Info !== "") &&
                                                <LandscapeCity cityInfo={this.state.city2Info} gradient={"bg-gradient-to-b from-lightblueG-100 to-lightblueG-200"} />
                                            }
                                            {(this.state.city3Info !== "") &&
                                                <LandscapeCity cityInfo={this.state.city3Info} gradient={"bg-gradient-to-b from-ultrablueG-100 to-ultrablueG-200"} />
                                            }
                                        </div> :
                                        <div className="flex " style={screenSize}>
                                            {this.state.city1Info !== "" && this.state.city2Info === "" && this.state.city3Info === "" &&
                                                <div className="p-4 text-white w-full h-full text-center flex flex-col bg-gradient-to-b from-blueG-100 via-blueG-200 to-blueG-300 gap-4" >
                                                    <span className="font-bold text-lg">{this.state.city1Info.location.name}, {this.state.city1Info.location.country}</span>
                                                    <div className="flex justify-center"><img src={this.state.city1Info.current.condition.icon} alt="Loading..."></img></div>
                                                    <div className="flex gap-4">
                                                        <div className="flex-1 self-center flex"><span className="font-bold text-4xl">{this.state.selectedTemp === "C" ? this.state.city1Info.current.temp_c : this.state.city1Info.current.temp_f}</span>
                                                            {this.state.selectedTemp === "C" ? <span className="text-sm">&#8451;</span> : <span className="text-sm">&#8457;</span>}
                                                        </div>
                                                        <div className="text-sm">
                                                            <span>
                                                                {format(parseISO(this.state.city1Info.current.last_updated), "d, EEEE yyyy, h:mm a")}
                                                            </span>
                                                            <br />
                                                            <span>
                                                                {this.state.city1Info.current.condition.text}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="flex font-light justify-around text-light-gold text-base text-center ">
                                                        <div>Percipation <br /> {this.state.city1Info.current.precip_mm} mm</div>
                                                        <div>Humidity <br />{this.state.city1Info.current.humidity} %</div>
                                                        <div>Wind <br /> {this.state.city1Info.current.wind_kph} km/h</div>
                                                    </div>
                                                    <div className="flex  font-normal gap-2 text-gray-20">
                                                        {this.state.city1Info.forecast.forecastday?.map((f, i) => {
                                                            if (i >= 1)
                                                                return (<div className="flex-1 flex-col flex items-center text-center gap-2" >
                                                                    <img src={f.day.condition.icon} className="w-10 h-10" alt="Loading..." />
                                                                    <span className="flex ">{format(parseISO(f.date), "E")}</span>
                                                                    {<span className="flex item-start">{this.state.selectedTemp === "C" ? f.day.mintemp_c : f.day.mintemp_f}<span className="-mt-2" style={{ fontSize: "8px" }}>o</span>&nbsp;{this.state.selectedTemp === "C" ? f.day.maxtemp_c : f.day.maxtemp_f}<span className="-mt-2" style={{ fontSize: "8px" }}>o</span></span>}
                                                                </div>)
                                                        })}
                                                    </div>
                                                </div>
                                            }
                                            {(this.state.city1Info !== "" && (this.state.city2Info !== "" || this.state.city3Info !== "")) &&
                                                <div className="text-white text-center flex flex-col flex-1" >
                                                    <div className="flex-1 py-2 px-4 bg-gradient-to-b from-blueG-100 via-blueG-200 to-blueG-300">
                                                        <VerticalView cityInfo={this.state.city1Info} />
                                                    </div>
                                                    {this.state.city2Info !== "" &&
                                                        <div className="flex-1 py-2 px-4 bg-gradient-to-b from-lightblueG-100 to-lightblueG-200">
                                                            <VerticalView cityInfo={this.state.city2Info} />
                                                        </div>}
                                                    {this.state.city3Info !== "" && <div className=" flex-1 py-2 px-4 bg-gradient-to-b from-lightblueG-100 to-lightblueG-200">
                                                        <VerticalView cityInfo={this.state.city3Info} />
                                                    </div>}

                                                </div>
                                            }
                                        </div>}</>}
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer />

            </div>)
    };
}

const mapStateToProps = (state) => ({
    isLoading: state.counter.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
    counterSlice: bindActionCreators(commanActionCreator, dispatch)
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WeatherAppDetailsComponent));
/*
                                                
                                                

                                                
*/