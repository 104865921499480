import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { MdSearch } from "react-icons/md";
import { FaRegCheckSquare } from "react-icons/fa";
import { format } from "date-fns";

const Searchthis = (e, plval, mediaList) => {
    console.log(e.target.value + " " + plval);
    //mediaList = mediaList.filter((item) => return item.toLowerCase().indexOf(query.toLowerCase()) !== -1));
    mediaList = mediaList.filter((item) => {
        if (item.name.toLowerCase().includes(e.target.value.toLowerCase())) {
            console.log(item);
            return item;
        }
    })

}

const ChannelAddPlaylistContent = (props) => {
    let [placeholdervalue, setplaceholdervalue] = useState('');

    let { mediaList, selectedMediaFile, type, searchMedia, onMediasearch } = props;

    useEffect(() => {
        if (type === "PLAYLIST") {
            setplaceholdervalue("Search playlist");
        }
        else {
            setplaceholdervalue("Search channel");
        }
    })
    return (
        <div>
            <div className="flex mt-2">
                <div className="grow px-2 relative text-sm">
                    <MdSearch opacity={0.5} size={25} className="absolute self-center m-2" />
                    <Form.Control
                        size="lg"
                        type="search"
                        placeholder={placeholdervalue}
                        className="me-1 px-5 text-sm"
                        aria-label="Search"
                        value={searchMedia}
                        onChange={onMediasearch}
                    />
                </div>
            </div>
            <div className="overflow-y-auto" style={{ height: "65vh" }}>
                {props.mediaList.map((m, i) => {
                    return (<div key={i} className={!m.isSelected ? "flex px-3 cursor-pointer m-2 hover:shadow-md rounded-lg bg-white" : "flex px-3 cursor-pointer m-2 hover:shadow-md rounded-lg bg-gray-200"} style={{ paddingBottom: "10px" }} onClick={() => selectedMediaFile(m, i)}>
                        <div className="flex flex-1">
                            <div className={"rounded-lg cursor-pointer border-solid self-center from-cyan-500 to-blue-500"}>
                                {type === "PLAYLIST" ? <svg width="22" className="self-center" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.14627 14.8703L5.5 14.4519V10.3227L6.14627 9.9043L10.7298 11.9689V12.8057L6.14627 14.8703ZM6.4167 11.032V13.7417L9.42532 12.3864L6.4167 11.032Z" fill="black" />
                                    <path d="M14.6626 19.2698H1.83707C0.824115 19.2698 0 18.4449 0 17.431V7.34472C0 6.33078 0.824115 5.50586 1.83707 5.50586H14.6626C15.6747 5.50586 16.4997 6.33078 16.4997 7.34472V17.431C16.4997 18.4449 15.6756 19.2698 14.6626 19.2698ZM1.83707 6.42346C1.32922 6.42346 0.916702 6.83637 0.916702 7.34472V17.431C0.916702 17.9393 1.32922 18.3522 1.83707 18.3522H14.6626C15.1705 18.3522 15.583 17.9393 15.583 17.431V7.34472C15.583 6.83637 15.1705 6.42346 14.6626 6.42346H1.83707Z" fill="black" />
                                    <path d="M19.2506 16.5159H18.3339V4.59082C18.3339 4.08247 17.9214 3.66955 17.4136 3.66955H2.75V2.75195H17.4136C18.4265 2.75195 19.2506 3.57687 19.2506 4.59082V16.5159Z" fill="black" />
                                    <path d="M22.0006 13.764H21.0839V1.83886C21.0839 1.33052 20.6714 0.917597 20.1636 0.917597H5.5V0H20.1636C21.1765 0 22.0006 0.82492 22.0006 1.83886V13.764Z" fill="black" />
                                </svg> :
                                    <img className="w-8 h-8 self-center"
                                        style={{ marginTop: "5px" }}
                                        src={process.env.PUBLIC_URL + "/lighter.svg"} />}
                            </div>
                            <div className="flex flex-col ml-4">
                                <div className="w-10 xl:w-52 lg:w-40 md:w-32 inline-block truncate text-black text-sm text-gray-500 mt-2"><span>{m.name}</span></div>
                                <span className="text-gray-400 text-xs">{format(new Date(m.createdOn), 'dd/MM/yyyy HH:mm')}</span>
                            </div>
                        </div>
                        <div className="self-center"  >{m.isSelected && <FaRegCheckSquare />}</div>
                    </div>
                    )
                })}
            </div>
        </div>
    );
};

export default ChannelAddPlaylistContent;
