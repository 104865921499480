import React from 'react';

const RssIcon = (props) => {
    let { style } = props;
    return (
        <svg width="30" height="30" className={style} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.14116 23.7178C1.40989 23.7178 0 25.1277 0 26.859C0 28.5903 1.40989 30.0001 3.14116 30.0001C4.87243 30.0001 6.28232 28.5903 6.28232 26.859C6.28232 25.1277 4.87243 23.7178 3.14116 23.7178ZM3.14116 28.9326C1.99764 28.9326 1.06746 28.0025 1.06746 26.8589C1.06746 25.7164 1.99764 24.7871 3.14116 24.7871C4.28468 24.7871 5.21392 25.7164 5.21392 26.8589C5.21392 28.0025 4.28468 28.9326 3.14116 28.9326Z" />
            <path d="M3.16131 11.8584H0.539239C0.242637 11.8584 0 12.0982 0 12.3921V17.607C0 17.9018 0.242637 18.1407 0.539239 18.1407H3.14116C5.47026 18.1407 7.66021 19.048 9.30635 20.6951C10.9534 22.3403 11.8598 24.5284 11.8598 26.8584V29.4512C11.8598 29.7487 12.0996 29.9904 12.3935 29.9904H17.6075C17.9014 29.9904 18.1412 29.7487 18.1412 29.4512V26.8584C18.1412 18.5985 11.4212 11.8703 3.16131 11.8584ZM17.0728 26.8584V28.9229H12.9282V26.8584C12.9282 24.2446 11.9092 21.7873 10.0607 19.9388C8.2114 18.0904 5.75409 17.0723 3.14116 17.0723H1.06752V12.9268H3.1595C5.03907 12.9296 6.86193 13.2985 8.57668 14.0264C10.2329 14.7286 11.7206 15.7339 12.9996 17.0137C14.2776 18.2918 15.2802 19.7805 15.9805 21.4403C16.7066 23.1542 17.0728 24.977 17.0728 26.8584Z"  />
            <path d="M3.16131 0H0.539239C0.242637 0 0 0.239883 0 0.533732V5.74765C0 6.04243 0.242637 6.28232 0.539239 6.28232H3.14116C5.92343 6.28232 8.61875 6.82525 11.1493 7.89546C12.3679 8.41179 13.5425 9.04906 14.6411 9.7915C15.7325 10.5285 16.7588 11.3763 17.6917 12.3083C18.6264 13.2422 19.4733 14.2694 20.2084 15.3588C20.9509 16.4584 21.589 17.6329 22.1054 18.8506C23.1757 21.3848 23.7186 24.0792 23.7186 26.8588V29.4516C23.7186 29.7491 23.9584 29.9908 24.2532 29.9908H29.4663C29.7602 29.9908 30 29.7491 30 29.4516V26.8588C30.0001 12.0603 17.9608 0.0110156 3.16131 0ZM28.9335 28.9234H24.787V26.8589C24.787 23.9338 24.2148 21.1002 23.0878 18.437C22.5476 17.157 21.8765 15.9211 21.0929 14.762C20.3165 13.6112 19.4257 12.5318 18.447 11.5531C17.4655 10.5726 16.3861 9.68264 15.2399 8.90808C14.0827 8.12621 12.8467 7.45513 11.565 6.91314C8.90076 5.78614 6.06722 5.21304 3.14116 5.21304H1.06752V1.06571L3.16044 1.06934C6.64314 1.07115 10.0177 1.75418 13.1928 3.09997C14.7181 3.74632 16.1912 4.54653 17.57 5.47853C18.9332 6.39863 20.2168 7.45789 21.3849 8.62888C22.5458 9.78887 23.6051 11.0742 24.5325 12.4457C25.4627 13.8227 26.262 15.2949 26.9084 16.8238C28.2524 20.0044 28.9335 23.3808 28.9335 26.859V28.9234Z"  />
        </svg>
    )
}

export default RssIcon;



