export const PLANS = {
    BASIC: 'FREE',
    STANDARD : 'BASIC',
    PREMIUM : 'PRO'
}; 

export const USERTYPES = {
    SUPPER_ADMINSUPPER_ADMIN: 'SUPPER_ADMIN',
    SUPPER_ADMIN : 'SUPPER_ADMIN',
    ADMIN : 'ADMIN',
    MANGER : 'MANGER',
    CREATOR : 'CREATOR',
    PUBLISHER : 'PUBLISHER',
    VIEWER : 'VIEWER',
}; 
