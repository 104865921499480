import React from 'react';

const Clock = (props) => {
    let { style } = props;
    return (
        <svg width="12" height="12" className={style} viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_7910_27256)">
                <path d="M7.60477 5.88351H6.27556C6.25053 5.8405 6.21503 5.805 6.17202 5.77997V3.87765C6.17202 3.79858 6.10781 3.73438 6.02874 3.73438C5.94967 3.73438 5.88546 3.79858 5.88546 3.87765V5.77997C5.80015 5.82962 5.74219 5.92101 5.74219 6.0268C5.74219 6.1851 5.87043 6.31335 6.02874 6.31335C6.13452 6.31335 6.2259 6.25538 6.27556 6.17007H7.60478C7.68385 6.17007 7.74806 6.10587 7.74806 6.0268C7.74806 5.94772 7.68385 5.88351 7.60477 5.88351Z" />
                <path d="M6.02904 0.869141C3.18501 0.869141 0.871094 3.18305 0.871094 6.02709C0.871094 8.87112 3.18501 11.185 6.02904 11.185C8.87308 11.185 11.187 8.87112 11.187 6.02709C11.187 3.18305 8.87308 0.869141 6.02904 0.869141ZM6.02904 10.6119C3.50103 10.6119 1.4442 8.5551 1.4442 6.02709C1.4442 3.49908 3.50103 1.44225 6.02904 1.44225C8.55705 1.44225 10.6139 3.49908 10.6139 6.02709C10.6139 8.5551 8.55705 10.6119 6.02904 10.6119Z" />
                <path d="M6.02736 2.01562C3.81538 2.01562 2.01562 3.8153 2.01562 6.02736C2.01562 8.23943 3.8153 10.0391 6.02736 10.0391C8.23943 10.0391 10.0391 8.23943 10.0391 6.02736C10.0391 3.8153 8.23943 2.01562 6.02736 2.01562ZM6.02736 9.75255C3.97339 9.75255 2.30218 8.08137 2.30218 6.02736C2.30218 3.97335 3.97335 2.30218 6.02736 2.30218C8.08137 2.30218 9.75255 3.97335 9.75255 6.02736C9.75255 8.08137 8.08137 9.75255 6.02736 9.75255Z" />
                <path d="M4.50578 3.10097C4.46621 3.03245 4.37842 3.00894 4.30998 3.04852C4.24146 3.08802 4.21795 3.17572 4.25745 3.24424L4.40072 3.49242C4.42727 3.53839 4.47548 3.56406 4.52489 3.56406C4.5492 3.56406 4.57382 3.5579 4.59645 3.54495C4.66497 3.50537 4.68848 3.41766 4.6489 3.34914L4.50578 3.10097Z" />
                <path d="M7.65813 8.56189C7.61847 8.49337 7.53076 8.46995 7.46232 8.50936C7.3938 8.54894 7.37029 8.63665 7.40979 8.70517L7.55307 8.95335C7.57961 8.99932 7.62782 9.02498 7.67723 9.02498C7.70154 9.02498 7.72617 9.01875 7.74879 9.00579C7.81731 8.9663 7.84082 8.87859 7.80124 8.81007L7.65813 8.56189Z" />
                <path d="M3.49437 4.3988L3.2462 4.25553C3.17776 4.21603 3.09013 4.23938 3.05047 4.30806C3.01089 4.37658 3.0344 4.46421 3.10292 4.50386L3.3511 4.64714C3.37364 4.66017 3.39835 4.66625 3.42265 4.66625C3.47215 4.66625 3.52028 4.64058 3.54674 4.59461C3.58632 4.52609 3.56281 4.43846 3.49437 4.3988Z" />
                <path d="M8.95533 7.55115L8.70716 7.40787C8.63872 7.36838 8.55101 7.39172 8.51135 7.4604C8.47186 7.52892 8.49536 7.61655 8.56388 7.65621L8.81206 7.79948C8.83461 7.81252 8.85923 7.81859 8.88354 7.81859C8.93311 7.81859 8.98124 7.79293 9.0077 7.74695C9.04736 7.67843 9.02385 7.59081 8.95533 7.55115Z" />
                <path d="M3.3511 7.40784L3.10292 7.55111C3.0344 7.59069 3.01089 7.6784 3.05047 7.74692C3.07694 7.79289 3.12515 7.81856 3.17456 7.81856C3.19886 7.81856 3.22349 7.8124 3.24612 7.79945L3.49429 7.65617C3.56281 7.6166 3.58632 7.52889 3.54674 7.46037C3.50709 7.39185 3.41938 7.36834 3.3511 7.40784Z" />
                <path d="M8.51141 4.59454C8.53788 4.64051 8.58617 4.66618 8.6355 4.66618C8.65981 4.66618 8.68451 4.66002 8.70706 4.64707L8.95523 4.50379C9.02375 4.46422 9.04726 4.37651 9.00768 4.30799C8.96803 4.23947 8.8804 4.21604 8.81196 4.25546L8.56378 4.39873C8.49534 4.43839 8.47183 4.52602 8.51141 4.59454Z" />
                <path d="M4.59653 8.50947C4.52809 8.4699 4.44038 8.49332 4.40072 8.56192L4.25745 8.8101C4.21795 8.87862 4.24146 8.96625 4.30998 9.0059C4.33252 9.01894 4.35723 9.02501 4.38153 9.02501C4.43103 9.02501 4.47916 8.99935 4.50562 8.95337L4.6489 8.7052C4.68856 8.63668 4.66505 8.54897 4.59653 8.50947Z" />
                <path d="M7.74887 3.04854C7.68059 3.00896 7.59272 3.03239 7.55307 3.10099L7.40979 3.34916C7.37029 3.41768 7.3938 3.50531 7.46232 3.54497C7.48487 3.558 7.50957 3.56407 7.53388 3.56407C7.58337 3.56407 7.6315 3.53841 7.65797 3.49244L7.80124 3.24426C7.8409 3.17574 7.81739 3.08803 7.74887 3.04854Z" />
                <path d="M5.74219 2.87444C5.74219 2.71614 5.87043 2.58789 6.02874 2.58789C6.18705 2.58789 6.31529 2.71614 6.31529 2.87444C6.31529 3.03275 6.18705 3.161 6.02874 3.161C5.87043 3.161 5.74219 3.03275 5.74219 2.87444Z" />
                <path d="M5.74219 9.17913C5.74219 9.02082 5.87043 8.89258 6.02874 8.89258C6.18705 8.89258 6.31529 9.02082 6.31529 9.17913C6.31529 9.33744 6.18705 9.46568 6.02874 9.46568C5.87043 9.46568 5.74219 9.33744 5.74219 9.17913Z" />
                <path d="M8.89453 6.02679C8.89453 5.86848 9.02278 5.74023 9.18108 5.74023C9.33939 5.74023 9.46764 5.86848 9.46764 6.02679C9.46764 6.1851 9.33939 6.31334 9.18108 6.31334C9.02278 6.31334 8.89453 6.1851 8.89453 6.02679Z" />
                <path d="M2.58984 6.02679C2.58984 5.86848 2.71809 5.74023 2.8764 5.74023C3.0347 5.74023 3.16295 5.86848 3.16295 6.02679C3.16295 6.1851 3.0347 6.31334 2.8764 6.31334C2.71809 6.31334 2.58984 6.1851 2.58984 6.02679Z" />
            </g>
            <defs>
                <clipPath id="clip0_7910_27256">
                    <rect width="12" height="12" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default Clock;