import React from "react";
import { connect } from "react-redux";
import { withRouter } from "../../router";
import ReactPlayer from 'react-player';

class PreviewScreenComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isAllAppsSelected: true,
            mediaInfo: this.props.selectedPlayList[0],
            index: 0,
            duration: 1000,
            timer: ""
        }
    }
    async componentDidMount() {
        this.timerList();
    }

    componentWillUnmount() {
        clearTimeout(this.state.timer);
    }
    timerList = () => {
        let duration = this.hhMMSSToSecond(this.props.selectedPlayList[this.state.index].duration);
        this.setState({ mediaInfo: this.props.selectedPlayList[this.state.index] })
        this.state.timer = setTimeout(function () {
            if ((this.props.selectedPlayList.length - 1) === this.state.index)
                this.state.index = 0
            else this.state.index += 1;
            this.timerList();
        }.bind(this), duration);
    }

    hhMMSSToSecond = (duration) => {
        var a = (duration || '').split(':')
        var times = [0, 0, 0]

        for (var i = 0; i < times.length; i++) {
            times[i] = isNaN(parseInt(a[i])) ? 0 : parseInt(a[i])
        }
        var hours = times[0]
        var minutes = times[1]
        var seconds = times[2]

        return ((hours * 60 * 60) + (minutes * 60) + seconds) * 1000;
    }
    render() {
        let { isHorizontal } = this.props;
        let screenSize = { height: "410px", width: "717px" }
        if (!isHorizontal)
            screenSize = { width: "45vh", height: "63vh", }
        return (<div className="bg-cover bg-center object-cover border-black border-4 shadow" style={screenSize}>
            {this.state.mediaInfo.type && (this.state.mediaInfo.type == "LINK" || this.state.mediaInfo.type == "APP") ?
                <div className="w-full h-full flex  justify-center items-center backdrop-blur-md">
                    <iframe className="w-full h-full bg-black" key={""} scrolling="no"
                        src={this.state.mediaInfo.url} />
                </div>
                : this.state.mediaInfo.contentType.includes("video") ?
                    <div className="w-full h-full flex bg-gray-900 justify-center items-center overflow-hidden">
                        <ReactPlayer
                            height={screenSize.height}
                            width={screenSize.width}
                            url={this.state.mediaInfo.url}
                            playing={true}
                            loop={true}
                            controls
                        />
                    </div> : <div className="w-full h-full bg-gray-900" style={{
                        backgroundImage: `url(${this.state.mediaInfo.url})`,
                        "backgroundSize": "cover",
                        "backgroundPosition": "center"
                    }}>
                        <div className="w-full h-full flex  justify-center items-center backdrop-blur-md	">
                            <img className="self-center flex-1 p-1 object-scale-down" style={screenSize} src={this.state.mediaInfo.url} alt="loadding..." />
                        </div>
                    </div>}
        </div>)
    };
}


const mapStateToProps = (state) => ({
    isLoading: state.counter.isLoading,
    currentColor: state.counter.currentColor

});

const mapDispatchToProps = (dispatch) => ({
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PreviewScreenComponent));
