import React from "react";
import { connect } from "react-redux";
import { withRouter } from "../router";
import { ToastContainer, toast } from 'react-toastify';
import { bindActionCreators } from "@reduxjs/toolkit";
import * as commanActionCreator from '../reducers/commanReduces'
import Navbar from "./subComponents/Navbar";
import { format } from 'date-fns'
import { InputFieldWithEdit, ToggalSetButton } from "./utilComponents/inputFieldAndButtons";
import { MdArrowBackIos, MdStayCurrentLandscape, MdStayCurrentPortrait } from "react-icons/md";
import { createApp, fetchAppData } from "../services/appServices";
import { Button, Form } from "react-bootstrap";
import { updateMedia } from "../services/mediaService";
import ClockIcon from "../appIcons/ClockIcon";
import '@pqina/flip/dist/flip.min.css';
import { FlipClock12 } from "./subComponents/flipClock";
import AnaLogClock from "./subComponents/analogClock";
const moment = require('moment-timezone');

class ColckAppDetailsComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isNameEdit: false,
            appName: "World Clock App",
            selectedClockType: "SIMPLE",
            clockTypes: ["SIMPLE", "FLIP", "DIGITAL", "ANALOG"],
            viewModes: ["DAY", "NIGHT"],
            selectedMode: "DAY",
            is12Format: true,
            displayDate: true,
            displaySecond: false,
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            timeZoneList: [],
            isUpdate: false,
            isLandscape: true,
            time: new Date(),
            appInfo: ""
        }
    }
    async componentDidMount() {
        this.props.counterSlice.startLoading();
        if (this.props.router.params.appId) {
            let appInfo = await fetchAppData(this.props.router.params.appId);
            this.setState({
                appInfo: appInfo.body,
                appName: appInfo.body.name,
                selectedClockType: appInfo.body.clockType,
                viewMode: appInfo.body.viewMode,
                is12Format: appInfo.body.is12Format,
                displayDate: appInfo.body.displayDate,
                displaySecond: appInfo.body.displaySecond,
                selectedMode: appInfo.body.viewMode,
                isUpdate: true
            });
        }
        this.props.counterSlice.stopLoading();
        this.intervalID = setInterval(() => {
            this.setState({ time: new Date() });
        }, 1000);
    }
    componentWillUnmount() {
        clearInterval(this.intervalID);
    }
    updateClockApp = async () => {
        if (this.state.appName.trim() == "") {
            toast.error('App name should not be empty.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
            return;
        }
        this.props.counterSlice.startLoading();
        let appDTO = {
            _id: this.state.appInfo._id,
            name: this.state.appName.trim(),
            clockType: this.state.selectedClockType,
            viewMode: this.state.selectedMode,
            is12Format: this.state.is12Format,
            displayDate: this.state.displayDate,
            displaySecond: this.state.displaySecond,
            timeZone: this.state.timeZone,
        }
        let response = await updateMedia(appDTO);
        if (response.success) {

            toast.success('Clock app updated successfully.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        } else {
            toast.error('Error while updating clock app.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        }
        this.props.counterSlice.stopLoading();
    }

    oninputchange = async (type, value) => {
        this.setState({ [type]: value });
    }

    createClockApp = async () => {

        this.props.counterSlice.startLoading();
        let appDTO = {
            name: this.state.appName.trim(),
            clockType: this.state.selectedClockType,
            viewMode: this.state.selectedMode,
            is12Format: this.state.is12Format,
            displayDate: this.state.displayDate,
            displaySecond: this.state.displaySecond,
            timeZone: this.state.timeZone,
            appType: "CLOCK"
        }
        let response = await createApp(appDTO);
        if (response.success) {

            toast.success('Clock app saved successfully.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
            this.props.router.navigate("/apps");

        } else {
            toast.error(response.message, {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        }
        this.props.counterSlice.stopLoading();
    }
    render() {
        let screenSize = { height: "410px", width: "717px" }
        if (!this.state.isLandscape)
            screenSize = { width: "55vh", height: "70vh" }
        const { time } = this.state;
        const date = moment.tz(time, this.state.timeZone).format("DD");
        const month = moment.tz(time, this.state.timeZone).format("MMM");
        const year = moment.tz(time, this.state.timeZone).format("YYYY");
        const day = moment.tz(time, this.state.timeZone).format("ddd");
        const hour = moment.tz(time, this.state.timeZone).format(this.state.is12Format ? "hh" : "HH");
        const minute = moment.tz(time, this.state.timeZone).format("mm");
        const seconds = moment.tz(time, this.state.timeZone).format("ss");

        const am = moment.tz(time, this.state.timeZone).format("A");
        let style = { fontFamily: 'DS-Digital' };
        let DateText = ({ lable, value }) => (
            <div className="flex flex-col">
                <span className="text-gray-20 text-base">{lable}</span>
                <span>{value}</span>
            </div>
        )
        const userRole = JSON.parse(localStorage.getItem("roles"));

        return (
            <div>
                <Navbar router={this.props.router} counterSlice={this.props.counterSlice} />
                <div className="flex bg-white border-b-1 border-slate-300 w-full min-h-min	">
                    <div className="flex-none p-4 border-1"
                        onClick={() => { this.props.router.navigate(-1) }}>
                        <MdArrowBackIos size={20} />
                    </div>
                    <div className="self-center bg-dark-team rounded p-2 m-2">
                        <ClockIcon style="fill-white" />
                    </div>
                    <div className="px-2 py-3 flex flex-col self-center grow">
                        <InputFieldWithEdit
                            isEdit={this.state.isNameEdit}
                            isRead={userRole.app["editName"].read}

                            onKeyDown={(e) => {
                                if (this.state.appName.trim() !== "" && (e.key === "Enter" || e.key === "Tab" || e.key === undefined)) {
                                    this.setState({ isNameEdit: false });
                                } else this.setState({ isNameEdit: true });
                            }}
                            inputValue={this.state.appName}
                            placeholder="Enter Currency App Name"
                            onInputchange={(e) => { userRole.app["editName"].write && this.setState({ appName: e.target.value }); }}
                            onEditClick={() => { userRole.app["editName"].write && this.setState({ isNameEdit: !this.state.isNameEdit }) }} />
                        {this.state.isUpdate ? <span className="order-last text-sm text-gray-450">{format(new Date(), 'dd/MM/yyyy HH:mm')}, modified by {this.state.appInfo != "" && this.state.appInfo.modifiedBy && <span>{this.state.appInfo.modifiedBy.firstName} {this.state.appInfo.modifiedBy.lastName}</span>} </span> :
                            <span className="order-last text-sm text-gray-450">{format(new Date(), 'dd/MM/yyyy HH:mm')}</span>}
                    </div>
                    <div className="flex gap-4 self-center" >
                        <Button className="bg-darkBlue text-white rounded p-2 mr-4"
                            onClick={this.state.isUpdate ? this.updateClockApp : this.createClockApp}>{this.state.isUpdate ? "Update" : "Save"}</Button>
                    </div>
                </div>
                <div className="flex" style={{ height: "82vh" }}>
                    <div className="bg-white h-full w-1/3 px-12 pt-5 gap-4 flex flex-col">
                        <div className="flex justify-between mr-4">
                            <label className="self-center">Clock Type*</label>
                            <div className="flex gap-2">
                                <Form.Select className="flex w-40" placeholder="Select clock type"
                                    value={this.state.selectedClockType}
                                    onChange={(e) => this.oninputchange("selectedClockType", e.target.value)}>
                                    {this.state.clockTypes?.map(c => (
                                        <option value={c} key={c}>{c}</option>
                                    ))}
                                </Form.Select>
                            </div>
                        </div>
                        {this.state.selectedClockType != "ANALOG" && <div className="flex justify-between mr-4">
                            <label className="self-center">{"View Mode*"}</label>
                            <div className="flex gap-2">
                                <Form.Select className="flex w-40" placeholder="Select clock type"
                                    value={this.state.selectedMode}
                                    onChange={(e) => this.oninputchange("selectedMode", e.target.value)}>
                                    {this.state.viewModes?.map(c => (
                                        <option value={c} key={c}>{c}</option>
                                    ))}
                                </Form.Select>
                            </div>
                        </div>}
                        {this.state.selectedClockType != "ANALOG" && <div className="flex justify-between mr-4">
                            <label>{"12 hours format"}</label>
                            <div className="flex">
                                <ToggalSetButton value={this.state.is12Format} customFunction={() => this.setState({ is12Format: !this.state.is12Format })} />
                            </div>
                        </div>}
                        <div className="flex justify-between mr-4">
                            <label>{"Display date"}</label>
                            <div className="flex">
                                <ToggalSetButton value={this.state.displayDate} customFunction={() => this.setState({ displayDate: !this.state.displayDate })} />
                            </div>
                        </div>
                        {this.state.selectedClockType != "ANALOG" && <div className="flex justify-between mr-4">
                            <label>{"Display Seconds"}</label>
                            <div className="flex">
                                <ToggalSetButton value={this.state.displaySecond} customFunction={() => this.setState({ displaySecond: !this.state.displaySecond })} />
                            </div>
                        </div>}
                    </div>
                    <div className="flex-1">
                        <div className="flex content-end m-4">
                            <button className="p-2 flex bg-black text-white rounded-lg font-bold border-solid border-1" onClick={() => {
                                this.setState({ isLandscape: !this.state.isLandscape })
                            }}>
                                {this.state.isLandscape ? <MdStayCurrentLandscape className="self-center font-bold mr-1 " size={25} /> :
                                    <MdStayCurrentPortrait className="self-center font-bold mr-1 " size={25} />}
                            </button>
                        </div>
                        <div className="flex justify-center">
                            <div className="bg-cover bg-center object-cover border-black border-4 shadow overflow-hidden" style={screenSize}>
                                {this.state.selectedClockType === "SIMPLE" && <div className={"flex sm:text-base xl:text-5xl lg:text-2xl md:text-xl items-center justify-center gap-4 font-bold " + (this.state.selectedMode === "DAY" ? "bg-white text-black " : "bg-gray-800 text-white ") + (!this.state.isLandscape && "flex-col")} style={screenSize}>
                                    <div className={"flex sm:text-xl md:text-3xl lg:text-5xl xl:text-7xl font-bold"} >
                                        {this.state.is12Format && <div className="inline-block align-top ">
                                            <span className="sm:text-sm md:text-lg lg:text-xl xl:text-2xl font-medium ">{am}</span></div>}
                                        <span>{hour}</span>
                                        <span>:{minute}</span>
                                        {this.state.displaySecond && <span className="w-24">:{seconds}</span>}
                                    </div>
                                    {this.state.displayDate && <div className={"flex flex-col sm:text-lg md:text-xl lg:text-3xl xl:text-5xl uppercase gap-1  font-semibold border-black p-2 " + (!this.state.isLandscape ? "border-t-2" : "border-l-2")}>
                                        <span className="sm:text-base md:text-lg lg:text-2xl xl:text-3xl">{day}, {date}</span>
                                        <span className="">{month}, {year}</span>
                                    </div>}
                                </div>}

                                {(this.state.selectedClockType === "DIGITAL") && <div className={"flex items-center justify-center gap-2 font-bold " + (this.state.selectedMode === "DAY" ? "bg-white text-black " : "bg-gray-800 text-white ") + (!this.state.isLandscape && "flex-col")} style={screenSize}>
                                    <div className={"flex sm:text-xl md:text-3xl lg:text-5xl xl:text-7xl font-bold "} >
                                        {this.state.is12Format && <div className="inline-block align-top "><span className="sm:text-sm md:text-lg lg:text-xl xl:text-2xl font-medium" style={style}>{am}</span></div>}
                                        <span style={style}>{hour}</span>
                                        <span style={style}>:{minute}</span>
                                        {this.state.displaySecond && <span className="w-24" style={style}>:{seconds}</span>}
                                    </div>
                                    {this.state.displayDate && <div className={"flex flex-col sm:text-lg md:text-xl lg:text-3xl xl:text-5xl uppercase gap-1 border-black p-2 font-semibold text-center "+ (!this.state.isLandscape ? "border-t-2 w-8/12" : "border-l-2")}>
                                        <span style={style}>{day}, {date}</span>
                                        <span style={style}>{month}, {year}</span>
                                    </div>}
                                </div>}

                                {this.state.selectedClockType === "FLIP" &&
                                    <div className={"flex items-center justify-center gap-2 font-bold " + (this.state.selectedMode === "DAY" ? "bg-white text-black " : "bg-gray-800 text-white ") + (!this.state.isLandscape && "flex-col")} style={screenSize}>
                                        {this.state.is12Format && <div className="inline-block align-top "><span className="text-lg font-medium ">{am}</span></div>}

                                        <FlipClock12 value={hour} />
                                        {this.state.isLandscape && ":"}
                                        <FlipClock12 value={minute} />
                                        {this.state.displaySecond && this.state.isLandscape && ":"}
                                        {this.state.displaySecond && (<FlipClock12 value={seconds} />)}
                                        {this.state.displayDate && <div className="flex sm:text-base md:text-lg lg:text-2xl xl:text-3xl flex-col uppercase gap-1  font-semibold">
                                            <span className="sm:text-base md:text-lg lg:text-xl xl:text-2xl">{day}, {date}</span>
                                            <span className="">{month}, {year}</span>
                                        </div>}
                                    </div>

                                }
                                {this.state.selectedClockType === "ANALOG" &&
                                    <div className={"flex relative items-center gap-2 font-bold " + (!this.state.isLandscape && "flex-col")} style={screenSize}>
                                        {this.state.displayDate && <div className={"basis-1/4 flex text-center font-bold text-xl gap-2 " + (this.state.isLandscape && "flex-col")}>
                                            <DateText lable={"DATE"} value={date} />
                                            <DateText lable={"MONTH"} value={month} />
                                            <DateText lable={"YEAR"} value={year} />
                                        </div>}
                                        <div className="flex-1 m-4 overflow-hidden"><AnaLogClock /></div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </div>)
    };
}

const mapStateToProps = (state) => ({
    isLoading: state.counter.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
    counterSlice: bindActionCreators(commanActionCreator, dispatch)
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ColckAppDetailsComponent));
