import './App.css';
import React from 'react'
import * as counterSliceActionCreator from './reducers/commanReduces'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'

import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import LoginComponent from './component/Login';
import PasswordComponent from './component/passwordComponent';

import "./css/loading.css";
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import ScreenComponent from './component/screenComponent';
import ChannelComponent from './component/channelComponent';
import PlayListComponent from './component/playListComponent';
import MediaComponent from './component/mediaComponent';
import LinkComponent from './component/linkComponent';
import AppComponent from './component/appComponent';
import ScreenMonitorComponent from './component/screenMonitorComponent';
import ScreenDetailComponent from './component/screenDetailComponent';
import PageNotFound from './component/pageNotFound';
import PlaylistDetailComponent from './component/playlistDetailComponent';
import ChannelDetailsComponent from './component/channelDetailsComponent';
import SettingComponent from './component/settingComponent';
import { FaFilm, FaMinus, FaTimes } from 'react-icons/fa';
import WeatherAppDetailsComponent from './component/weatherAppDetailsComponent';
import WeatherAppPublicComponent from './component/appComponents/weatherAppPublicComponent';
import CurrencyAppDetailsComponent from './component/currencyAppDetailsComponent';
import CurrencyAppPublicComponent from './component/appComponents/currencyAppPublicComponent';
import ClockAppDetailsComponent from './component/clockAppDetailsComponent';
import ClockAppPublicComponent from './component/appComponents/clockAppPublicComponent';
import CricketAppDetailsComponent from './component/cricketAppDetailsComponent';
import CricketAppPublicComponent from './component/appComponents/cricketAppPublicComponent';
import SoccerAppDetailsComponent from './component/soccerAppDetailsComponent';

import AdminLoginComponent from './component/admin/adminLogin';
import ScreenManager from './component/admin/screenManager';
import RolesManagement from './component/admin/rolesMangement';
import RolesManagementDetails from './component/admin/rolesMangementDetails';
import Customers from './component/admin/customers';
import AddCustomer from './component/admin/addCustomer';
import AddPlan from './component/admin/addPlan';
import Subscription from './component/admin/subscription';
import ViewCustomer from './component/admin/viewCustomer';
import Plans from './component/admin/plans';
import YoutubeAppDetailsComponent from './component/youtubeAppDetailsComponent';
import YouTubeAppPublicComponent from './component/appComponents/youTubeAppPublicComponent';
import AddSubscription from './component/admin/addSubscription';
import SoccerAppPublicComponent from './component/appComponents/soccerAppPublicComponent';
import MeetTheTeamAppComponent from './component/meetTheTeamAppComponent';
import InstagramGalleryDetailsComponent from './component/instagramGalleryDetailsComponent';
import InstagramloginComponent from './component/subComponents/instagramloginComponent';
import RssDetailsComponent from './component/rssDetailsComponent';
import StockExchangeDetails from './component/stockExchangeDetails';
import RssAppPublicComponent from './component/appComponents/rssAppPublicComponent';
import InstaAppPublicComponent from './component/appComponents/instaAppPublicComponent';
import StockAppPublicComponent from './component/appComponents/stockAppPublicComponent';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import MeetTheTeamPublicComponent from './component/appComponents/meetTheTeamPublicComponent';
import SignUpComponent from './component/signUpComponent';
import "react-datepicker/dist/react-datepicker.css";

class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isHide: false
    }
  }
  render() {
    let { loading } = this.props;
    const isLogin = localStorage.getItem("isLogin");
    const isAdminLogin = localStorage.getItem("isAdminLogin");
    return (
      <>
      {/* xxs:invisible xs:invisible sm:invisible md:invisible lg:visible xl:visible */}
        <div className='z-50' style={{ backgroundColor: "#f8f8f8" }}>
          {loading && (
            <div className="loader-container">
              <div className="spinner"></div>
            </div>
          )}

          {<div className={this.props.isMediaFileUploadLoader ? "absolute border bg-white opacity-90  bottom-0 p-2 right-0 overflow-y-auto max-h-96 w-1/3 m-3 rounded-lg z-index-9" : "hidden"}>
            <div className="flex">
              <p className="text-bold flex-1 text-black text-lg">File Upload</p>
              <button className='p-2' onClick={() => { this.setState({ isHide: !this.state.isHide }); }}>{this.state.isHide ? <IoIosArrowUp className="self-center text-black hover:text-black" /> :
                <IoIosArrowDown className="self-center text-black hover:text-black" />}</button>
            </div>
            <hr />
            <div className={this.state.isHide ? "hidden" : ""}>
              {this.props.selectedMediaFileList.map((f, index) => {
                let loding = f.isUpload ? "" : "animate-spin";
                return (
                  <><div className="flex px-6 py-2 my-2">
                    <div className="flex flex-1">
                      {f.type.includes("video") ?
                        <div className="rounded-2xl flex border-solid border-1 w-10 h-10">
                          {!f.isUpload ? <img className="rounded-2xl animate-spin	 border-solid border-1 w-10 h-10" src={process.env.PUBLIC_URL + "/Spinner.svg"} alt="loading..." /> :
                            <FaFilm className="self-center" size={30} opacity={0.6} />}</div> :
                        <img className={"rounded-2xl border-solid border-1 w-10 h-10 " + loding} src={f.isUpload ? f.imageUrl : process.env.PUBLIC_URL + "/Spinner.svg"} alt="loadding..." />}
                      <div className="ml-4 self-center">
                        <span className="text-black ">{f.fileName}</span>
                      </div>
                    </div>
                  </div>
                    <hr />
                  </>)
              })}
            </div>
          </div>}
          <div>
            <Router>
              <Routes>
                {isAdminLogin == "true" &&
                  <>
                    <Route path="/admin/screenManager" element={<ScreenManager />} />
                    <Route path="/admin/rolesManagement" element={<RolesManagement />} />
                    <Route path="/admin/rolesManagementDetails/:roleId" element={<RolesManagementDetails />} />
                    <Route path="/admin/customers" element={<Customers />} />
                    <Route path="/admin/addCustomer" element={<AddCustomer />} />
                    <Route path="/admin/viewCustomer/:customerId" element={<ViewCustomer />} />
                    <Route path="/admin/plans" element={<Plans />} />
                    <Route path="/admin/addPlan/:planId" element={<AddPlan />} />
                    <Route path="/admin/subscription" element={<Subscription />} />
                    <Route path="/admin/addSubscription/:subId" element={<AddSubscription />} />
                  </>
                }
                {isLogin == "true" &&
                  <>
                    <Route path="/screens" element={<ScreenComponent />} />
                    <Route path="/screens/:screenId" element={<ScreenDetailComponent />} />
                    <Route path="/channels" element={<ChannelComponent />} />
                    <Route path="/channels/:channelId" element={<ChannelDetailsComponent />} />
                    <Route path="/playlist" element={<PlayListComponent />} />
                    <Route path="/playlist/:playListId" element={<PlaylistDetailComponent />} />
                    <Route path="/media" element={<MediaComponent />} />
                    <Route path="/links" element={<LinkComponent />} />
                    <Route path="/apps" element={<AppComponent />} />
                    <Route path="/app/weather" element={<WeatherAppDetailsComponent />} />
                    <Route path="/app/weather/:appId" element={<WeatherAppDetailsComponent />} />
                    <Route path="/app/currency" element={<CurrencyAppDetailsComponent />} />
                    <Route path="/app/currency/:appId" element={<CurrencyAppDetailsComponent />} />
                    <Route path="/app/clock" element={<ClockAppDetailsComponent />} />
                    <Route path="/app/clock/:appId" element={<ClockAppDetailsComponent />} />
                    <Route path="/app/cricket" element={<CricketAppDetailsComponent />} />
                    <Route path="/app/cricket/:appId" element={<CricketAppDetailsComponent />} />
                    <Route path="/app/youtube" element={<YoutubeAppDetailsComponent />} />
                    <Route path="/app/youtube/:appId" element={<YoutubeAppDetailsComponent />} />
                    <Route path="/app/soccer" element={<SoccerAppDetailsComponent />} />
                    <Route path="/app/soccer/:appId" element={<SoccerAppDetailsComponent />} />
                    <Route path="/app/meet_team" element={<MeetTheTeamAppComponent />} />
                    <Route path="/app/meet_team/:appId" element={<MeetTheTeamAppComponent />} />
                    <Route path="/app/insta_gallery" element={<InstagramGalleryDetailsComponent />} />
                    <Route path="/app/insta_gallery/:appId" element={<InstagramGalleryDetailsComponent />} />
                    <Route path="/app/rss" element={<RssDetailsComponent />} />
                    <Route path="/app/rss/:appId" element={<RssDetailsComponent />} />

                    <Route path="/app/stock" element={<StockExchangeDetails />} />
                    <Route path="/app/stock/:appId" element={<StockExchangeDetails />} />

                    <Route path="/monitor" element={<ScreenMonitorComponent />} />
                    <Route path="/setting" element={<SettingComponent />} />
                    <Route path="/instagram" element={<InstagramloginComponent />} />

                  </>
                }
                <Route path="/WEATHER/public/:appId" element={<WeatherAppPublicComponent />} />
                <Route path="/CURRENCY/public/:appId" element={<CurrencyAppPublicComponent />} />
                <Route path="/CLOCK/public/:appId" element={<ClockAppPublicComponent />} />
                <Route path="/CRICKET/public/:appId" element={<CricketAppPublicComponent />} />
                <Route path="/SOCCER/public/:appId" element={<SoccerAppPublicComponent />} />
                <Route path="/YOUTUBE/public/:appId" element={<YouTubeAppPublicComponent />} />
                <Route path="/rss/public/:appId" element={<RssAppPublicComponent />} />
                <Route path="/stock/public/:appId" element={<StockAppPublicComponent />} />
                <Route path="/INSTA_GALLERY/public/:appId" element={<InstaAppPublicComponent />} />
                <Route path="/MEET_TEAM/public/:appId" element={<MeetTheTeamPublicComponent />} />

                

                <Route path="/" element={<LoginComponent />} />
                <Route path="/login" element={<LoginComponent />} />
                <Route path="/signup" element={<SignUpComponent />} />
                <Route path="admin/login" element={<AdminLoginComponent />} />
                <Route path="/*" element={<PageNotFound />} />
                <Route path="/password/:userId/token/:token" element={<PasswordComponent />} />
              </Routes>
            </Router>
          </div>
        </div>
        {/* <div className='xxs:visible xs:visible sm:visible md:visible lg:hidden xl:hidden text-black h-screen w-screen -mt-96'>
          <div className="flex h-screen justify-center items-center bg-gray-200">
            <div className="bg-white p-8 rounded shadow-md max-w-md text-center">
              <p className="text-red-500 text-xl font-semibold mb-4">
                This screen size is not supported yet.
              </p>
              <p className="text-gray-700">
                Please switch to your desktop or laptop.
              </p>
            </div>
          </div>
        </div> */}
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  loading: state.counter.isLoading,
  selectedMediaFileList: state.counter.selectedMediaFileList,
  isMediaFileUploadLoader: state.counter.isMediaFileUploadLoader
});

const mapDispatchToProps = (dispatch) => ({
  counterSlice: bindActionCreators(counterSliceActionCreator, dispatch)
})
export default connect(mapStateToProps, mapDispatchToProps)(App);
