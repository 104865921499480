import * as Constants from '../constant';

let createChannel = async (channelDTO) => {
  const token = localStorage.getItem("accessToken");

  return fetch(Constants.API_URL + "/channel", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: "Barear " + token
    },
    body: JSON.stringify(channelDTO),
  }).then((data) => data.json())
    .catch((error) => {
      return {};
    });
};

let updateChannelList = async (channelDTO) => {
  const token = localStorage.getItem("accessToken");

  return fetch(Constants.API_URL + "/channel", {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      authorization: "Barear " + token
    },
    body: JSON.stringify(channelDTO),
  }).then((data) => data.json())
    .catch((error) => {
      return {};
    });
};


let fetchChannelDetails = async () => {
  const token = localStorage.getItem("accessToken");

  return fetch(Constants.API_URL + "/channel", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: "Barear " + token
    },
  }).then((data) => data.json())
    .catch((error) => {
      return {};
    });
};

let fetchChannelDetailsWithId = async (id) => {
  const token = localStorage.getItem("accessToken");

  return fetch(Constants.API_URL + "/channel/info/"+id, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: "Barear " + token
    },
  }).then((data) => data.json())
    .catch((error) => {
      return {};
    });
};

let deleteChannel = async (id) => {
  const token = localStorage.getItem("accessToken");

  return fetch(Constants.API_URL + "/channel?channelId="+id, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      authorization: "Barear " + token
    },
  }).then((data) => data.json())
    .catch((error) => {
      return {};
    });
};

let attachedChannelDataList = async (id) => {
  const token = localStorage.getItem("accessToken");

  return fetch(Constants.API_URL + "/channel/attached/"+id, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: "Barear " + token
    },
  }).then((data) => data.json())
    .catch((error) => {
      return {};
    });
};

let revertChannelPublish = async (channelId) => {
  const token = localStorage.getItem("accessToken");

  return fetch(Constants.API_URL + "/channel/revert/publish/"+channelId, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      authorization: "Barear " + token
    },
  }).then((data) => data.json())
    .catch((error) => {
      return {};
    });
};

export {
  createChannel,
  updateChannelList,
  fetchChannelDetailsWithId,
  fetchChannelDetails,
  deleteChannel,
  attachedChannelDataList,
  revertChannelPublish
};