import React from "react";
import { connect } from "react-redux";
import { Modal, Container, Button } from "react-bootstrap";
import { FaRegCheckCircle } from 'react-icons/fa'
import { withRouter } from "../router";
import { bindActionCreators } from "@reduxjs/toolkit";
import { ToastContainer, toast } from 'react-toastify';

import Loginbg from "../images/Signaxe_signup.svg"

import { singUp } from '../services/login.service';
import * as commanActionCreator from '../reducers/commanReduces'

class SignUpComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isRegisterDoneModel: false,
      firstName: "",
      lastName: "",
      email: "",
      orgName: "",
      isEmail: true,
    };
  }
  onInputChange = (type, event) => {
    this.setState({ [type]: event.target.value });
  }

  signup = async () => {
    let userDTO = {
      firstName: this.state.firstName.trim(),
      lastName: this.state.lastName.trim(),
      email: this.state.email.toLowerCase().trim(),
      userType: "ADMIN",
      organizationInfo: {
        name: this.state.orgName.trim()
      }
    };
    this.props.counterSlice.startLoading();
    let response = await singUp(userDTO);
    this.props.counterSlice.stopLoading();

    if (response.success) {
      this.setState({ isRegisterDoneModel: true })
    } else {
      toast.error(response.message, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        theme: "colored",
        progress: undefined,
      });
    }
  }

  validateEmail = (e) => {
    let emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    if (e.target.value === '' || emailRegex.test(e.target.value)) {
      this.setState({ email: e.target.value, isEmail: true });
    } else {
      this.setState({ email: e.target.value, isEmail: false });
    }
  }
  render() {
    let nameValidationRegex = /^(?=.*[A-Za-z])[A-Za-z!@#$%^&*()_+|~=`{}\[\]:";'<>?,.\/]*$/;
    return (
      <div className="h-screen w-screen flex items-center justify-center bg-cover" style={{ backgroundImage: `url(${Loginbg})` }}>
        <div className="bg-white p-8 rounded-md shadow-md sm:w-2/5 md:w-2/5 lg:w-4/12 xl:w-4/12">            
            <div className="flex flex-col justify-center items-center bg-white gap-8">
              <div className="mb-8 flex flex-col text-center gap-6">
                <h1 className="text-3xl font-medium">Welcome To</h1>
                <img className="self-center h-8" src={process.env.PUBLIC_URL + "/signaxe_logo.svg"} alt="..." />
              </div>
              <form action="#!">
                <div className="form-group flex flex-col gap-3 text-sm text-black">
                  <div className="relative sm:w-full md:w-full lg:w-60 xl:w-96 border-b-black border-opacity-50 border-2 rounded-md flex gap-2">
                    <span className="absolute inset-y-0 left-0 flex items-center pl-2 text-gray-500">
                      <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.3109 15.4063C16.2398 13.5264 14.5672 12.0616 12.5625 11.2477C13.5594 10.5 14.2959 9.45754 14.6674 8.26805C15.039 7.07856 15.0269 5.80231 14.6328 4.62008C14.2387 3.43785 13.4827 2.40958 12.4717 1.68093C11.4608 0.952278 10.2462 0.560181 9 0.560181C7.75382 0.560181 6.53923 0.952278 5.52828 1.68093C4.51733 2.40958 3.76127 3.43785 3.36719 4.62008C2.97311 5.80231 2.961 7.07856 3.33257 8.26805C3.70414 9.45754 4.44055 10.5 5.4375 11.2477C3.43275 12.0616 1.76019 13.5264 0.689059 15.4063C0.622688 15.513 0.578452 15.632 0.558981 15.7561C0.53951 15.8803 0.545201 16.0071 0.575716 16.129C0.606231 16.2509 0.660947 16.3655 0.736612 16.4658C0.812276 16.5662 0.907344 16.6503 1.01616 16.7131C1.12498 16.776 1.24533 16.8164 1.37006 16.8318C1.49478 16.8472 1.62134 16.8374 1.7422 16.803C1.86306 16.7685 1.97576 16.7101 2.07359 16.6312C2.17143 16.5523 2.25241 16.4546 2.31172 16.3438C3.72734 13.8969 6.22734 12.4375 9 12.4375C11.7727 12.4375 14.2727 13.8977 15.6883 16.3438C15.8168 16.5504 16.0207 16.699 16.2567 16.7581C16.4928 16.8172 16.7426 16.7822 16.9534 16.6604C17.1641 16.5387 17.3192 16.3398 17.386 16.1057C17.4527 15.8717 17.4258 15.6208 17.3109 15.4063ZM4.9375 6.50002C4.9375 5.69654 5.17576 4.9111 5.62215 4.24302C6.06854 3.57495 6.70302 3.05424 7.44534 2.74676C8.18767 2.43928 9.0045 2.35883 9.79255 2.51558C10.5806 2.67234 11.3045 3.05925 11.8726 3.6274C12.4408 4.19555 12.8277 4.91942 12.9844 5.70747C13.1412 6.49552 13.0607 7.31235 12.7533 8.05468C12.4458 8.797 11.9251 9.43148 11.257 9.87787C10.5889 10.3243 9.80348 10.5625 9 10.5625C7.92294 10.5613 6.89034 10.1329 6.12875 9.37127C5.36715 8.60968 4.93874 7.57709 4.9375 6.50002Z" fill="black" fill-opacity="0.5" />
                      </svg>
                    </span>
                    <input type="text" value={this.state.firstName} name="firstName" placeholder="First Name" onChange={(e) => (e.target.value == "" || nameValidationRegex.test(e.target.value)) && this.onInputChange("firstName", e)}
                      className="pl-12 pr-4 py-2 w-full focus:outline-none placeholder:text-sm text-black" />
                  </div>
                  <div className="relative sm:w-full md:w-full lg:w-60 xl:w-96 border-b-black border-opacity-50 border-2 rounded-md flex gap-2">
                    <span className="absolute inset-y-0 left-0 flex items-center pl-2 text-gray-500">
                      <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.3109 15.4063C16.2398 13.5264 14.5672 12.0616 12.5625 11.2477C13.5594 10.5 14.2959 9.45754 14.6674 8.26805C15.039 7.07856 15.0269 5.80231 14.6328 4.62008C14.2387 3.43785 13.4827 2.40958 12.4717 1.68093C11.4608 0.952278 10.2462 0.560181 9 0.560181C7.75382 0.560181 6.53923 0.952278 5.52828 1.68093C4.51733 2.40958 3.76127 3.43785 3.36719 4.62008C2.97311 5.80231 2.961 7.07856 3.33257 8.26805C3.70414 9.45754 4.44055 10.5 5.4375 11.2477C3.43275 12.0616 1.76019 13.5264 0.689059 15.4063C0.622688 15.513 0.578452 15.632 0.558981 15.7561C0.53951 15.8803 0.545201 16.0071 0.575716 16.129C0.606231 16.2509 0.660947 16.3655 0.736612 16.4658C0.812276 16.5662 0.907344 16.6503 1.01616 16.7131C1.12498 16.776 1.24533 16.8164 1.37006 16.8318C1.49478 16.8472 1.62134 16.8374 1.7422 16.803C1.86306 16.7685 1.97576 16.7101 2.07359 16.6312C2.17143 16.5523 2.25241 16.4546 2.31172 16.3438C3.72734 13.8969 6.22734 12.4375 9 12.4375C11.7727 12.4375 14.2727 13.8977 15.6883 16.3438C15.8168 16.5504 16.0207 16.699 16.2567 16.7581C16.4928 16.8172 16.7426 16.7822 16.9534 16.6604C17.1641 16.5387 17.3192 16.3398 17.386 16.1057C17.4527 15.8717 17.4258 15.6208 17.3109 15.4063ZM4.9375 6.50002C4.9375 5.69654 5.17576 4.9111 5.62215 4.24302C6.06854 3.57495 6.70302 3.05424 7.44534 2.74676C8.18767 2.43928 9.0045 2.35883 9.79255 2.51558C10.5806 2.67234 11.3045 3.05925 11.8726 3.6274C12.4408 4.19555 12.8277 4.91942 12.9844 5.70747C13.1412 6.49552 13.0607 7.31235 12.7533 8.05468C12.4458 8.797 11.9251 9.43148 11.257 9.87787C10.5889 10.3243 9.80348 10.5625 9 10.5625C7.92294 10.5613 6.89034 10.1329 6.12875 9.37127C5.36715 8.60968 4.93874 7.57709 4.9375 6.50002Z" fill="black" fill-opacity="0.5" />
                      </svg>
                    </span>
                    <input type="text" value={this.state.lastName} name="lastName" placeholder="Last Name" onChange={(e) => (e.target.value == "" || nameValidationRegex.test(e.target.value)) && this.onInputChange("lastName", e)}
                      className="pl-12 pr-4 py-2 w-full focus:outline-none placeholder:text-sm text-black" />
                  </div>
                  <div className="relative sm:w-full md:w-full lg:w-60 xl:w-96 border-b-black border-opacity-50 border-2 rounded-md flex gap-2">
                    <span className="absolute inset-y-0 left-0 flex items-center pl-2 text-gray-500">
                      <svg width="18" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17 0H3C2.20435 0 1.44129 0.316071 0.87868 0.87868C0.316071 1.44129 0 2.20435 0 3V13C0 13.7956 0.316071 14.5587 0.87868 15.1213C1.44129 15.6839 2.20435 16 3 16H17C17.7956 16 18.5587 15.6839 19.1213 15.1213C19.6839 14.5587 20 13.7956 20 13V3C20 2.20435 19.6839 1.44129 19.1213 0.87868C18.5587 0.316071 17.7956 0 17 0ZM16.33 2L10 6.75L3.67 2H16.33ZM17 14H3C2.73478 14 2.48043 13.8946 2.29289 13.7071C2.10536 13.5196 2 13.2652 2 13V3.25L9.4 8.8C9.5731 8.92982 9.78363 9 10 9C10.2164 9 10.4269 8.92982 10.6 8.8L18 3.25V13C18 13.2652 17.8946 13.5196 17.7071 13.7071C17.5196 13.8946 17.2652 14 17 14Z" fill="black" fill-opacity="0.5" />
                      </svg>
                    </span>
                    <input type="email" name="email" value={this.state.email} placeholder="Email ID" onChange={(e) => this.validateEmail(e)}
                      className="pl-12 pr-4 py-2 w-full focus:outline-none placeholder:text-sm text-black" />
                  </div>
                  {this.state.email !== "" && !this.state.isEmail && <span className="text-sm text-red-600 -mt-4">Invalid Email ID</span>}
                  <div className="relative sm:w-full md:w-full lg:w-60 xl:w-96 border-b-black border-opacity-50 border-2 rounded-md flex gap-2">
                    <span className="absolute inset-y-0 left-0 flex items-center pl-2 text-gray-500">
                      <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.1667 10.5H15.8333V12.1667H14.1667V10.5ZM15.8333 7.16669H14.1667V8.83335H15.8333V7.16669Z" fill="black" fill-opacity="0.5" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8333 3.83333H19.1667V15.5H0.833336V0.5H10.8333V3.83333ZM6.66667 2.16667H9.16667V3.83333H6.66667V2.16667ZM9.16667 13.8333V12.1667H6.66667V13.8333H9.16667ZM9.16667 10.5V8.83333H6.66667V10.5H9.16667ZM9.16667 7.16667V5.5H6.66667V7.16667H9.16667ZM17.5 13.8333V5.5H10.8333V7.16667H12.5V8.83333H10.8333V10.5H12.5V12.1667H10.8333V13.8333H17.5ZM2.5 13.8333V12.1667H5V13.8333H2.5ZM2.5 10.5H5V8.83333H2.5V10.5ZM5 7.16667V5.5H2.5V7.16667H5ZM2.5 3.83333H5V2.16667H2.5V3.83333Z" fill="black" fill-opacity="0.5" />
                      </svg>
                    </span>
                    <input type="text" value={this.state.orgName} name="orgName" placeholder="Organization Name" onChange={(e) => this.onInputChange("orgName", e)}
                      className="pl-12 pr-4 py-2 w-full focus:outline-none placeholder:text-sm text-black" />
                  </div>
                  <div className="flex flex-col gap-1 mt-6">
                    <Button disabled={this.state.firstName.trim() === "" || this.state.lastName.trim() === "" || this.state.email.trim() === "" || this.state.orgName.trim() === "" || !this.state.isEmail}
                      className="block bg-darkBlue hover:bg-blue-800 cursor-pointer w-full text-white py-1 px-8 rounded "
                      onClick={() => { this.signup() }}>
                      Sign Up
                    </Button>
                    <div className='text-center text-black text-opacity-50 text-sm font-medium	'>
                      Have an account? <span className='text-blue-700 cursor-pointer hover:underline hover:underline-offset-2' onClick={() => this.props.router.navigate("/")}>Login Here</span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
        </div>

        <Modal
          show={this.state.isRegisterDoneModel}
          aria-labelledby="contained-modal-title-vcenter"
          centered>
          <Modal.Body className="m-0 p-0">
            <Container className="bg-green-200 h-auto rounded-lg text-center float-center">
              <div className="flex text-white p-4">
                <FaRegCheckCircle className="flex-1" size={40} />
              </div>
              <h1 className="text-xl font-semibold text-white">SUCCESS</h1>
              <div className="m-3 text-white text-bolt"><span>Congratulations, your account has been created successfully. A set password request has been sent to your registered email.
                <br />Thank you.</span></div>
            </Container>
          </Modal.Body>
          <Modal.Footer className="justify-center">
            <button className="rounded-lg hover:shadow-lg text-white bg-dark-team p-3 font-semibold" onClick={() => {
              this.props.router.navigate("/")
            }}>Continue</button>
          </Modal.Footer>
        </Modal>

        <ToastContainer />
      </div>
    );
  }
};

const mapStateToProps = (state) => ({
  isLoading: state.counter.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  counterSlice: bindActionCreators(commanActionCreator, dispatch)
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SignUpComponent));
