import * as Constants from '../../../constant';



let fetchGroupRules = async (orgId,status) => {
    const token = localStorage.getItem("accessToken");

    return fetch(Constants.API_URL + "/permissions/roles-permissions", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            authorization: "Barear " + token
        },
    }).then((data) => data.json())
        .catch((error) => {
            return {};
        });
};



let getRole = async (roleID) => {
  const token = localStorage.getItem("accessToken");
  const url = `/permissions/role-permission/${roleID}`;
  return fetch(Constants.API_URL + url, {
      method: "GET",
      headers: {
          "Content-Type": "application/json",
          authorization: "Barear " + token
      },
  }).then((data) => data.json())
      .catch((error) => {
          return {};
      });
};

async function createRoles(userDTO) {
    const token = localStorage.getItem("accessToken");
    return fetch(Constants.API_URL + "/permissions/create-roleAndPermissions", {
      method: "POST",
      headers: { "Content-Type": "application/json" ,
      authorization: "Barear " + token},
      body: JSON.stringify(userDTO),
    })
      .then((data) => data.json())
      .catch((error) => {
        return {};
      });
  }



  let updateRole = async (groupDTO,roleID) => {
    const token = localStorage.getItem("accessToken");
    const url = `/permissions/update-roleAndPermissions/${roleID}`
    return fetch(Constants.API_URL + url, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            authorization: "Barear " + token
        },
        body: JSON.stringify(groupDTO),
    }).then((data) => data.json())
        .catch((error) => {
            return {};
        });
};



export {
    fetchGroupRules,
    createRoles,
    getRole,
    updateRole
};