import React from 'react';

const Screens = (props) => {
  let { style } = props;
  return (
    <svg className={style} width="22" height="15" viewBox="0 0 22 15" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.625 0.618164H1.375C1.01045 0.618574 0.660954 0.763713 0.40318 1.02174C0.145407 1.27976 0.000409425 1.6296 0 1.99451L0 13.0053C0.000409425 13.3702 0.145407 13.72 0.40318 13.978C0.660954 14.236 1.01045 14.3812 1.375 14.3816H20.625C20.9895 14.3812 21.339 14.236 21.5968 13.978C21.8546 13.72 21.9996 13.3702 22 13.0053V1.99451C21.9996 1.6296 21.8546 1.27976 21.5968 1.02174C21.339 0.763713 20.9895 0.618574 20.625 0.618164V0.618164ZM20.625 13.6934H1.375C1.19266 13.6934 1.0178 13.6209 0.888864 13.4919C0.759933 13.3628 0.6875 13.1878 0.6875 13.0053H21.3125C21.3125 13.1878 21.2401 13.3628 21.1111 13.4919C20.9822 13.6209 20.8073 13.6934 20.625 13.6934ZM21.3125 12.3171H0.6875V1.99451C0.6875 1.81199 0.759933 1.63695 0.888864 1.5079C1.0178 1.37884 1.19266 1.30634 1.375 1.30634H20.625C20.8073 1.30634 20.9822 1.37884 21.1111 1.5079C21.2401 1.63695 21.3125 1.81199 21.3125 1.99451V12.3171Z"/>
        <path d="M12.8195 5.19289L8.6945 9.32192C8.66256 9.35386 8.63723 9.39179 8.61994 9.43354C8.60266 9.47528 8.59376 9.52003 8.59375 9.56522C8.59374 9.61041 8.60263 9.65516 8.6199 9.69691C8.63718 9.73867 8.6625 9.7766 8.69442 9.80856C8.72634 9.84051 8.76424 9.86586 8.80596 9.88315C8.84767 9.90044 8.89237 9.90933 8.93752 9.90932C8.98266 9.90932 9.02737 9.90041 9.06907 9.8831C9.11078 9.8658 9.14867 9.84044 9.18058 9.80848L13.3056 5.67945C13.3698 5.61487 13.4057 5.52745 13.4056 5.43636C13.4055 5.34527 13.3693 5.25795 13.3049 5.19354C13.2406 5.12913 13.1533 5.0929 13.0624 5.09277C12.9714 5.09265 12.884 5.12866 12.8195 5.19289L12.8195 5.19289Z" />
        <path d="M12.4757 7.42824L11.1007 8.80458C11.0688 8.83653 11.0435 8.87446 11.0262 8.9162C11.0089 8.95795 11 9.0027 11 9.04789C11 9.09308 11.0089 9.13783 11.0262 9.17958C11.0434 9.22133 11.0687 9.25927 11.1007 9.29122C11.1326 9.32318 11.1705 9.34852 11.2122 9.36581C11.2539 9.3831 11.2986 9.392 11.3438 9.39199C11.3889 9.39198 11.4336 9.38307 11.4753 9.36577C11.517 9.34847 11.5549 9.32311 11.5868 9.29114L12.9618 7.9148C13.026 7.85022 13.062 7.7628 13.0619 7.67171C13.0617 7.58063 13.0255 7.4933 12.9612 7.42889C12.8968 7.36449 12.8096 7.32825 12.7186 7.32813C12.6276 7.328 12.5403 7.36401 12.4757 7.42824L12.4757 7.42824Z" />
        <path d="M9.45307 7.50035C9.49822 7.50041 9.54294 7.49154 9.58465 7.47424C9.62636 7.45694 9.66424 7.43155 9.69611 7.39954L11.0711 6.0232C11.1353 5.95862 11.1713 5.8712 11.1711 5.78011C11.171 5.68902 11.1348 5.6017 11.0705 5.53729C11.0061 5.47288 10.9189 5.43665 10.8279 5.43652C10.7369 5.4364 10.6495 5.47241 10.585 5.53664L9.21003 6.91299C9.16197 6.96111 9.12924 7.02241 9.11598 7.08915C9.10272 7.15589 9.10953 7.22507 9.13554 7.28793C9.16155 7.3508 9.2056 7.40454 9.26212 7.44234C9.31864 7.48015 9.38509 7.50034 9.45307 7.50035V7.50035Z" />
    </svg>

  )
}

export default Screens;