import React from "react";
import { connect } from "react-redux";

import { withRouter } from "../../router";
import * as commanActionCreator from '../../reducers/commanReduces'
import { bindActionCreators } from "@reduxjs/toolkit";
import { ToastContainer } from 'react-toastify';
import ConfigureAppModel from "../utilComponents/configureAppModel";

import { fetchAllApps } from "../../services/appServices";
import { AppIcon } from "../utilComponents/inputFieldAndButtons";



class AllAppsComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            addWeatherApp: false,
            currencyModel: false,
            worldClockModel: false,
            cricketModel: false,
            soccerModel: false,
            youTubeModel: false,
            appAllList: [],
            appList: [],
            selectedApp: ""
        }
    }
    async componentDidMount() {
        this.props.counterSlice.startLoading();
        let appRes = await fetchAllApps();
        this.setState({ appList: appRes.body, appAllList: appRes.body })
        this.props.counterSlice.stopLoading();
        console.log(this.props.appSearchName);
        // this.searchName();
    }

    searchName = () => {
        if (this.props.appSearchName !== "") {
            this.setState({ appList: this.state.appAllList.filter((s) => s.name.toUpperCase().includes(this.props.appSearchName.toUpperCase())) })
        }
    }
    closeAll = () => {
        this.setState({ addWeatherApp: false, currencyModel: false, youTubeModel: false, worldClockModel: false, cricketModel: false, soccerModel: false });
    }

    render() {

        let AppPanel = ({ onClick, icon, title, about }) => (
            <button className="flex bg-white border rounded gap-2 px-2.5 py-3 text-start" key={title} onClick={onClick}>
                <div className="self-center bg-dark-team rounded p-2">
                    {icon}
                </div>
                <div className="self-center">
                    <div className="font-extrabold">{title}</div>
                    <div className="text-xs">{about}</div>
                </div>
            </button>
        );
        const userRole = JSON.parse(localStorage.getItem("roles"));

        if (this.props.appSearchName.trim() && this.props.appSearchName != "") {
            this.state.appList = this.state.appAllList.filter((s) => s.name.toUpperCase().includes(this.props.appSearchName.toUpperCase()));
        } else {
            this.state.appList = this.state.appAllList;
        }

        return (
            <div>
                <div className="grid md:grid-cols-4 sm:md:grid-cols-2 gap-4 my-4">
                    {this.state.appList?.map((app) => (
                        <AppPanel onClick={() => this.setState({ addWeatherApp: true, selectedApp: app })}
                            icon={<AppIcon style={"w-8 h-8 " + (app.type === "MEET_TEAM" ? "fill-black" : "fill-white")} type={app.type} />}
                            title={app.name} about={app.appInfo} />
                    ))}
                </div>
                {this.state.selectedApp != "" && <ConfigureAppModel
                    show={this.state.addWeatherApp}
                    isRead ={userRole.app["add"].read}
                    closeAll={this.closeAll}
                    onConfigure={() =>  {
                        if(userRole.app["add"].write){
                            this.setState({ addWeatherApp: false });
                            this.props.router.navigate("/app/" + this.state.selectedApp.type.toLowerCase());
                        }
                    }}
                    imageList={this.state.selectedApp.imageLink}
                    icon={<AppIcon style={"h-10 w-10 " + (this.state.selectedApp.type === "MEET_TEAM" ? "fill-black" : "fill-white")} type={this.state.selectedApp.type} />}
                    appName={this.state.selectedApp.name}
                    appDetails={this.state.selectedApp.appInfo}
                    appDetailsInfo={(<>
                        <p>{this.state.selectedApp.description}</p>
                        <br />
                        <ol>
                            {this.state.selectedApp.points?.map((p) => (<li>- {p}</li>))}
                        </ol>
                    </>)}
                />}
                <ToastContainer />
            </div>
        );
    }
};

const mapStateToProps = (state) => ({
    isLoading: state.counter.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
    counterSlice: bindActionCreators(commanActionCreator, dispatch)
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AllAppsComponent));
