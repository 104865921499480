import { configureStore } from '@reduxjs/toolkit'
import commanReduces from '../reducers/commanReduces'

const reducer = {
  counter: commanReduces,
};

export default configureStore({
  reducer: reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false}), // added for media upload file object serlisation 
})