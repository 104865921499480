import * as Constants from '../../../constant';


  let getSubscription = async (page,limit,searchText,start_date,end_date) => {
    const token = localStorage.getItem("accessToken");
    let params = "/admin/subscriptions?limit="+limit+"&page="+page;
    if(searchText && searchText != ""){
        params += `&name=${searchText}`
    }
    if(start_date){
        params += `&startDate=${start_date}`
    }
    if(end_date){
        params += `&endDate=${end_date}`
    }    
    return fetch(Constants.API_URL + params, {
        method: "GET",
        headers: { "Content-Type": "application/json" ,
        authorization: "Barear " + token},
    }).then((data) => data.json())
        .catch((error) => {
            return {};
        });
}


let downloaSubscription = async (start_date,end_date) => {
    const token = localStorage.getItem("accessToken");
    let params = "/admin/download/subscriptions?";
    if (start_date) {
      params += `startDate=${start_date}`;
    }
    if (end_date) {
      params += `&endDate=${end_date}`;
    }
    return fetch(Constants.API_URL + params, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: "Barear " + token,
      },
    })
      .then((res) => {
        return res.blob();
      })
      .catch((error) => {
        return {};
      }); 
}



export {    
    getSubscription,
    downloaSubscription
};