import * as Constants from '../constant';

let fetchUserProfile = async () => {
    const token = localStorage.getItem("accessToken");

    return fetch(Constants.API_URL + "/user", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            authorization: "Barear " + token
        },
    }).then((data) => data.json())
        .catch((error) => {
            return {};
        });
};

let fetchTagsInfo = async () => {
    const token = localStorage.getItem("accessToken");

    return fetch(Constants.API_URL + "/user/tag", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            authorization: "Barear " + token
        },
    }).then((data) => data.json())
        .catch((error) => {
            return {};
        });
};

let fetchTagsWithData = async () => {
    const token = localStorage.getItem("accessToken");

    return fetch(Constants.API_URL + "/user/tag/info", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            authorization: "Barear " + token
        },
    }).then((data) => data.json())
        .catch((error) => {
            return {};
        });
};

let addTagsService = async (tagsDTO) => {
    const token = localStorage.getItem("accessToken");

    return fetch(Constants.API_URL + "/user/tag", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            authorization: "Barear " + token
        },
        body: JSON.stringify(tagsDTO),
    }).then((data) => data.json())
        .catch((error) => {
            return {};
        });
};

let updateOrgService = async (orgDTO) => {
    const token = localStorage.getItem("accessToken");

    return fetch(Constants.API_URL + "/user/organization", {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            authorization: "Barear " + token
        },
        body: JSON.stringify(orgDTO),
    }).then((data) => data.json())
        .catch((error) => {
            return {};
        });
};

let sendInvitationService = async (invitationDTO) => {
    const token = localStorage.getItem("accessToken");

    return fetch(Constants.API_URL + "/user/invitation", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            authorization: "Barear " + token
        },
        body: JSON.stringify(invitationDTO),
    }).then((data) => data.json())
        .catch((error) => {
            return {};
        });
};

let fetchInvitation = async (page,limit) => {
    const token = localStorage.getItem("accessToken");

    return fetch(Constants.API_URL + `/user/invitation/?limit=${limit}&page=${page}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            authorization: "Barear " + token
        },
    }).then((data) => data.json())
        .catch((error) => {
            return {};
        });
};

let fetchGroupRules = async (orgId,status) => {
    const token = localStorage.getItem("accessToken");

    return fetch(Constants.API_URL + "/user/org/group/rule", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            authorization: "Barear " + token
        },
    }).then((data) => data.json())
        .catch((error) => {
            return {};
        });
};

let addGroupService = async (groupDTO) => {
    const token = localStorage.getItem("accessToken");

    return fetch(Constants.API_URL + "/user/org/group/rule", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            authorization: "Barear " + token
        },
        body: JSON.stringify(groupDTO),
    }).then((data) => data.json())
        .catch((error) => {
            return {};
        });
};

let updateGroupService = async (groupDTO) => {
    const token = localStorage.getItem("accessToken");

    return fetch(Constants.API_URL + "/user/org/group/rule", {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            authorization: "Barear " + token
        },
        body: JSON.stringify(groupDTO),
    }).then((data) => data.json())
        .catch((error) => {
            return {};
        });
};

let updateUserInfo = async (userDTO) => {
    const token = localStorage.getItem("accessToken");

    return fetch(Constants.API_URL + "/user", {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            authorization: "Barear " + token
        },
        body: JSON.stringify(userDTO),
    }).then((data) => data.json())
        .catch((error) => {
            return {};
        });
};
let deleteTagService = async (tagId) => {
    const token = localStorage.getItem("accessToken");

    return fetch(Constants.API_URL + "/user/tag?tagId="+tagId, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            authorization: "Barear " + token
        },
    }).then((data) => data.json())
        .catch((error) => {
            return {};
        });
};
let updateTagService = async (tagDTO) => {
    const token = localStorage.getItem("accessToken");

    return fetch(Constants.API_URL + "/user/tag", {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            authorization: "Barear " + token
        },
        body: JSON.stringify(tagDTO),
    }).then((data) => data.json())
        .catch((error) => {
            return {};
        });
};

let updateUserPassword = async (passDTO) => {
    const token = localStorage.getItem("accessToken");

    return fetch(Constants.API_URL + "/user/change/password", {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            authorization: "Barear " + token
        },
        body: JSON.stringify(passDTO),
    }).then((data) => data.json())
        .catch((error) => {
            return {};
        });
};
let reSendInvitationService = async (id) => {

    const token = localStorage.getItem("accessToken");

    return fetch(Constants.API_URL + "/user/invitation/resend/"+id, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            authorization: "Barear " + token
        },
    }).then((data) => data.json())
        .catch((error) => {
            return {};
        });
}
 
let removeInvitationService = async (id) => {
    const token = localStorage.getItem("accessToken");
    return fetch(Constants.API_URL + "/user/invitation/"+id, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            authorization: "Barear " + token
        },
    }).then((data) => data.json())
        .catch((error) => {
            return {};
        });
}

let mergeTagService = async (tagDTO) => {
    const token = localStorage.getItem("accessToken");

    return fetch(Constants.API_URL + "/user/tags/merge", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            authorization: "Barear " + token
        },
        body: JSON.stringify(tagDTO),
    }).then((data) => data.json())
        .catch((error) => {
            return {};
        });
};
let fetchDefaultScreen = async () => {
    const token = localStorage.getItem("accessToken");

    return fetch(Constants.API_URL + "/media/default", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            authorization: "Barear " + token
        },
    }).then((data) => data.json())
        .catch((error) => {
            return {};
        });
};
export {
    fetchUserProfile,
    fetchTagsInfo,
    addTagsService,
    updateOrgService,
    sendInvitationService,
    fetchInvitation,
    fetchGroupRules,
    addGroupService,
    fetchTagsWithData,
    updateUserInfo,
    deleteTagService,
    updateTagService,
    updateUserPassword,
    reSendInvitationService,
    removeInvitationService,
    updateGroupService,
    mergeTagService,
    fetchDefaultScreen
};