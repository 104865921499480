import * as Constants from '../../../constant';


let getCustomers = async (page, limit, searchText) => {
    const token = localStorage.getItem("accessToken");
    let params = "/admin/organization?limit=" + limit + "&page=" + page;
    if (searchText) {
        params += `&name=${searchText}`
    }
    return fetch(Constants.API_URL + params, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            authorization: "Barear " + token
        },
    }).then((data) => data.json())
        .catch((error) => {
            return {};
        });
}

let getPlan = async (page, limit, searchText) => {
    const token = localStorage.getItem("accessToken");
    let url = Constants.API_URL + "/admin/plan?limit=" + limit + "&page=" + page;
    if (searchText && searchText !== "")
        url = url + "&name=" + searchText;
    return fetch(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            authorization: "Barear " + token
        },
    }).then((data) => data.json())
        .catch((error) => {
            return {};
        });
}



async function addOrganization(userDTO) {
    const token = localStorage.getItem("accessToken");
    return fetch(Constants.API_URL + "/user", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "authorization": "Barear " + token
        },
        body: JSON.stringify(userDTO),
    }).then((data) => data.json())
        .catch((error) => {
            return {};
        });
}

async function organizationLogin(orgID) {
    const token = localStorage.getItem("accessToken");
    const url = `/admin/organization/login/${orgID}`;
    return fetch(Constants.API_URL + url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "authorization": "Barear " + token
        }
    }).then((data) => data.json())
        .catch((error) => {
            return {};
        });
}

let getCustomerDetails = async (id) => {
    const token = localStorage.getItem("accessToken");
    const url = `/admin/organization/${id}`
    return fetch(Constants.API_URL + url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            authorization: "Barear " + token
        },
    }).then((data) => data.json())
        .catch((error) => {
            return {};
        });
}

let updateCustomerDetails = async (playListDTO) => {
    const token = localStorage.getItem("accessToken");

    return fetch(Constants.API_URL + "/admin/organization", {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            authorization: "Barear " + token
        },
        body: JSON.stringify(playListDTO),
    }).then((data) => data.json())
        .catch((error) => {
            return {};
        });
};

export {
    getCustomers,
    addOrganization,
    getCustomerDetails,
    updateCustomerDetails,
    getPlan,
    organizationLogin
};