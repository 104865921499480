import React from "react";
import { FaTimes } from 'react-icons/fa';

import "../../css/tags.css";

const Tag = props => <span className="tag bg-gray-100 m-1" {...props} />

const TagsInput = (props) => {
  let { selectedTagList, onChange,inputClass, inputValue, onIputChange, handleRemoveTag, tagList } = props;

  return (
    <div className="tags-input w-full flex-wrap p-2">
      {selectedTagList?.map((t, index) => (
        <Tag>
          {t}
          <FaTimes key={index} className="ml-1 self-center" onClick={() => handleRemoveTag(index)} />
        </Tag>
      ))}
      <input type="text"
        list='suggestion'
        value={inputValue}
        placeholder="Enter your tag"
        className={inputClass}
        onChange={onIputChange}
        autoComplete="on"
        maxLength={30}
        onKeyDown={onChange} />
      <datalist id='suggestion' className="text-black border-1 border-black">
        {tagList.map((tag, index) => {
          return (<option key={index} value={tag.tag}>{tag.tag}</option>)
        })}
      </datalist>
    </div>
  );
}

export default TagsInput;