import React from "react";
import { connect } from "react-redux";
import { withRouter } from "../router";
import { ToastContainer, toast } from 'react-toastify';
import { bindActionCreators } from "@reduxjs/toolkit";
import * as commanActionCreator from '../reducers/commanReduces'
import Navbar from "./subComponents/Navbar";
import { format } from 'date-fns'
import { InputFieldWithEdit, } from "./utilComponents/inputFieldAndButtons";
import { MdArrowBackIos, MdStayCurrentLandscape, MdStayCurrentPortrait } from "react-icons/md";
import { Button, Form } from "react-bootstrap";
import { updateMedia } from "../services/mediaService";
import ClockIcon from "../appIcons/ClockIcon";
import { createApp, fetchCricketMatchList, fetchMatchInfo } from "../services/appServices";
import * as Constants from '../constant';

class CricketAppDetailsComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isNameEdit: false,
            appName: "Cricket App",
            appInfo: "",
            matchTypes: [],
            matchType: "",
            matchInfo: "",
            seriesMatches: [],
            seriesId: "",
            matches: [],
            matchId: "",
            selectedSeries: "",
            diplayContents: Constants.CRICKET_DISPLAY_CONTENT,
            isLandscape: true,
            diplayContentType: "",
            matchDetails: "",
            idDisabled: false
        }
    }
    async componentDidMount() {
        this.props.counterSlice.startLoading();
        let cricketInfo = await fetchCricketMatchList(this.state.diplayContents[0].value);
        if (cricketInfo.message) {
            toast.error('Crickbuzz API Error.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
            this.setState({ idDisabled: true })
        } else {
            this.setState({ matchTypes: cricketInfo.filters.matchType, matchInfo: cricketInfo })
        }
        this.props.counterSlice.stopLoading();
    }

    updateClockApp = async () => {
        if (this.state.appName.trim() == "") {
            toast.error('App name should not be empty.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
            return;
        }
        this.props.counterSlice.startLoading();
        let appDTO = {
            _id: this.state.appInfo._id,
            name: this.state.appName.trim(),
            clockType: this.state.selectedClockType,
            viewMode: this.state.selectedMode,
            is12Format: this.state.is12Format,
            displayDate: this.state.displayDate,
            displaySecond: this.state.displaySecond,
            timeZone: this.state.timeZone,
        }
        let response = await updateMedia(appDTO);
        if (response.success) {

            toast.success('Clock app updated successfully.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        } else {
            toast.error('Error while updating clock app.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        }
        this.props.counterSlice.stopLoading();
    }
    onDiplaychange = async (type) => {
        this.props.counterSlice.startLoading();
        let cricketInfo = await fetchCricketMatchList(type);
        if (cricketInfo) {
            this.setState({
                matchTypes: cricketInfo.filters.matchType, matchInfo: cricketInfo,
                seriesMatches: [], seriesId: "", matchType: "", matches: [], matchId: ""
            })
        } else {
            toast.error('Matches Not found.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        }
        this.props.counterSlice.stopLoading();
    }
    onMatchTypechange = (matchType) => {
        for (let i = 0; i < this.state.matchInfo.typeMatches.length; i++) {
            if (this.state.matchInfo.typeMatches[i].matchType === matchType) {
                this.state.matchInfo.typeMatches[i].seriesMatches.forEach(match => {
                    if (match.seriesAdWrapper)
                        this.state.seriesMatches.push(match.seriesAdWrapper)
                });
                break;
            }
        }
        this.setState({ seriesMatches: this.state.seriesMatches, matchType, seriesId: "", matches: [], matchId: "" })
    }
    onSeriesIdchange = async (seriesId) => {
        for (let i = 0; i < this.state.seriesMatches.length; i++) {
            if (this.state.seriesMatches[i].seriesId == seriesId) {
                this.setState({ matches: this.state.seriesMatches[i].matches });
                break;
            }
        }
        this.setState({ seriesId, matchId: "" });
    }
    onMatchsChange = async (matchId) => {
        this.setState({ matchId })
        if (matchId != "") {
            let matchDetails = await fetchMatchInfo(matchId);
            if (matchDetails.code)
                return;
            this.setState({ matchDetails })
        }

    }
    createCricketApp = async () => {

        this.props.counterSlice.startLoading();
        let appDTO = {
            name: this.state.appName.trim(),
            matchType: this.state.matchType,
            matchId: this.state.matchId.toString(),
            appType: "CRICKET"
        }
        let response = await createApp(appDTO);
        if (response.success) {

            toast.success('Cricket app saved successfully.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
            this.props.router.navigate("/apps");

        } else {
            toast.error(response.message, {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        }
        this.props.counterSlice.stopLoading();
    }

    render() {
        const userRole = JSON.parse(localStorage.getItem("roles"));

        let screenSize = { height: "410px", width: "717px", "backgroundImage": `url(${process.env.PUBLIC_URL + "/cricket2.svg"})` }
        if (!this.state.isLandscape)
            screenSize = { width: "45vh", height: "65vh", "backgroundImage": `url(${process.env.PUBLIC_URL + "/cricket3.svg"})`}

        return (
            <div>
                <Navbar router={this.props.router} counterSlice={this.props.counterSlice} />
                <div className="flex bg-white border-b-1 border-slate-300 w-full min-h-min	">
                    <div className="flex-none p-4 border-1"
                        onClick={() => { this.props.router.navigate(-1) }}>
                        <MdArrowBackIos size={20} />
                    </div>
                    <div className="self-center bg-dark-team rounded p-2 m-2">
                        <ClockIcon style="fill-white" />
                    </div>
                    <div className="px-2 py-3 flex flex-col self-center grow">
                        <InputFieldWithEdit
                            isEdit={this.state.isNameEdit}
                            isRead={userRole.app["editName"].read}

                            onKeyDown={(e) => {
                                if (this.state.appName.trim() !== "" && (e.key === "Enter" || e.key === "Tab" || e.key === undefined)) {
                                    this.setState({ isNameEdit: false });
                                } else this.setState({ isNameEdit: true });
                            }}
                            inputValue={this.state.appName}
                            placeholder="Enter Currency App Name"
                            onInputchange={(e) => { userRole.app["editName"].write && this.setState({ appName: e.target.value }); }}
                            onEditClick={() => { userRole.app["editName"].write && this.setState({ isNameEdit: !this.state.isNameEdit }) }} />
                        {this.state.isUpdate ? <span className="order-last text-sm text-gray-450">{format(new Date(), 'dd/MM/yyyy HH:mm')}, modified by {this.state.appInfo != "" && this.state.appInfo.modifiedBy && <span>{this.state.appInfo.modifiedBy.firstName} {this.state.appInfo.modifiedBy.lastName}</span>} </span> :
                            <span className="order-last text-sm text-gray-450">{format(new Date(), 'dd/MM/yyyy HH:mm')}</span>}
                    </div>
                    <div className="flex gap-4 self-center" >
                        <Button className="bg-darkBlue text-white rounded p-2 mr-4"
                            disabled={this.state.idDisabled || this.state.matchDetails === ""}
                            onClick={this.state.isUpdate ? this.updateClockApp : this.createCricketApp}>{this.state.isUpdate ? "Update" : "Save"}</Button>
                    </div>
                </div>
                <div className="flex" style={{ height: "82vh" }}>
                    <div className="bg-white h-full w-1/3 px-12 pt-5 gap-4 flex flex-col">
                        <div className="flex flex-col mr-4">
                            <label>{"Display Content"}</label>
                            <div className="flex flex-1 gap-2">
                                <Form.Select className="flex flex-1" placeholder="Select clock type"
                                    value={this.state.diplayContent}
                                    disabled={this.state.idDisabled}
                                    onChange={(e) => this.onDiplaychange(e.target.value)}>
                                    {this.state.diplayContents?.map(dc => (
                                        <option value={dc.value} key={dc.value}>{dc.label}</option>
                                    ))}
                                </Form.Select>
                            </div>
                        </div>
                        <div className="flex flex-col mr-4">
                            <label>{"Matches"}</label>
                            <div className="flex flex-1 gap-2">
                                <Form.Select className="flex flex-1" placeholder="Select Matches"
                                    value={this.state.matchType}
                                    onChange={(e) => this.onMatchTypechange(e.target.value)}>
                                    <option value="">{"Select match type"}</option>
                                    {this.state.matchTypes?.map(c => (
                                        <option value={c} key={c}>{c}</option>
                                    ))}
                                </Form.Select>
                            </div> 
                        </div>

                        <div className="flex flex-col mr-4">
                            <label>{"Tournament"}</label>
                            <div className="flex flex-1 gap-2">
                                <Form.Select className="flex flex-1" placeholder="Select Tournament"
                                    value={this.state.seriesId}
                                    onChange={(e) => this.onSeriesIdchange(e.target.value)}>
                                    <option value="">{"Select tournament"}</option>
                                    {this.state.seriesMatches?.map(sm => (
                                        <option value={sm.seriesId} key={sm.seriesId}>{sm.seriesName}</option>
                                    ))}
                                </Form.Select>
                            </div>
                        </div>

                        <div className="flex flex-col mr-4">
                            <label>{"Match"}</label>
                            <div className="flex flex-1 gap-2">
                                <Form.Select className="flex flex-1" placeholder="Select Match"
                                    value={this.state.matchId}
                                    onChange={(e) => this.onMatchsChange(e.target.value)}>
                                    <option value="">{"Select Match"}</option>
                                    {this.state.matches?.map(sm => (
                                        <option value={sm.matchInfo.matchId} key={sm.matchInfo.matchId}>{sm.matchInfo.team1.teamName} VS {sm.matchInfo.team2.teamName}</option>
                                    ))}
                                </Form.Select>
                            </div>
                        </div>

                    </div>
                    <div className="flex-1">
                        <div className="flex content-end m-4">
                            <button className="p-2 flex bg-black text-white rounded-lg font-bold border-solid border-1" onClick={() => {
                                this.setState({ isLandscape: !this.state.isLandscape })}}>
                                {this.state.isLandscape ? <MdStayCurrentLandscape className="self-center font-bold mr-1 " size={25} /> :
                                    <MdStayCurrentPortrait className="self-center font-bold mr-1 " size={25} />}
                            </button>
                        </div>
                        <div className="flex justify-center">
                            <div className="bg-cover flex bg-center object-cover border-black border-4 shadow overflow-hidden text-white" style={screenSize}>
                                {this.state.matchDetails != "" && <div className="backdrop-blur-md flex bg-center flex-1">
                                    <div className="flex flex-col flex-1 text-2xl font-bold justify-center text-center items-center">
                                        <span>{this.state.matchDetails.matchHeader.team1.name}</span>
                                    </div>
                                    <div className="flex flex-col flex-1 text-center justify-center items-center font-bold">
                                        <span className="text-lg ">AT</span>
                                        {<span>{format(new Date(this.state.matchDetails.matchHeader.matchStartTimestamp), 'EEEE, dd MMM, yyyy HH:mm')}</span>}
                                        <br />
                                        <span className="text-tahiti">{this.state.matchDetails.matchHeader.status}</span>
                                        <br />
                                        {this.state.matchDetails.scoreCard.length > 0 && <div className="flex flex-col">
                                            {this.state.matchDetails.scoreCard?.map((sd) => (<span>
                                                {sd.batTeamDetails.batTeamShortName} {sd.scoreDetails.runs}-{sd.scoreDetails.wickets}({sd.scoreDetails.overs})
                                            </span>))}
                                        </div>}
                                    </div>
                                    <div className="flex flex-col flex-1 text-2xl font-bold justify-center text-center items-center">
                                        <span>{this.state.matchDetails.matchHeader.team2.name}</span>
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </div >)
    };
}

const mapStateToProps = (state) => ({
    isLoading: state.counter.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
    counterSlice: bindActionCreators(commanActionCreator, dispatch)
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CricketAppDetailsComponent));
