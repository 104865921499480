import React from "react";
import { Draggable } from "react-beautiful-dnd";
import Playee from "../../appIcons/Playee";

function DragPlayList(props) {
    const { media, index } = props;

    return (
        <Draggable
            key={"id-" + media.id}
            draggableId={"id-" + media._id}
            index={index}>
            {(provider) => (
                <div className="flex py-2 px-4 bg-white shadow-md my-2 truncate rounded-lg"{...provider.dragHandleProps} {...provider.draggableProps} ref={provider.innerRef}>
                    <div className="flex  basis-1/2" >
                            <Playee style="self-center"/>
                        <div className="flex flex-col ml-4 self-center w-10 xl:w-52 lg:w-40 md:w-32 inline-block truncate">
                            <div className="truncate text-black text-sm"><span>{media.name}</span></div>
                            <span className="text-gray-450 text-xs">{(media.status === "DRAFT") ? "Publish required." : media.status}</span>
                        </div>
                    </div>
                </div>
            )}
        </Draggable>
    );
}

export default DragPlayList;