import React from "react";
import { connect } from "react-redux";

import { withRouter } from "../../router";
import * as commanActionCreator from '../../reducers/commanReduces'
import { bindActionCreators } from "@reduxjs/toolkit";
import { ToastContainer, toast } from 'react-toastify';
import { fetchUserProfile, updateOrgService, updateUserInfo, updateUserPassword } from "../../services/profiledetailsService";
import { FaCheckCircle, FaEye, FaEyeSlash } from "react-icons/fa";
import { Button } from "react-bootstrap";

class ProfileComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            profileData: "",
            ownerName: "",
            ownerEmail: "",
            ownerPhoneNumber: "",
            orgName: "",
            companyAddress: "",
            currentPassword: "",
            newPassword: "",
            confirmPassword: "",
            updatePassword: false,
            isNameDisabled: true,
            isValidNumber: true,
            editMobile: false,
            isCompnayDetailsDisabled: true,
            visible: false,
            isMatch: false
        }
    }

    async componentDidMount() {
        this.props.counterSlice.startLoading();
        const queryParams = new URLSearchParams(this.props.router.location.search);
        let profileData = await fetchUserProfile();
        this.setState({ profileData: profileData.body });
        this.populateProfileData(profileData.body);
        if (queryParams.get('updatePassword') == 1) {
            this.setState({ updatePassword: true });
        }
        this.props.counterSlice.stopLoading();
    }

    populateProfileData = (pd) => {
        if (pd) {
            let ownerName = "";
            let ownerEmail = "";
            let ownerPhoneNumber = "";
            let companyAddress = "";
            if (pd.orgId.orgOwner) {
                ownerName = pd.orgId.orgOwner.firstName + " " + pd.orgId.orgOwner.lastName;
                ownerEmail = pd.orgId.orgOwner.email;
                ownerPhoneNumber = pd.orgId.orgOwner.phoneNumber;
                companyAddress = pd.orgId.address
            }

            this.setState({
                firstName: pd.firstName,
                lastName: pd.lastName,
                orgName: pd.orgId.name,
                phoneNumber: pd.phoneNumber,
                editMobile: pd.phoneNumber ? true : false,
                ownerName,
                ownerEmail,
                ownerPhoneNumber,
                companyAddress
            })
        }
    }

    updateUserInfo = async (userDTO) => {
        let userRes = await updateUserInfo(userDTO);
        if (userRes.success) {
            let profileData = await fetchUserProfile();
            this.populateProfileData(profileData.body)
            this.setState({ profileData: profileData.body, isNameDisabled: true });
            toast.success('Profile updated.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        } else {
            toast.error('Failed to update profile.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        }
    }

    updateOrgData = async (orgDTO) => {

        let response = await updateOrgService(orgDTO);
        if (response.success) {
            let profileData = await fetchUserProfile();
            this.setState({ profileData: profileData.body });
            const userInfo = JSON.parse(localStorage.getItem("userInfo"));
            userInfo.orgName = this.state.orgName;
            localStorage.setItem("userInfo", JSON.stringify(userInfo));
            this.setState({ isCompnayDetailsDisabled: true });
            this.populateProfileData(profileData.body);
            toast.success('Organization details updated.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        } else {
            toast.error('Error while updating organization.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        }
    }

    onInputChange = (type, e) => {
        this.setState({ [type]: e.target.value });
    }
    updatesUserPassword = async () => {
        let symbolRegex = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        let upperCaseRegex = /[A-Z]/;
        let numberRegex = /[0-9]/;
        let lowercaseRegex = /[a-z]/;
        let password = this.state.newPassword;
        let isError = false;
        let message = "";
        if (this.state.newPassword.length < 9) {
            message = "Password should has at least 8 characters";
            isError = true;
        } else if (!upperCaseRegex.test(password)) {
            message = "Password should contains more than 1 uppercase letters";
            isError = true;
        } else if (!numberRegex.test(password)) {
            message = "Password should contains more than 1 numbers";
            isError = true;
        } else if (!lowercaseRegex.test(password)) {
            message = "Password should contains more than 1 lowercase letters";
            isError = true;
        } else if (!symbolRegex.test(password)) {
            message = "Password should contains more than 1 symbols";
            isError = true;
        }

        if (isError) {
            toast.error(message, {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
            return;
        }

        let userDTO = {
            password: this.state.currentPassword,
            newPassword: this.state.newPassword,
            _id: this.state.profileData._id
        }
        let userRes = await updateUserPassword(userDTO);
        if (userRes.success) {
            toast.success('Password Updated Successfully.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        } else {
            toast.error(userRes.message, {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        }
    }

    validateName = (text, type) => {

    }
    render() {
        let EditIcon = ({ className }) => (<svg width="17" height="17" className={className} viewBox="0 0 17 17" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.74312 11.3238V11.3471L0.187198 15.9715C0.162877 16.0448 0.159425 16.1235 0.177226 16.1986C0.195026 16.2738 0.233378 16.3425 0.287996 16.3971C0.342614 16.4518 0.411345 16.4901 0.486507 16.5079C0.561669 16.5257 0.6403 16.5223 0.713612 16.4979L5.32473 14.962H5.34805C5.39385 14.9419 5.43562 14.9137 5.47133 14.8787L16.0429 4.30712C16.3482 3.99572 16.5191 3.57706 16.5191 3.14102C16.5191 2.70497 16.3482 2.28631 16.0429 1.97491L14.7102 0.642216C14.3988 0.336984 13.9802 0.166016 13.5441 0.166016C13.1081 0.166016 12.6894 0.336984 12.378 0.642216L1.82641 11.2005C1.79136 11.2361 1.76313 11.2779 1.74312 11.3238ZM2.30285 12.2633L4.42183 14.3823L1.24003 15.4418L2.30285 12.2633ZM10.259 3.93397L12.7445 6.41944L5.19479 13.9791L2.70932 11.4937L10.259 3.93397ZM11.1386 3.05439L13.6207 5.53986L13.3309 5.82972L10.8454 3.34425L11.1386 3.05439ZM12.9711 1.22194C13.1259 1.06772 13.3356 0.981134 13.5541 0.981134C13.7727 0.981134 13.9823 1.06772 14.1372 1.22194L15.4432 2.5513C15.5201 2.62804 15.581 2.71919 15.6227 2.81952C15.6643 2.91985 15.6857 3.0274 15.6857 3.13602C15.6857 3.24463 15.6643 3.35219 15.6227 3.45252C15.581 3.55285 15.5201 3.644 15.4432 3.72074L14.2105 4.96347L11.7283 2.478L12.9711 1.22194Z" strokeWidth="0.2125" />
            <path d="M7.40332 9.98494C7.4409 10.0248 7.48598 10.0569 7.53599 10.0794C7.58599 10.1018 7.63994 10.1141 7.69472 10.1157C7.74951 10.1172 7.80406 10.108 7.85525 10.0884C7.90644 10.0688 7.95327 10.0393 7.99304 10.0016L11.468 6.72984C11.5426 6.65295 11.5847 6.5503 11.5855 6.4432C11.5864 6.3361 11.5459 6.23279 11.4726 6.15473C11.3992 6.07668 11.2987 6.02988 11.1917 6.02405C11.0848 6.01823 10.9797 6.05383 10.8983 6.12346L7.41998 9.39523C7.38008 9.4328 7.348 9.47789 7.32556 9.52789C7.30312 9.5779 7.29078 9.63184 7.28923 9.68663C7.28768 9.74141 7.29696 9.79596 7.31654 9.84716C7.33612 9.89835 7.36561 9.94517 7.40332 9.98494Z" />
        </svg>);
        let regexforName = /^[A-Za-z]+$/;
        return (
            <div className="flex gap-4 mt-4 text-sm">
                <div className="flex flex-col gap-2 bg-white flex-1 rounded shadow p-3">
                    <span className="text-gray-450 text-sm">My Profile</span>
                    <div className=" flex flex-col h-20 w-20 rounded-full flex justify-center bg-dark-team text-center">
                        <span className="text-white text-4xl font-medium">{this.state.profileData && this.state.profileData.firstName.charAt(0).toUpperCase() + this.state.profileData.lastName.charAt(0).toUpperCase()}</span>
                    </div>
                    {this.state.updatePassword ? <div className="gap-2 flex flex-col">
                        <div >
                            <div><span className="text-sm text-black">Current Password</span>
                            </div>
                            <div className="border-2 border-gray-20 flex p-2 rounded">
                                <input type="password" className="text-gray-500" placeholder="Current Password" value={this.state.currentPassword}
                                    onChange={(e) => this.onInputChange("currentPassword", e)} />
                            </div>
                        </div>
                        <div>
                            <div><span className="text-sm text-black">New Password</span>
                            </div>
                            <div className="border-2 border-gray-20 flex p-2 rounded">
                                <input type="password" className="text-gray-500" placeholder="New Password" value={this.state.newPassword}
                                    onChange={(e) => this.onInputChange("newPassword", e)} />
                                {this.state.isMatch && <FaCheckCircle className="self-center text-green-200" size={15} />}
                            </div>
                        </div>
                        <div>
                            <div><span className="text-sm text-black">Confirm Password</span>
                            </div>
                            <div className="border-2 border-gray-20 flex p-2 rounded">
                                <input type={this.state.visible ? "text" : "password"} className="text-gray-500" placeholder="Confirm Password" value={this.state.confirmPassword}
                                    onChange={(e) => {
                                        this.setState({ isMatch: (this.state.newPassword === e.target.value) })
                                        this.onInputChange("confirmPassword", e)
                                    }} />
                                <button onClick={() => this.setState({ visible: !this.state.visible })}>{this.state.visible ? <FaEye className="opacity-50" size={20} /> : <FaEyeSlash className="opacity-50" size={20} />}</button>
                            </div>
                        </div>
                        <div className="flex justify-center gap-2">
                            <button className="border-1 text-black rounded p-2" onClick={() => this.setState({ updatePassword: false })}>Back</button>
                            <Button className="text-white bg-darkBlue rounded p-2" disabled={this.state.currentPassword === "" || !this.state.isMatch} onClick={this.updatesUserPassword}>Save</Button>
                        </div>
                    </div> :
                        <><div className="flex-1 flex text-black gap-2">
                            <div className="self-center flex-1">
                                <div><span className="text-sm">First Name</span>
                                </div>
                                <div className="border-2 border-gray-20 flex p-2 rounded">
                                    <input type="text" maxLength={20} className={"text-black"}
                                        placeholder="Enter First Name" value={this.state.firstName}
                                        onBlur={(e) => {
                                            let userDTO = {
                                                firstName: this.state.firstName,
                                                _id: this.state.profileData._id
                                            }
                                            if (e.target.value.trim() !== "" && (this.state.profileData.firstName !== this.state.firstName))
                                                this.updateUserInfo(userDTO);
                                        }}
                                        onChange={(e) => (regexforName.test(e.target.value) || e.target.value === '') && this.onInputChange("firstName", e)} />
                                </div>
                            </div>
                            <div className="self-center flex-1">
                                <div className="flex">
                                    <span className="text-sm flex flex-1">Last Name </span>
                                    {/* <button title="Edit" onClick={() => this.setState({ isNameDisabled: false })}><EditIcon className={this.state.isNameDisabled ? "fill-gray-450" : "fill-black"} /></button> */}
                                </div>
                                <div className="border-2 border-gray-20 flex p-2 rounded">
                                    <input type="text" maxLength={20} value={this.state.lastName} className={"text-black"} placeholder="Enter Last Name"
                                        onBlur={(e) => {
                                            let userDTO = {
                                                lastName: this.state.lastName,
                                                _id: this.state.profileData._id
                                            }
                                            if (e.target.value !== "" && (this.state.profileData.lastName !== this.state.lastName))
                                                this.updateUserInfo(userDTO);
                                        }} onChange={(e) => (regexforName.test(e.target.value) || e.target.value === '') && this.onInputChange("lastName", e)} />
                                </div>
                            </div>
                        </div>
                            <div>
                                <div><span className="text-sm text-black">E-mail id</span>
                                </div>
                                <div className="border-2 border-gray-20 flex p-2 rounded">
                                    <input type="text" className="text-gray-500" disabled={true} defaultValue={this.state.profileData.email} />
                                </div>
                                <div className="text-end">
                                    <button className="text-gray-450 text-sm hover:underline" onClick={() => this.setState({ updatePassword: true })}>Change Password</button>
                                </div>
                            </div>
                            <div>
                                <div className="flex">
                                    <span className="text-sm flex flex-1">Mobile Number</span>
                                    <button title="Edit" onClick={() => this.setState({ editMobile: false })}><EditIcon className={this.state.editMobile ? "fill-gray-450" : "fill-black"} /></button>
                                </div>
                                <div className="flex">
                                    <div className="border-2 flex-1 flex border-gray-20 flex p-2 rounded">
                                        <input type="tel" className="text-black" placeholder="Please Enter Mobile Number" value={this.state.phoneNumber}
                                            pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                                            disabled={this.state.editMobile}
                                            onChange={(e) => this.onInputChange("phoneNumber", e)} />
                                        {this.state.profileData && (this.state.profileData.isNumberVerified && this.state.editMobile) &&
                                            <FaCheckCircle className="self-center text-green-500" size={20} />}

                                    </div>
                                    {this.state.profileData && (!this.state.profileData.isNumberVerified || !this.state.editMobile) && <button onClick={() => {
                                        let phoneno = /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/;
                                        if (this.state.phoneNumber.trim() && this.state.phoneNumber.match(phoneno)) {
                                            let userDTO = {
                                                phoneNumber: this.state.phoneNumber,
                                                isNumberVerified: true,
                                                _id: this.state.profileData._id
                                            }
                                            this.setState({ isValidNumber: true });
                                            this.updateUserInfo(userDTO);
                                        } else {
                                            this.setState({ isValidNumber: false });
                                        }

                                    }} className="rounded bg-darkBlue text-white -ml-2 text-sm px-2">Add Number</button>}
                                </div>
                                {!this.state.isValidNumber && <span className="text-red-500 text-xs">Invalid Mobile Number</span>}
                            </div></>}
                </div>
                <div className="flex flex-col gap-3 bg-white flex-1 rounded shadow p-3">
                    <div className="flex justify-between gap-2">
                        <span className="text-gray-450 text-sm">Company Profile</span>
                    </div>
                    <div>
                        <div><span className="text-sm text-black">Company Name</span>
                        </div>
                        <div className="border-2 border-gray-20 flex p-2 rounded">
                            <input type="text" className="text-black" placeholder="Enter Compnay Name"
                                value={this.state.orgName}
                                maxLength={30}
                                onBlur={(e) => {
                                    let orgDTO = {
                                        _id: this.state.profileData.orgId._id,
                                        name: this.state.orgName.trim(),
                                    };
                                    (e.target.value.trim() !== "" && (this.state.profileData.orgId.name !== this.state.orgName)) &&
                                        this.updateOrgData(orgDTO)
                                }}
                                onChange={(e) => this.onInputChange("orgName", e)}
                            />
                        </div>
                    </div>
                    <div>
                        <div><span className="text-sm text-black">Company Address</span>
                        </div>
                        <div className="border-2 border-gray-20 flex p-2 rounded">
                            <input type="text" placeholder="Enter Compnay Address" className="text-black"
                                value={this.state.companyAddress}
                                onBlur={(e) => {
                                    let orgDTO = {
                                        _id: this.state.profileData.orgId._id,
                                        address: this.state.companyAddress
                                    };
                                    (e.target.value.trim() !== "" && (this.state.profileData.orgId.address !== this.state.companyAddress)) && this.updateOrgData(orgDTO)
                                }}
                                onChange={(e) => this.onInputChange("companyAddress", e)}
                            />
                        </div>
                    </div>
                    <div className="flex gap-1 text-gray-450">
                        <span>Owner Information</span>
                        <hr className="self-center flex-1 text-black bg-black" />
                    </div>
                    <div>
                        <div><span className="text-sm text-black">Owner Name</span>
                        </div>
                        <div className="border-2 border-gray-20 flex p-2 rounded">
                            <input type="text" className="text-gray-500"
                                defaultValue={this.state.ownerName}
                                disabled={true} />
                        </div>
                    </div>
                    <div className="flex-1 flex text-black gap-2">
                        <div className="self-center flex-1">
                            <div><span className="text-sm">E-mail id</span>
                            </div>
                            <div className="border-2 border-gray-20 flex p-2 rounded">
                                <input type="text" className="text-gray-500"
                                    defaultValue={this.state.ownerEmail}
                                    disabled={true} />
                            </div>
                        </div>
                        <div className="self-center flex-1">
                            <span className="text-sm flex flex-1">Mobile Number</span>
                            <div className="border-2 border-gray-20 flex p-2 rounded">
                                <input type="text" value={this.state.ownerPhoneNumber}
                                    className="text-gray-500"
                                    disabled={true} />
                            </div>
                        </div>
                    </div>
                </div>

                <ToastContainer />
            </div>
        );
    }
};

const mapStateToProps = (state) => ({
    isLoading: state.counter.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
    counterSlice: bindActionCreators(commanActionCreator, dispatch)
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProfileComponent));
