



import React from 'react';

const Mediaa = () => {
  return (
    <><svg width="22" height="22" viewBox="0 0 22 22" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
<path d="M21.736 6.3372H15.4273L13.1907 3.77182C13.1404 3.71415 13.0678 3.68131 12.9918 3.68131H6.20682L5.54964 2.96962C5.49967 2.91541 5.42927 2.88477 5.35573 2.88477H0.264C0.118328 2.88477 0 3.00309 0 3.14877V19.0423C0 19.1879 0.118328 19.3063 0.264 19.3063H21.736C21.8817 19.3063 22 19.1879 22 19.0423V6.60118C22 6.45535 21.8817 6.3372 21.736 6.3372ZM12.872 4.20963L14.7271 6.33752H8.6586L6.69416 4.20963H12.872ZM21.4721 18.778H0.528285V3.4127H5.24026L8.34948 6.78028C8.39946 6.83449 8.46986 6.86513 8.5434 6.86513H15.305C15.3061 6.86513 15.3071 6.86576 15.3082 6.86576C15.3096 6.86576 15.311 6.86513 15.3121 6.86513H21.4723L21.4721 18.778Z" />
</svg>&nbsp;</>

  )
}

export default Mediaa;
