import React from "react";
import { Modal, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "../../router";
import { ToastContainer, toast } from 'react-toastify';
import { fetchScreenDetails, setToScreenService } from "../../services/screenService";

import { MdResetTv } from 'react-icons/md';
import { FaRegCopy, FaEye, FaTrashAlt, FaTimes } from 'react-icons/fa';
import { Form, } from "react-bootstrap";
import { MdSearch } from 'react-icons/md';
import { fetchChannelDetails, createChannel, deleteChannel, attachedChannelDataList } from "../../services/channelServices";
import SearchWithButton from "./../utilComponents/searchWithButton";
import { DataIsEmpty, IconButton } from "./../utilComponents/inputFieldAndButtons";
import { bindActionCreators } from "@reduxjs/toolkit";
import * as commanActionCreator from '../../reducers/commanReduces';
import AdminSidebar from "./subComponents/adminSidebar";
import Navbar from "./../subComponents/Navbar";
import Channels from "../../appIcons/Channels";
import SetToScreenModel from "./../utilComponents/setToScreenModel";

class ScreenManager extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      // addChannelModel: false,
      // deleteChannelModel: false,
      // channelName: "",
      // addChannelScreen: false,
      // setToScreenModel: false,
      // selectAll: false,
      // attachedList: {},
      // screenTempList: [],
      // selectedChannelObject: "",
      // addContentSlider: {
      //   isPlayList: false,
      //   isMedia: true,
      //   isLinks: false,
      //   isApps: false
      // },
      screenData: [],
      // screenList: [],
    }
  }
  async componentDidMount() {
    const APIRES = {
      "success": true,
      "message": "Channel data fetched successfully",
      "body": [
          {
              "_id": "64b559bd6bc3614d403659b4",
              "name": "master",
              "orgId": "64a26defd6bea500264ec617",
              "status": "DRAFT",
              "createdBy": {
                  "_id": "64a26defd6bea500264ec615",
                  "firstName": "Aman",
                  "lastName": "Mukati"
              },
              "modifiedBy": {
                  "_id": "64a26defd6bea500264ec615",
                  "firstName": "Aman",
                  "lastName": "Mukati"
              },
              "modifiedOn": "2023-07-17T15:13:12.884Z",
              "createdOn": "2023-07-17T15:09:49.795Z",
              "channelPlayList": [
                  {
                      "isAuditLog": false,
                      "_id": "64b55a886bc3614d403659fb",
                      "duration": "00:00:10",
                      "refrenceId": "64b549676bc3614d403656a6",
                      "type": "MEDIA",
                      "schedulingList": {
                          "isEveryDay": true,
                          "setPriority": false,
                          "isAllDate": true,
                          "isAllTime": true,
                          "scheduledData": "111111111111111111111111|111111111111111111111111|111111111111111111111111|111111111111111111111111|111111111111111111111111|111111111111111111111111|111111111111111111111111",
                          "_id": "64b55a886bc3614d403659fc",
                          "selectedDate": []
                      }
                  }
              ]
          },
          {
              "_id": "64b554206bc3614d40365843",
              "name": "yashi",
              "orgId": "64a26defd6bea500264ec617",
              "status": "PUBLISHED",
              "createdBy": {
                  "_id": "64a26defd6bea500264ec615",
                  "firstName": "Aman",
                  "lastName": "Mukati"
              },
              "modifiedBy": {
                  "_id": "64a26defd6bea500264ec615",
                  "firstName": "Aman",
                  "lastName": "Mukati"
              },
              "modifiedOn": "2023-07-17T14:47:15.734Z",
              "createdOn": "2023-07-17T14:45:52.709Z",
              "channelPlayList": [
                  {
                      "isAuditLog": false,
                      "_id": "64b554726bc3614d40365867",
                      "duration": "00:00:12",
                      "refrenceId": "64b54e456bc3614d403656b6",
                      "type": "MEDIA",
                      "schedulingList": {
                          "isEveryDay": true,
                          "setPriority": false,
                          "isAllDate": true,
                          "isAllTime": true,
                          "scheduledData": "111111111111111111111111|111111111111111111111111|111111111111111111111111|111111111111111111111111|111111111111111111111111|111111111111111111111111|111111111111111111111111",
                          "_id": "64b554726bc3614d40365868",
                          "selectedDate": []
                      }
                  }
              ]
          },
          {
              "_id": "64b554956bc3614d4036588c",
              "name": "yashi-(Copy)",
              "orgId": "64a26defd6bea500264ec617",
              "status": "DRAFT",
              "createdBy": {
                  "_id": "64a26defd6bea500264ec615",
                  "firstName": "Aman",
                  "lastName": "Mukati"
              },
              "modifiedBy": {
                  "_id": "64a26defd6bea500264ec615",
                  "firstName": "Aman",
                  "lastName": "Mukati"
              },
              "modifiedOn": "2023-07-17T14:47:49.123Z",
              "createdOn": "2023-07-17T14:45:52.709Z",
              "channelPlayList": [
                  {
                      "isAuditLog": false,
                      "_id": "64b554726bc3614d40365867",
                      "duration": "00:00:12",
                      "refrenceId": "64b54e456bc3614d403656b6",
                      "type": "MEDIA",
                      "schedulingList": {
                          "isEveryDay": true,
                          "setPriority": false,
                          "isAllDate": true,
                          "isAllTime": true,
                          "scheduledData": "111111111111111111111111|111111111111111111111111|111111111111111111111111|111111111111111111111111|111111111111111111111111|111111111111111111111111|111111111111111111111111",
                          "_id": "64b554726bc3614d40365868",
                          "selectedDate": []
                      }
                  }
              ]
          },
          {
              "_id": "64b554986bc3614d40365898",
              "name": "yashi-(Copy)",
              "orgId": "64a26defd6bea500264ec617",
              "status": "DRAFT",
              "createdBy": {
                  "_id": "64a26defd6bea500264ec615",
                  "firstName": "Aman",
                  "lastName": "Mukati"
              },
              "modifiedBy": {
                  "_id": "64a26defd6bea500264ec615",
                  "firstName": "Aman",
                  "lastName": "Mukati"
              },
              "modifiedOn": "2023-07-17T14:47:52.486Z",
              "createdOn": "2023-07-17T14:45:52.709Z",
              "channelPlayList": [
                  {
                      "isAuditLog": false,
                      "_id": "64b554726bc3614d40365867",
                      "duration": "00:00:12",
                      "refrenceId": "64b54e456bc3614d403656b6",
                      "type": "MEDIA",
                      "schedulingList": {
                          "isEveryDay": true,
                          "setPriority": false,
                          "isAllDate": true,
                          "isAllTime": true,
                          "scheduledData": "111111111111111111111111|111111111111111111111111|111111111111111111111111|111111111111111111111111|111111111111111111111111|111111111111111111111111|111111111111111111111111",
                          "_id": "64b554726bc3614d40365868",
                          "selectedDate": []
                      }
                  }
              ]
          },
          {
              "_id": "64b5549b6bc3614d403658a5",
              "name": "yashi-(Copy)-(Copy)",
              "orgId": "64a26defd6bea500264ec617",
              "status": "DRAFT",
              "createdBy": {
                  "_id": "64a26defd6bea500264ec615",
                  "firstName": "Aman",
                  "lastName": "Mukati"
              },
              "modifiedBy": {
                  "_id": "64a26defd6bea500264ec615",
                  "firstName": "Aman",
                  "lastName": "Mukati"
              },
              "modifiedOn": "2023-07-17T14:47:55.787Z",
              "createdOn": "2023-07-17T14:45:52.709Z",
              "channelPlayList": [
                  {
                      "isAuditLog": false,
                      "_id": "64b554726bc3614d40365867",
                      "duration": "00:00:12",
                      "refrenceId": "64b54e456bc3614d403656b6",
                      "type": "MEDIA",
                      "schedulingList": {
                          "isEveryDay": true,
                          "setPriority": false,
                          "isAllDate": true,
                          "isAllTime": true,
                          "scheduledData": "111111111111111111111111|111111111111111111111111|111111111111111111111111|111111111111111111111111|111111111111111111111111|111111111111111111111111|111111111111111111111111",
                          "_id": "64b554726bc3614d40365868",
                          "selectedDate": []
                      }
                  }
              ]
          },
          {
              "_id": "64b554fe6bc3614d403658b3",
              "name": "yashi-(Copy)",
              "orgId": "64a26defd6bea500264ec617",
              "status": "DRAFT",
              "createdBy": {
                  "_id": "64a26defd6bea500264ec615",
                  "firstName": "Aman",
                  "lastName": "Mukati"
              },
              "modifiedBy": {
                  "_id": "64a26defd6bea500264ec615",
                  "firstName": "Aman",
                  "lastName": "Mukati"
              },
              "modifiedOn": "2023-07-17T14:49:34.554Z",
              "createdOn": "2023-07-17T14:45:52.709Z",
              "channelPlayList": [
                  {
                      "isAuditLog": false,
                      "_id": "64b554726bc3614d40365867",
                      "duration": "00:00:12",
                      "refrenceId": "64b54e456bc3614d403656b6",
                      "type": "MEDIA",
                      "schedulingList": {
                          "isEveryDay": true,
                          "setPriority": false,
                          "isAllDate": true,
                          "isAllTime": true,
                          "scheduledData": "111111111111111111111111|111111111111111111111111|111111111111111111111111|111111111111111111111111|111111111111111111111111|111111111111111111111111|111111111111111111111111",
                          "_id": "64b554726bc3614d40365868",
                          "selectedDate": []
                      }
                  }
              ]
          }
      ]
  }
    // this.props.counterSlice.startLoading();
    // let channelData = await fetchChannelDetails();
    // console.log(channelData)
    this.setState({ screensDataList: APIRES.body, screenData: APIRES.body });
    this.props.counterSlice.stopLoading();
  }
  // closeAll = () => {
  //   this.setState({ deleteChannelModel: false, selectAll: false, setToScreenModel: false, screenList: [], addChannelModel: false, channelName: "", channelId: "", addChannelScreen: false });
  // }
  // saveChannelList = async () => {
  //   const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  //   let channelDTO = {
  //     name: this.state.channelName,
  //     orgId: userInfo.orgId,
  //     status: "DRAFT"
  //   };
  //   let response = await createChannel(channelDTO);
  //   if (response.success) {
  //     this.props.router.navigate("/channels/" + response.body._id);
  //   } else {
  //     toast.error('Error while adding channel.', {
  //       position: "top-right",
  //       autoClose: 1000,
  //       hideProgressBar: true,
  //       closeOnClick: true,
  //       pauseOnHover: false,
  //       draggable: true,
  //       theme: "colored",
  //       progress: undefined,
  //     });
  //   }
  // }
  // copyChannelList = async (copyData) => {
  //   copyData._id = undefined;
  //   copyData.status = "DRAFT";
  //   copyData.name = copyData.name + "-(Copy)"
  //   let response = await createChannel(copyData);
  //   if (response.success) {

  //     let dasboardResponse = await fetchChannelDetails();
  //     this.setState({ screenData: dasboardResponse.body });
  //     toast.success('Channel created successfully.', {
  //       position: "top-right",
  //       autoClose: 1000,
  //       hideProgressBar: true,
  //       closeOnClick: true,
  //       pauseOnHover: false,
  //       draggable: true,
  //       theme: "colored",
  //       progress: undefined,
  //     });
  //   } else {
  //     toast.error('Error while adding channel.', {
  //       position: "top-right",
  //       autoClose: 1000,
  //       hideProgressBar: true,
  //       closeOnClick: true,
  //       pauseOnHover: false,
  //       draggable: true,
  //       theme: "colored",
  //       progress: undefined,
  //     });
  //   }
  // }

  // deleteChannel = async () => {
  //   this.props.counterSlice.startLoading();

  //   let response = await deleteChannel(this.state.channelId);
  //   if (response.success) {

  //     let dasboardResponse = await fetchChannelDetails();
  //     this.setState({ screenData: dasboardResponse.body });
  //     toast.success('Channel deleted successfully.', {
  //       position: "top-right",
  //       autoClose: 1000,
  //       hideProgressBar: true,
  //       closeOnClick: true,
  //       pauseOnHover: false,
  //       draggable: true,
  //       theme: "colored",
  //       progress: undefined,
  //     });
  //   } else {
  //     toast.error('Error while deleting channel.', {
  //       position: "top-right",
  //       autoClose: 1000,
  //       hideProgressBar: true,
  //       closeOnClick: true,
  //       pauseOnHover: false,
  //       draggable: true,
  //       theme: "colored",
  //       progress: undefined,
  //     });
  //   }
  //   this.props.counterSlice.stopLoading();

  //   this.closeAll()
  // }
  // attachedChannelData = async (c) => {
  //   this.props.counterSlice.startLoading();
  //   let attachedData = await attachedChannelDataList(c._id);
  //   this.props.counterSlice.stopLoading();
  //   this.setState({ deleteChannelModel: true, channelId: c._id, attachedList: attachedData.body })
  //   this.setState({ deleteChannelModel: true, addChannelScreen: false })
  // }
  // onSetToScreenClick = async (p) => {
  //   this.props.counterSlice.startLoading();
  //   let screenDetails = await fetchScreenDetails();
  //   let attachedData = await attachedChannelDataList(p._id);

  //   this.props.counterSlice.stopLoading();

  //   this.setState({ setToScreenModel: true, selectedChannelObject: p });
  //   if (screenDetails.success) {
  //     let screenList = screenDetails.body;

  //     if (attachedData.success && attachedData.body.screenList.length > 0) {
  //       let attachedScreenInfo = attachedData.body;
  //       screenList.forEach((s) => {
  //         s.isSelected = false;
  //         for (let i = 0; i < attachedScreenInfo.screenList.length; i++) {
  //           if (s._id === attachedScreenInfo.screenList[i]._id)
  //             s.isSelected = true;
  //         }
  //       });
  //     } else
  //       screenList.forEach((s) => s.isSelected = false);

  //     this.setState({ screenList: screenList, screenTempList: screenList });
  //   }
  // }
  // setToScreenService = async () => {
  //   let screenList = [];
  //   this.setState({ setToScreenModel: false });
  //   this.props.counterSlice.startLoading();

  //   this.state.screenList.forEach((s) => {
  //     if (s.isSelected)
  //       screenList.push(s._id);
  //   });

  //   let screenDTO = {
  //     type: "CHANNEL",
  //     refrenceId: this.state.selectedChannelObject._id,
  //     screenIdList: screenList
  //   };
  //   let screenDetails = await setToScreenService(screenDTO);
  //   if (screenDetails.success) {
  //     toast.success('Screens has been updated.', {
  //       position: "top-right",
  //       autoClose: 1000,
  //       hideProgressBar: true,
  //       closeOnClick: true,
  //       pauseOnHover: false,
  //       draggable: true,
  //       theme: "colored",
  //       progress: undefined,
  //     });
  //   } else {
  //     toast.error('Error while adding channel.', {
  //       position: "top-right",
  //       autoClose: 1000,
  //       hideProgressBar: true,
  //       closeOnClick: true,
  //       pauseOnHover: false,
  //       draggable: true,
  //       theme: "colored",
  //       progress: undefined,
  //     });
  //   }
  //   this.props.counterSlice.stopLoading();

  //   this.closeAll();
  // }
  // searchScreenName = (e) => {
  //     if ((e.target.value != "")) {
  //       console.log(e.target.value.length);
  //       if (e.target.value.length % 2 === 0) {
  //         let screenList = [];
  //         for (let i = 0; i < this.state.screenTempList.length; i++) {
  //           let s = this.state.screenTempList[i]
  //           for (let j = 0; j < s.tags.length; j++) {
  //             if (s.tags[j] && s.tags[j].tag.toUpperCase().includes(e.target.value.toUpperCase())) {
  //               screenList.push(s)
  //               break;
  //             }
  //           }
  //         }
  //         let screenNameSort = this.state.screenTempList.filter((s) => s.name.toUpperCase().includes(e.target.value.toUpperCase()));
  //         let screenNameList = screenList.concat(screenNameSort);
  //         this.setState({ screenList: this.removeDuplicates(screenNameList) });
  //       }
  //     } else
  //       this.setState({ screenList: this.state.screenTempList })
  // }
  // removeDuplicates = (arr) => {
  //   const uniqueArray = [];
  //   const idTracker = {};

  //   for (let obj of arr) {
  //     const id = obj._id;
  //     if (!idTracker[id]) {
  //       uniqueArray.push(obj);
  //       idTracker[id] = true;
  //     }
  //   }
  //   return uniqueArray;
  // }
  searchName = (e) => {
    if (e.target.value !== "") {
      this.setState({ screenData: this.state.screensDataList.filter((s) => s.name.toUpperCase().includes(e.target.value.toUpperCase())) })
    } else
      this.setState({ screenData: this.state.screensDataList })
  }

  render() {
    return (
      <div className="flex">
        <AdminSidebar type={"admin/screenManager"} />
        <div className="h-screen grow md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10 shadow">
          <Navbar router={this.props.router} counterSlice={this.props.counterSlice} />
          <div>
          <div className="flex px-11 py-2.5 shadow-lg ml-4 mr-5 text-3xl rounded-lg	">
                            <h1 className="self-center mr-5 text-black text-xl">Screen Manager</h1>
                            <div className="grow px-2 relative ">
                                <Form.Control
                                    size="lg"
                                    type="search"
                                    value={this.state.serachApp}
                                    placeholder="Search screens"
                                    className="me-2 px-5 placeholder:italic"
                                    aria-label="Search"
                                    onChange={(e) => this.searchName(e)}
                                />
                                <MdSearch opacity={0.5} className="absolute top-1 m-1" />
                            </div>
                        </div>                    
            <br />
            {(this.state.screenData.length === 0) ?
              <div className="flex h-full w-full justify-center items-center">No screens Available now</div> :
              <div className="mx-16">
                {this.state.screenData?.map((p, i) => {
                  return (<div className="flex bg-white p-3 hover:shadow-md my-2 rounded-lg group" key={p._id}>
                    {/* <div className={"rounded-lg self-center cursor-pointer border-solid border-1 p-2 bg-dark-team"} onClick={() => this.props.router.navigate("/channels/" + p._id)}>
                      <Channels style="self-center h-8 w-8" />
                    </div> */}
                    <div className="grow px-3 cursor-pointer self-center" onClick={() => this.props.router.navigate("/screens")}>
                      <span className="text-gray-800 text-bold flex-auto">{p.name}</span>
                      {/* <p className={(p.status === "DRAFT") ? "text-currentSecondaryColor text-xs italic" : "text-gray-400 lowercase text-xs italic"}>{(p.status === "DRAFT") ? "Draft Saved. Publish required." : p.status}</p> */}
                    </div>
                    {/* <div className=" self-center flex mt-2 z-10">
                      <IconButton toolTip={"Duplicate"} icon={<FaRegCopy size={20} />} customFunc={() => { this.copyChannelList(p) }} />
                      <IconButton toolTip={"Preview"} icon={<FaEye size={20} />} customFunc={() => this.props.router.navigate("/channels/" + p._id+"?isPreview=1")} />
                      <IconButton toolTip={"Set To screen"} icon={<MdResetTv size={20} />} customFunc={() => this.onSetToScreenClick(p)} />
                      <IconButton toolTip={"Delete"} icon={<FaTrashAlt size={20} />} customFunc={() => this.attachedChannelData(p)} />
                    </div> */}
                  </div>);
                })}</div>}
          </div>
        </div>
        {/* <Modal
          show={this.state.addChannelModel}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered>
          <Modal.Body className="p-0 m-0">
            <div className="flex m-3 shadow-b-lg">
              <span className="flex-1 text-xl font-semibold">Create a new channel</span>
              <div onClick={this.closeAll}>
                <FaTimes size={25} className="self-center text-gray-400 hover:text-gray-600" />
              </div>
            </div>
            <hr />
            <div className="bg-blue-100 py-4">
              <div className="flex justify-center px-5 text-center text-sm"><span>Create a Channel to play individual media, show live URL feeds, Dashboards, integrated apps, Canvas creations and Playlists.</span></div>
              <div className="text-center  bg-blue-100 flex justify-center ">
                <img src={process.env.PUBLIC_URL + "/channel.svg"} alt="ChannelLogo" />
              </div>
              <div className=" bg-blue-100 flex justify-center ">
                <input size="lg" value={this.state.channelName} onChange={(e) => { this.setState({ channelName: e.target.value }) }} type="text" placeholder="Channel Name" className="w-3/5 focus:outline-none text-6xl text-currentSecondaryColor font-bold	text-center bg-transparent border-b-currentSecondaryColor" style={{ borderBottom: "2px solid" }} />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-primary" className="mr-2" id="addbtn" disabled={this.state.channelName.length > 0 ? false : true}
              onClick={() => {
                this.setState({ addChannelModel: false })
                this.saveChannelList()
              }}>Continue</Button>
          </Modal.Footer>
        </Modal> */}
        {/* <Modal show={this.state.deleteChannelModel} onHide={this.closeAll}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Channel</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>Are you sure, you would like to delete this channel permanently?</div>
            <br />
            {this.state.attachedList && this.state.attachedList.screenList && this.state.attachedList.screenList.length > 0 && <div>
              <span className="text-gray font-bold text-sm">Attached to screen</span>
              {this.state.attachedList.screenList?.map((s, i) => (
                <div key={i} className="text-xs">- {s.name}</div>
              ))}
            </div>}
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn btn-pr" onClick={this.closeAll}>
              Cancel
            </Button>
            <Button className="btn btn-prime" onClick={this.deleteChannel}>
              Delete
            </Button>

          </Modal.Footer>
        </Modal> */}
        {/* <SetToScreenModel
          setToScreenModel={this.state.setToScreenModel}
          closeAll={this.closeAll}
          searchScreenName={this.searchScreenName}
          selectAllScreen={() => {
            let isSelected = !this.state.selectAll ? true : false;
            this.state.screenList.forEach((sl) => sl.isSelected = isSelected);
            this.setState({ screenList: this.state.screenList, selectAll: !this.state.selectAll })
          }}
          isSelectAll={this.state.selectAll}
          screenList={this.state.screenList}
          routeTo={() => this.props.router.navigate("/screens")}
          onScreenSelected={(s, i) => {
            this.state.screenList[i].isSelected = !this.state.screenList[i].isSelected;
            let isAllSelected = true;
            this.state.screenList.forEach((si) => {
              if (!si.isSelected)
                isAllSelected = false;
            })
            this.setState({ screenList: this.state.screenList, selectAll: isAllSelected });
          }}
          setToScreenService={this.setToScreenService}
        /> */}
        <ToastContainer />
      </div>
    )
  };
}

const mapStateToProps = (state) => ({
  isLoading: state.counter.isLoading,
  currentColor: state.counter.currentColor
});

const mapDispatchToProps = (dispatch) => ({
  counterSlice: bindActionCreators(commanActionCreator, dispatch)
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ScreenManager));
