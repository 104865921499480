import React from "react";
import { connect } from "react-redux";
import { withRouter } from "../../router";
import { ToastContainer,toast } from "react-toastify";
import { bindActionCreators } from "@reduxjs/toolkit";
import * as commanActionCreator from "../../reducers/commanReduces";
import AdminSidebar from "../admin/subComponents/adminSidebar";
import AdminNavbar from "./subComponents/adminNavbar";
import { fetchGroupRules } from "./services/rolesManagement.service";
import { createRoles } from "./services/rolesManagement.service";

class RolesManagement extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rolesInfoList: [],
      showAddRole: true,
      roleName: "",
    };
  }
  async componentDidMount() {
    this.getRoles();
  }

  getRoles = async (searchText) => {
    
    this.props.counterSlice.startLoading();
    let rolesInfo = await fetchGroupRules();
    console.log(rolesInfo)
    this.setState({ rolesInfoList: rolesInfo.data });
    this.props.counterSlice.stopLoading();
  };

  viewMore = (r) => {
    console.log(r)
    this.props.router.navigate("/admin/rolesManagementDetails/" + r._id);
    // let obj = r.permissions;
    // const rolesList = [];
    // for (const key in obj) {
    //     const innerObj = obj[key];
    //     for (const innerKey in innerObj) {
    //         const innerValue = innerObj[innerKey];
    //         rolesList.push({ type: key, key: innerKey, value: innerValue });
    //     }
    // }
    // this.setState({ selectedRole: r, totalCount: Math.ceil(rolesList.length / this.state.limit), featureList: rolesList.slice(0, this.state.limit), rolesList, isViewMore: true });
}

  onInputChange = (type, event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  createRoles = async () => {   
    
    
    if (
      this.state.roleName == ""    
    ) {
      toast.error("Please enter role name", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        theme: "colored",
        progress: undefined,
      });
      return;
    }

    let userDTO = {
      "name": this.state.roleName,
      "isPublic":true
    };
    let response = await createRoles(userDTO);

    if (response.success) {
      this.state.roleName ="";
      this.setState({showAddRole : true}) 
      this.getRoles();
      toast.success(response.message, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        theme: "colored",
        progress: undefined,
      });
    } else {
      toast.error(response.message, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        theme: "colored",
        progress: undefined,
      });
    }
  }
  render() {
    return (
      <div className="flex">
        <AdminSidebar type={"admin/rolesManagement"} />
        <div className="h-screen grow md:overflow-hidden overflow-auto md:hover:overflow-auto  shadow">
          <AdminNavbar
            router={this.props.router}
            counterSlice={this.props.counterSlice}
          />
          <div className="flex px-11 py-4 shadow-md text-3xl ">
            <h1 className="self-center mr-5 text-black text-xl">
              Roles Management
            </h1>
          </div>
          <br />
          <div>
            <div className="px-11">
              <div className="flex flex-row items-center justify-end relative">
                {this.state.showAddRole && (
                  <span
                    onClick={() => {
                      console.log("in here");
                      this.setState({ showAddRole: false });
                    }}
                  >
                    + Add New Role
                  </span>
                )}
                {!this.state.showAddRole && (
                  <div>
                    <input
                      type="text"
                      placeholder="Enter name"
                      name="roleName"
                      value={this.state.roleName}
                      onChange={(e) => this.onInputChange("roleName", e)}
                      className="form-control  bg-transparent rounded-none pl-0"
                      style={{
                        width: "200px",
                        border: "0",
                        borderBottom: "1px solid #1C438F",
                      }}
                    />
                    <div
                      onClick={() => {
                        console.log("in here");
                       this.createRoles()
                      }}

                      style={{
                        background: "#1C438F",
                        width: "20px",
                        height: "20px",
                        position: "absolute",
                        top: "10px",
                        right: "0",
                      }}
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6 11.6405H18M18 11.6405L14 7.84961M18 11.6405L14 15.4313"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                )}
              </div>

              <div className="grid grid-cols-3  my-8 mx-20 justify-center">
                {this.state.rolesInfoList?.map((r, i) => (
                  <div
                    className={
                      i % 2 == 0
                        ? "bg-currentSecondaryColor bg-opacity-40 m-4"
                        : "bg-gray-400 m-4"
                    }
                  >
                    <div className="bg-white gap-y-2 -translate-y-2 -translate-x-2 shadow p-3 flex flex-col justify-center text-center">
                      <svg
                        className="self-center"
                        width="32"
                        height="35"
                        viewBox="0 0 32 35"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M20.0575 19.2576C19.9305 19.2237 19.796 19.2322 19.6742 19.2817C19.5525 19.3313 19.4502 19.4192 19.3829 19.5321L15.8217 25.6976L12.2604 19.5086C12.1955 19.3953 12.0948 19.3069 11.9741 19.2572C11.8533 19.2075 11.7195 19.1994 11.5937 19.2341C8.2779 20.1669 5.35469 22.1512 3.26385 24.8885C1.17301 27.6259 0.0276238 30.9681 0 34.4125C0 34.5686 0.0619827 34.7182 0.172313 34.8285C0.282643 34.9389 0.432283 35.0008 0.588313 35.0008H31.0551C31.2111 35.0008 31.3607 34.9389 31.4711 34.8285C31.5814 34.7182 31.6434 34.5686 31.6434 34.4125C31.615 30.9722 30.4698 27.6342 28.3803 24.901C26.2908 22.1678 23.37 20.1873 20.0575 19.2576ZM25.721 33.8242V30.8748C25.721 30.7188 25.6591 30.5691 25.5487 30.4588C25.4384 30.3485 25.2888 30.2865 25.1327 30.2865C24.9767 30.2865 24.8271 30.3485 24.7167 30.4588C24.6064 30.5691 24.5444 30.7188 24.5444 30.8748V33.8242H7.11466V30.8748C7.11466 30.7188 7.05268 30.5691 6.94235 30.4588C6.83202 30.3485 6.68238 30.2865 6.52635 30.2865C6.37032 30.2865 6.22068 30.3485 6.11035 30.4588C6.00002 30.5691 5.93804 30.7188 5.93804 30.8748V33.8242H1.19231C1.33609 30.8163 2.39921 27.9249 4.23817 25.5403C6.07714 23.1557 8.60341 21.3926 11.476 20.4891L15.3118 27.141C15.363 27.2312 15.4371 27.3063 15.5266 27.3586C15.6162 27.411 15.718 27.4387 15.8217 27.4391C15.9257 27.4404 16.0282 27.4133 16.118 27.3608C16.2079 27.3083 16.2817 27.2323 16.3316 27.141L20.1674 20.4891C23.0406 21.3916 25.5675 23.1544 27.4066 25.5392C29.2457 27.9241 30.3084 30.816 30.4511 33.8242H25.7446H25.721Z"
                          fill="#00807F"
                        />
                        <path
                          d="M22.03 27.9916C22.3138 27.9916 22.5912 27.9074 22.8271 27.7496C23.063 27.5919 23.2469 27.3677 23.3553 27.1055C23.4638 26.8432 23.492 26.5547 23.4364 26.2764C23.3809 25.9981 23.244 25.7426 23.0431 25.5421C22.8422 25.3416 22.5864 25.2053 22.308 25.1503C22.0296 25.0952 21.7411 25.1241 21.4791 25.2331C21.217 25.3421 20.9932 25.5264 20.836 25.7626C20.6788 25.9988 20.5951 26.2764 20.5957 26.5602C20.5972 26.9399 20.7489 27.3035 21.0177 27.5717C21.2864 27.8398 21.6503 27.9908 22.03 27.9916ZM22.03 25.5641C22.227 25.5641 22.4196 25.6226 22.5834 25.732C22.7472 25.8415 22.8748 25.997 22.9502 26.179C23.0256 26.361 23.0453 26.5613 23.0069 26.7546C22.9685 26.9478 22.8736 27.1253 22.7343 27.2646C22.595 27.4039 22.4175 27.4987 22.2243 27.5372C22.0311 27.5756 21.8308 27.5559 21.6488 27.4805C21.4668 27.4051 21.3112 27.2774 21.2017 27.1136C21.0923 26.9498 21.0339 26.7572 21.0339 26.5602C21.0346 26.2963 21.1398 26.0434 21.3265 25.8567C21.5131 25.6701 21.766 25.5649 22.03 25.5641Z"
                          fill="#00807F"
                        />
                        <circle
                          cx="15.8222"
                          cy="9.04097"
                          r="8.47591"
                          stroke="#00807F"
                          stroke-width="1.13012"
                        />
                      </svg>

                      <div
                        className={
                          i % 2 == 0
                            ? "text-currentSecondaryColor"
                            : "text-blueDark"
                        }
                      >
                        {r.name}
                      </div>
                      <button
                        className="text-darkBlue"
                        onClick={() => this.viewMore(r)}
                      >
                        View More
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.counter.isLoading,
  currentColor: state.counter.currentColor,
});

const mapDispatchToProps = (dispatch) => ({
  counterSlice: bindActionCreators(commanActionCreator, dispatch),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RolesManagement));
