import React from "react";
import { connect } from "react-redux";
import { withRouter } from "../router";
import { ToastContainer, toast } from 'react-toastify';
import { bindActionCreators } from "@reduxjs/toolkit";
import * as commanActionCreator from '../reducers/commanReduces'
import Navbar from "./subComponents/Navbar";
import { format } from 'date-fns'
import { DataIsEmptyWithoutButton, InputFieldWithEdit } from "./utilComponents/inputFieldAndButtons";
import { MdArrowBackIos, MdPreview, MdStayCurrentLandscape, MdStayCurrentPortrait } from "react-icons/md";
import { Button, Form } from "react-bootstrap";
import RssIcon from "../appIcons/RssIcon";
import { createApp, fetchAppData, getStockPrice } from "../services/appServices";
import RssPreview from "./subComponents/rssPreview";
import { updateMedia } from "../services/mediaService";
import StocksPreview from "./subComponents/stocksPreview";

class StockExchangeDetailsAppComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isNameEdit: false,
            isLandscape: true,
            appName: "Stock & Exchange app",
            idDisabled: true,
            isUpdate: false,
            duration: 10,
            stocksType: "",
            slotStocksList: [],
            elementsPerSlot: 8,
            stocksTypes: ["BANKNIFTY", "CNX100", "CNX200", "CNX500", "CNXALPHA", "CNXAUTO", "CNXCOMMO", "CNXCONSM", "CNXDEFTY", "CNXDIVOPT", "CNXENER", "CNXFIN", "CNXFMCG", "CNXHIGH", "CNXINFRA", "CNXIT", "CNXLOW", "CNXLOWV30", "CNXMCAP", "CNXMEDIA", "CNXMETAL", "CNXMNC", "CNXNFTYJUN", "CNXPHAR", "CNXPSE", "CNXPSU", "CNXREALTY", "CNXSCAP", "CNXSERV", "CPSE", "INDIAVIX", "LIX15", "LIX15MCAP", "LRGMID250", "NI15", "NIFADIBIR", "NIFESG", "NIFFINEX", "NIFFINSE", "NIFIND", "NIFMAHI", "NIFMIC", "NIFMIDS", "NIFMIDSE", "NIFMOM", "NIFMUL", "NIFREIN", "NIFSMQUA", "NIFTALPF", "NIFTATGRO", "NIFTATGRO25PC", "NIFTCOHOUS", "NIFTDEFE", "NIFTHOUS", "NIFTMANU", "NIFTMFIN", "NIFTMHEA", "NIFTMIT", "NIFTMOBI", "NIFTMOME", "NIFTNON", "NIFTPR1X", "NIFTPR2X", "NIFTRA", "NIFTTOTA", "NIFTTR1X", "NIFTTR2X", "NIFTY", "NIFTY100EQW", "NIFTY100ESG", "NIFTY200QUA", "NIFTY500VAL", "NIFTYALP", "NIFTYALPHQUAL", "NIFTYALPHVOLT30", "NIFTYCON", "NIFTYDIV", "NIFTYEQUWEI", "NIFTYM150", "NIFTYM50", "NIFTYMIDQUA", "NIFTYMSC400", "NIFTYOIL", "NIFTYPVTBANK", "NIFTYQUALVOLT30", "NIFTYSCAP250", "NIFTYSCAP50", "NIFTYSME", "NIFTYVALUEVOLT30", "NSEHCARE", "NSEQ30", "NV20"]

            // ["NIFTY 50", "NIFTY NEXT 50", "NIFTY 100", "NIFTY 200", "NIFTY 500", "NIFTY MIDCAP 50", "NIFTY MIDCAP 100", "NIFTY MIDCAP 150", 'NIFTY FMCG',
            //     "NIFTY SMLCAP 50", "NIFTY SMLCAP 100", "NIFTY SMLCAP 250", "NIFTY MIDSML 400", "NIFTY BANK", "NIFTY AUTO", "NIFTY FINSRV25 50", "NIFTY FIN SERVICE",
            //     "NIFTY IT", "NIFTY MEDIA", 'NIFTY METAL', 'NIFTY INFRA', 'NIFTY ENERGY', 'NIFTY PHARMA', 'NIFTY PSU BANK', 'NIFTY PVT BANK']
        }
    }
    async componentDidMount() {
        this.props.counterSlice.startLoading();
        if (this.props.router.params.appId) {
            let appInfo = await fetchAppData(this.props.router.params.appId);
            this.setState({
                appInfo: appInfo.body,
                appName: appInfo.body.name,
                duration: appInfo.body.timer,
                stocksType: appInfo.body.stocksType,
                isUpdate: true,
            });
            await this.onStockIndicateChange(appInfo.body.stocksType);
        }

        this.props.counterSlice.stopLoading();
    }

    onStockIndicateChange = async (type) => {
        this.props.counterSlice.startLoading();
        this.setState({ stocksType: type, slotStocksList: [] })
        let stocksPriceList = await getStockPrice(type);
        for (let i = 0; i < stocksPriceList.length; i += this.state.elementsPerSlot) {
            const slot = stocksPriceList.slice(i, i + this.state.elementsPerSlot);
            this.state.slotStocksList.push(slot);
        }
        this.setState({ slotStocksList: this.state.slotStocksList });
        this.props.counterSlice.stopLoading();
    }

    createAppService = async () => {
        if (this.state.appName.trim() == "") {
            toast.error('App name should not be empty.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
            return;
        }
        this.props.counterSlice.startLoading();
        let appDTO = {
            name: this.state.appName.trim(),
            stocksType: this.state.stocksType,
            timer: this.state.duration,
            appType: "STOCK"
        }
        let response = await createApp(appDTO);
        if (response.success) {

            toast.success('App saved successfully.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
            this.props.router.navigate("/apps");

        } else {
            toast.error(response.message, {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        }
        this.props.counterSlice.stopLoading();
    }

    updateAppService = async () => {
        if (this.state.appName.trim() == "") {
            toast.error('App name should not be empty.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
            return;
        }
        this.props.counterSlice.startLoading();
        let appDTO = {
            _id: this.state.appInfo._id,
            name: this.state.appName.trim(),
            stocksType: this.state.stocksType,
            timer: this.state.duration,
        }
        let response = await updateMedia(appDTO);
        if (response.success) {

            toast.success('App updated successfully.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        } else {
            toast.error('Error while updating app.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        }
        this.props.counterSlice.stopLoading();
    }

    render() {
        const userRole = JSON.parse(localStorage.getItem("roles"));

        let screenSize = { height: "410px", width: "717px" };
        if (!this.state.isLandscape) {
            screenSize = { width: "55vh", height: "68vh" };
        }
        return (
            <div>
                <Navbar router={this.props.router} counterSlice={this.props.counterSlice} />
                <div className="flex bg-white border-b-1 border-slate-300 w-full min-h-min	">
                    <div className="flex-none p-4 border-1"
                        onClick={() => this.props.router.navigate(-1)}>
                        <MdArrowBackIos size={20} />
                    </div>
                    <div className="self-center bg-dark-team rounded p-2 m-2">
                        <RssIcon style="fill-white" />
                    </div>
                    <div className="px-2 py-3 flex flex-col self-center grow">
                        <InputFieldWithEdit
                            isEdit={this.state.isNameEdit}
                            isRead={userRole.app["editName"].read}

                            onKeyDown={(e) => {
                                if (this.state.appName.trim() !== "" && (e.key === "Enter" || e.key === "Tab" || e.key === undefined)) {
                                    this.setState({ isNameEdit: false });
                                } else this.setState({ isNameEdit: true });
                            }}
                            inputValue={this.state.appName}
                            placeholder="Enter Instagram Gallery App Name"
                            onInputchange={(e) => { userRole.app["editName"].write && this.setState({ appName: e.target.value }); }}
                            onEditClick={() => { userRole.app["editName"].write && this.setState({ isNameEdit: !this.state.isNameEdit }) }} />
                        {this.state.isUpdate ? <span className="order-last text-sm text-gray-450">{format(new Date(), 'dd/MM/yyyy HH:mm')}, modified by {this.state.appInfo != "" && this.state.appInfo.modifiedBy && <span>{this.state.appInfo.modifiedBy.firstName} {this.state.appInfo.modifiedBy.lastName}</span>} </span> :
                            <span className="order-last text-sm text-gray-450">{format(new Date(), 'dd/MM/yyyy HH:mm')}</span>}
                    </div>
                    <div className="flex gap-4 self-center" >
                        <Button className="bg-darkBlue text-white rounded p-2 mr-4"
                            disabled={this.state.slotStocksList.length === 0}
                            onClick={this.state.isUpdate ? this.updateAppService : this.createAppService}>{this.state.isUpdate ? "Update" : "Save"}</Button>
                    </div>
                </div>
                <div className="flex" style={{ height: "82vh" }}>
                    <div className="bg-white h-full w-1/3 px-12 pt-2 gap-4 flex flex-col">
                        <div className="flex mr-4">
                            <label className="self-center flex-1">{"Stock Indices *"}</label>
                            <div className="flex flex-1 gap-2">
                                <Form.Select className="flex flex-1 w-60  p-2" placeholder="Select News type"
                                    value={this.state.stocksType}
                                    onChange={(e) => this.onStockIndicateChange(e.target.value)}>
                                    <option value={""}>Select Indices</option>
                                    {this.state.stocksTypes?.map(c => (
                                        <option value={c} key={c}>{c}</option>
                                    ))}
                                </Form.Select>
                            </div>
                        </div>
                        <div className="flex mr-4">
                            <label className="self-center flex-1">{"Duration *"}</label>
                            <div className="flex gap-2">
                                <input type="number" id="inputo"
                                    max={50}
                                    min={5}
                                    className="border-1 text-center rounded p-2 text-black my-select w-60"
                                    value={this.state.duration} onChange={(e) => e.target.value >= 5 && e.target.value <= 50 && this.setState({ duration: e.target.value })}
                                    style={{ backgroundColor: "inherit", color: "white" }}
                                    placeholder="eg. 10" />
                            </div>
                        </div>

                    </div>
                    <div className="flex-1">
                        <div className="flex content-end m-4">
                            <button className="p-2 flex bg-black text-white rounded-lg font-bold border-solid border-1" onClick={() => {
                                this.state.elementsPerSlot = this.state.isLandscape ? 4 : 8;
                                this.setState({ isLandscape: !this.state.isLandscape, elementsPerSlot: this.state.isLandscape ? 4 : 8 });
                                this.onStockIndicateChange(this.state.stocksType)
                            }}>
                                {this.state.isLandscape ? <MdStayCurrentLandscape className="self-center font-bold mr-1 " size={25} /> :
                                    <MdStayCurrentPortrait className="self-center font-bold mr-1 " size={25} />}
                            </button>
                        </div>
                        <div className="flex justify-center">
                            <div className="bg-cover flex bg-white items-center justify-center object-cover border-black border-4 shadow overflow-hidden" style={screenSize}>
                                {this.state.slotStocksList.length > 0 ? <StocksPreview stocksPriceList={this.state.slotStocksList} duration={this.state.duration}
                                    isLandscape={this.state.isLandscape} /> :
                                    <DataIsEmptyWithoutButton url={process.env.PUBLIC_URL + "/blankScreenIcon.svg"}
                                        title="App instance preview"
                                        body1="Edit and save the configuration to preview this app instance" />}
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </div >)
    };
}

const mapStateToProps = (state) => ({
    isLoading: state.counter.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
    counterSlice: bindActionCreators(commanActionCreator, dispatch)
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StockExchangeDetailsAppComponent));
