import React from "react";
import { connect } from "react-redux";

import { withRouter } from "../../../router";
import * as commanActionCreator from '../../../reducers/commanReduces'
import { bindActionCreators } from "@reduxjs/toolkit";
import { ToastContainer } from 'react-toastify';
import SortIcon from "../adminIcons/SortIcon";

import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

const moment = require('moment-timezone');

class CustomersTableComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            }
    }
    async componentDidMount() {
       
    }
        
    render() {
        const userRole = JSON.parse(localStorage.getItem("roles"));

        return (
            <div >
                {
                    <div className="shadow mt-8 rounded-lg">
                        <div className="flex rounded-t-lg bg-white p-3  text-center text-sm font-medium border-b-1">
                            <div className="flex items-center justify-center flex-1">Company Name
                            <div className="pl-2 cursor-pointer"><SortIcon></SortIcon></div></div>
                            <div className="flex items-center justify-center flex-1">Plan <div className="pl-2 cursor-pointer"><SortIcon></SortIcon></div></div>
                            <div className="flex items-center justify-center flex-1">Number of License<div className="pl-2 cursor-pointer"><SortIcon></SortIcon></div> </div>
                            <div className="flex items-center justify-center flex-1">Date of Expiry<div className="pl-2 cursor-pointer"><SortIcon></SortIcon></div> </div>
                            <div className="flex items-center justify-center flex-1">Number of Screens<div className="pl-2 cursor-pointer"><SortIcon></SortIcon></div> </div>
                        </div>
                        {this.props.customerDataArray?.map((m, i) => (
                            <div key={i} className="flex bg-white p-3 text-gray-450 text-xs text-center text-black border-b-1 cursor-pointer"
                            onClick={async () => this.props.router.navigate("/admin/viewCustomer/" + m._id)}>
                                <div className=" flex-1">                                    
                                     <span className="self-center">{m.name}</span>                                    

                                </div>
                                <div className="flex-1">
                                    <span className="self-center">{m.planId.name}</span>
                                </div>
                                <div className="flex-1">
                                    <span className="self-center">{m.totalLicense}</span>
                                </div>
                                <div className="flex-1">
                                    <span className="self-center">{moment(m.licenseExpiredOn).format("Do MMM YYYY")}</span>
                                </div>
                                <div className="flex-1">
                                    <span className="self-center">{m.totalScreen}</span>
                                </div>                               
                            </div>
                        ))}                         
                    </div>
                }                

                <ToastContainer />
            </div>
        );
    }
};

const mapStateToProps = (state) => ({
    isLoading: state.counter.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
    counterSlice: bindActionCreators(commanActionCreator, dispatch)
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CustomersTableComponent));
