import React from "react";
import { connect } from "react-redux";
import { withRouter } from "../../router";
import { format } from 'date-fns'
class StocksPreview extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            stockInfo: this.props.stocksPriceList[0],
            index: 0,
            timer: "",
        }
    }
    async componentDidMount() {
        this.timerList();
    }

    componentWillUnmount() {
        clearTimeout(this.state.timer);
    }

    timerList = async () => {
        this.setState({ stockInfo: this.props.stocksPriceList[this.state.index] })
        this.state.timer = setTimeout(function () {
            if ((this.props.stocksPriceList.length - 1) === this.state.index)
                this.state.index = 0
            else this.state.index += 1;
            this.timerList();
        }.bind(this), this.props.duration * 1000);
    }
    roundDecimal = (number, n) => {
        const multiplier = Math.pow(10, n);
        const roundedNumber = Math.round(number * multiplier) / multiplier;
        return roundedNumber;
    }
    render() {
        return (<div className="flex w-full h-full flex-col items-center bg-gray-900 justify-center gap-2">
            <span className="text-white text-2xl">Stock & Exchange</span>
            <div className={this.props.isLandscape ? "grid grid-rows-4 grid-flow-col gap-3" : "grid grid-col-1 gap-3"}>
                {this.state.stockInfo?.map((stock) => (
                    <div key={stock["NSE Symbol"]} className={"flex self-center items-center flex-1 w-80 h-14	text-white justify-between rounded bg-gradient-to-t from-stock  to-stock1 p-2"}>
                        <div className="flex flex-1 flex-col">
                            <span className="font-medium">{stock["NSE Symbol"]}</span>
                        </div>
                        <div className={"items-center flex self-center font-medium gap-2 "+(stock["%Chng"] > 0 ?"text-green":"text-red-900")}>
                            <span>{stock.LTP}</span>
                            <span className={"bg-light-black2 rounded p-1.5"}>{stock["%Chng"]}%</span>
                        </div>
                    </div>
                ))}
            </div>
            <span className="text-white opacity-50 text-sm">Updated {format(new Date(this.state.stockInfo[0]["Date/Time"]), 'dd/MM/yyyy HH:mm')}</span>

        </div>)
    };
}


const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StocksPreview));
