import { createSlice } from '@reduxjs/toolkit'

export const counterSlice = createSlice({
  name: 'counter',
  initialState: {
    userRole: {},
    isLoading:false,
    selectedMediaFileList: [],
    isMediaFileUploadLoader: false,
  },
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
    },
    stopLoading: (state) => {
      state.isLoading = false;
    },
    getRoleData: (state, data) => {
      state.userRole = data.payload
    },
    addMediaToObject: (state,action) =>{ // add selected media to object
      state.selectedMediaFileList.push(action.payload);
    },    
    updateMediaToObject: (state,action) =>{  //  update loading key in the selectedMediaFileList object
      state.selectedMediaFileList[action.payload.index].isUpload = action.payload.isUpload;
    },    
    clearMediaToObject: (state) =>{  // clear selectedMediaFileList object when all media uploading is done
      state.selectedMediaFileList = [];
    },
    showFileUploading: (state,action) => { // show hide media uploading popup
      state.isMediaFileUploadLoader = action.payload;
    },    
  },
})

// Action creators are generated for each case reducer function
export const { startLoading,stopLoading, getRoleData, addMediaToObject,showFileUploading,updateMediaToObject ,clearMediaToObject } = counterSlice.actions

export default counterSlice.reducer