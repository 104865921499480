import React from "react";

const TableViewIcon = (props) => {
  let { style } = props;
  return ( props.isSelected ? <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="9" y="9" width="18" height="3" fill="#1B438F"/>
  <rect x="9" y="14" width="18" height="3" fill="#1B438F"/>
  <rect x="9" y="19" width="18" height="3" fill="#1B438F"/>
  <rect x="9" y="24" width="18" height="3" fill="#1B438F"/>
  <rect x="1" y="1" width="34" height="34" stroke="#1B438F" stroke-opacity="0.5"/>
  </svg> :
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="8" y="8" width="18" height="3" fill="#C4C4C4" />
      <rect x="8" y="13" width="18" height="3" fill="#C4C4C4" />
      <rect x="8" y="18" width="18" height="3" fill="#C4C4C4" />
      <rect x="8" y="23" width="18" height="3" fill="#C4C4C4" />
    </svg> 
  );
};

export default TableViewIcon;
