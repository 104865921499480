import React from "react";
import { connect } from "react-redux";
import { withRouter } from "../router";
import { ToastContainer, toast } from 'react-toastify';
import { bindActionCreators } from "@reduxjs/toolkit";
import * as commanActionCreator from '../reducers/commanReduces'
import Navbar from "./subComponents/Navbar";
import { format } from 'date-fns'
import { DataIsEmptyWithoutButton, InputFieldWithEdit } from "./utilComponents/inputFieldAndButtons";
import { MdArrowBackIos, MdPreview, MdStayCurrentLandscape, MdStayCurrentPortrait } from "react-icons/md";
import { Button, Form } from "react-bootstrap";
import { FaRegCheckCircle } from "react-icons/fa";
import Instagram from "../appIcons/Instagram";
import InstagramPreviewScreen from "./subComponents/instagramPreviewScreen";
import { fetchUserInstagramToken } from "../services/appServices";
import { createApp, fetchInstaAppData } from "../services/appServices";
import { updateMedia } from "../services/mediaService";

class InstagramGalleryAppComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isNameEdit: false,
            isLandscape: true,
            appName: "Instagram gallery app",
            idDisabled: true,
            isUpdate: false,
            duration: 10,
            isValidURL: true,
            accessToken: "",
            barerToken: "",
            layoutType: "SINGLE_CARD",
            instaPostData: [],
            userInfo: "",
            layouts: [{ label: "Single Card", value: "SINGLE_CARD" }, { label: "Single", value: "SINGLE" }]//{ label: "Grid", value: "GRID" }
        }
    }
    async componentDidMount() {
        this.props.counterSlice.startLoading();
        console.log(window.location)
        const queryParams = new URLSearchParams(window.location.search);
        const code = queryParams.get('code');
        if (code) {
            let userInstaData = await fetchUserInstagramToken(code);
            if (userInstaData.success)
                this.setState({ instaPostData: userInstaData.body.data, userInfo: userInstaData.userInfo, idDisabled: false });
            this.setState({ barerToken: code });
        }

        if (this.props.router.params.appId) {
            let appInfo = await fetchInstaAppData(this.props.router.params.appId);
            if (appInfo.success)
                this.setState({
                    appInfo: appInfo.body,
                    appName: appInfo.body.name,
                    duration: appInfo.body.timer,
                    layoutType: appInfo.body.layoutType,
                    isUpdate: true,
                    barerToken: appInfo.body.instaUserId,
                    instaPostData: appInfo.data,
                    idDisabled: false
                });
        }
        this.props.counterSlice.stopLoading();
    }
    handleInstagramLogin = () => {
        const redirectUri = 'https://login.signaxe.com/app/insta_gallery'; // Your authorized redirect URI

        console.log(redirectUri);
        const authUrl = `https://api.instagram.com/oauth/authorize?client_id=1638600069963975&redirect_uri=${encodeURIComponent(redirectUri)}&scope=user_profile,user_media&response_type=code`;
        window.location.href = authUrl;
    };

    createAppService = async () => {

        if (this.state.userInfo == "") {
            this.setState({ idDisabled: true });
            return;
        }
        if (this.state.appName.trim() == "") {
            toast.error('App name should not be empty.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
            return;
        }
        this.props.counterSlice.startLoading();
        let appDTO = {
            name: this.state.appName.trim(),
            timer: this.state.duration,
            userToken: this.state.userInfo.access_token,
            instaUserId: this.state.userInfo.user_id,
            layoutType: this.state.layoutType,
            appType: "INSTA_GALLERY",
            duration: "00:00:50"
        }
        let response = await createApp(appDTO);
        if (response.success) {

            toast.success('Instagram app saved successfully.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
            this.props.router.navigate("/apps");

        } else {
            toast.error(response.message, {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        }
        this.props.counterSlice.stopLoading();
    }

    updateAppService = async () => {
        if (this.state.appName.trim() == "") {
            toast.error('App name should not be empty.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
            return;
        }
        this.props.counterSlice.startLoading();
        let appDTO = {
            _id: this.state.appInfo._id,
            name: this.state.appName.trim(),
            timer: this.state.duration,
            layoutType: this.state.layoutType
        }
        let response = await updateMedia(appDTO);
        if (response.success) {

            toast.success('App updated successfully.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        } else {
            toast.error('Error while updating app.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        }
        this.props.counterSlice.stopLoading();
    }

    render() {
        const userRole = JSON.parse(localStorage.getItem("roles"));

        let screenSize = { height: "410px", width: "717px" };
        if (!this.state.isLandscape) {
            screenSize = { width: "55vh", height: "68vh" };
        }
        return (
            <div>
                <Navbar router={this.props.router} counterSlice={this.props.counterSlice} />
                <div className="flex bg-white border-b-1 border-slate-300 w-full min-h-min	">
                    <div className="flex-none p-4 border-1"
                        onClick={() => this.props.router.navigate(-1)}>
                        <MdArrowBackIos size={20} />
                    </div>
                    <div className="self-center bg-dark-team rounded p-2 m-2">
                        <Instagram style="fill-white" />
                    </div>
                    <div className="px-2 py-3 flex flex-col self-center grow">
                        <InputFieldWithEdit
                            isEdit={this.state.isNameEdit}
                            isRead={userRole.app["editName"].read}

                            onKeyDown={(e) => {
                                if (this.state.appName.trim() !== "" && (e.key === "Enter" || e.key === "Tab" || e.key === undefined)) {
                                    this.setState({ isNameEdit: false });
                                } else this.setState({ isNameEdit: true });
                            }}
                            inputValue={this.state.appName}
                            placeholder="Enter Instagram Gallery App Name"
                            onInputchange={(e) => { userRole.app["editName"].write && this.setState({ appName: e.target.value }); }}
                            onEditClick={() => { userRole.app["editName"].write && this.setState({ isNameEdit: !this.state.isNameEdit }) }} />
                        {this.state.isUpdate ? <span className="order-last text-sm text-gray-450">{format(new Date(), 'dd/MM/yyyy HH:mm')}, modified by {this.state.appInfo != "" && this.state.appInfo.modifiedBy && <span>{this.state.appInfo.modifiedBy.firstName} {this.state.appInfo.modifiedBy.lastName}</span>} </span> :
                            <span className="order-last text-sm text-gray-450">{format(new Date(), 'dd/MM/yyyy HH:mm')}</span>}
                    </div>
                    <div className="flex gap-4 self-center" >
                        <Button className="bg-darkBlue text-white rounded p-2 mr-4"
                            disabled={this.state.idDisabled}
                            onClick={this.state.isUpdate ? this.updateAppService : this.createAppService}>{this.state.isUpdate ? "Update" : "Save"}</Button>
                    </div>
                </div>
                <div className="flex" style={{ height: "82vh" }}>
                    <div className="bg-white h-full w-1/3 px-12 pt-2 gap-4 flex flex-col">
                        <div className="flex flex mr-4 ">
                            <label className="self-center flex-1">{"Login to Instagram *"}</label>
                            <button onClick={this.handleInstagramLogin} disabled={this.state.isUpdate} className={"border flex gap-2 justify-center p-2 w-36 rounded " + (this.state.isUpdate && "cursor-not-allowed")} >Login{this.state.barerToken != "" && <FaRegCheckCircle className="self-center text-green-200" />}</button>
                        </div>
                        <div className="flex mr-4">
                            <label className="self-center flex-1">{"Images to Load*"}</label>
                            <div className="flex gap-2">
                                <input type="number" id="inputo"
                                    max={50}
                                    min={5}
                                    className="border-1 text-center rounded p-2 text-black my-select w-60"
                                    value={this.state.duration} onChange={(e) => e.target.value >= 5 && e.target.value <= 50 && this.setState({ duration: e.target.value })}
                                    style={{ backgroundColor: "inherit", color: "white" }}
                                    placeholder="Enter images to load" />
                            </div>
                        </div>
                        <div className="flex mr-4">
                            <label className="self-center flex-1">{"Layout *"}</label>
                            <div className="flex flex-1 gap-2">
                                <Form.Select className="flex flex-1 w-60  p-2" placeholder="Select clock type"
                                    value={this.state.layoutType}
                                    onChange={(e) => { this.setState({ layoutType: e.target.value }) }}>
                                    {this.state.layouts?.map(c => (
                                        <option value={c.value} key={c.value}>{c.label}</option>
                                    ))}
                                </Form.Select>
                            </div>
                        </div>
                    </div>
                    <div className="flex-1">
                        <div className="flex content-end m-4">
                            <button className="p-2 flex bg-black text-white rounded-lg font-bold border-solid border-1" onClick={() => {
                                this.setState({ isLandscape: !this.state.isLandscape })
                            }}>
                                {this.state.isLandscape ? <MdStayCurrentLandscape className="self-center font-bold mr-1 " size={25} /> :
                                    <MdStayCurrentPortrait className="self-center font-bold mr-1 " size={25} />}
                            </button>
                        </div>
                        <div className="flex justify-center">
                            <div className="bg-cover flex bg-white items-center justify-center object-cover border-black border-4 shadow overflow-hidden" style={screenSize}>
                                {this.state.instaPostData.length > 0 ? <InstagramPreviewScreen type={this.state.layoutType} postList={this.state.instaPostData} duration={this.state.duration}
                                    isLandscape={this.state.isLandscape} /> :
                                    <DataIsEmptyWithoutButton url={process.env.PUBLIC_URL + "/blankScreenIcon.svg"}
                                        title="App instance preview"
                                        body1="Edit and save the configuration to preview this app instance" />}
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </div >)
    };
}

const mapStateToProps = (state) => ({
    isLoading: state.counter.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
    counterSlice: bindActionCreators(commanActionCreator, dispatch)
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(InstagramGalleryAppComponent));
