import React from 'react';

const FacebookIcon = (props) => {
    let { style } = props;

    return (
        <svg width="35" height="35" viewBox="0 0 420 420" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_1_915)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M140.526 30H279.474C340.516 30 390 79.4842 390 140.526V279.474C390 340.516 340.516 390 279.474 390H140.526C79.4842 390 30 340.516 30 279.474V140.526C30 79.4842 79.4842 30 140.526 30Z" fill="white" />
            </g>
            <mask id="mask0_1_915" style={{"mask-type":"alpha"}} maskUnits="userSpaceOnUse" x="30" y="30" width="360" height="360">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M140.526 30H279.474C340.516 30 390 79.4842 390 140.526V279.474C390 340.516 340.516 390 279.474 390H140.526C79.4842 390 30 340.516 30 279.474V140.526C30 79.4842 79.4842 30 140.526 30Z" fill="white" />
            </mask>
            <g mask="url(#mask0_1_915)">
                <path d="M258 179.761H226.327V159.003C226.327 151.208 231.498 149.39 235.139 149.39C238.773 149.39 257.49 149.39 257.49 149.39V115.12L226.709 115C192.538 115 184.762 140.56 184.762 156.917V179.761H165V215.075H184.762C184.762 260.394 184.762 315 184.762 315H226.327C226.327 315 226.327 259.856 226.327 215.075H254.375L258 179.761Z" fill="#385999" />
            </g>
            <defs>
                <filter id="filter0_d_1_915" x="0" y="0" width="420" height="420" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset />
                    <feGaussianBlur stdDeviation="15" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0720881 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1_915" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1_915" result="shape" />
                </filter>
            </defs>
        </svg>

    )
}

export default FacebookIcon;