import React from "react";
import { connect } from "react-redux";

import { withRouter } from "../../router";
import * as commanActionCreator from '../../reducers/commanReduces'
import { bindActionCreators } from "@reduxjs/toolkit";
import { ToastContainer, toast } from 'react-toastify';
import { fetchGroupRules, fetchInvitation, reSendInvitationService, removeInvitationService, sendInvitationService } from "../../services/profiledetailsService";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { format } from 'date-fns'
import { Button, Modal, OverlayTrigger, Popover } from "react-bootstrap";
import { FaBan, FaEllipsisV, FaReply } from "react-icons/fa";

class UsersInvitationComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            limit: 10,
            totalCount: 0,
            firstName: "",
            lastName: "",
            invitationEmail: "",
            userType: "",
            usersList: [],
            inviteUserModel: false,
            isValidEmail: false,
            role: []
        }
    }

    async componentDidMount() {
        this.props.counterSlice.startLoading();
        let usersData = await fetchInvitation(this.state.page, this.state.limit);
        let rolesInfo = await fetchGroupRules();
        if (rolesInfo.body) {
            rolesInfo.body.forEach(r => {
                this.state.role.push(r.roleId.name);
            });
        }
        this.setState({ usersList: usersData.body, totalCount: Math.ceil(usersData.totalCount / this.state.limit) })
        this.props.counterSlice.stopLoading();
    }
    sendInvitation = async () => {
        let invitationDTO = {
            firstName: this.state.firstName.trim(),
            lastName: this.state.lastName.trim(),
            email: this.state.invitationEmail.trim(),
            userType: this.state.userType
        };
        this.props.counterSlice.startLoading();
        let response = await sendInvitationService(invitationDTO);

        if (response.success) {
            let usersData = await fetchInvitation(this.state.page, this.state.limit);
            this.setState({ usersList: usersData.body, totalCount: Math.ceil(usersData.totalCount / this.state.limit) })
            toast.success(response.message, {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        } else {
            toast.error(response.message, {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        }
        this.props.counterSlice.stopLoading();
        this.onClose();
    }
    onPageChange = async (page) => {

        if (page > 0 && page <= this.state.totalCount) {
            this.props.counterSlice.startLoading();
            this.setState({ page });
            let usersData = await fetchInvitation(page, this.state.limit);
            this.setState({ usersList: usersData.body, totalCount: Math.ceil(usersData.totalCount / this.state.limit) });
            this.props.counterSlice.stopLoading();
        }
    }
    onLimitChange = async (e) => {
        this.props.counterSlice.startLoading();
        this.setState({ limit: e.target.value, page: 1 });
        let usersData = await fetchInvitation(1, e.target.value);
        this.setState({ usersList: usersData.body, totalCount: Math.ceil(usersData.totalCount / this.state.limit) });
        this.props.counterSlice.stopLoading();

    }
    onClose = () => this.setState({
        firstName: "", lastName: "", invitationEmail: "",
        userType: "", inviteUserModel: false
    })
    onInputChange = (type, e) => {
        this.setState({ [type]: e.target.value });
    }
    onEmailChange = (e) => {
        this.setState({ invitationEmail: e.target.value });
        let emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (emailRegex.test(e.target.value)) {
            this.setState({ isValidEmail: true });
        } else
            this.setState({ isValidEmail: false });
    }
    reSendInvitation = async (id) => {
        this.props.counterSlice.startLoading();
        let response = await reSendInvitationService(id);
        if (response.success) {
            let usersData = await fetchInvitation(this.state.page, this.state.limit);
            this.setState({ usersList: usersData.body, totalCount: Math.ceil(usersData.totalCount / this.state.limit) })
            toast.success(response.message, {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        } else {
            toast.error(response.message, {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        }
        this.props.counterSlice.stopLoading();
    }
    removeInvitation = async (id) => {
        this.setState({ deleteInvitationModel: false });
        this.props.counterSlice.startLoading();
        let response = await removeInvitationService(id);
        if (response.success) {
            let usersData = await fetchInvitation(this.state.page, this.state.limit);
            this.setState({ usersList: usersData.body, totalCount: Math.ceil(usersData.totalCount / this.state.limit) })
            toast.success(response.message, {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        } else {
            toast.error(response.message, {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        }
        this.props.counterSlice.stopLoading();
    }

    render() {
        const userRole = JSON.parse(localStorage.getItem("roles"));

        let regexforName = /^(?=.*[A-Za-z])[A-Za-z!@#$%^&*()_+|~=`{}\[\]:";'<>?,.\/]*$/;

        return (
            <div>
                {<div className={"flex justify-end m-2 " + (userRole.setting["inviteNewUser"].read ? " visible " : " invisible")} >
                    {!this.state.inviteUserModel && <button className="bg-darkBlue text-white rounded p-2" onClick={() => userRole.setting["inviteNewUser"].write && this.setState({ inviteUserModel: true })}>Add New User</button>}
                </div>}
                {this.state.inviteUserModel ?
                    <div className="flex mt-10 justify-center">
                        <div className="flex justify-center gap-3 flex-col w-3/5 bg-white shadow rounded justify-center p-3">
                            <div className="flex gap-4">
                                <div className="flex-1">
                                    <div><span className="text-sm text-black">First Name</span>
                                    </div>
                                    <div className="border-2 border-gray-20 flex p-2 rounded">
                                        <input type="text" className="text-black" placeholder="First Name" value={this.state.firstName}
                                            onChange={(e) => (regexforName.test(e.target.value) || e.target.value === '') && this.onInputChange("firstName", e)} />
                                    </div>
                                </div>
                                <div className="flex-1">
                                    <div><span className="text-sm text-black">Last Name</span>
                                    </div>
                                    <div className="border-2 border-gray-20 flex p-2 rounded">
                                        <input type="text" className="text-black" placeholder="Last Name" value={this.state.lastName}
                                            onChange={(e) => (regexforName.test(e.target.value) || e.target.value === '') && this.onInputChange("lastName", e)} />
                                    </div>
                                </div>
                            </div>
                            <div className="flex-1">
                                <div><span className="text-sm text-black">E-mail id</span>
                                </div>
                                <div className="border-2 border-gray-20 flex p-2 rounded">
                                    <input type="email" className="text-black" placeholder="Email" value={this.state.invitationEmail}
                                        onChange={(e) => this.onEmailChange(e)} />
                                </div>
                            </div>
                            <div className="flex-1">
                                <div><span className="text-sm text-black">User Role</span>
                                </div>
                                <select value={this.state.userType} onChange={(e) => this.onInputChange("userType", e)} className="border-2 border-gray-20 flex p-2 rounded">
                                    <option value="">Select specific role</option>
                                    {this.state.role?.map((r) => (<option value={r}>{r}</option>))}
                                </select>
                            </div>
                            <div className="flex justify-end gap-3">
                                <button onClick={() => this.onClose()} className="text-black p-2 shadow rounded">Back</button>
                                <Button className="bg-darkBlue text-white rounded p-2" disabled={!this.state.isValidEmail || this.state.firstName === "" || this.state.lastName === "" || this.state.userType === ""} onClick={() => this.sendInvitation()}>Send Invitation</Button>
                            </div>
                        </div>
                    </div>
                    : <div className="shadow mt-8 rounded-lg">
                        <div className="flex rounded-t-lg bg-white p-3 text-gray-450 text-center text-sm font-medium border-b-1">
                            <div className="flex-1">Name</div>
                            <div className="flex-1">E-mail id</div>
                            <div className="flex-1">Role</div>
                            <div className="flex-1">Status</div>
                            <div className="flex-1">Last Login</div>
                        </div>
                        {this.state.usersList?.map((u, i) =>
                            <div key={i} className="flex bg-white p-2 text-gray-450 text-xs text-center text-black border-b-1">
                                <div className="flex-1">
                                    <span className="self-center">{u.firstName} {u.lastName}</span>
                                </div>
                                <div className="flex-1">
                                    <div className="w-14 xl:w-40 lg:w-32 md:w-28 inline-block truncate text-black text-gray-500 self-center cursor-pointer" title={u.email}><span>{u.email}</span></div>
                                </div>
                                <div className="flex-1 text-center">
                                    <span className="self-center">{u.userType}</span>
                                </div>
                                <div className="flex-1 text-center">
                                    <span className={"self-center " + (u.status === "ACCEPTED" ? "text-green" : "text-brown")}>{u.status}</span>
                                </div>
                                <div className="flex-1 flex justify-center">
                                    <div className="flex-1">{u.loginTime ? format(new Date(u.loginTime), "dd/MM/yyyy kk:mm:ss") : "-"}</div>
                                    <OverlayTrigger
                                        trigger={"click"}
                                        className="rounded"
                                        placement="left"
                                        rootClose
                                        overlay={<Popover className="rounded meg overflow-hidden">
                                            {(userRole.setting["resendIU"].read || userRole.setting["deleteIU"].read) ? <div>
                                                <>{userRole.setting["resendIU"].read && <button className={"flex p-2 gap-2 cursor-pointer hover:bg-gray-300 " + (u.status === "ACCEPTED" && "bg-gray-300 cursor-not-allowed")} disabled={(u.status === "ACCEPTED")} onClick={() => userRole.setting["resendIU"].write && this.reSendInvitation(u._id)}>
                                                    <FaReply className="self-center opacity-50" />Re-Send
                                                </button>}</>
                                                {userRole.setting["resendIU"].read && userRole.setting["deleteIU"].read && <hr />}
                                                {userRole.setting["deleteIU"].read && <button className="flex p-2 gap-2 cursor-pointer hover:bg-gray-300 w-full" onClick={() => userRole.setting["deleteIU"].write && this.setState({ deleteInvitationModel: true, id: u._id })}>
                                                    <FaBan className="self-center opacity-50" />Deactivate 
                                                </button>}
                                            </div> : <div>You do not have access</div>}
                                        </Popover>}>
                                        <button className="p-1"><FaEllipsisV className="self-center" /></button>
                                    </OverlayTrigger>
                                </div>
                            </div>)
                        }
                        <div className="flex justify-end gap-4 rounded-b-lg bg-white p-3 text-gray-450 text-center text-sm font-medium border-b-1">
                            <span className="self-center" >Row per page:</span>
                            <select className="bg-white border-b-1 self-center border-b-black" onChange={(e) => this.onLimitChange(e)} >
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                                <option value="40">40</option>
                                <option value="50">50</option>
                            </select>
                            <span>{this.state.page} of {this.state.totalCount}</span>
                            <IoIosArrowBack className="self-center" onClick={() => this.onPageChange(this.state.page - 1)} />
                            <IoIosArrowForward className="self-center" onClick={() => this.onPageChange(this.state.page + 1)} />
                        </div>
                    </div >}

                <Modal show={this.state.deleteInvitationModel} onHide={() => this.setState({ deleteInvitationModel: false })}>
                    <Modal.Header >
                        <Modal.Title>Remove Invitation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure, you would like to remove this invitation permanently?</Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-pr" onClick={() => this.setState({ deleteInvitationModel: false })}>
                            No
                        </Button>
                        <Button className="btn btn-prime" onClick={() => this.removeInvitation(this.state.id)}>
                            Yes
                        </Button>
                    </Modal.Footer>
                </Modal>
                <ToastContainer />
            </div >
        );
    }
};

const mapStateToProps = (state) => ({
    isLoading: state.counter.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
    counterSlice: bindActionCreators(commanActionCreator, dispatch)
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UsersInvitationComponent));
