import React from "react";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import EditIcon from "../../appIcons/editIcon";
import { FaSearch, FaTimes } from "react-icons/fa";
import WeatherIcon from "../../appIcons/WeatherIcon";
import Select from 'react-select';
import ClockIcon from "../../appIcons/ClockIcon";
import Currency from "../../appIcons/Currency";
import SoccerIcon from "../../appIcons/Soccer";
import CricketIcon from "../../appIcons/CricketIcon";
import YouTubeIcon from "../../appIcons/YouTubeIcon";
import Instagram from "../../appIcons/Instagram";
import MTTIcon from "../../appIcons/MTTIcon";
import RssIcon from "../../appIcons/RssIcon";
import FacebookIcon from "../../appIcons/facebookIcon";
import StocksIcon from "../../appIcons/StockIcon";

const InputFieldWithEdit = (props) => {
    let { isEdit, onKeyDown, inputValue, placeholder, onInputchange, onEditClick, isRead = true } = props;
    return (
        <div className="flex">{isEdit ?
            <input className="focus:outline-none w-1/2 text-2xl text-black font-bold border-b-black"
                onKeyDown={onKeyDown}
                value={inputValue}
                placeholder={placeholder}
                autoFocus={true}
                maxLength={60}
                onBlur={onKeyDown}
                style={{ borderBottom: "1px solid" }}
                onChange={onInputchange} />
            : <div className="flex">
                <span className="mr-3 text-ls text-black font-medium">{inputValue}</span>
                {isRead && <div onClick={onEditClick}>
                    <EditIcon style="self-center" />
                </div>}
            </div>}
        </div>
    );
};

let IconButton = ({ customFunc, icon, toolTip }) => (
    toolTip === "" ? <button
        type="button"
        className={`text-1xl p-2 ml-3 hover:text-black opacity-50 child group-hover:block hidden`} onClick={customFunc}>
        {icon}
    </button> : <TooltipComponent content={toolTip}>
        <button
            type="button"
            className={`text-1xl p-2 ml-3 hover:text-black opacity-50 child group-hover:block hidden`} onClick={customFunc}>
            {icon}
        </button>
    </TooltipComponent>
);

let CustomTab = ({ label, customFunction, isSelected }) => (
    <div className={`flex-1 p-2 border-b-4 hover:border-blue-700 cursor-pointer ${isSelected ? "border-blue-700" : "border-transparent"} ${isSelected ? "text-gray-600" : "text-gray-400"} hover:text-gray-600 font-semibold`} onClick={customFunction}>{label}</div>
);

let DaysModel = ({ day, isMarked }) => (
    <div style={{ backgroundColor: isMarked ? "#1d3980" : "#eeeeee" }} className="text-white w-9 self-center px-2 py-1 rounded-md text-center text-xs font-semibold">{day}</div>
);
let AddContentButtons = ({ label, customFunction, icon, isSelected }) => (
    <div id="addcon" className={`${isSelected ? "bgn flex cursor-pointer hove text-sm" : "flex cursor-pointer hov text-sm"}`} onClick={customFunction}>{icon} <lable className="ml-3">{label}</lable></div>);

let DataIsEmpty = ({ url, title, body1, body2, customFunction, buttonName }) => (
    <div className="flex flex-col text-black mt-14">
        <img className="w-1/4 h-1/4 self-center"
            src={url} alt="Loding..." />
        <div className="self-center w-1/2 text-center text-black font-semibold text-xl"><p>{title}</p><br /></div>
        <div style={{ marginBottom: "30px" }}
            className="self-center w-1/2 text-black text-center"><p>{body1}</p><p>{body2}</p>
        </div>
        <button className="self-center border-1 rounded-xl bg-white m-2 border-black p-2 hover:shadow-lg" onClick={customFunction}>{buttonName}</button>
    </div>
);

let DataIsEmptyWithoutButton = ({ url, title, body1, body2, }) => (
    <div className="flex flex-col text-black mt-14">
        <img className="w-1/4 h-1/4 self-center"
            src={url} alt="Loding..." />
        <div className="self-center w-1/2 text-center text-black font-semibold text-xl"><p>{title}</p><br /></div>
        <div style={{ marginBottom: "30px" }}
            className="self-center w-1/2 text-black text-center"><p>{body1}</p><p>{body2}</p>
        </div>
    </div>
);

let ToggalButton = ({ value, customFunction, label }) => (
    <label className="relative self-center inline-flex items-center cursor-pointer">
        <input type="checkbox" value="" className="sr-only peer" checked={value} onChange={customFunction} />
        <div className="w-8 h-4 bg-gray-200 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-black after:content-[''] after:absolute  after:left-[2px] after:bg-white after:m-0.1 after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-primary"></div>
        <span className="ml-1 text-xs font-medium text-gray-300">{label}</span>
    </label>
);

let ToggalSetButton = ({ value, customFunction }) => (
    <label className="relative inline-flex items-center cursor-pointer">
        <input type="checkbox" value="" className="sr-only peer" checked={value} onChange={customFunction} />
        <div className="w-11 h-5 bg-gray-200 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-black after:content-['']  after:absolute after:left-[3px] after:m-0.2 after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-primary"></div>
    </label>
);

let FileDetails = ({ lable, value }) => (
    <tr>
        <td><span className="text-sm font-normal text-gray-600 px-2">{lable}</span></td>
        <td className="text-start"><p className="text-base text-black">{value} </p></td>
    </tr>
)

let BuyPlanButton = ({ customFunction }) => (
    <button onClick={customFunction}
        className="m-2 border-1 border-green-500 self-center rounded-full bg-green-500 p-2 text-black bg-opacity-60	 font-bold hover:bg-white">Buy Plan</button>
)

let InputFiledWithSearch = ({ lable, value, onChnage, onClickSearch, isOptional, onClickClear, isDisabled }) => (
    <div className="flex flex-col gap-1 mr-4">
        <label>{lable} {isOptional && <span className="text-sm text-gray-400">(Optional)</span>}</label>
        <div className="flex gap-2">
            <input type="text" id="inputo"
                maxLength={50}
                className="border-1 border-gray-300 rounded p-2 text-black my-select"
                value={value}
                disabled={isDisabled}
                onChange={(e) => onChnage(e)}
                style={{ backgroundColor: "inherit", color: "white" }}
                placeholder="Enter screen name" />
            {isOptional && <button title="Clear City" className="rounded p-2 border-1" onClick={onClickClear}> <FaTimes /></button>}
            <button title="Search City Name" className="rounded p-2 bg-darkBlue text-white" onClick={onClickSearch}> <FaSearch /></button>
        </div>
    </div>
);

let LatLongInputFiled = ({ lable, latvalue, longvalue, onLatChnage, onLongChnage, onClickSearch, isOptional, onClickClear }) => (
    <div className="flex flex-col gap-1 mr-4">
        <label>{lable} {isOptional && <span className="text-sm text-gray-400">(Optional)</span>}</label>
        <div className="flex gap-2">
            <input type="text" id="inputo"
                maxLength={50}
                className="border-1 border-gray-300 rounded p-2 text-black my-select"
                value={latvalue} onChange={onLatChnage}
                style={{ backgroundColor: "inherit", color: "white" }}
                placeholder="Latitude" />
            <input type="text" id="inputo"
                maxLength={50}
                className="border-1 border-gray-300 rounded p-2 text-black my-select"
                value={longvalue} onChange={onLongChnage}
                style={{ backgroundColor: "inherit", color: "white" }}
                placeholder="Longitude" />
            {isOptional && <button title="Clear City" className="rounded p-2 border-1" onClick={onClickClear}> <FaTimes /></button>}
            <button title="Search City Name" className="rounded p-2 bg-darkBlue text-white" onClick={onClickSearch}> <FaSearch /></button>
        </div>
    </div>
);

let InputFiledSelect = ({ lable, value, onChange, optionsList }) => (
    <div className="flex flex-col gap-1 mr-4">
        <label>{lable}</label>
        <div className="flex flex-1 gap-2">
            <Select
                defaultValue={value}
                onChange={onChange}
                options={optionsList}
            />
        </div>
    </div>
);

let AppIcon = ({ type, style }) => {
    return (
        <>{type === "WEATHER" ? <WeatherIcon style={style} />
            : type === "CLOCK" ? <ClockIcon style={style} />
                : type === "CURRENCY" ? <Currency style={style} />
                    : type === "SOCCER" ? <SoccerIcon style={style} />
                        : type === "STOCK" ? <StocksIcon style={style} />
                            : type === "CRICKET" ? <CricketIcon style={style} />
                                : type === "YOUTUBE" ? <YouTubeIcon style={style} />
                                    : type === "INSTA_GALLERY" ? <Instagram style={style} />
                                        : type === "MEET_TEAM" ? <MTTIcon style={style} />
                                            : type === "RSS" ? <RssIcon style={style} />
                                                : type === "FACEBOOK" ? <FacebookIcon style={style} />
                                                    : null}</>
    )
};

let DataNotFound = () => {
    return (
        <div className="text-center text-xl p-4 font-semibold text-gray-450">
            Data Not Found
        </div>
    )
};

export {
    InputFieldWithEdit,
    IconButton,
    CustomTab,
    DaysModel,
    AddContentButtons,
    DataIsEmpty,
    ToggalButton,
    ToggalSetButton,
    FileDetails,
    BuyPlanButton,
    DataIsEmptyWithoutButton,
    InputFiledWithSearch,
    LatLongInputFiled,
    AppIcon,
    InputFiledSelect,
    DataNotFound
};
