import * as Constants from '../constant';

let addLinksDataService = async (linkDTO) => {
  const token = localStorage.getItem("accessToken");

  return fetch(Constants.API_URL + "/media/link", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: "Barear " + token
    },
    body: JSON.stringify(linkDTO),

  })
    .then((data) => data.json())
    .catch((error) => {
      return {};
    });
}

let fetchAllLinksData = async () => {
  const token = localStorage.getItem("accessToken");

  return fetch(Constants.API_URL + "/media/link", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: "Barear " + token
    },
  })
    .then((data) => data.json())
    .catch((error) => {
      return {};
    });
}

let deleteLink = async (linkId) => {
  const token = localStorage.getItem("accessToken");

  return fetch(Constants.API_URL + "/media/link?linkId="+linkId, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      authorization: "Barear " + token
    },
  }).then((data) => data.json())
    .catch((error) => {
      return {};
    });
};

export {
  addLinksDataService,
  fetchAllLinksData,
  deleteLink
};