import React from "react";
import { Modal, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "../../router";
import { ToastContainer, toast } from "react-toastify";
import { bindActionCreators } from "@reduxjs/toolkit";
import * as commanActionCreator from "../../reducers/commanReduces";
import AdminSidebar from "../admin/subComponents/adminSidebar";
import AdminNavbar from "./subComponents/adminNavbar";
import { getPlan } from "./services/customer.service";
import { MdArrowBackIosNew } from "react-icons/md";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import "./assets/css/admin.css";
import addlicense from "./assets/img/addlicense.png";
import { getCustomerDetails } from './services/customer.service';
import { updateCustomerDetails } from './services/customer.service';
import { organizationLogin } from './services/customer.service';
import { userRoleAndPermission } from '../../services/login.service';

const moment = require('moment-timezone');
class ViewCustomer extends React.Component {

  constructor(props) {
    super(props);

    this.hiddenFileInput = React.createRef();
    this.state = {
      id: null,
      selectedCustomerId: this.props.router.params.customerId,
      customerData: null,
      isTableView: false,
      isGridView: true,
      companyName: "",
      comapanyAddress: "",
      ownerFirstName: "",
      ownerLastName: "",
      ownerEmail: "",
      ownerMobileno: "",
      plan: "FREE",
      userProfile: "",
      startDate: "22 Aug 2024",
      loading: false,
      isEdit: false,
      isRedirect: false,
      planList: [],
      licensesList: []
    };
  }
  async componentDidMount() {
    this.props.counterSlice.startLoading();
    let APIRES = await getCustomerDetails(this.state.selectedCustomerId);

    if (APIRES.body.licensesList.length) {
      APIRES.body.licensesList.filter((s) => s['remainingScreens'] = 0);

      APIRES.body.licensesList.filter((item) => {

        item.licenseList.filter((subItem) => {
          if (subItem.isUsed == false) {
            item.remainingScreens++;
          }
        })
      });
    }
    this.setState({
      id: APIRES.body._id,
      customerData: APIRES.body,
      companyName: APIRES.body.name,
      comapanyAddress: APIRES.body.address,
      ownerFirstName: APIRES.body.createdBy.firstName,
      ownerLastName: APIRES.body.createdBy.lastName,
      ownerEmail: APIRES.body.createdBy.email,
      ownerMobileno: APIRES.body.createdBy.phoneNumber,
      licensesList: APIRES.body.licensesList,
      plan: APIRES.body.planId.name,
      planID: APIRES.body.planId._id,
      loading: true
    });
    this.props.counterSlice.stopLoading();
    // await this.setCustomerValues()

    this.getPlan();

  }

  getPlan = async (searchText) => {

    this.props.counterSlice.startLoading();
    let data = await getPlan(this.state.page, this.state.limit, searchText);
    console.log(data)
    this.setState({ planList: data.body });
    this.props.counterSlice.stopLoading();

  }



  handlechangedrop = (e) => {
    console.log(e)
    this.setState({
      plan: e.name,
      planID: e._id,
    });
  };
  updateCustomer = async () => {

    let payload = {
      _id: this.state.id,
      name: this.state.companyName,
      address: this.state.address,
      planId: this.state.planID,
    };

    let response = await updateCustomerDetails(payload);
    console.log(response)
    if (response.success) {
      this.setState({ isEdit: false });
      toast.success('Account information saved successfully', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        theme: "colored",
        progress: undefined,
      });
    } else {
      toast.error('Error while saving account information details.', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        theme: "colored",
        progress: undefined,
      });
    }

  }
  onInputChange = (type, event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleClick = (event) => {
    this.hiddenFileInput.current.click();
  };
  closeAll = () => this.setState({ isRedirect: false })
  redirectToScreenManager = async () => {
    this.closeAll();
    this.props.counterSlice.startLoading();
    let response = await organizationLogin(this.state.selectedCustomerId);
    if (response.success) {
      console.log(response);
      let roleDetails = await userRoleAndPermission(response.body.userType, response.body.accessToken);
      if (roleDetails.success) {
        localStorage.setItem("roles", JSON.stringify(roleDetails.body.permissions));
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        userInfo.orgId = this.state.selectedCustomerId;
        userInfo.orgName = roleDetails.body.orgName;
        localStorage.setItem("userInfo", JSON.stringify(userInfo));
        window.open("/screens", '_blank', 'noreferrer');
      }
    }
    // this.props.router.navigate("/screens");
    this.props.counterSlice.stopLoading();
  }

  getInitials = (string) => {
    if (!string) {
      return "$"
    }
    var names = string.split(' '),
      initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };
  render() {
    return (
      <div className="flex">
        <AdminSidebar type={"admin/customers"} />
        <div className="h-screen grow md:overflow-hidden overflow-auto md:hover:overflow-auto  shadow">
          <AdminNavbar
            router={this.props.router}
            counterSlice={this.props.counterSlice}
          />
          <div className="flex  shadow-md text-3xl ">
            <div className="px-3 border-r-1 flex items-center cursor-pointer">
              <MdArrowBackIosNew
                opacity={0.5}
                className=" top-1 m-1 "
                style={{ width: "15px" }}
                onClick={async () =>
                  this.props.router.navigate("/admin/customers")
                }
              />
            </div>
            <h1 className="self-center mr-5 text-black text-xl pr-11 py-4 pl-4">
              Customers
            </h1>
            <div className="flex justify-end flex-1  items-center pr-5">
              <button
                type="button"
                className={`text-base  ml-3 px-3 py-2 btn-prime text-white  rounded-lg`}
                onClick={() => this.setState({ isRedirect: true })}
              >
                Redirect To Customer
              </button>
            </div>
          </div>
          <div style={{ height: "calc(100vh - 119px)", overflowY: "scroll" }}>
            <div className="flex mt-4">
              <div className="w-2/4 flex justify-center items-center">
                {/* <UserAvtarIcon></UserAvtarIcon> */}
                <div
                  className="flex justify-center items-center rounded-md flex-col w-8/12 h-5/6"
                  style={{ background: "#FFFFFF" }}
                >
                  <div
                    className="relative flex items-center justify-center"
                    style={{
                      width: "180px", height: "180px",
                      border: "1px solid gray",
                      borderRadius: "50%"
                    }}
                  >
                    <span style={{ fontSize: "80px" }}>{this.getInitials(this.state.ownerFirstName)}</span>
                    <span style={{ fontSize: "80px" }}>{this.getInitials(this.state.ownerLastName)}</span>
                  </div>
                  <div className="flex justify-center items-center rounded-md flex-col">
                    <span className="mt-8 text-2xl">{this.state.ownerFirstName} {this.state.ownerLastName}</span>
                    <span className="mt-2 text-sm">{this.state.ownerEmail}</span>
                    <span className="mt-2 text-sm">
                      {this.state.ownerMobileno}
                    </span>
                  </div>
                </div>
              </div>
              <div className="w-2/4 pr-14">
                <form action="#!">
                  <div className="form-group">
                    <div className="flex flex-row justify-between " style={{ color: "#1B438F" }}>
                      <span className="text-base">Account Information</span>
                      {!this.state.isEdit && <span className="text-base cursor-pointer" onClick={(e) => this.setState({ isEdit: !this.state.isEdit })}>Edit</span>}
                      {this.state.isEdit && <span className="text-base cursor-pointer" onClick={(e) => this.updateCustomer()}>Save</span>}
                    </div>
                    <div className="mb-2 mt-3">
                      <label
                        class="block text-gray-400 text-sm  mb-2"
                        for="grid-first-name"
                      >
                        Company name
                      </label>
                      <input
                        type="text"
                        value={this.state.companyName}
                        name="companyName"
                        disabled={!this.state.isEdit}
                        className="form-control placeholder-gray-400 text-sm py-2.5 disabled:text-gray-400 "
                        placeholder="Enter company name"
                        onChange={(e) => this.onInputChange("companyName", e)}
                      />
                    </div>
                    <div className="form-group mb-2">
                      <label
                        class="block text-gray-400 text-sm  mb-2"
                        for="grid-first-name"
                      >
                        Company address
                      </label>
                      <input
                        type="text"
                        value={this.state.comapanyAddress}
                        name="comapanyAddress"
                        disabled={!this.state.isEdit}
                        className="form-control placeholder-gray-400 text-sm py-2.5 disabled:text-gray-400"
                        placeholder="Enter company address"
                        onChange={(e) => this.onInputChange("comapanyAddress", e)}
                      />
                    </div>
                    <div className="mb-2">
                      <label
                        class="block text-gray-400 text-sm  mb-2"
                        for="grid-first-name"
                      >
                        Owner First Name
                      </label>
                      <input
                        type="text"
                        value={this.state.ownerFirstName}
                        name="ownerFirstName"
                        disabled
                        className="form-control placeholder-gray-400 text-sm py-2.5 disabled:text-gray-400"
                        placeholder="Enter  name"
                        onChange={(e) => this.onInputChange("ownerFirstName", e)}
                      />
                    </div>
                    <div className="mb-2">
                      <label
                        class="block text-gray-400 text-sm  mb-2"
                        for="grid-first-name"
                      >
                        Owner Last Name
                      </label>
                      <input
                        type="text"
                        value={this.state.ownerLastName}
                        name="ownerLastName"
                        disabled
                        className="form-control placeholder-gray-400 text-sm py-2.5 disabled:text-gray-400"
                        placeholder="Enter last name"
                        onChange={(e) => this.onInputChange("ownerLastName", e)}
                      />
                    </div>
                    <div className="form-group mb-2">
                      <label
                        class="block text-gray-400 text-sm  mb-2"
                        for="grid-first-name"
                      >
                        E-mail id
                      </label>
                      <input
                        type="text"
                        value={this.state.ownerEmail}
                        name="ownerEmail"
                        disabled
                        className="form-control placeholder-gray-400 text-sm py-2.5 disabled:text-gray-400"
                        placeholder="Enter email id"
                        onChange={(e) => this.onInputChange("ownerEmail", e)}
                      />
                    </div>
                    <div className="form-group mb-2">
                      <label
                        class="block text-gray-400 text-sm  mb-2"
                        for="grid-first-name"
                      >
                        Mobile number
                      </label>
                      <input
                        type="text"
                        value={this.state.ownerMobileno}
                        name="ownerMobileno"
                        disabled
                        className="form-control placeholder-gray-400 text-sm py-2.5 disabled:text-gray-400"
                        placeholder="Enter mobile number"
                        onChange={(e) => this.onInputChange("ownerMobileno", e)}
                      />
                    </div>
                    <div className="form-group mb-3 mt-4">
                      <label
                        class="block text-gray-400 text-sm  mb-2"
                        for="grid-first-name"
                      >
                        Plan
                      </label>
                      <DropdownButton
                        disabled={!this.state.isEdit}
                        variant=""
                        title={this.state.plan}
                        id="input-group-dropdown-plan"
                        className={"w-full " + (!this.state.isEdit ? 'disabledState' : '')}
                      >
                        {this.state.planList?.map((m, i) => (
                          <Dropdown.Item
                            key={i}
                            style={{ textDecoration: "none" }}
                            className="w-full"
                            onClick={(e) => this.handlechangedrop(m)}
                          >
                            {m.name}
                          </Dropdown.Item>
                        ))}
                      </DropdownButton>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="flex items-center justify-center pr-11 pl-11">
              <hr
                class="my-6 w-full h-px pr-14 pl-14 border-t-0 bg-neutral-100 opacity-100 dark:opacity-50"
                style={{ background: "#C4C4C4" }}
              />
            </div>
            <div className="pr-11 pl-11">
              <div >
                <span className="text-base">License Information</span>
              </div>
              <div className="grid grid-cols-3 gap-4 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 mb-4">
                {this.state.licensesList?.map((m, i) => (
                  <div className="mt-3 px-2" >
                    <div className="flex flex-row rounded-t-lg" style={{ background: "#F3F6FA" }}>
                      <div className="flex flex-col items-center justify-center w-6/12 border-r-2 my-2">
                        <span className="text-sm">{m.licenseList.length}</span>
                        <span className="text-sm">Number of license</span>
                      </div>
                      <div className="flex flex-col items-center justify-cente w-6/12 my-2">
                        <span className="text-sm">{m.remainingScreens}</span>
                        <span className="text-sm">Number of Screens</span>
                      </div>
                    </div>
                    <div style={{ background: "#FFFFFF" }} className="px-4 py-3">
                      <div className="flex flex-row items-center justify-between ">
                        <span className="text-lg" ><span className="pr-1">&#8377;</span>{parseFloat(m.totalAmount).toFixed(2)}</span>

                      </div>
                    </div>
                    <div style={{ background: "#FFFFFF" }} className="px-4 rounded-b-lg">
                      <div className="flex flex-row items-center justify-between ">
                        <div className="mb-3 mt-4">
                          <label class="block text-gray-400 text-sm  mb-2" for="grid-first-name">
                            Start Date
                          </label>
                          <input type="text" value={moment(m.createdOn).format("YYYY-MM-DD")} name="startDate" className="form-control placeholder-gray-400 text-sm py-2.5 disabledStateExpiryBtn" placeholder="Enter company name" disabled />
                        </div>

                        <div className="mb-3 mt-4">
                          <label class="block text-gray-400 text-sm  mb-2" for="grid-first-name">
                            Expiry Date
                          </label>


                          <input type="text" value={moment(m.createdOn).add(m.totalDuration, 'days').format("YYYY-MM-DD")} name="startDate" className="form-control placeholder-gray-400 text-sm py-2.5 disabledStateExpiryBtn" placeholder="Enter company name" disabled />

                        </div>

                      </div>
                    </div>
                  </div>

                ))}
                {this.state.licensesList.length == 1 &&
                  <div className="flex flex-col items-center justify-center py-3 border-dashed mt-3 px-2 border-2 rounded-2xl" style={{ width: "500px" }}>

                    <img
                      className=""
                      style={{
                        width: "120px",

                      }}
                      src={
                        addlicense
                      }
                      alt="ChannelLogo"
                    />
                    <span className="py-3">Do you want to add license ?</span>
                    <div>
                      <button
                        type="button"
                        className={`text-base  ml-3 px-3 py-2 btn-prime text-white  rounded-lg`}
                        onClick={async () =>
                          this.props.router.navigate("/admin/addSubscription/" + this.state.selectedCustomerId)
                        }
                      >
                        Add License
                      </button>
                    </div>
                  </div>}

              </div>

              {this.state.licensesList.length >= 2 && <div className="flex flex-row items-center justify-center py-3">

                <span>Do you want to add license ?</span>
                <div>
                  <button
                    type="button"
                    className={`text-base  ml-3 px-3 py-2 btn-prime text-white  rounded-lg`}
                    onClick={async () =>
                      this.props.router.navigate("/admin/addSubscription/" + this.state.selectedCustomerId)
                    }
                  >
                    Add License
                  </button>
                </div>
              </div>}


            </div>
          </div>
        </div>
        <Modal show={this.state.isRedirect} onHide={this.closeAll}>
          <Modal.Header >
            <Modal.Title>Customer</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure, you would view this customer account?</Modal.Body>
          <Modal.Footer>
            <Button className="btn btn-pr" onClick={this.closeAll}>
              NO
            </Button>
            <Button className="btn btn-prime" onClick={this.redirectToScreenManager}>
              YES
            </Button>
          </Modal.Footer>
        </Modal>
        <ToastContainer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.counter.isLoading,
  currentColor: state.counter.currentColor,
});

const mapDispatchToProps = (dispatch) => ({
  counterSlice: bindActionCreators(commanActionCreator, dispatch),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ViewCustomer));
