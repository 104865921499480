
import React from 'react';

const Links = (props) => {
  let { style } = props;
  return (
    <svg className={style} width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_7220_8108)">
    <path d="M19.694 2.31605C17.8414 0.463717 14.7724 0.437609 12.8938 2.31605L10.6269 4.58294C9.34245 5.86737 8.8889 7.71862 9.41781 9.41869C7.95602 8.93143 5.98644 9.18547 4.58206 10.5898L2.31517 12.8567C0.459123 14.7124 0.440166 17.7815 2.31517 19.6569C4.18173 21.5232 7.25103 21.5209 9.11544 19.6569L11.3823 17.3901C12.6668 16.1056 13.1203 14.2541 12.5914 12.5538C14.0448 13.0384 16.0167 12.7928 17.4272 11.3827L19.694 9.11578C21.5572 7.25367 21.562 4.1839 19.694 2.31618V2.31605ZM10.8537 16.8989L8.5868 19.1657C6.98981 20.763 4.46467 20.7864 2.84428 19.1657C1.25755 17.5793 1.25755 15.0099 2.84428 13.4238L5.11117 11.1569C6.09229 10.1758 7.6152 9.70197 9.19133 10.1747L4.3933 14.9722C3.63778 15.6526 3.63778 16.9362 4.3933 17.6167C5.17863 18.4025 6.38953 18.3372 7.03778 17.6167L11.8358 12.8186C12.2891 14.2167 11.9491 15.8033 10.8536 16.8988L10.8537 16.8989ZM6.66019 15.3498C6.96246 15.6521 7.34008 15.841 7.71801 15.8787L6.50894 17.0878C6.13156 17.5411 5.29987 17.5416 4.92221 17.0878C4.46894 16.7099 4.46894 15.8793 4.92221 15.5013L6.13128 14.2923C6.16896 14.7076 6.35793 15.0855 6.66019 15.3498ZM8.77576 14.8209C8.32012 15.2768 7.6442 15.2768 7.18903 14.8209C6.73576 14.3679 6.73576 13.6499 7.18903 13.2345L13.2339 7.18966C13.6842 6.73903 14.3694 6.7385 14.8203 7.18966C15.2736 7.64293 15.2736 8.36075 14.8203 8.77639L8.77576 14.8209ZM14.2914 6.09392L15.5005 4.88485C15.9951 4.39074 16.7446 4.47428 17.0869 4.88485C17.5821 5.37949 17.4972 6.13002 17.0869 6.47158L15.9161 7.71857C15.8405 7.30298 15.6895 6.92507 15.3872 6.66075C15.085 6.35848 14.7071 6.16954 14.2915 6.0939L14.2914 6.09392ZM19.1651 8.58742L16.8982 10.8543C15.8955 11.8573 14.4105 12.3514 12.818 11.8744L17.616 7.07639C18.4008 6.29213 18.3373 5.08097 17.616 4.43191C16.9435 3.61021 15.6388 3.65291 14.9716 4.39422L10.1358 9.19199C9.72023 7.79422 10.0225 6.20756 11.1559 5.11183L13.4228 2.84493C15.0224 1.24505 17.5483 1.22791 19.1648 2.84493C20.7517 4.43166 20.7517 7.00076 19.1651 8.58742Z" fill="#C4C4C4"/>
    </g>
    <defs>
    <clipPath id="clip0_7220_8108">
    <rect width="22" height="22" fill="white"/>
    </clipPath>
    </defs>
    </svg>

  )
}

export default Links;

