import React from 'react';

const SoccerIcon = (props) => {
    let { style } = props;
    return (
        <svg width="32" height="37" className={style} viewBox="0 0 32 37" xmlns="http://www.w3.org/2000/svg">
            <path d="M28.0171 36.2314C29.8516 36.2314 31.3388 34.7442 31.3388 32.9096C31.3388 31.0751 29.8516 29.5879 28.0171 29.5879C26.1825 29.5879 24.6953 31.0751 24.6953 32.9096C24.6953 34.7442 26.1825 36.2314 28.0171 36.2314Z" />
            <path d="M7.78953 7.40039C7.78953 7.40039 6.13911 7.78408 5.63211 8.04548C5.12052 8.25083 2.38068 10.7079 1.2877 12.1086C0.895343 13.7117 0.171274 17.4935 0.0107663 19.0599C-0.113564 20.0596 0.874451 20.3006 1.14451 20.3236C1.22859 20.3302 2.40463 20.376 2.54679 19.255C2.68946 18.1345 3.61429 13.4971 3.61429 13.4971L7.42521 11.1282C7.42521 11.1282 7.0889 12.0526 6.30522 12.6421C5.52 13.2301 10.8168 23.0955 10.8168 23.0955C10.8168 23.0955 15.9158 29.5347 15.9739 29.6192C16.0315 29.7033 20.1986 36.4686 20.1986 36.4686C20.1986 36.4686 20.8992 37.503 22.2714 36.6847C23.3053 36.0426 22.4605 34.5975 22.4605 34.5975L19.1484 28.2251L15.1612 21.4981C15.1612 21.4981 14.937 12.1382 13.3115 7.4009C13.3105 7.40039 11.1801 9.55884 7.78953 7.40039Z" />
            <path d="M6.54141 32.9097C6.54141 32.9097 4.53582 30.8991 6.24332 29.7286L11.565 26.3096L13.7296 29.1055L6.54141 32.9097Z" />
            <path d="M16.5391 20.7627L16.6109 22.2012L18.9227 25.8485C18.9227 25.8485 20.2302 25.3874 16.5391 20.7627Z" />
            <path d="M14.7773 8.21387C14.7773 8.21387 15.4968 9.55857 15.6232 12.5863L17.64 14.1206L21.3424 14.7743C21.3424 14.7743 22.5204 14.7009 22.6183 13.4979C22.7029 12.4615 21.5885 12.3035 21.3424 12.2215C21.2598 12.1934 18.6983 11.661 18.6983 11.661L14.7773 8.21387Z" />
            <path d="M10.6262 0C10.187 0 8.11719 0.22471 8.11719 2.508C8.11719 4.79128 9.10113 6.97215 10.6257 6.97215C12.1503 6.97215 13.1337 4.5895 13.1337 2.508C13.1337 0.426492 11.2127 0 10.6262 0Z" />
        </svg>

    )
}

export default SoccerIcon;