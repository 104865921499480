import React from 'react';

const EditIcon = ({style}) => {
    return (
        <svg width="25" className={style} height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.74312 15.3247V15.3481L4.1872 19.9725C4.16288 20.0458 4.15943 20.1244 4.17723 20.1996C4.19503 20.2748 4.23338 20.3435 4.288 20.3981C4.34261 20.4527 4.41134 20.4911 4.48651 20.5089C4.56167 20.5267 4.6403 20.5232 4.71361 20.4989L9.32473 18.963H9.34805C9.39385 18.9429 9.43562 18.9147 9.47133 18.8797L20.0429 8.3081C20.3482 7.9967 20.5191 7.57804 20.5191 7.14199C20.5191 6.70595 20.3482 6.28728 20.0429 5.97589L18.7102 4.64319C18.3988 4.33796 17.9802 4.16699 17.5441 4.16699C17.1081 4.16699 16.6894 4.33796 16.378 4.64319L5.82641 15.2015C5.79136 15.2371 5.76313 15.2789 5.74312 15.3247ZM6.30285 16.2643L8.42183 18.3833L5.24003 19.4428L6.30285 16.2643ZM14.259 7.93495L16.7445 10.4204L9.19479 17.9801L6.70932 15.4946L14.259 7.93495ZM15.1386 7.05537L17.6207 9.54084L17.3309 9.8307L14.8454 7.34523L15.1386 7.05537ZM16.9711 5.22291C17.1259 5.0687 17.3356 4.98211 17.5541 4.98211C17.7727 4.98211 17.9823 5.0687 18.1372 5.22291L19.4432 6.55228C19.5201 6.62902 19.581 6.72016 19.6227 6.8205C19.6643 6.92083 19.6857 7.02838 19.6857 7.137C19.6857 7.24561 19.6643 7.35316 19.6227 7.45349C19.581 7.55383 19.5201 7.64497 19.4432 7.72171L18.2105 8.96445L15.7283 6.47898L16.9711 5.22291Z" fill="#C4C4C4" stroke="#C4C4C4" strokeWidth="0.2125" />
            <path d="M11.4033 13.9859C11.4409 14.0258 11.486 14.0579 11.536 14.0803C11.586 14.1028 11.6399 14.1151 11.6947 14.1167C11.7495 14.1182 11.8041 14.1089 11.8553 14.0894C11.9064 14.0698 11.9533 14.0403 11.993 14.0026L15.468 10.7308C15.5426 10.6539 15.5847 10.5513 15.5855 10.4442C15.5864 10.3371 15.5459 10.2338 15.4726 10.1557C15.3992 10.0777 15.2987 10.0309 15.1917 10.025C15.0848 10.0192 14.9797 10.0548 14.8983 10.1244L11.42 13.3962C11.3801 13.4338 11.348 13.4789 11.3256 13.5289C11.3031 13.5789 11.2908 13.6328 11.2892 13.6876C11.2877 13.7424 11.297 13.7969 11.3165 13.8481C11.3361 13.8993 11.3656 13.9461 11.4033 13.9859Z" fill="#C4C4C4" />
        </svg>


    )
}

export default EditIcon;