import React from "react";
import { connect } from "react-redux";
import { Modal, Container } from "react-bootstrap";
import { FaRegCheckCircle, FaEyeSlash, FaEye } from 'react-icons/fa'
import { withRouter } from "../../router";
import { bindActionCreators } from "@reduxjs/toolkit";
import { ToastContainer, toast } from 'react-toastify';

import { adminLogin  } from './services/adminLogin.service';
import * as commanActionCreator from '../../reducers/commanReduces'

class AdminLoginComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      usernameError: "",
      passwordError: "",
      loading: false,
      showPassword: false,
    };
  }
  onInputChange = (type, event) => {
    this.setState({ [event.target.name]: event.target.value });
  }

  login = async () => {

    let loginDTO = {
      userName: this.state.email,
      password: this.state.password,
    };
    this.props.counterSlice.startLoading();
    let response = await adminLogin(loginDTO);
    this.props.counterSlice.stopLoading();

    if (response.success) {
      localStorage.setItem("isAdminLogin", true);
      localStorage.setItem("isLogin", true);
      localStorage.setItem("userInfo", JSON.stringify(response.body));
      localStorage.setItem("accessToken", response.body.accessToken);
      this.props.router.navigate("/admin/customers",{ replace: true });
    } else {
      toast.error('Invalid credentials.', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        theme: "colored",
        progress: undefined,
      });
    }
  }
 
  render() {
    return (
      <div className='flex flex-col w-full h-screen'>
        <div className="grid lg:grid-cols-1 h-full " >
          
          {          
            <div className="flex justify-center items-center">
              <div className='min-w-[450] px-8'>
                <div className="mb-8">
                  <h1 className="text-3xl font-medium">Welcome Back</h1>
                  <p>Please enter your admin credentials to sign in !</p>
                </div>
                <div>
                  <div className="mb-3">
                    <label className='font-medium mb-2 flex'>Email</label>
                    <input type="text" placeholder="Enter your Email" name="email" value={this.state.email} onChange={(e) => this.onInputChange("email", e)} className='w-full border form-control rounded-md bg-transparent border-gray-400 p-3' />
                  </div>
                  <div className="mb-3 relative">
                    <label className='font-medium mb-2 flex'>Password</label>
                    <input type={this.state.showPassword ? "text" : "password"} name="password" value={this.state.password} placeholder="Enter your Password"
                      className="w-full border rounded-md bg-transparent border-gray-400 p-3"
                      id="logipwd"
                      onChange={(e) => this.onInputChange("password", e)} />
                    {this.state.showPassword ? <FaEye className="absolute top-1 right-1 mt-10 mr-2" size={25} opacity={0.7} style={{ marginLeft: "-30px", cursor: "pointer" }} onClick={() => this.setState({ showPassword: !this.state.showPassword })} /> :
                      <FaEyeSlash className="absolute top-1 right-1 mt-10 mr-2" size={25} opacity={0.7} style={{ marginLeft: "-30px", cursor: "pointer" }} onClick={() => this.setState({ showPassword: !this.state.showPassword })} />}
                  </div>
                  
                  <button className="block bg-blue-700 hover:bg-blue-800 cursor-pointer w-full text-white py-2 px-8 rounded " onClick={this.login}>
                    Sign In
                  </button>                  
                </div>
              </div>
            </div>            
            }
        </div>       
        <ToastContainer />
      </div>
    );
  }
};

const mapStateToProps = (state) => ({
  isLoading: state.counter.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  counterSlice: bindActionCreators(commanActionCreator, dispatch)
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AdminLoginComponent));
