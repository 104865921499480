import * as Constants from '../constant';
import XMLParser from 'react-xml-parser';


let fetchWeatherInfo = async (city) => {
  let url = `https://api.weatherapi.com/v1/forecast.json?key=28da268109df466e9d655831230708&q=${city}&days=4&aqi=no&alerts=no`;
  return fetch(url, {
    method: "GET",
  })
    .then((data) => data.json())
    .catch((error) => {
      return {};
    });
}

let createApp = async (appDTO) => {
  const token = localStorage.getItem("accessToken");

  return fetch(Constants.API_URL + "/media/app", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: "Barear " + token
    },
    body: JSON.stringify(appDTO),

  })
    .then((data) => data.json())
    .catch((error) => {
      return {};
    });
}
let fetchAllApps = async () => {
  const token = localStorage.getItem("accessToken");

  return fetch(Constants.API_URL + "/admin/app", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: "Barear " + token
    },
  })
    .then((data) => data.json())
    .catch((error) => {
      return {};
    });
};

let fetchAllAppData = async () => {
  const token = localStorage.getItem("accessToken");

  return fetch(Constants.API_URL + "/media/app", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: "Barear " + token
    },
  })
    .then((data) => data.json())
    .catch((error) => {
      return {};
    });
}

let fetchAppData = async (id) => {
  const token = localStorage.getItem("accessToken");

  return fetch(Constants.API_URL + "/media/app/" + id, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: "Barear " + token
    },
  })
    .then((data) => data.json())
    .catch((error) => {
      return {};
    });
}

let fetchCurrencyList = async () => {
  let url = `https://openexchangerates.org/api/currencies.json?app_id=176a6a6ec1cc41218953ff2b84af519f`;
  return fetch(url, {
    method: "GET",
  })
    .then((data) => data.json())
    .catch((error) => {
      return {};
    });
}

let fetchCurrencyData = async (currency) => {
  let url = `https://openexchangerates.org/api/latest.json?app_id=176a6a6ec1cc41218953ff2b84af519f&base=` + currency;
  return fetch(url, {
    method: "GET",
  })
    .then((data) => data.json())
    .catch((error) => {
      return {};
    });
}

let fetchTimeZoneList = async () => {
  let url = `https://world-time2.p.rapidapi.com/timezone`;
  return fetch(url, {
    method: 'GET',
    headers: {
      'X-RapidAPI-Key': '3aaa194146msh4a7492382cf8cc6p1af324jsn9b4636aa8169',
      'X-RapidAPI-Host': 'world-time2.p.rapidapi.com'
    }
  })
    .then((data) => data.json())
    .catch((error) => {
      return {};
    });
}

let fetchCricketMatchList = async (type) => {
  let url = `https://crickbuzz-official-apis.p.rapidapi.com/matches/` + type;
  return fetch(url, {
    method: 'GET',
    headers: {
      'X-RapidAPI-Key': '398e76b4e4mshc606a345c0bbde6p135cc3jsn7d726e03253c',
      'X-RapidAPI-Host': 'crickbuzz-official-apis.p.rapidapi.com'
    }
  }).then((data) => data.json())
    .catch((error) => {
      return {};
    });
}

let fetchMatchInfo = async (matchId) => {
  let url = `https://crickbuzz-official-apis.p.rapidapi.com/match/${matchId}/scorecard`;
  return fetch(url, {
    method: 'GET',
    headers: {
      'X-RapidAPI-Key': '398e76b4e4mshc606a345c0bbde6p135cc3jsn7d726e03253c',
      'X-RapidAPI-Host': 'crickbuzz-official-apis.p.rapidapi.com'
    }
  }).then((data) => data.json())
    .catch((error) => {
      return {};
    });
}

let fetchSoccerCountryList = async () => {
  let url = `https://livescore-football.p.rapidapi.com/soccer/countries`;
  return fetch(url, {
    method: 'GET',
    headers: {
      'X-RapidAPI-Key': 'c9738b0567msh31326b9ca1473b3p10703djsnabbd88ec16ed',
      'X-RapidAPI-Host': 'livescore-football.p.rapidapi.com'
    }
  }).then((data) => data.json())
    .catch((error) => {
      return {};
    });
};
let fetchSoccerLeaguesService = async (countryCode) => {
  let url = `https://livescore-football.p.rapidapi.com/soccer/leagues-by-country?country_code=` + countryCode;
  return fetch(url, {
    method: 'GET',
    headers: {
      'X-RapidAPI-Key': 'c9738b0567msh31326b9ca1473b3p10703djsnabbd88ec16ed',
      'X-RapidAPI-Host': 'livescore-football.p.rapidapi.com'
    }
  }).then((data) => data.json())
    .catch((error) => {
      return {};
    });
};
let fetchSoccerMatchesService = async (countryCode, leagueCode) => {
  let url = `https://livescore-football.p.rapidapi.com/soccer/matches-by-league?country_code=${countryCode}&league_code=${leagueCode}`;
  return fetch(url, {
    method: 'GET',
    headers: {
      'X-RapidAPI-Key': 'c9738b0567msh31326b9ca1473b3p10703djsnabbd88ec16ed',
      'X-RapidAPI-Host': 'livescore-football.p.rapidapi.com'
    }
  }).then((data) => data.json())
    .catch((error) => {
      return {};
    });
};
let getGoogleSheetListService = async (fileId, accessToken) => {
  let url = `https://sheets.googleapis.com/v4/spreadsheets/${fileId}`;
  return fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    }
  }).then((data) => data.json())
    .catch((error) => {
      return {};
    });
};
let getGoogleSheetDataService = async (fileId, sheetTitle, accessToken) => {
  let url = `https://sheets.googleapis.com/v4/spreadsheets/${fileId}/values/${sheetTitle}`;
  return fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    }
  }).then((data) => data.json())
    .catch((error) => {
      return {};
    });
};

let loadImageFromDrive = async (fileId, accessToken) => {
  let url = `https://www.googleapis.com/drive/v3/files/${fileId}?alt=media`;
  return fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    }
  }).then(async (data) => await data.blob())
    .catch((error) => {
      return {};
    });
};

let fetchUserInstagramToken = async (code) => {
  const token = localStorage.getItem("accessToken");

  return fetch(Constants.API_URL + "/media/getInstagramToken", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: "Barear " + token
    },
    body: JSON.stringify({ code }),
  })
    .then((data) => data.json())
    .catch((error) => {
      return {};
    });
}

let getStockPrice = async (type) => {
  let url = `https://latest-stock-price.p.rapidapi.com/equities?Indicies=` + type;
  return fetch(url, {
    method: 'GET',
    headers: {
      'X-RapidAPI-Key': 'c9738b0567msh31326b9ca1473b3p10703djsnabbd88ec16ed',
      'X-RapidAPI-Host': 'latest-stock-price.p.rapidapi.com'
    }
  }).then(async (data) => await data.json())
    .catch((error) => {
      return {};
    });
};

let getRssLinkData = async (url) => {

  return fetch(url)
    .then(async (data) => await data.text())
    .then(data => {
      return new XMLParser().parseFromString(data);
    }).catch((error) => {
      return "Error";
    });

};

let fetchInstaAppData = async (id) => {
  const token = localStorage.getItem("accessToken");

  return fetch(Constants.API_URL + "/media/app/instagram/" + id, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: "Barear " + token
    },
  })
    .then((data) => data.json())
    .catch((error) => {
      return {};
    });
}
let fetchMeetTheTeamAppData = async (id) => {
  const token = localStorage.getItem("accessToken");

  return fetch(Constants.API_URL + "/media/app/meetTeam/" + id, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: "Barear " + token
    },
  })
    .then((data) => data.json())
    .catch((error) => {
      return {};
    });
}

export {
  fetchAllApps,
  fetchWeatherInfo,
  createApp,
  fetchAllAppData,
  fetchAppData,
  fetchCurrencyList,
  fetchCurrencyData,
  fetchTimeZoneList,
  fetchCricketMatchList,
  fetchMatchInfo,
  //Soccer API
  fetchSoccerCountryList,
  fetchSoccerLeaguesService,
  fetchSoccerMatchesService,
  //MEET_THE_TEAM
  getGoogleSheetListService,
  getGoogleSheetDataService,
  loadImageFromDrive,
  fetchMeetTheTeamAppData,
  //INSTA_GALLERY
  fetchUserInstagramToken,
  fetchInstaAppData,
  //RSS
  getRssLinkData,
  //Stock
  getStockPrice,
};