import * as Constants from '../../../constant';


let adminLogin = async (loginDTO) => {
    return fetch(Constants.API_URL + "/admin/login", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(loginDTO),
    }).then((data) => data.json())
        .catch((error) => {
            return {};
        });
}


export {
  adminLogin,
};