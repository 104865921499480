import React from "react";
import { connect } from "react-redux";
import { Dropdown, DropdownButton, Form, InputGroup, } from "react-bootstrap";
import { format } from 'date-fns'

import { withRouter } from "../../router";
import * as commanActionCreator from '../../reducers/commanReduces'
import { bindActionCreators } from "@reduxjs/toolkit";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

import { fetchUserActivityLogs, downloadUserActivityLog } from "../../services/screenService";
import { FaSortAmountDown, FaSortAmountUp, FaSpinner } from "react-icons/fa";
import { DataNotFound } from "../utilComponents/inputFieldAndButtons";
import DatePicker from "react-datepicker";
import { convertDate } from "../utilComponents/utilFuncation";
import moment from "moment";

class UserActivityComponent extends React.Component {

    constructor(props) {
        super(props);
        // let dateCurrent = new Date();
        // let month = (dateCurrent.getMonth() + 1);
        // let day = dateCurrent.getDate();
        // dateCurrent = dateCurrent.getFullYear() + "-" + (month < 10 ? "0" + month : month) + "-" + (day < 10 ? "0" + day : day);
        // console.log(dateCurrent);
        this.state = {
            onlineOffline: true,
            screenLogs: false,
            isMoreDetails: false,
            isDownloading: false,
            isGridView: true,
            userActivityList: [],
            userActivityListTemp: [],
            specificScreenLogData: [],
            selectedScreen: "",
            selectedDate: "",
            selectedStatus: "ALL",
            dropdownbuttontitle: "Search By",
            page: 1,
            limit: 10,
            totalCount: 0,
            duration: 7,
            screenList: [],
            screenTempList: [],
            timelineForSelectedDate: [],
            dateSelectedModel: false,
            isAcending: true,
            startDate: new Date(),
            endDate: convertDate(new Date()),
        }
    }

    async componentDidMount() {
        this.props.counterSlice.startLoading();
        let d = new Date();
        d.setDate(d.getDate() - 7);
        let startDate = convertDate(d);
        let userLogInfo = await fetchUserActivityLogs(this.state.page, this.state.limit, this.state.isAcending, startDate, this.state.endDate);
        this.setState({ startDate, userActivityList: userLogInfo.body, userActivityListTemp: userLogInfo.body, totalCount: Math.ceil(userLogInfo.totalCount / this.state.limit) })
        this.props.counterSlice.stopLoading();

    }

    downaloadFile = async () => {
        this.setState({ isDownloading: true });
        await downloadUserActivityLog(this.state.startDate, this.state.endDate);
        this.setState({ isDownloading: false });
    }
    handlechangedrop = (e) => {
        if (e === "Name") {
            this.setState({
                dropdownbuttontitle: "Name",
                selectedFilter: "NAME"
            })
        }
        if (e === "Email") {
            this.setState({
                dropdownbuttontitle: "Email",
                selectedFilter: "Email"
            })
        }
    }
    onLimitChange = async (e) => {
        this.props.counterSlice.startLoading();
        let userLogInfo = await fetchUserActivityLogs(1, e.target.value, this.state.isAcending, this.state.startDate, this.state.endDate);
        this.setState({ userActivityList: userLogInfo.body, userActivityListTemp: userLogInfo.body, limit: e.target.value, page: 1, totalCount: Math.ceil(userLogInfo.totalCount / this.state.limit) })
        this.props.counterSlice.stopLoading();
    }
    onPageChange = async (page) => {

        if (page > 0 && page <= this.state.totalCount) {
            this.props.counterSlice.startLoading();
            let userLogInfo = await fetchUserActivityLogs(page, this.state.limit, this.state.isAcending, this.state.startDate, this.state.endDate);
            this.setState({ userActivityList: userLogInfo.body, userActivityListTemp: userLogInfo.body, page, totalCount: Math.ceil(userLogInfo.totalCount / this.state.limit) })
            this.props.counterSlice.stopLoading();
        }
    }
    searchEmail = (e) => {
        if (e.target.value != "") {
            this.setState({ userActivityList: this.state.userActivityListTemp.filter((s) => s.userId.email.toUpperCase().includes(e.target.value.toUpperCase())) })
        } else
            this.setState({ userActivityList: this.state.userActivityListTemp })
    }
    searchName = (e) => {
        if (e.target.value != "") {
            this.setState({ userActivityList: this.state.userActivityListTemp.filter((s) => s.userId.firstName.toUpperCase().includes(e.target.value.toUpperCase())) })
        } else
            this.setState({ userActivityList: this.state.userActivityListTemp })
    }
    removeDuplicates = (arr) => {
        const uniqueArray = [];
        const idTracker = {};

        for (let obj of arr) {
            const id = obj._id;
            if (!idTracker[id]) {
                uniqueArray.push(obj);
                idTracker[id] = true;
            }
        }
        return uniqueArray;
    }
    searchByEmailAndName = (e) => {

        if ((e.target.value != "")) {
            let screenList = [];
            for (let i = 0; i < this.state.screenTempList.length; i++) {
                let s = this.state.screenTempList[i]
                for (let j = 0; j < s.tags.length; j++) {
                    if (s.tags[j] && s.tags[j].tag.toUpperCase().includes(e.target.value.toUpperCase())) {
                        screenList.push(s)
                        break;
                    }
                }
            }
            let screenNameSort = this.state.screenTempList.filter((s) => s.name.toUpperCase().includes(e.target.value.toUpperCase()));
            let screenNameList = screenList.concat(screenNameSort);
            this.setState({ screenList: this.removeDuplicates(screenNameList) });

        } else
            this.setState({ screenList: this.state.screenTempList })
    }
    onChnageFilter = async () => {
        this.setState({ isAcending: !this.state.isAcending });
        this.props.counterSlice.startLoading();
        let userLogInfo = await fetchUserActivityLogs(1, this.state.limit, !this.state.isAcending, this.state.startDate, this.state.endDate);
        this.setState({ userActivityList: userLogInfo.body, userActivityListTemp: userLogInfo.body, page: 1, totalCount: Math.ceil(userLogInfo.totalCount / this.state.limit) })
        this.props.counterSlice.stopLoading();
    }
    searchUserActivityLog = async () => {
        this.props.counterSlice.startLoading();
        let userLogInfo = await fetchUserActivityLogs(this.state.page, this.state.limit, this.state.isAcending, this.state.startDate, this.state.endDate);
        this.setState({ userActivityList: userLogInfo.body, userActivityListTemp: userLogInfo.body, totalCount: Math.ceil(userLogInfo.totalCount / this.state.limit) })
        this.props.counterSlice.stopLoading();
    }
    ondurationchange = async (e) => {
        this.setState({ duration: e.target.value });
        console.log(e.target.value);
        if (e.target.value != 0) {
            let d = new Date();
            d.setDate(d.getDate() - e.target.value);
            let startDate = convertDate(d);
            this.props.counterSlice.startLoading();
            let userLogInfo = await fetchUserActivityLogs(this.state.page, this.state.limit, this.state.isAcending, startDate, this.state.endDate);
            this.setState({ startDate: startDate, userActivityList: userLogInfo.body, userActivityListTemp: userLogInfo.body, totalCount: Math.ceil(userLogInfo.totalCount / this.state.limit) })
            this.props.counterSlice.stopLoading();
        } else {
            let date = convertDate(new Date());
            this.setState({ endDate: date, startDate: date });
        }
    }
    onDateChange = (date, type) => {
        this.setState({ [type]: convertDate(new Date(date)) })
    }
    render() {
        const userRole = JSON.parse(localStorage.getItem("roles"));
        return (
            <div>
                <div className="text-sm flex flex-wrap my-3 gap-2 relative bg-white-200">
                    <InputGroup className="self-center xl:flex-1">
                        <DropdownButton
                            style={{ backgroundColor: "red" }}
                            variant=""
                            title={this.state.dropdownbuttontitle}
                            id="input-group-dropdown-1">
                            <Dropdown.Item style={{ textDecoration: "none" }} onClick={(e) => this.handlechangedrop("Name")}>Name</Dropdown.Item>
                            <Dropdown.Item style={{ textDecoration: "none" }} onClick={(e) => this.handlechangedrop("Email")}>Email</Dropdown.Item>
                        </DropdownButton>
                        <Form.Control
                            size="lg"
                            type="search"
                            placeholder={this.state.selectedFilter === "Search By" ? "Search by Name or Email" : this.state.selectedFilter === "NAME" ? "Search by Name" : "Search by Email"}
                            className="rounded-t-lg text-sm focus:border-gray-300 active:border-gray-300"
                            style={{ borderLeft: "0px" }}
                            onChange={(e) => this.state.selectedFilter === "Search By" ? this.searchByEmailAndName(e) : this.state.selectedFilter === "SCREEN" ? this.searchName(e) : this.searchEmail(e)}
                        />
                    </InputGroup>
                    <div className="flex flex-wrap gap-2">
                        <select className="bg-white-200 w-40 flex-1 p-1 text-sm shadow rounded self-center" value={this.state.duration} onChange={(e) => this.ondurationchange(e)} >
                            <option value={7}>Last 7 Days</option>
                            <option value={30}>Last 30 Days</option>
                            <option value={0}>Custom</option>
                        </select>
                        <div className={this.state.duration != 0 ? " flex p-1 text-gray-450 self-center border-1 border-solid border-gray-450 rounded-lg" : "flex p-1 text-black text-xs self-center border-1 border-solid border-black rounded-lg"}>
                            <div className="flex-1"><span className="text-gray-450 text-xs">Start&nbsp;Date:&nbsp;</span></div>
                            <DatePicker
                                className={"text-xs max-w-24 " + (this.state.duration != 0 ? "text-gray-450" : "text-black")}
                                onChange={(e) => { this.onDateChange(e, "startDate"); this.onDateChange(new Date(), "endDate") }}
                                disabled={this.state.duration != 0}
                                selected={this.state.startDate}
                                maxDate={convertDate(new Date())}
                                dateFormat="dd/MM/yyyy"
                                />
                        </div>
                        <div className="flex">
                            <div className={this.state.duration != 0 ? " flex flex-1 p-1 text-gray-450 self-center border-1 border-solid border-gray-450 rounded-lg" : "flex flex-1 p-1 text-black text-xs self-center border-1 border-solid border-black rounded-lg"}>
                                <div className="flex-1"><span className="text-gray-450 text-xs">End&nbsp;Date:&nbsp;</span></div>
                                <DatePicker
                                    className={"text-xs max-w-24 " + (this.state.duration != 0 ? "text-gray-450" : "text-black")}
                                    onChange={(e) => this.onDateChange(e, "endDate")}
                                    disabled={this.state.duration != 0}
                                    selected={this.state.endDate}
                                    maxDate={convertDate(new Date())}
                                    minDate={this.state.startDate}
                                    dateFormat="dd/MM/yyyy"
                                    />
                            </div>
                        </div>
                    </div>
                    <button className="self-center" title="Search" disabled={this.state.duration != 0} onClick={this.searchUserActivityLog}>  <svg className={this.state.duration != 0 ? "fill-gray-450 w-5 h-5" : "flex fill-black h-5 w-5"}
                        strokeWidth="0" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                        <path d="M909.6 854.5L649.9 594.8C690.2 542.7 712 479 712 412c0-80.2-31.3-155.4-87.9-212.1-56.6-56.7-132-87.9-212.1-87.9s-155.5 31.3-212.1 87.9C143.2 256.5 112 331.8 112 412c0 80.1 31.3 155.5 87.9 212.1C256.5 680.8 331.8 712 412 712c67 0 130.6-21.8 182.7-62l259.7 259.6a8.2 8.2 0 0 0 11.6 0l43.6-43.5a8.2 8.2 0 0 0 0-11.6zM570.4 570.4C528 612.7 471.8 636 412 636s-116-23.3-158.4-65.6C211.3 528 188 471.8 188 412s23.3-116.1 65.6-158.4C296 211.3 352.2 188 412 188s116.1 23.2 158.4 65.6S636 352.2 636 412s-23.3 116.1-65.6 158.4z"></path></svg>
                    </button>
                    {this.state.userActivityList.length > 0 && <>{this.state.isDownloading ?
                        <div className="p-2 mx-2 self-center"><FaSpinner className="animate-spin" /></div> :
                        <button className={"p-2 mx-2 " + (userRole.monitoring["downaloadUA"].read ? "visible" : "collapse")} title="Dowload Logs" onClick={(e) => userRole.monitoring["downaloadUA"].write && this.downaloadFile()}>
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.984375 15.9844H17.0156V18H0.984375V15.9844ZM12.9844 9.98438L9 14.0156L5.01562 9.98438H8.01562V0H9.98438V9.98438H12.9844Z" fill="black" />
                            </svg>
                        </button>}</>}
                </div>
                <div className="shadow rounded">
                    <div className="flex bg-white p-3 text-gray-450 text-center text-sm font-medium border-b-1">
                        <div className="flex-1">Type</div>
                        <div className="flex-1">Name</div>
                        <div className="flex-1">E-mail</div>
                        <div className="flex-1">Activity</div>
                        <div className="flex-1 justify-center flex gap-2 cursor-pointer" onClick={this.onChnageFilter}>Action Date & Time{!this.state.isAcending ?
                            <FaSortAmountDown className="self-center" /> :
                            <FaSortAmountUp className="self-center" />}
                        </div>
                    </div>
                    {this.state.userActivityList.length > 0 ? <> {this.state.userActivityList?.map((ua) => (
                        <div className="flex bg-white p-2 text-gray-450 text-xs text-center text-black border-b-1">
                            <div className="flex-1">{ua.type}</div>
                            <div className="flex-1">{ua.userId.firstName} {ua.userId.lastName}</div>
                            <div className="flex-1">{ua.userId.email}</div>
                            <div className="flex-1">{ua.activity}</div>
                            <div className="flex-1">{format(new Date(ua.createdOn), "dd/MM/yyyy kk:mm:ss")}</div>
                        </div>
                    ))}
                        <div className="flex justify-end gap-4 rounded-b-lg bg-white p-3 text-gray-450 text-center text-sm font-medium border-b-1">
                            <span className="self-center" >Row per page:</span>
                            <select className="bg-white border-b-1 self-center border-b-black" onChange={(e) => this.onLimitChange(e)} >
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                                <option value="40">40</option>
                                <option value="50">50</option>
                            </select>
                            <span>{this.state.page} of {this.state.totalCount}</span>
                            <IoIosArrowBack className="self-center" onClick={() => this.onPageChange(this.state.page - 1)} />
                            <IoIosArrowForward className="self-center" onClick={() => this.onPageChange(this.state.page + 1)} />
                        </div>
                    </> : <DataNotFound />}
                </div>
            </div>
        );
    }
};

const mapStateToProps = (state) => ({
    isLoading: state.counter.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
    counterSlice: bindActionCreators(commanActionCreator, dispatch)
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserActivityComponent));
