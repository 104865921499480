import * as Constants from '../constant';

let addPlayList = async (playListDTO) => {
  const token = localStorage.getItem("accessToken");

  return fetch(Constants.API_URL + "/playlist", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: "Barear " + token
    },
    body: JSON.stringify(playListDTO),
  }).then((data) => data.json())
    .catch((error) => {
      return {};
    });
};

let updatePlayList = async (playListDTO) => {
  const token = localStorage.getItem("accessToken");

  return fetch(Constants.API_URL + "/playlist", {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      authorization: "Barear " + token
    },
    body: JSON.stringify(playListDTO),
  }).then((data) => data.json())
    .catch((error) => {
      return {};
    });
};

let fetchAllMedia = async (path) => {
  const token = localStorage.getItem("accessToken");

  return fetch(Constants.API_URL + "/media?path=" + path, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: "Barear " + token
    },
  }).then((data) => data.json())
    .catch((error) => {
      return {};
    });
};

let fetchAllPlayList = async () => {
  const token = localStorage.getItem("accessToken");

  return fetch(Constants.API_URL + "/playlist", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: "Barear " + token
    },
  }).then((data) => data.json())
    .catch((error) => {
      return {};
    });
};

let fetchPlayListDetails = async (playListId) => {
  const token = localStorage.getItem("accessToken");

  return fetch(Constants.API_URL + "/playlist/playListInfo/"+playListId, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: "Barear " + token
    },
  }).then((data) => data.json())
    .catch((error) => {
      return {};
    });
};

let attachedDataList = async (playListId) => {
  const token = localStorage.getItem("accessToken");

  return fetch(Constants.API_URL + "/playlist/attached/"+playListId, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: "Barear " + token
    },
  }).then((data) => data.json())
    .catch((error) => {
      return {};
    });
};

let deletePlayList = async (playListId) => {
  const token = localStorage.getItem("accessToken");

  return fetch(Constants.API_URL + "/playlist?playListId="+playListId, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      authorization: "Barear " + token
    },
  }).then((data) => data.json())
    .catch((error) => {
      return {};
    });
};
export {
  addPlayList,
  updatePlayList,
  fetchAllMedia,
  fetchAllPlayList,
  fetchPlayListDetails,
  attachedDataList,
  deletePlayList
};