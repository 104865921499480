
import React from 'react';

const Playlist = (props) => {
  let { style } = props;
  return (
    <svg className={style} width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.14627 15.7356L5.5 15.3171V11.188L6.14627 10.7695L10.7298 12.8341V13.671L6.14627 15.7356ZM6.4167 11.8973V14.6069L9.42532 13.2516L6.4167 11.8973Z" fill="#C4C4C4"/>
        <path d="M14.6626 20.1341H1.83707C0.824115 20.1341 0 19.3092 0 18.2952V8.20898C0 7.19504 0.824115 6.37012 1.83707 6.37012H14.6626C15.6747 6.37012 16.4997 7.19504 16.4997 8.20898V18.2952C16.4997 19.3092 15.6756 20.1341 14.6626 20.1341ZM1.83707 7.28771C1.32922 7.28771 0.916702 7.70063 0.916702 8.20898V18.2952C0.916702 18.8036 1.32922 19.2165 1.83707 19.2165H14.6626C15.1705 19.2165 15.583 18.8036 15.583 18.2952V8.20898C15.583 7.70063 15.1705 7.28771 14.6626 7.28771H1.83707Z" fill="#C4C4C4"/>
        <path d="M19.2506 17.3821H18.3339V5.45703C18.3339 4.94868 17.9214 4.53576 17.4136 4.53576H2.75V3.61816H17.4136C18.4265 3.61816 19.2506 4.44308 19.2506 5.45703V17.3821Z" fill="#C4C4C4"/>
        <path d="M22.0006 14.6292H21.0839V2.7041C21.0839 2.19575 20.6714 1.78283 20.1636 1.78283H5.5V0.865234H20.1636C21.1765 0.865234 22.0006 1.69015 22.0006 2.7041V14.6292Z" fill="#C4C4C4"/>
    </svg>

  )
}

export default Playlist;
