import React from "react";

const SortIcon = (props) => {
  let { style } = props;
  return ( <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M5.67773 14C5.60731 14 5.5394 13.9673 5.49664 13.9095L2.71232 10.2021C2.63686 10.1015 2.65698 9.9606 2.75759 9.88515C2.8582 9.80969 2.99905 9.82981 3.0745 9.93042L5.68025 13.3964L8.28348 9.93042C8.35893 9.82981 8.49978 9.80969 8.60039 9.88515C8.701 9.9606 8.72112 10.1015 8.64566 10.2021L5.85883 13.9095C5.81607 13.9673 5.74816 14 5.67773 14Z" fill="#979797" stroke="#979797" strokeWidth="0.5"/>
  <path d="M5.67773 14C5.55197 14 5.45136 13.8994 5.45136 13.7736V2.22637C5.45136 2.10061 5.55197 2 5.67773 2C5.80349 2 5.9041 2.10061 5.9041 2.22637V13.7736C5.9041 13.8994 5.80349 14 5.67773 14Z" fill="#979797" stroke="#979797" strokeWidth="0.5"/>
  <path d="M13.1127 6.16013C13.0447 6.16013 12.9768 6.12995 12.9316 6.06959L10.3283 2.60365L7.7251 6.06959C7.64965 6.1702 7.50879 6.19032 7.40819 6.11486C7.30758 6.0394 7.28746 5.89855 7.36291 5.79795L10.1472 2.09055C10.19 2.0327 10.2579 2 10.3283 2C10.3988 2 10.4667 2.0327 10.5094 2.09055L13.2937 5.79795C13.3692 5.89855 13.3491 6.0394 13.2485 6.11486C13.2082 6.14504 13.1604 6.16013 13.1127 6.16013Z" fill="#979797" stroke="#979797" strokeWidth="0.5"/>
  <path d="M10.3283 14C10.2026 14 10.102 13.8994 10.102 13.7736V2.22637C10.102 2.10061 10.2026 2 10.3283 2C10.4541 2 10.5547 2.10061 10.5547 2.22637V13.7736C10.5547 13.8994 10.4541 14 10.3283 14Z" fill="#979797" stroke="#979797" strokeWidth="0.5"/>
  </svg>
  
  )
};

export default SortIcon;
