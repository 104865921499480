import React from "react";
import { Modal } from "react-bootstrap";
import { FaTimes } from "react-icons/fa";
import CustomeCarousel from "./customeCarousel";

const ConfigureAppModel = (props) => {
    let { show, closeAll, onConfigure, imageList, icon, appName, appDetails, appDetailsInfo, isRead = true } = props;

    return (
        <Modal show={show} size="lg">
            <Modal.Body>
                <div className="flex justify-end">
                    <FaTimes size={20} className="self-center text-gray-400 hover:text-gray-600" onClick={closeAll} />
                </div>
                <div className="flex justify-between my-2">
                    <div className="flex gap-3">
                        <div className="self-center bg-dark-team rounded p-2.5">
                            {icon}
                        </div>
                        <div className="flex flex-col justify-between">
                            <div className="font-extrabold">{appName}</div>
                            <div>{appDetails}</div>
                        </div>
                    </div>
                    {isRead && <button className="p-2 bg-darkBlue text-white rounded" onClick={onConfigure} >CONFIGURE</button>}
                </div>
                <div className="flex">
                    <CustomeCarousel imageList={imageList} />
                </div>
                <div className="mx-16 mt-4 text-sm">
                    {appDetailsInfo}
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default ConfigureAppModel;