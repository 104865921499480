import React from "react";
import { connect } from "react-redux";
import { withRouter } from "../../router";
import { bindActionCreators } from "@reduxjs/toolkit";
import * as commanActionCreator from '../../reducers/commanReduces'
import { fetchAppData, fetchCurrencyData, fetchWeatherInfo } from "../../services/appServices";
import CurrencyPreview from "../subComponents/currencyPreview";
import { DataIsEmptyWithoutButton } from "../utilComponents/inputFieldAndButtons";

class CurrencyAppPublicComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currenciesList: [],
            currenciesSlotList: [],
            appInfo: "",
            baseCurrency: "",
            title: "",
            decimal: 0,
            elementsPerSlot: 8,
            duration: 5,
        }
    }
    async componentDidMount() {
        if (this.props.router.params.appId) {
            this.props.counterSlice.startLoading();
            let appInfo = await fetchAppData(this.props.router.params.appId);
            if (appInfo.success) {
                this.populateInfo(appInfo.body);
            }
            this.props.counterSlice.stopLoading();
        }
    }
    populateInfo = async (data) => {
        this.setState({
            appInfo: data,
            appName: data.name,
            baseCurrency: data.baseCurrency,
            title: data.pageTitle,
            decimal: data.decimal,
        });

        let currencyDataList = await fetchCurrencyData(data.baseCurrency);
        this.setState({ currencyDataList });
        data.currencyList.forEach(a => this.state.currenciesList.push({ ...a, rate: currencyDataList.rates[a.id] }));

        let slotData = [];
        for (let i = 0; i < this.state.currenciesList.length; i += this.state.elementsPerSlot) {
            const slot = this.state.currenciesList.slice(i, i + this.state.elementsPerSlot);
            slotData.push(slot);
        }
        this.setState({ currenciesSlotList: slotData });
    }

    roundDecimal = (number, n) => {
        const multiplier = Math.pow(10, n);
        const roundedNumber = Math.round(number * multiplier) / multiplier;
        return roundedNumber;
    }
    render() {

        return (
            <div className="flex flex-col flex-1 justify-center items-center bg-gray-800 h-screen w-full gap-4">
                {this.state.currenciesSlotList.length > 0 ?
                    <CurrencyPreview title={this.state.title} baseCurrency={this.state.baseCurrency} currenciesSlotList={this.state.currenciesSlotList} duration={this.state.duration}
                        isLandscape={true} decimal={this.state.decimal} /> :
                    <DataIsEmptyWithoutButton url={process.env.PUBLIC_URL + "/blankScreenIcon.svg"}
                        title="App instance preview"
                        body1="Edit and save the configuration to preview this app instance" />}
            </div>
        )
    };
}

const mapStateToProps = (state) => ({
    isLoading: state.counter.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
    counterSlice: bindActionCreators(commanActionCreator, dispatch)
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CurrencyAppPublicComponent));
