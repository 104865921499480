import React from "react";
import { connect } from "react-redux";
import { withRouter } from "../../router";
import { format } from 'date-fns'
import ReactPlayer from "react-player";

class InstagramPreviewScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            post: this.props.postList[0],
            index: 0,
            timer: "",
            duration: 0,
            playing: 0
        }
    }
    async componentDidMount() {
        this.timerList();
    }

    componentWillUnmount() {
        clearTimeout(this.state.timer);
    }


    timerList = async () => {
        this.setState({ post: this.props.postList[this.state.index] })
        this.state.timer = setTimeout(function () {
            if ((this.props.postList.length - 1) === this.state.index)
                this.state.index = 0
            else this.state.index += 1;
            this.timerList();
        }.bind(this), this.props.duration * 1000);
    }

    handleDuration = (duration) => {
        console.log('onDuration', duration)
    }

    render() {
        let style = "shadow flex overflow-hidden"
        if (this.props.type == "SINGLE_CARD") {
            style = style + " rounded w-4/5 h-4/5";
        } else {
            style = style + " w-full h-full";
        }
        return (<div className={style}>
            <div className="flex-1 ">
                {this.state.post.media_type !== "VIDEO" ? <img src={this.state.post.media_url} className="w-full h-full" alt="Loding..." /> :
                    <div className="h-full w-full"> <ReactPlayer
                        url={this.state.post.media_url}
                        width={"100%"}
                        height={"100%"}
                        playing={true}
                        onDuration={this.handleDuration}
                        onError={e => console.log('onError', e)}
                    />
                    </div>
                }
            </div>
            <div className="flex-1 m-3 flex flex-col">
                <div className="flex flex-col gap-1">
                    <span className="font-bold">{this.state.post.username}</span>
                    <span className="text-xs text-gray-500 italic">{format(new Date(this.state.post.timestamp), 'dd,MMMM yyyy HH:mm')}</span>
                </div>
                <span className="text-xs">{this.state.post.caption}</span>
            </div>
        </div>)
    };
}


const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(InstagramPreviewScreen));
