import React from "react";
import { connect } from "react-redux";
import { withRouter } from "../router";
import Sidebar from "./subComponents/Sidebar";
import Navbar from "./subComponents/Navbar";
import SearchWithButton from "./utilComponents/searchWithButton";
import Apps from "../appIcons/Apps";
import AllAppsComponent from "./subComponents/allAppsComponent";
import MyAppComponent from "./appComponents/myAppComponent";

class AppComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isAllAppsSelected: true,
            appSearchName: "",
            selectedImage: "",
            serachApp: "",
            tempAppList: []
        }
    }
    async componentDidMount() {
        this.setState({ tempAppList: this.state.appList });
    }
    closeAll = () => {
        this.setState({ addLinkModel: false });
    }
    searchAppsWithTitle = (e) => {
        this.setState({ serachApp: e.target.value });
        if (e.target.value !== "") {
            let tempData = this.state.appList.filter(app => {
                return (app.title.toLowerCase().search(e.target.value.toLowerCase()) >= 0)
            });
            this.setState({ tempAppList: tempData })
        } else {
            this.setState({ tempAppList: this.state.appList })
        }
    }
    render() {
        const userRole = JSON.parse(localStorage.getItem("roles"));

        return (
            <div className="flex">
                <Sidebar type={"apps"} />
                <div className="h-screen grow md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10 shadow">
                    <Navbar router={this.props.router} counterSlice={this.props.counterSlice} />
                    <div>
                        <SearchWithButton
                            icon={<Apps design={"self-center mr-3"} />}
                            title="Apps Store"
                            placeholder="Search Apps"
                            onChangeSearch={(e) => this.setState({ appSearchName: e.target.value })}
                            buttonName="Apps"
                            isRead={false} />
                        <div className="mx-20">
                            <div>
                                <div className="flex w-1/5 mt-3 text-center">
                                    <div className={"flex-1"}  >
                                        <button
                                            className={this.state.isAllAppsSelected ? "p-2 text-currentSecondaryColor" : "p-2"}
                                            onClick={() => this.setState({ isAllAppsSelected: true, appSearchName: "" })}>All Apps</button>
                                        {this.state.isAllAppsSelected && <hr className="border-4 rounded-lg mx-3" style={{ borderColor: "#00807F", opacity: "inherit" }} />}
                                    </div>
                                    <div className={"flex-1"}  >
                                        <button
                                            className={!this.state.isAllAppsSelected ? "p-2 text-currentSecondaryColor" : "p-2"}
                                            onClick={() => this.setState({ isAllAppsSelected: false, appSearchName: "" })}>My Apps</button>
                                        {!this.state.isAllAppsSelected && <hr className="border-4 rounded-lg mx-3" style={{ borderColor: "#00807F", opacity: "inherit" }} />}
                                    </div>
                                </div>
                                <hr />
                            </div>
                            {this.state.isAllAppsSelected ? <AllAppsComponent appSearchName={this.state.appSearchName} /> : <MyAppComponent appSearchName={this.state.appSearchName} />}
                        </div>
                    </div>
                </div>
            </div>)
    };
}


const mapStateToProps = (state) => ({
    isLoading: state.counter.isLoading,
});

export default connect(mapStateToProps, {})(withRouter(AppComponent));
