import React from 'react';

const DeleteIcon = ({style}) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.0226 18.5346C17.0043 18.9706 16.6306 19.3282 16.1934 19.3282H7.81411C7.37684 19.3282 7.00293 18.9699 6.98488 18.5346L6.50467 7.01029C6.49864 6.86581 6.37662 6.75366 6.23215 6.75969C6.08767 6.76572 5.97534 6.88773 5.98136 7.03221L6.46158 18.5565C6.49154 19.2723 7.0961 19.8521 7.81414 19.8521H16.1934C16.9111 19.8521 17.516 19.2734 17.546 18.5565L18.0262 7.03221C18.0322 6.88773 17.9199 6.76572 17.7754 6.75969C17.6309 6.75366 17.5089 6.86581 17.5029 7.01029L17.0226 18.5346ZM11.7416 17.4953C11.7416 17.6399 11.8589 17.7572 12.0035 17.7572C12.1482 17.7572 12.2655 17.6399 12.2655 17.4953V8.5923C12.2655 8.44763 12.1482 8.33037 12.0035 8.33037C11.8589 8.33037 11.7416 8.44763 11.7416 8.5923V17.4953ZM9.12328 17.5029C9.12748 17.6476 9.24821 17.7612 9.39269 17.757C9.53735 17.7528 9.65096 17.6321 9.64676 17.4876L9.38484 8.58463C9.38063 8.44015 9.26008 8.32636 9.11542 8.33074C8.97095 8.33494 8.85715 8.45549 8.86135 8.60015L9.12328 17.5029ZM14.3603 17.4876C14.3561 17.6321 14.4697 17.7528 14.6144 17.757C14.7589 17.7612 14.8796 17.6476 14.8838 17.5029L15.1457 8.59997C15.1499 8.4553 15.0361 8.33475 14.8917 8.33055C14.747 8.32617 14.6264 8.43996 14.6222 8.58444L14.3603 17.4876ZM14.7994 5.44988H18.5498C18.6945 5.44988 18.8118 5.56715 18.8118 5.71181C18.8118 5.85647 18.6945 5.97374 18.5498 5.97374H5.45724C5.31258 5.97374 5.19531 5.85647 5.19531 5.71181C5.19531 5.56715 5.31258 5.44988 5.45724 5.44988H9.20766L9.42209 4.91398C9.59616 4.4789 10.0957 4.14062 10.5633 4.14062H13.4438C13.9128 4.14062 14.4104 4.47762 14.585 4.91398L14.7994 5.44988ZM14.2354 5.44988L14.0988 5.1085C14.0036 4.87087 13.6986 4.66447 13.4438 4.66447H10.5633C10.3098 4.66447 10.0029 4.87215 9.9083 5.1085L9.77168 5.44988H14.2354Z" fill="#800001"/>
        </svg>



    )
}

export default DeleteIcon;