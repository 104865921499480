import React from "react";
import { connect } from "react-redux";
import { withRouter } from "../../router";
import { ToastContainer } from "react-toastify";
import { bindActionCreators } from "@reduxjs/toolkit";
import * as commanActionCreator from "../../reducers/commanReduces";
import AdminSidebar from "../admin/subComponents/adminSidebar";
import AdminNavbar from "./subComponents/adminNavbar";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import SortIcon from "./adminIcons/SortIcon";
import correct from "./assets/img/correct.png";
import wrong from "./assets/img/wrong.png";
import DeleteIcon from "../../appIcons/deleteIcon";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import download from "./assets/img/download.png";
import customers from "./assets/img/customers.png";
import license from "./assets/img/license.png";
import money from "./assets/img/money.png";
import { getSubscription } from "./services/subscription.service";
import { downloaSubscription } from "./services/subscription.service";
import debounce from "lodash.debounce";
const moment = require("moment-timezone");
class Subscription extends React.Component {
  constructor(props) {
    super(props);
    let dateCurrent = new Date();
    let month = dateCurrent.getMonth() + 1;
    let day = dateCurrent.getDate();
    dateCurrent =
      dateCurrent.getFullYear() +
      "-" +
      (month < 10 ? "0" + month : month) +
      "-" +
      (day < 10 ? "0" + day : day);

    const last30Date = moment(dateCurrent)
      .subtract(30, "days")
      .format("YYYY-MM-DD");

    this.state = {
      screenData: [],
      isTableView: true,
      isGridView: true,
      subscriptionList: [],
      page: 1,
      limit: 10,
      totalCount: 0,
      totalCustomer: 0,
      daysValue: "Last 30 Days",
      dateCurrent: dateCurrent,
      startDate: last30Date,
      endDate: dateCurrent,
      totalLicense: null,
      totalAmount: null,
      searchText: "",
      disableDates: false,
      daysfilter: [
        {
          key: 1,
          value: "Today",
        },
        {
          key: 2,
          value: "Yesterday",
        },
        {
          key: 3,
          value: "Last 7 days",
        },
        {
          key: 4,
          value: "Last 30 days",
        },
        {
          key: 5,
          value: "Custom",
        },
      ],
    };
  }
  async componentDidMount() {
    this.getSubscription();
  }

  downloadSubscription = async (searchText) => {
    this.props.counterSlice.startLoading();
    let data = await downloaSubscription(
      this.state.startDate,
      this.state.endDate
    );
    console.log(data)
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${Date.now()}.xlsx`);
    document.body.appendChild(link);
    link.click();
    this.props.counterSlice.stopLoading();
  }

  getSubscription = async (searchText) => {
    if (searchText) {
      this.setState({ searchText: searchText });
    }
    this.props.counterSlice.startLoading();
    let data = await getSubscription(
      this.state.page,
      this.state.limit,
      searchText,
      this.state.startDate,
      this.state.endDate
    );
    this.setState({
      subscriptionList: data.body,
      customersData: data.body,
      isLoading: true,
      totalCustomer: data.totalCount,
      totalLicense: data.totalLicense,
      totalAmount: data.totalAmount,
      totalCount: Math.ceil(data.totalCount / this.state.limit),
    });
    this.props.counterSlice.stopLoading();
  };

  getSubscriptionOnFilter = async (searchText, startDate, endDate) => {
    if (searchText) {
      this.setState({ searchText: searchText });
    }
    if (startDate) {
      this.setState({ startDate: startDate });
    }
    if (endDate) {
      this.setState({ endDate: endDate });
    }
    this.props.counterSlice.startLoading();
    let data = await getSubscription(
      this.state.page,
      this.state.limit,
      searchText,
      startDate,
      endDate
    );
    this.setState({
      subscriptionList: data.body,
      customersData: data.body,
      isLoading: true,
      totalCount: data.totalCount,
      totalLicense: data.totalLicense,
      totalAmount: data.totalAmount,
      totalCount: Math.ceil(data.totalCount / this.state.limit),
    });
    this.props.counterSlice.stopLoading();
  };
  convertToTimeFormat = (str) => {
    let count = str.split(":").length - 1;
    if (count == 0) {
      return "00:00:" + str;
    } else if (count == 1) {
      return "00:" + str;
    }
    return str;
  };

  onLimitChange = async (e) => {
    this.props.counterSlice.startLoading();
    this.setState({ limit: e.target.value, page: 1 });
    let data = await getSubscription(
      1,
      e.target.value,
      this.state.searchText,
      this.state.startDate,
      this.state.endDate
    );
    // this.setState({ customersDataList: data.body, customersData: data.body , isLoading : true ,totalCount: Math.ceil(data.totalCount / this.state.limit)});
    this.setState({
      subscriptionList: data.body,
      customersData: data.body,
      isLoading: true,
      totalCustomer: data.totalCount,
      totalLicense: data.totalLicense,
      totalAmount: data.totalAmount,
      totalCount: Math.ceil(data.totalCount / this.state.limit),
    });
    this.props.counterSlice.stopLoading();
  };

  onPageChange = async (page) => {
    if (page > 0 && page <= this.state.totalCount) {
      this.props.counterSlice.startLoading();
      this.setState({ page });
      let data = await getSubscription(
        page,
        this.state.limit,
        this.state.searchText,
        this.state.startDate,
        this.state.endDate
      );
      // this.setState({ customersDataList: data.body, customersData: data.body , isLoading : true ,totalCount: Math.ceil(data.totalCount / this.state.limit)});
      this.setState({
        subscriptionList: data.body,
        customersData: data.body,
        isLoading: true,
        totalCustomer: data.totalCount,
        totalLicense: data.totalLicense,
        totalAmount: data.totalAmount,
        totalCount: Math.ceil(data.totalCount / this.state.limit),
      });
      this.props.counterSlice.stopLoading();
    }
  };

  searchName = debounce((e) => {
    this.setState({ searchText: e.target.value })
    if (e.target.value.trim() != "")
      this.getSubscription(e.target.value);
  }, 500);

  handlechangedrop = (e) => {
    switch (e.value) {
      case "Today":
        // code block
        this.getSubscriptionOnFilter(
          this.state.searchText,
          this.state.dateCurrent,
          this.state.dateCurrent
        );
        this.state.disableDates = false;
        break;
      case "Yesterday":
        const date = moment(this.state.dateCurrent)
          .subtract(1, "days")
          .format("YYYY-MM-DD");
        this.getSubscriptionOnFilter(
          this.state.searchText,
          date,
          this.state.dateCurrent
        );
        this.state.disableDates = false;
        break;
      case "Last 7 days":
        const last7Date = moment(this.state.dateCurrent)
          .subtract(7, "days")
          .format("YYYY-MM-DD");
        console.log(last7Date);
        this.getSubscriptionOnFilter(
          this.state.searchText,
          last7Date,
          this.state.dateCurrent
        );
        this.state.disableDates = false;
        break;
      case "Last 30 days":
        const last30Date = moment(this.state.dateCurrent)
          .subtract(30, "days")
          .format("YYYY-MM-DD");
        this.getSubscriptionOnFilter(
          this.state.searchText,
          last30Date,
          this.state.dateCurrent
        );
        this.state.disableDates = false;
        break;
      case "Custom":
        this.state.disableDates = true;
        break;

      // code block
    }

    this.setState({
      daysValue: e.value,
    });
  };

  render() {
    return (
      <div className="flex">
        <AdminSidebar type={"admin/subscription"} />
        <div className="h-screen grow md:overflow-hidden overflow-auto md:hover:overflow-auto  shadow">
          <AdminNavbar
            router={this.props.router}
            counterSlice={this.props.counterSlice}
          />
          <div className="flex px-11 py-4 shadow-md text-3xl ">
            <h1 className="self-center mr-5 text-black text-xl">
              Subscription
            </h1>
          </div>
          <br />
          <div>
            <div className="px-11">
              <div className="flex flex-row items-center">
                <form
                  style={{ width: "400px", borderRight: "1px solid #C4C4C4" }}
                  className="pr-4 "
                >
                  <label
                    for="search"
                    class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
                  >
                    Search
                  </label>
                  <div class="relative">
                    <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      <svg
                        class="w-4 h-4 text-gray-500 dark:text-gray-400"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 20 20"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          strokeWidth="2"
                          d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                        />
                      </svg>
                    </div>
                    <input
                      type="search"
                      id="search"
                      class="block w-full py-2 pl-10 text-sm text-gray-900 border-bottom border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Search Name"
                      onChange={(e) => this.searchName(e)}
                    />
                  </div>
                </form>

                <div className="form-group ml-4">
                  <DropdownButton
                    variant=""
                    title={this.state.daysValue}
                    style={{ width: "130px" }}
                    id="input-group-dropdown-subscription"
                    className="w-full text-xs"
                  >
                    {this.state.daysfilter?.map((m, i) => (
                      <Dropdown.Item
                        key={i}
                        style={{ textDecoration: "none" }}
                        className="w-full text-xs"
                        onClick={(e) => this.handlechangedrop(m)}
                      >
                        <span className="text-xs">{m.value}</span>
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                </div>

                <div className="ml-4 subScriptionDates">
                  <span
                    className="text-xs"
                    style={{ width: "80px", color: "#C4C4C4" }}
                  >
                    Start Date :
                  </span>
                  <input
                    type="date"
                    disabled={!this.state.disableDates}
                    value={this.state.startDate}
                    style={{ width: "100px" }}
                    className="self-center text-black text-xs"
                    // min={this.state.dateCurrent}
                    onChange={(e) => {
                      if (e.target.value === "") return;
                      this.setState({ startDate: e.target.value });
                      this.getSubscription();
                    }}
                  />
                </div>

                <div
                  style={{ borderRight: "1px solid #C4C4C4" }}
                  className="pr-4"
                >
                  <div className="ml-4 subScriptionDates">
                    <span
                      className="text-xs"
                      style={{ width: "80px", color: "#C4C4C4" }}
                    >
                      End Date :
                    </span>
                    <input
                      type="date"
                      disabled={!this.state.disableDates}
                      value={this.state.endDate}
                      style={{ width: "100px" }}
                      className="self-center text-black text-xs"
                      // min={this.state.dateCurrent}
                      onChange={(e) => {
                        if (e.target.value === "") return;
                        this.setState({ endDate: e.target.value });
                        this.getSubscription();
                      }}
                    />
                  </div>
                </div>

                <div>
                  <img
                    src={download}
                    style={{ width: "20px" }}
                    className="ml-4"
                    onClick={(e) => {
                      this.downloadSubscription();
                    }}
                  />
                </div>
              </div>

              <div className="flex items-center flex-row subscriptionDetails mt-3 subscriptionDetails justify-between">
                <div className="flex items-center flex-row">
                  <img
                    src={customers}
                    style={{ width: "40px" }}
                    className="ml-4"
                  />
                  <span className="px-3 text-sm" style={{ color: "#C4C4C4" }}>
                    Number of Customers :
                  </span>
                  <span className="text-sm" style={{ color: "#C4C4C4" }}>
                    {" "}
                    {this.state.totalCustomer}
                  </span>
                </div>
                <div className="flex items-center flex-row ">
                  <img
                    src={license}
                    style={{ width: "40px" }}
                    className="ml-4"
                  />
                  <span className="px-3 text-sm" style={{ color: "#C4C4C4" }}>
                    Number of License :
                  </span>
                  <span className="text-sm" style={{ color: "#C4C4C4" }}>
                    {" "}
                    {this.state.totalLicense}
                  </span>
                </div>
                <div className="flex items-center flex-row">
                  <img src={money} style={{ width: "40px" }} className="ml-4" />
                  <span className="px-3 text-sm" style={{ color: "#C4C4C4" }}>
                    Amount :
                  </span>
                  <span className="text-sm" style={{ color: "#C4C4C4" }}>
                    {" "}
                    {parseFloat(this.state.totalAmount).toFixed(2)}
                  </span>
                </div>
              </div>
            </div>
            {
              <div className="px-11">
                <div
                  className="shadow mt-3 rounded-lg"
                  style={{
                    overflowX: "scroll",
                    width: "calc(100vw - 328px)",
                    height: "calc(100vh - 322px)",
                  }}
                >
                  <div
                    className="flex rounded-t-lg bg-white p-3  text-center text-sm font-medium border-b-1"
                    style={{ width: "calc(100vw)" }}
                  >
                    <div
                      className="flex items-center justify-center flex-1"
                      style={{}}
                    >
                      Invoice number
                      <div className="px-2 cursor-pointer">
                        <SortIcon></SortIcon>
                      </div>
                    </div>
                    <div
                      className="flex items-center justify-center flex-1"
                      style={{}}
                    >
                      Customers
                      <div className="px-2 cursor-pointer">
                        <SortIcon></SortIcon>
                      </div>
                    </div>
                    <div
                      className="flex items-center justify-center flex-1"
                      style={{}}
                    >
                      Owner Name
                      <div className="px-2 cursor-pointer">
                        <SortIcon></SortIcon>
                      </div>{" "}
                    </div>
                    <div
                      className="flex items-center justify-center flex-1"
                      style={{}}
                    >
                      Owner Number
                      <div className="px-2 cursor-pointer">
                        <SortIcon></SortIcon>
                      </div>{" "}
                    </div>
                    <div
                      className="flex items-center justify-center flex-1"
                      style={{}}
                    >
                      Plan
                      <div className="px-2 cursor-pointer">
                        <SortIcon></SortIcon>
                      </div>{" "}
                    </div>
                    <div
                      className="flex items-center justify-center flex-1"
                      style={{}}
                    >
                      No. of License
                      <div className="px-2 cursor-pointer">
                        <SortIcon></SortIcon>
                      </div>{" "}
                    </div>
                    <div
                      className="flex items-center justify-center flex-1"
                      style={{}}
                    >
                      Amount
                      <div className="px-2 cursor-pointer">
                        <SortIcon></SortIcon>
                      </div>{" "}
                    </div>
                    <div
                      className="flex items-center justify-center flex-1"
                      style={{}}
                    >
                      Start date
                      <div className="px-2 cursor-pointer">
                        <SortIcon></SortIcon>
                      </div>{" "}
                    </div>
                    <div
                      className="flex items-center justify-center flex-1"
                      style={{}}
                    >
                      End date
                      <div className="px-2 cursor-pointer">
                        <SortIcon></SortIcon>
                      </div>{" "}
                    </div>
                    <div
                      className="flex items-center justify-center flex-1"
                      style={{}}
                    >
                      Status
                      <div className="px-2 cursor-pointer">
                        <SortIcon></SortIcon>
                      </div>{" "}
                    </div>
                    {/* <div className="flex items-center justify-center flex-1" style={{ }} ><div className="px-2 cursor-pointer"></div> </div> */}
                  </div>
                  {this.state.subscriptionList?.map((m, i) => (
                    <div
                      key={i}
                      className="flex bg-white p-3 text-gray-450 text-xs text-center text-black border-b-1 cursor-pointer"
                      style={{ width: "calc(100vw)" }}
                    >
                      <div className="flex-1" style={{}}>
                        <span className="self-center">{m.licenseNumber}</span>
                      </div>
                      <div className="flex-1" style={{}}>
                        <span className="self-center">{m.orgId?.name}</span>
                      </div>
                      <div className="flex-1" style={{}}>
                        <span className="self-center">
                          {m.orgId?.orgOwner.firstName}
                        </span>
                      </div>
                      <div className="flex-1" style={{}}>
                        <span className="self-center">
                          {m.orgId?.orgOwner.phoneNumber}
                        </span>
                      </div>
                      <div className="flex-1" style={{}}>
                        <span className="self-center">{m.planId?.name}</span>
                      </div>
                      <div className="flex-1" style={{}}>
                        <span className="self-center">{m.totalScreen}</span>
                      </div>

                      <div className="flex-1" style={{}}>
                        <span className="self-center">{parseFloat(m.totalAmount).toFixed(2)}</span>
                      </div>

                      <div className="flex-1" style={{}}>
                        <span className="self-center">
                          {moment(m.createdOn).format("YYYY-MM-DD")}
                        </span>
                      </div>

                      <div className="flex-1" style={{}}>
                        <span className="self-center">
                          {moment(m.createdOn)
                            .add(m.totalDuration, "days")
                            .format("YYYY-MM-DD")}
                        </span>
                      </div>

                      <div className="flex-1" style={{}}>
                        <span className="self-center flex justify-center">
                          {!m.isActive && (
                            <span style={{ color: "#CC0505" }}>Expired</span>
                          )}
                          {m.isActive && (
                            <span style={{ color: "#038D00" }}>Active</span>
                          )}
                        </span>
                      </div>

                      {/* <div className="flex-1 flex justify-center" >
                                <DeleteIcon />
                                </div> */}
                    </div>
                  ))}
                </div>
                <div className="flex justify-end gap-4 rounded-b-lg bg-white p-3 text-gray-450 text-center text-sm font-medium border-b-1">
                  <span className="self-center">Row per page:</span>
                  <select
                    className="bg-white border-b-1 self-center border-b-black"
                    onChange={(e) => this.onLimitChange(e)}
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                    <option value="40">40</option>
                    <option value="50">50</option>
                  </select>
                  <span>
                    {this.state.page} of {this.state.totalCount}
                  </span>
                  <IoIosArrowBack
                    className="self-center"
                    onClick={() => this.onPageChange(this.state.page - 1)}
                  />
                  <IoIosArrowForward
                    className="self-center"
                    onClick={() => this.onPageChange(this.state.page + 1)}
                  />
                </div>

                <ToastContainer />
              </div>
            }
            {/* {this.state.isGridView && <div className="px-11">
              <CustomersGridViewComponent />
            </div>} */}
          </div>
        </div>
        <ToastContainer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.counter.isLoading,
  currentColor: state.counter.currentColor,
});

const mapDispatchToProps = (dispatch) => ({
  counterSlice: bindActionCreators(commanActionCreator, dispatch),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Subscription));
