import React from "react";
import { connect } from "react-redux";

import { withRouter } from "../../../router";
import * as commanActionCreator from "../../../reducers/commanReduces";
import { bindActionCreators } from "@reduxjs/toolkit";
import { ToastContainer } from "react-toastify";
const moment = require('moment-timezone');
class CustomersGridViewComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  async componentDidMount() {
    
  }


  render() {
    const userRole = JSON.parse(localStorage.getItem("roles"));
    return (
      <div>
        {
          <div className=" mt-8 ">
            <div class="grid grid-cols-3 gap-4 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 mb-4">
              {this.props.customerDataArray?.map((m, i) => (
                // <div>{i}</div>
                <div key={i} className="rounded-lg py-2 px-2 border cursor-pointer" onClick={async () => this.props.router.navigate("/admin/viewCustomer/" + m._id)}>
                  <div className="flex flex-row">
                    <span className="text-base">{m.name}</span>
                    <span className="flex justify-end flex-1">
                      <span
                        style={{ backgroundColor: "#E6F2F2", color: "#00807F" }}
                        className="text-xs rounded-lg px-1.5 flex items-center"
                      >
                        {m.planId.name}
                      </span>
                    </span>
                  </div>
                  <div className="flex mt-4">
                    <div
                      className="flex flex-col justify-center items-center text-center"
                      style={{ width: "30%" }}
                    >
                      <span className="text-sm">{m.totalLicense}</span>
                      <span className="text-xs text-gray-400">
                        Total license
                      </span>
                    </div>
                    <div
                      className="flex flex-col justify-center items-center border-x-2 text-center"
                      style={{ width: "30%" }}
                    >
                      <span className="text-sm">{m.totalScreen}</span>
                      <span className="text-xs text-gray-400">
                        Total Screens
                      </span>
                    </div>
                    <div
                      className="flex flex-col justify-center items-center  text-center"
                      style={{ width: "40%" }}
                    >
                      {/* <span className="text-sm">22nd July 2023 </span> */}
                      <span className="text-sm">{moment(m.licenseExpiredOn).format("Do MMM YYYY")}</span>
                      <span className="text-xs text-gray-400">Expiry</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>          
          </div>
        }

        <ToastContainer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.counter.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  counterSlice: bindActionCreators(commanActionCreator, dispatch),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CustomersGridViewComponent));
