import React from "react";
import { connect } from "react-redux";
import { withRouter } from "../../router";
import { bindActionCreators } from "@reduxjs/toolkit";
import * as commanActionCreator from '../../reducers/commanReduces'
import { fetchAppData, fetchWeatherInfo } from "../../services/appServices";
import { format, parseISO } from "date-fns";

class WeatherAppPublicComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            city1: "",
            city2: "",
            city3: "",
            city1Info: "",
            city2Info: "",
            city3Info: "",
            selectedTemp: "C",
            lat1: "",
            long1: "",
            lat2: "",
            long2: "",
            lat3: "",
            long3: "",
            appInfo: "",
        }
    }
    async componentDidMount() {
        if (this.props.router.params.appId) {
            this.props.counterSlice.startLoading();
            let appInfo = await fetchAppData(this.props.router.params.appId);
            if (appInfo.success) {
                this.populateInfo(appInfo.body);
            }
            this.props.counterSlice.stopLoading();
        }
    }
    populateInfo = (data) => {
        this.setState({
            appInfo: data,
            appName: data.name,
            selectedTemp: data.tempUnit,
            isUpdate: true,
            isCity: data.withName
        });
        if (data.withName) {
            this.setState({
                city1: data.weatherInfo.city1,
                city2: data.weatherInfo.city2,
                city3: data.weatherInfo.city3
            });
            this.searchCity("city1Info", data.weatherInfo.city1);
            this.searchCity("city2Info", data.weatherInfo.city2);
            this.searchCity("city3Info", data.weatherInfo.city3);

        } else {
            this.setState({
                lat1: data.weatherInfo.lat1,
                long1: data.weatherInfo.lat1,
                lat2: data.weatherInfo.lat2,
                long2: data.weatherInfo.lat2,
                lat3: data.weatherInfo.lat3,
                long3: data.weatherInfo.lat3,
            })
            if (data.weatherInfo.lat1)
                this.searchCity("city1Info", data.weatherInfo.lat1 + "," + data.weatherInfo.lat1);
            if (data.weatherInfo.lat2)
                this.searchCity("city2Info", data.weatherInfo.lat2 + "," + data.weatherInfo.lat2);
            if (data.weatherInfo.lat3)
                this.searchCity("city3Info", data.weatherInfo.lat3 + "," + data.weatherInfo.lat3);
        }
    }
    searchCity = async (type, cityName) => {
        if (cityName && cityName != "") {
            this.props.counterSlice.startLoading();
            let cityWeather = await fetchWeatherInfo(cityName);
            this.props.counterSlice.stopLoading();

            if (cityWeather.error) {
                return;
            }
            this.setState({ [type]: cityWeather });
        }
    }
    render() {

        let LandscapeCity = ({ cityInfo, gradient }) => (
            <div className={"text-white flex-1 items-center flex flex-col justify-center justify-evenly	" + gradient}>
                <span className="font-bold lg:text-4xl sm:text-lg md:text-2xl">{cityInfo.location.name}, {cityInfo.location.country}</span>
                <img src={cityInfo.current.condition.icon} className="sm:w-12 sm:h-12 md:w-20 md:h-20 lg:h-24 lg:w-24 item-center" alt="Loading..." />
                <div className="text-center xxs:gap-0 xs:gap-2 sm:gap-3">
                    <div className="flex-1 justify-center flex flex-wrap "><span className="font-bold lg:text-4xl sm:text-lg md:text-2xl">{this.state.selectedTemp === "C" ? cityInfo.current.temp_c : cityInfo.current.temp_f}</span>
                        {this.state.selectedTemp === "C" ? <span className="text-sm">&#8451;</span> : <span className="text-sm">&#8457;</span>}
                    </div>
                    <div className="text-xs md:text-base lg:text-lg">
                        <span>
                            {format(parseISO(cityInfo.current.last_updated), "EEEE d, MMM, yyyy, h:mm a")}
                        </span>
                        <br />
                        <span>
                            {cityInfo.current.condition.text}
                        </span>
                    </div>
                </div>
                <div className="flex font-light	text-light-gold text-xs md:text-sm lg:text-base text-center sm:gap-0.5 md:gap-3 lg:gap-4 xxs:hidden xs:flex">
                    <div>Percipation <br /> {cityInfo.current.precip_mm} mm</div>
                    <div>Humidity <br />{cityInfo.current.humidity} %</div>
                    <div>Wind <br /> {cityInfo.current.wind_kph} km/h
                    </div>
                </div>
                <div className="flex text-xs font-normal sm:gap-2 md:gap-3 lg:gap-4 text-gray-20 xxs:hidden xs:flex">
                    {cityInfo.forecast.forecastday?.map((f, i) => {
                        if (i >= 1)
                            return (<div className="flex-1 flex-col flex items-center text-xs md:text-sm lg:text-base gap-2" key={i}>
                                <img src={f.day.condition.icon} className="w-8 h-8 md:w-10 md:h-10 lg:h-12 lg:w-12" alt="Loading..." />
                                <div><span className="flex ">{format(parseISO(f.date), "E")}</span></div>
                                {<span className="flex item-start">{this.state.selectedTemp === "C" ? f.day.mintemp_c : f.day.mintemp_f}<span className="-mt-2" style={{ fontSize: "8px" }}>o</span>&nbsp;{this.state.selectedTemp === "C" ? f.day.maxtemp_c : f.day.maxtemp_f}<span className="-mt-2" style={{ fontSize: "8px" }}>o</span></span>}
                            </div>)
                    })
                    }
                </div>
            </div>
        );
        return (
            <div className="flex justify-center h-screen w-screen">
                {(this.state.city1Info !== "" && this.state.city2Info === "" && this.state.city3Info === "") ?
                    (<div className="text-white flex flex-1 items-center  bg-gradient-to-b justify-center from-blueG-100 via-blueG-200 to-blueG-300 px-4 py-8" >
                        <div className="basis-1/3 flex flex-col gap-4 items-center justify-start">
                            <span className="font-bold lg:text-4xl sm:text-lg md:text-4xl xl:text-6xl">{this.state.city1Info.location.name}, {this.state.city1Info.location.country}</span>
                            <img className="sm:w-12 sm:h-12 md:w-20 md:h-20 lg:h-24 lg:w-24 xl:h-30 xl:w-24 item-center" src={this.state.city1Info.current.condition.icon} alt="Loading..." />
                        </div>
                        <div className="flex flex-col gap-4" >
                            <div className="flex-1 flex gap-4">
                                <div className="flex-1 flex"><span className="font-bold text-7xl">{this.state.selectedTemp === "C" ? this.state.city1Info.current.temp_c : this.state.city1Info.current.temp_f}</span>
                                    {this.state.selectedTemp === "C" ? <span className="text-lg">&#8451;</span> : <span className="text-lg">&#8457;</span>}
                                </div>
                                <div className="">
                                    <span>
                                        {format(parseISO(this.state.city1Info.current.last_updated), "EEEE d, MMM, yyyy, h:mm a")}
                                    </span>
                                    <br />
                                    <span>
                                        {this.state.city1Info.current.condition.text}
                                    </span>
                                </div>
                            </div>
                            <div className="flex font-light	text-light-gold text-base text-center">
                                <div className="flex-1">Percipation <br /> {this.state.city1Info.current.precip_mm} mm</div>
                                <div className="flex-1">Humidity <br />{this.state.city1Info.current.humidity} %</div>
                                <div className="flex-1">Wind <br /> {this.state.city1Info.current.wind_kph} km/h
                                </div>
                            </div>
                            <div className="flex text-base font-normal gap-3 text-gray-20">
                                {this.state.city1Info.forecast.forecastday?.map((f, i) =>
                                (<div className="flex-1 text-center" key={i}>
                                    <img src={f.day.condition.icon} alt="Loading..." />
                                    <span className="flex justify-center">{format(parseISO(f.date), "E")}</span>
                                    {this.state.selectedTemp === "C" ? <span className="flex item-start">{f.day.mintemp_c}<span className="text-xs">&#8451;</span>&nbsp;&nbsp;{f.day.maxtemp_c}<span className="text-xs">&#8451;</span></span> :
                                        <span className="flex item-start">{f.day.mintemp_f}<span className="text-xs">&#8457;</span>&nbsp;{f.day.maxtemp_f}<span className="text-xs">&#8457;</span></span>}
                                </div>))
                                }
                            </div>
                        </div>
                    </div>) :
                    <div className="flex-1 xs:flex ">
                        {(this.state.city1Info !== "" && (this.state.city2Info !== "" || this.state.city3Info !== "")) &&
                            <LandscapeCity cityInfo={this.state.city1Info} gradient={"bg-gradient-to-b from-blueG-100 via-blueG-200 to-blueG-300 "} />
                        }
                        {(this.state.city2Info !== "") &&
                            <LandscapeCity cityInfo={this.state.city2Info} gradient={"bg-gradient-to-b from-lightblueG-100 to-lightblueG-200"} />
                        }
                        {(this.state.city3Info !== "") &&
                            <LandscapeCity cityInfo={this.state.city3Info} gradient={"bg-gradient-to-b from-ultrablueG-100 to-ultrablueG-200"} />
                        }
                    </div>
                }

            </div>)
    };
}

const mapStateToProps = (state) => ({
    isLoading: state.counter.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
    counterSlice: bindActionCreators(commanActionCreator, dispatch)
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WeatherAppPublicComponent));
