import React from "react";
import { Button, InputGroup, Form, Modal, OverlayTrigger, Popover } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "../router";
import { FaStar, FaLink, FaTrashAlt, FaEllipsisH, FaPencilAlt, FaTimes } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import { bindActionCreators } from "@reduxjs/toolkit";
import * as commanActionCreator from '../reducers/commanReduces'
import { updateMedia } from "../services/mediaService";
import { attachedDataList } from "../services/mediaService";
import { fetchAllLinksData, addLinksDataService, deleteLink } from "../services/linkservice";
import SearchWithButton from "./utilComponents/searchWithButton";
import Sidebar from "./subComponents/Sidebar";
import Navbar from "./subComponents/Navbar";
import Link from "../appIcons/Link";
import { format } from 'date-fns'
import debounce from 'lodash.debounce';
// import IframeViewer from "./utilComponents/IframeViewer";

class LinksComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      addLinkModel: false,
      isImageNotFound: true,
      selectedImage: "",
      isDisabled: true,
      isValidURL: false,
      linkList: [],
      linkListTemp: [],
      linkName: "",
      attachedList: {},
      showLinkModel: false,
      deleteLinkModel: false,
      selectedLink: "",
      isNameEdit: false,
      selectedMedia: "",
    }
  }
  async componentDidMount() {
    this.props.counterSlice.startLoading();

    let dasboardResponse = await fetchAllLinksData();
    this.setState({ linkList: dasboardResponse.body, linkListTemp: dasboardResponse.body });
    this.props.counterSlice.stopLoading();

  }
  closeAll = () => {
    this.setState({
      addLinkModel: false, isImageNotFound: true,
      selectedImage: "",
      isDisabled: true,
      showLinkModel: false,
      deleteLinkModel: false,
      link: "",
      selectedLink: "",
      linkName: "",
      isNameEdit: false
    });
  }
  addLinksData = async () => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));

    let linkDTO = {
      name: this.state.linkName.trim(),
      url: this.state.selectedImage,
      orgId: userInfo.orgId,
      type: "LINK"
    };
    this.props.counterSlice.startLoading();
    let response = await addLinksDataService(linkDTO);
    this.props.counterSlice.stopLoading();

    if (response.success) {

      let dasboardResponse = await fetchAllLinksData();
      this.setState({ linkList: dasboardResponse.body, linkListTemp: dasboardResponse.body });
      toast.success('Link added successfully.', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        theme: "colored",
        progress: undefined,
      });
    } else {
      toast.error('Error while saving Link.', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        theme: "colored",
        progress: undefined,
      });
    }
    this.closeAll();
  }
  updateIsStart = (i) => {
    this.state.linkList[i].isStar = !this.state.linkList[i].isStar
    this.setState({ linkList: this.state.linkList })
  }
  handleImageError = () => {
    this.setState({ isDisabled: true, isImageNotFound: true });
  }
  updateName = async (e) => {
    this.setState({ linkName: e.target.value });
  }

  updateMedia = async (m) => {
    let mediaDTO = {
      _id: m._id,
      name: m.name,
      isMarked: m.isMarked
    }
    let updated = await updateMedia(mediaDTO);
    if (updated.success) {
      let dasboardResponse = await fetchAllLinksData();
      this.setState({ linkList: dasboardResponse.body, linkName: mediaDTO.name, linkListTemp: dasboardResponse.body });
    }
  }
  handleError = (e) => {
    console.log('Error loading iframe content', e);
    // handle error logic here
  };
  attachedData = async (l) => {
    let attachedData = await attachedDataList(l._id);
    this.setState({ deleteLinkModel: true, selectedLink: l, attachedList: attachedData.body })
  }
  deleteMediaList = async () => {
    this.setState({ deleteLinkModel: false });

    this.props.counterSlice.startLoading();
    let response = await deleteLink(this.state.selectedLink._id);
    if (response.success) {
      let dasboardResponse = await fetchAllLinksData();
      this.setState({ linkList: dasboardResponse.body, linkListTemp: dasboardResponse.body });
      toast.success('link deleted successfully.', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        theme: "colored",
        progress: undefined,
      });
    } else {
      toast.error('Error while deleting Link.', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        theme: "colored",
        progress: undefined,
      });
    }
    this.props.counterSlice.stopLoading();

    this.closeAll();
  }
  searchLink = debounce(async (sheetUrl) => {
    if (sheetUrl.trim() != "") {
      const urlPattern = /^((https|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
      this.setState({ link: sheetUrl });
      if (urlPattern.test(sheetUrl)) {
        this.validateWebsite(sheetUrl)
      }
    }
  }, 500);


  validateWebsite = async (url) => {
    const iframe = document.createElement('iframe');
    console.log(url);
    iframe.onload = async () => {
      console.log("valid");

      this.setState({ isValidURL: true });
      iframe.remove();
    };
    iframe.onerror = () => {
      console.log("Not valid");

      this.setState({ isValidURL: false });
      iframe.remove();
    };
    iframe.src = url;
    iframe.sandbox = 'allow-same-origin';
    iframe.style.display = 'none';
    document.body.appendChild(iframe);
  };
  searchName = (e) => {
    if (e.target.value != "") {
      this.setState({ linkList: this.state.linkListTemp.filter((s) => s.name.toUpperCase().includes(e.target.value.toUpperCase())) })
    } else
      this.setState({ linkList: this.state.linkListTemp })
  }
  render() {
    const userRole = JSON.parse(localStorage.getItem("roles"));

    let FileDetails = ({ lable, value }) => (
      <tr className="w-full ">
        <tr><span className="text-sm font-normal text-gray-600 px-2">{lable}</span></tr>
        <tr><span className="text-sm font-normal text-gray-600 px-2">{value}</span></tr>
      </tr>
    )
    return (
      <div className="flex">
        <Sidebar type={"links"} />
        <div className="h-screen grow md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10 shadow">
          <Navbar router={this.props.router} counterSlice={this.props.counterSlice} />
          <ToastContainer />
          <SearchWithButton
            icon={<Link style="self-center mr-3" />}
            title="Links"
            placeholder="Search links"
            onChangeSearch={(e) => this.searchName(e)}
            buttonName="ADD LINK"
            isRead={userRole.links["add"].read}
            onButtonClick={() => userRole.links["add"].write && this.setState({ addLinkModel: true })} />

          {this.state.linkList.length > 0 ? <div className="m-5">
            <div className="flex rounded-t-lg bg-white p-3 text-gray-450 text-center text-sm font-medium border-b-1">
              <div className="flex-1">Link Name</div>
              <div className="flex-1">Created by</div>
              <div className="flex-1">Date & Time</div>
              <div className="flex-1"></div>
              <div className="flex-1">URL</div>
              <div className="basis-10"></div>
            </div>
            <hr />
            {this.state.linkList?.map((l, i) => {
              return (
                <div key={i} className="flex bg-white p-2 text-gray-450 text-xs text-center text-black border-b-1">
                  <div className="flex-1" onClick={() => this.setState({ selectedMedia: l, showLinkModel: true, linkName: l.name })}>
                    <div className="w-36 text-center inline-block truncate"><span className="truncate">{l.name}</span></div>
                  </div>
                  <div className="flex-1" onClick={() => this.setState({ selectedMedia: l, showLinkModel: true, linkName: l.name })}>
                    {l.createdBy && <span className="self-center">{l.createdBy.firstName} {l.createdBy.lastName}</span>}
                  </div>
                  <div className="flex-1" onClick={() => this.setState({ selectedMedia: l, showLinkModel: true, linkName: l.name })}>
                    <span className="self-center">{format(new Date(l.createdOn), "dd/MM/yyyy kk:mm")}</span>
                  </div>
                  <div className="flex-1" onClick={() => { if (userRole.links["starLink"].write) { l.isMarked = !l.isMarked; this.updateMedia(l) } }}>
                    {userRole.links["starLink"].read && <FaStar className={l.isMarked ? "text-cyan-500" : "text-gray-500"} size={15} />}</div>
                  <div className="flex-1 self-center" style={{
                    whiteSpace: "nowrap",
                    width: "50px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}><a className="text-sm text-gray-400 hover:text-black" href={l.url} target="_blank">{l.url}</a></div>
                  {userRole.links["deleteLink"].read && <button className="flex basis-1 p-2 text-red-600  hover:bg-gray-300" onClick={() => userRole.links["deleteLink"].write && this.attachedData(l)}><FaTrashAlt className="self-center mx-1" /></button>}
                </div>);
            })}
          </div> :
            <div className="flex justify-center mt-16">
              <img src={process.env.PUBLIC_URL + "/blacklink.svg"} alt="Link" />
            </div>}
        </div>

        <Modal
          show={this.state.addLinkModel}
          size={"lg"}>
          <Modal.Body className="m-0 p-0" >
            {/* Modal hedder */}
            <div className="flex m-3">
              <span className="flex-1 text-black font-bold">Add Link</span>
              <div onClick={this.closeAll}>
                <FaTimes size={25} className="self-center text-gray-400 hover:text-gray-600" />
              </div>
            </div>
            <hr />
            <div className="flex" style={{ height: "60vh" }}>
              <div className="flex-1 flex justify-center items-center	 ">
                <iframe className="border mx-2 border-4 rounded w-full h-64 bg-black" key={""} scrolling="no"
                  src={this.state.link}
                  onLoad={() => console.log("loaded .........")}
                  onError={() => console.log("Error .........")} />
              </div>
              <div className="basis-80 p-3 border-l-2">
                <InputGroup className="mb-3">
                  <Form.Control
                    placeholder="https://example.com"
                    aria-describedby="basic-addon2"
                    value={this.state.selectedImage}
                    onChange={(e) => {
                      this.setState({ link: "", selectedImage: e.target.value, isImageNotFound: false });
                      const urlPattern =
                        /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g;
                      if (urlPattern.test(e.target.value))
                        this.setState({ isValidURL: true });
                      else
                        this.setState({ isValidURL: false });
                    }
                    }
                    onBlur={(e) => this.searchLink(e.target.value)}
                  />
                </InputGroup>
                {(this.state.selectedImage.trim() != "" && !this.state.isValidURL) && (
                  <p className="text-red-500 text-xs">Invalid URL or website could not be loaded.</p>
                )}
                {this.state.selectedImage !== "" && <Form.Control
                  placeholder="Enter link name"
                  className="mt-10"
                  maxLength={40}
                  value={this.state.linkName}
                  onChange={(e) => this.setState({ linkName: e.target.value, isDisabled: (e.target.value.trim() == "" || this.state.isImageNotFound) ? true : false })}
                />}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="justify-center">
            <Button variant="" className="hover:bg-gray-300" onClick={this.closeAll}>Cancel</Button>
            <Button variant="outline-secondary" id="addbtn" disabled={this.state.isDisabled || !this.state.isValidURL} onClick={this.addLinksData}>Add Link</Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.showLinkModel} onHide={this.closeAll} size="lg"
        >
          <Modal.Header closeButton={false}>
            <Modal.Title id="contained-modal-title-vcenter">
              <div className="flex">
                {this.state.isNameEdit ?
                  <input className="focus:outline-none grow text-black text-2xl p-1 font-bold border-b-black"
                    style={{ borderBottom: "1px solid" }} placeholder="Link name"
                    maxLength={40}
                    onKeyDown={(e) => {
                      if (e.target.value.trim() !== "" && (e.key === "Enter" || e.key === "Tab" || e.key === undefined)) {
                        this.setState({ isNameEdit: false });
                        this.state.selectedMedia.name = e.target.value;
                        this.updateMedia(this.state.selectedMedia);
                      } else this.setState({ isNameEdit: true });

                    }}
                    value={this.state.linkName} onChange={(e) => this.updateName(e)} />
                  : <div className="flex grow">
                    <span className="mr-3 text-ls">{this.state.selectedMedia && this.state.selectedMedia.name}</span>
                    {userRole.links["editName"].read && <FaPencilAlt className="self-center text-gray-400 hover:text-gray-600" size={20} onClick={() => { userRole.links["editName"].write && this.setState({ isNameEdit: true }) }} />
                    }                  </div>}
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="flex">
              <div className="flex-1 p-3  border-r-2">
                <div>
                  <iframe height={300} width={500} src={this.state.selectedMedia.url} alt="loadding..." />
                </div>
              </div>
              <div className="basis-1/2 flex-col text-center bg-white">
                <span className="font-semibold ">DETAILS</span>
                <hr />
                <div className="border-solid mt-3 flex flex-col">
                  <div><span className="text-xs font-normal text-gray-600">Uploaded By</span></div>
                  <div className="text-sm font-normal">{this.state.selectedMedia && (this.state.selectedMedia.createdBy.firstName + " " + this.state.selectedMedia.createdBy.lastName)}</div>
                  <div><span className="text-xs font-normal text-gray-600">Uploaded Date</span></div>
                  <div className="text-sm font-normal">{new Date(this.state.selectedMedia.createdOn).toLocaleString()} </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.deleteLinkModel} onHide={this.closeAll}>
          <Modal.Header closeButton closeVariant={"#ffff"}>
            <Modal.Title>Delete Link</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>Are you sure, you would like to delete this Link permanently?</div>
            <br />
            {this.state.attachedList && this.state.attachedList.screenList && this.state.attachedList.screenList.length > 0 && <div>
              <span className="text-gray font-bold text-sm">Attached to screen</span>
              {this.state.attachedList.screenList?.map((s) => (
                <div className="text-xs">- {s.name}</div>
              ))}
            </div>}
            {this.state.attachedList && this.state.attachedList.channelList && this.state.attachedList.channelList.length > 0 && <div>
              <span className="text-gray font-bold text-sm">Attached to channel</span>
              {this.state.attachedList.channelList?.map((c) => (
                <div className="text-xs">- {c.name}</div>
              ))}
            </div>}
            {this.state.attachedList && this.state.attachedList.playList && this.state.attachedList.playList.length > 0 && <div>
              <span className="text-gray font-bold text-sm">Attached to playList</span>
              {this.state.attachedList.playList?.map((c) => (
                <div className="text-xs">- {c.name}</div>
              ))}
            </div>}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-secondary" onClick={this.closeAll}>
              Cancel
            </Button>
            <Button variant="outline-danger" onClick={this.deleteMediaList}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>)
  };
}

const mapStateToProps = (state) => ({
  isLoading: state.counter.isLoading,
  userRole: state.counter.userRole

});

const mapDispatchToProps = (dispatch) => ({
  counterSlice: bindActionCreators(commanActionCreator, dispatch)
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LinksComponent));
