import React from "react";
import { connect } from "react-redux";
import { withRouter } from "../../router";
import { bindActionCreators } from "@reduxjs/toolkit";
import * as commanActionCreator from '../../reducers/commanReduces'
import { fetchAppData } from "../../services/appServices";
import ReactPlayer from 'react-player/lazy'

class YouTubeAppPublicComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            youtubeURL: "",
            isMute: false,
            isLoop: false,
            isControl: false
        }
    }
    async componentDidMount() {
        if (this.props.router.params.appId) {
            this.props.counterSlice.startLoading();
            let appInfo = await fetchAppData(this.props.router.params.appId);
            if (appInfo.success) {
                this.setState({
                    isMute: appInfo.body.muteVideo,
                    isLoop: appInfo.body.loopVideo,
                    isControl: appInfo.body.showControl,
                    youtubeURL: appInfo.body.link,
                })
            }
            this.props.counterSlice.stopLoading();
        }
    }

    render() {

        return (
            <div className="flex flex-col flex-1 justify-center items-center bg-gray-800 h-screen w-full gap-4">
                <ReactPlayer url={this.state.youtubeURL} width='100%' height='100%'
                    muted={this.state.isMute} controls={this.state.isControl}
                    playing={true} loop={this.state.isLoop}
                />
            </div>
        )
    };
}

const mapStateToProps = (state) => ({
    isLoading: state.counter.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
    counterSlice: bindActionCreators(commanActionCreator, dispatch)
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(YouTubeAppPublicComponent));
