import React from "react";
import { Modal, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "../../router";
import { ToastContainer, toast } from "react-toastify";
import { bindActionCreators } from "@reduxjs/toolkit";
import * as commanActionCreator from "../../reducers/commanReduces";
import AdminSidebar from "../admin/subComponents/adminSidebar";
import AdminNavbar from "./subComponents/adminNavbar";
import { MdArrowBackIosNew } from "react-icons/md";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import "./assets/css/admin.css";
import { addOrganization } from "./services/customer.service";
import { getPlan } from "./services/customer.service";
import * as ENUM from "./constants/enum";
class AddCustomer extends React.Component {
  constructor(props) {
    super(props);
    // this.hiddenFileInput = React.createRef();
    this.state = {
      screenData: [],      
      companyName: "",
      comapanyAddress: "",
      ownerFirstName: "",
      ownerLastName: "",
      ownerEmail: "",
      ownerMobileno: "",
      plan: "FREE",
      planID:null,      
      isValidNumber: true,
      userProfile: "",
      page:1,
      limit:500,
      planList:[]
    };
  }
  async componentDidMount() {    
    this.getPlan();
  }

  getPlan = async () =>{

    this.props.counterSlice.startLoading();
    let data = await getPlan(this.state.page, this.state.limit);    
    this.setState({ planList: data.body});    
    this.props.counterSlice.stopLoading();

  }

  handlechangedrop = (e) => {
    this.setState({
      plan: e.name,
      planID:e._id,      
    });
  };

  onInputChange = (type, event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onNumberInputChange = (type, e) => {
    const re = /^[0-9\b]+$/;

    // if value is not blank, then test the regex

    if (e.target.value === '' || re.test(e.target.value)) {
      this.setState({ [e.target.name]: e.target.value });
    }else{
      const value = e.target.value.replace(/\D/g, "");
      this.setState({ [e.target.name]: value });
    }

    
  }

  // handleClick = (event) => {
  //   this.hiddenFileInput.current.click();
  // };

  resetValues = () => {
    this.setState({ companyName: "",
    comapanyAddress: "",
    ownerFirstName: "",
    ownerLastName: "",
    ownerEmail: "",
    ownerMobileno: "",
    plan: "Basic",
    isValidNumber: true,});

  };

  addCustomerDetails = async () => {


    if (
      this.state.ownerFirstName == "" ||
      this.state.ownerLastName == "" ||
      this.state.ownerMobileno == "" ||
      this.state.companyName == "" ||
      this.state.comapanyAddress == ""
    ) {
      toast.error("Please fill all the fields", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        theme: "colored",
        progress: undefined,
      });
      return;
    }
    let regEmail = /\S+@\S+\.\S+/;
    console.log("no valid ", regEmail.test(this.state.ownerEmail));
    if (!regEmail.test(this.state.ownerEmail)) {
      toast.error("Please add valid email", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        theme: "colored",
        progress: undefined,
      });
      return;
    }

    let phoneno = /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/;
    if (this.state.ownerMobileno.match(phoneno)) {
      this.setState({ isValidNumber: true });
    } else {
      this.setState({ isValidNumber: false });
      return;
    }



    let userDTO = {
      firstName: this.state.ownerFirstName,
      lastName: this.state.ownerLastName,
      email: this.state.ownerEmail,
      phoneNumber: this.state.ownerMobileno,
      userType: ENUM.USERTYPES.ADMIN,
      organizationInfo: {
        name: this.state.companyName,
        address: this.state.comapanyAddress,
        subscriptionPlanType: this.state.plan,
      },
    };

    console.log(userDTO);
    
    this.props.counterSlice.startLoading();
    let response = await addOrganization(userDTO);
    this.props.counterSlice.stopLoading();

    if (response.success) {
      // let orgData = await fetchOrganization();
      toast.success("Account created successfully.", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        theme: "colored",
        progress: undefined,
      });
      this.resetValues();
      this.props.router.navigate("/admin/customers")
    } else {
      console.log(response);

      let msg = "Error while creating Account.";
      if (response.message == "Email already exist.") {
        msg = "Email already exist.";
      }

      toast.error(msg, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        theme: "colored",
        progress: undefined,
      });
    }
  };
  // handleChange = (event) => {
  //   let files = event.target.files;
  //   console.log(files);
    // for (const file of files) {
    // this.state.selectedFileList.push({
    //   imageUrl: URL.createObjectURL(file),
    //   fileName: file.name,
    //   size: this.returnFileSize(file.size),
    //   fileData: file,
    //   type: file.type,
    //   isUpload: false
    // });
    // }
  //   this.setState({ userProfile: URL.createObjectURL(files[0]) });
  // };

  render() {
    return (
      <div className="flex">
        <AdminSidebar type={"admin/customers"} />
        <div className="h-screen grow md:overflow-hidden overflow-auto md:hover:overflow-auto  shadow">
          <AdminNavbar
            router={this.props.router}
            counterSlice={this.props.counterSlice}
          />
          <div className="flex  shadow-md text-3xl ">
            <div className="px-3 border-r-1 flex items-center cursor-pointer">
              <MdArrowBackIosNew
                opacity={0.5}
                className=" top-1 m-1 "
                style={{ width: "15px" }}
                onClick={async () =>
                  this.props.router.navigate("/admin/customers")
                }
              />
            </div>
            <h1 className="self-center mr-5 text-black text-xl pr-11 py-4 pl-4">
              Add Customer
            </h1>
            <div className="flex justify-end flex-1  items-center pr-5">
              <button
                type="button"
                className={`text-base  ml-3 px-3 py-2 btn-prime text-white  rounded-lg`}
                onClick={async () => {
                  this.addCustomerDetails();
                }}
              >
                Save
              </button>
            </div>
          </div>
          <br />
          <div className="flex">
            <div className="w-2/4 pl-14">
              <form action="#!" id="customer-form">
                <div className="form-group">
                  <div>
                    <span className="text-base">Account Information</span>                    
                  </div>
                  <div className="mb-3 mt-4">
                    <label
                      class="block text-gray-400 text-sm  mb-2"
                      for="grid-first-name"
                    >
                      Company name
                    </label>
                    <input
                      type="text"
                      value={this.state.companyName}
                      name="companyName"
                      className="form-control placeholder-gray-400 text-sm py-2.5"
                      placeholder="Enter company name"
                      onChange={(e) => this.onInputChange("companyName", e)}
                    />
                  </div>
                  <div className="form-group mb-3">
                    <label
                      class="block text-gray-400 text-sm  mb-2"
                      for="grid-first-name"
                    >
                      Company address
                    </label>
                    <input
                      type="text"
                      value={this.state.comapanyAddress}
                      name="comapanyAddress"
                      className="form-control placeholder-gray-400 text-sm py-2.5"
                      placeholder="Enter company address"
                      onChange={(e) => this.onInputChange("comapanyAddress", e)}
                    />
                  </div>
                  <div>
                    <span className="text-base">Owner Information</span>
                  </div>
                  <div className="mb-3 mt-4">
                    <label
                      class="block text-gray-400 text-sm  mb-2"
                      for="grid-first-name"
                    >
                      First Name
                    </label>
                    <input
                      type="text"
                      value={this.state.ownerFirstName}
                      name="ownerFirstName"
                      className="form-control placeholder-gray-400 text-sm py-2.5"
                      placeholder="Enter  name"
                      onChange={(e) => this.onInputChange("ownerFirstName", e)}
                    />
                  </div>
                  <div className="mb-3 mt-4">
                    <label
                      class="block text-gray-400 text-sm  mb-2"
                      for="grid-first-name"
                    >
                      Last Name
                    </label>
                    <input
                      type="text"
                      value={this.state.ownerLastName}
                      name="ownerLastName"
                      className="form-control placeholder-gray-400 text-sm py-2.5"
                      placeholder="Enter  name"
                      onChange={(e) => this.onInputChange("ownerLastName", e)}
                    />
                  </div>

                  <div className="form-group mb-3">
                    <label
                      class="block text-gray-400 text-sm  mb-2"
                      for="grid-first-name"
                    >
                      E-mail id
                    </label>
                    <input
                      type="email"
                      value={this.state.ownerEmail}
                      name="ownerEmail"
                      className="form-control placeholder-gray-400 text-sm py-2.5"
                      placeholder="Enter email id"
                      onChange={(e) => this.onInputChange("ownerEmail", e)}
                    />
                  </div>
                  <div className="form-group mb-3">
                    <label
                      class="block text-gray-400 text-sm  mb-2"
                      for="grid-first-name"
                    >
                      Mobile number
                    </label>
                    <input
                      type="text"
                      value={this.state.ownerMobileno}
                      name="ownerMobileno"
                      className="form-control placeholder-gray-400 text-sm py-2.5"
                      pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                      placeholder="Enter mobile number"
                      onChange={(e) => this.onNumberInputChange("ownerMobileno", e)}
                    />
                    {!this.state.isValidNumber && this.state.ownerMobileno && (
                      <span className="text-red-500 text-xs">
                        Invalid Mobile Number
                      </span>
                    )}
                  </div>
                  <div>
                    <span className="text-base">Add Plan Details</span>
                  </div>
                  <div className="form-group mb-3 mt-4">
                    <label
                      class="block text-gray-400 text-sm  mb-2"
                      for="grid-first-name"
                    >
                      Add Plan
                    </label>
                    <DropdownButton
                      variant=""
                      title={this.state.plan}
                      id="input-group-dropdown-add-customer"
                      className="w-full"
                    >

                          {this.state.planList?.map((m, i) => (
                           <Dropdown.Item
                           key={i}
                           style={{ textDecoration: "none" }}
                           className="w-full"
                           onClick={(e) => this.handlechangedrop(m)}
                         >
                           {m.name}
                         </Dropdown.Item>
                          ))}                      
                    </DropdownButton>
                  </div>
                </div>
                {/* <div className="d-flex justify-content-between align-items-center mb-5">
                    <input name="signUp" className="btn login-btn" type="button" value="Sign Up" onClick={() => { this.signup() }} />
                  </div> */}
              </form>
            </div>          
          </div>
        </div>
        <ToastContainer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.counter.isLoading,
  currentColor: state.counter.currentColor,
});

const mapDispatchToProps = (dispatch) => ({
  counterSlice: bindActionCreators(commanActionCreator, dispatch),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AddCustomer));
