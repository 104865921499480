import React from "react";

const PlanIcon = (props) => {
  let { style } = props;
  return ( <svg width="24" height="17" viewBox="0 0 24 17" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M14.2629 5.90396H4.87514C4.79178 5.90396 4.71184 5.87085 4.6529 5.81191C4.59396 5.75297 4.56085 5.67303 4.56085 5.58968C4.56085 5.50632 4.59396 5.42638 4.6529 5.36744C4.71184 5.3085 4.79178 5.27539 4.87514 5.27539H14.2629C14.3462 5.27539 14.4261 5.3085 14.4851 5.36744C14.544 5.42638 14.5771 5.50632 14.5771 5.58968C14.5771 5.67303 14.544 5.75297 14.4851 5.81191C14.4261 5.87085 14.3462 5.90396 14.2629 5.90396Z" fill="white" stroke="white" strokeWidth="0.3"/>
  <path d="M17.5597 6.04228C17.4779 6.04028 17.4001 6.00646 17.3429 5.948L16.4943 5.10571C16.4428 5.04559 16.4159 4.96825 16.419 4.88915C16.422 4.81005 16.4548 4.73502 16.5108 4.67905C16.5668 4.62307 16.6418 4.59028 16.7209 4.58723C16.8 4.58417 16.8773 4.61108 16.9374 4.66257L17.5346 5.25971L18.8734 3.59714C18.9265 3.53504 19.0016 3.49602 19.0829 3.48833C19.1642 3.48065 19.2453 3.5049 19.309 3.55596C19.3727 3.60702 19.414 3.68088 19.4243 3.76189C19.4345 3.8429 19.4128 3.92471 19.3637 3.99L17.7923 5.92285C17.7644 5.95732 17.7296 5.9855 17.69 6.00554C17.6505 6.02558 17.6072 6.03703 17.5629 6.03914H17.5597V6.04228Z" fill="white" stroke="white" strokeWidth="0.3"/>
  <path d="M14.2629 9.32974H4.87514C4.79178 9.32974 4.71184 9.29663 4.6529 9.23769C4.59396 9.17875 4.56085 9.09881 4.56085 9.01546C4.56085 8.9321 4.59396 8.85216 4.6529 8.79322C4.71184 8.73428 4.79178 8.70117 4.87514 8.70117H14.2629C14.3462 8.70117 14.4261 8.73428 14.4851 8.79322C14.544 8.85216 14.5771 8.9321 14.5771 9.01546C14.5771 9.09881 14.544 9.17875 14.4851 9.23769C14.4261 9.29663 14.3462 9.32974 14.2629 9.32974Z" fill="white" stroke="white" strokeWidth="0.3"/>
  <path d="M17.5597 9.468C17.4779 9.466 17.4001 9.43217 17.3429 9.37371L16.4943 8.53142C16.4428 8.4713 16.4159 8.39396 16.419 8.31487C16.422 8.23577 16.4548 8.16073 16.5108 8.10476C16.5668 8.04879 16.6418 8.016 16.7209 8.01294C16.8 8.00989 16.8773 8.03679 16.9374 8.08828L17.5346 8.68543L18.8734 7.02285C18.8987 6.98919 18.9305 6.96096 18.9669 6.93982C19.0033 6.91869 19.0436 6.90508 19.0854 6.89981C19.1272 6.89454 19.1696 6.89772 19.2101 6.90915C19.2506 6.92058 19.2884 6.94004 19.3213 6.96636C19.3541 6.99269 19.3813 7.02534 19.4013 7.06239C19.4213 7.09944 19.4337 7.14013 19.4376 7.18204C19.4416 7.22395 19.4371 7.26624 19.4244 7.30638C19.4117 7.34652 19.3911 7.3837 19.3637 7.41571L17.7923 9.34857C17.7644 9.38304 17.7296 9.41122 17.69 9.43126C17.6505 9.4513 17.6072 9.46275 17.5629 9.46485H17.5597V9.468Z" fill="white" stroke="white" strokeWidth="0.3"/>
  <path d="M14.2629 12.7554H4.87514C4.79178 12.7554 4.71184 12.7223 4.6529 12.6634C4.59396 12.6044 4.56085 12.5245 4.56085 12.4411C4.56085 12.3578 4.59396 12.2778 4.6529 12.2189C4.71184 12.1599 4.79178 12.1268 4.87514 12.1268H14.2629C14.3462 12.1268 14.4261 12.1599 14.4851 12.2189C14.544 12.2778 14.5771 12.3578 14.5771 12.4411C14.5771 12.5245 14.544 12.6044 14.4851 12.6634C14.4261 12.7223 14.3462 12.7554 14.2629 12.7554Z" fill="white" stroke="white" strokeWidth="0.3"/>
  <path d="M17.5597 12.9001C17.477 12.8964 17.3991 12.8603 17.3429 12.7995L16.4943 11.9572C16.4428 11.8971 16.4159 11.8197 16.419 11.7406C16.422 11.6615 16.4548 11.5865 16.5108 11.5305C16.5668 11.4746 16.6418 11.4418 16.7209 11.4387C16.8 11.4357 16.8773 11.4626 16.9374 11.5141L17.5346 12.1112L18.8734 10.4486C18.8987 10.415 18.9305 10.3867 18.9669 10.3656C19.0033 10.3445 19.0436 10.3309 19.0854 10.3256C19.1272 10.3203 19.1696 10.3235 19.2101 10.3349C19.2506 10.3464 19.2884 10.3658 19.3213 10.3921C19.3541 10.4185 19.3813 10.4511 19.4013 10.4882C19.4213 10.5252 19.4337 10.5659 19.4376 10.6078C19.4416 10.6497 19.4371 10.692 19.4244 10.7322C19.4117 10.7723 19.3911 10.8095 19.3637 10.8415L17.7923 12.7743C17.7644 12.8088 17.7296 12.837 17.69 12.857C17.6505 12.8771 17.6072 12.8885 17.5629 12.8906H17.5597V12.9001Z" fill="white" stroke="white" strokeWidth="0.3"/>
  <path d="M22.0571 16.0429H1.94286C1.6928 16.0429 1.45298 15.9435 1.27616 15.7667C1.09934 15.5899 1 15.3501 1 15.1V1.90001C1 1.64995 1.09934 1.41013 1.27616 1.23331C1.45298 1.05649 1.6928 0.957153 1.94286 0.957153H22.0571C22.3072 0.957153 22.547 1.05649 22.7238 1.23331C22.9007 1.41013 23 1.64995 23 1.90001V15.1C23 15.3501 22.9007 15.5899 22.7238 15.7667C22.547 15.9435 22.3072 16.0429 22.0571 16.0429ZM1.94286 1.58572C1.8595 1.58572 1.77956 1.61884 1.72062 1.67778C1.66168 1.73672 1.62857 1.81666 1.62857 1.90001V15.1C1.62857 15.1834 1.66168 15.2633 1.72062 15.3222C1.77956 15.3812 1.8595 15.4143 1.94286 15.4143H22.0571C22.1405 15.4143 22.2204 15.3812 22.2794 15.3222C22.3383 15.2633 22.3714 15.1834 22.3714 15.1V1.90001C22.3714 1.81666 22.3383 1.73672 22.2794 1.67778C22.2204 1.61884 22.1405 1.58572 22.0571 1.58572H1.94286Z" fill="white" stroke="white" strokeWidth="0.3"/>
  </svg>
  
  
  )
};

export default PlanIcon;
