import React , { useCallback } from "react";
import { Modal, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "../../router";
import { ToastContainer } from "react-toastify";
import { bindActionCreators } from "@reduxjs/toolkit";
import * as commanActionCreator from "../../reducers/commanReduces";
import AdminSidebar from "../admin/subComponents/adminSidebar";
import AdminNavbar from "./subComponents/adminNavbar";
import CustomersTableComponent from "./subComponents/customersTableView";
import CustomersGridViewComponent from "./subComponents/customersGridView";
import GridViewIcon from "./adminIcons/GridViewIcon";

import TableViewIcon from "./adminIcons/TableViewIcon";
import { getCustomers  } from './services/customer.service';
import debounce from 'lodash.debounce';
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
class Customers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customersData: [],
      isTableView: false,
      isGridView: true,
      isLoading: false,
      page: 1,
      limit: 10,
      searchText:"",
    };
  }

  searchName = debounce((e) => {    
    console.log(e.target.value)    
    this.getCustomers(e.target.value)    
  }, 500);

  async componentDidMount() {
   
    this.getCustomers();
  }

  getCustomers = async (searchText) =>{


    if(searchText){
      this.setState({searchText : searchText});
    }

    this.props.counterSlice.startLoading();
    let data = await getCustomers(this.state.page, this.state.limit ,searchText);    
    this.setState({ customersDataList: data.body, customersData: data.body , isLoading : true,
      totalCount: Math.ceil(data.totalCount / this.state.limit)});    
    this.props.counterSlice.stopLoading();

  }
  

  onLimitChange = async (e) => {
    this.props.counterSlice.startLoading();
    this.setState({ limit: e.target.value, page: 1 });
    let data = await getCustomers(1, e.target.value,this.state.searchText);
    this.setState({ customersDataList: data.body, customersData: data.body , isLoading : true ,totalCount: Math.ceil(data.totalCount / this.state.limit)});    
    this.props.counterSlice.stopLoading();

}

onPageChange = async (page) => {

    if (page > 0 && page <= this.state.totalCount) {
        this.props.counterSlice.startLoading();
        this.setState({ page });
        let data = await getCustomers(page, this.state.limit,this.state.searchText);
        this.setState({ customersDataList: data.body, customersData: data.body , isLoading : true ,totalCount: Math.ceil(data.totalCount / this.state.limit)});       
        this.props.counterSlice.stopLoading();
    }
}

limitChangeFromChild = (page) => {
  console.log(page)          
}

  render() {
    return (
      <div className="flex">
        <AdminSidebar type={"admin/customers"} />
        <div className="h-screen grow md:overflow-hidden overflow-auto md:hover:overflow-auto  shadow ">
          <AdminNavbar
            router={this.props.router}
            counterSlice={this.props.counterSlice}
          />
          <div className="flex px-11 py-4 shadow-md text-3xl ">
            <h1 className="self-center mr-5 text-black text-xl">Customers</h1>
            <div className="flex justify-end flex-1">
              <button
                type="button"
                className={`text-base  ml-3 px-3 py-2 btn-prime text-white  rounded-lg`}
                onClick={async () => this.props.router.navigate("/admin/addCustomer")}
              >
                Add Customer
              </button>
            </div>
          </div>
          <br />
          <div>
            <div className="px-11">
              <div className="flex">
                <form style={{ width: "300px" }}>
                  <label
                    for="search"
                    class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
                  >
                    Search
                  </label>
                  <div class="relative">
                    <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      <svg
                        class="w-4 h-4 text-gray-500 dark:text-gray-400"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 20 20"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          strokeWidth="2"
                          d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                        />
                      </svg>
                    </div>
                    <input
                      type="search"
                      id="search"
                      class="block w-full py-2 pl-10 text-sm text-gray-900 border-bottom border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Search Name"
                      onChange={(e) => this.searchName(e)}
                    />
                  </div>
                </form>
                <div className="flex justify-end flex-1">
                  <div className="cursor-pointer"
                    onClick={async () => {
                      this.setState({ isGridView: true, isTableView: false });
                    }}
                  >
                    <GridViewIcon
                      isSelected={this.state.isGridView}
                    ></GridViewIcon>
                  </div>
                  <div
                  className="cursor-pointer"
                    onClick={async () => {
                      this.setState({ isGridView: false, isTableView: true });
                    }}
                  >
                  <TableViewIcon
                    isSelected={this.state.isTableView}                        
                  ></TableViewIcon>
                  </div>
                </div>
              </div>
            </div>
            <div className="px-11 flex flex-col justify-between" 
            style={{height: "calc(100vh - 191px)"}}                        
            >

            {this.state.isTableView && <div >
              <CustomersTableComponent  customerDataArray={this.state.customersData}  />
            </div>}
            {this.state.isGridView &&  this.state.isLoading && <div >
              <CustomersGridViewComponent  customerDataArray={this.state.customersData} />
            </div>}

            <div className="flex justify-end gap-4 rounded-b-lg bg-white p-3 text-gray-450 text-center text-sm font-medium border-b-1 ">
                            <span className="self-center" >Row per page:</span>
                            <select className="bg-white border-b-1 self-center border-b-black" onChange={(e) => this.onLimitChange(e)} >
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                                <option value="40">40</option>
                                <option value="50">50</option>
                            </select>
                            <span>{this.state.page} of {this.state.totalCount}</span>
                            <IoIosArrowBack className="self-center cursor-pointer" onClick={() => this.onPageChange(this.state.page - 1)} />
                            <IoIosArrowForward className="self-center cursor-pointer" onClick={() => this.onPageChange(this.state.page + 1)} />
                        </div> 
            </div>
                      

          </div>
        </div>
        <ToastContainer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.counter.isLoading,
  currentColor: state.counter.currentColor,
});

const mapDispatchToProps = (dispatch) => ({
  counterSlice: bindActionCreators(commanActionCreator, dispatch),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Customers));
