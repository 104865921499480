import React from "react";
import { connect } from "react-redux";
import { withRouter } from "../router";

class PageNotFound extends React.Component {
    render() {
        return (
            <div className="flex ">
                <div className="flex gap-3 flex-col h-screen flex-1 justify-center items-center">
                    <div>
                        <p className="text-4xl font-bold " style={{textShadow: "6px 6px 10px rgba(0, 0, 0, 0.5)"}}>404 - PAGE NOT FOUND</p>
                    </div>
                    <div className="text-lg text-center">
                        <span>The page you are looking for might have been removed</span>
                        <br/>
                        <span>had its name changed or is temporarily unavailable.</span>
                    </div>
                    <a href="/"><button className="bg-darkBlue text-white rounded-full p-3 shadow">GO TO HOMEPAGE</button></a>
                </div>
            </div>)
    };
}


const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(withRouter(PageNotFound));
