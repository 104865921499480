import React from 'react';

const FileUploader = (props) => {
  const hiddenFileInput = React.useRef(null);
  let { handleChange } = props;
  const handleClick = event => {
    hiddenFileInput.current.click();
  };

  return (
    <>
      <div onClick={handleClick} className="flex text-1xl p-4 rounded-lg hover:drop-shadow-xl hover:bg-light-gray cursor-pointer ">
        <div className="flex flex-col flex-1 text-black">
          <img className="w-50 h-50 self-center"
            src={process.env.PUBLIC_URL + "/mediaUpload.svg"} alt="Loding..." />
          <div className="self-center w-1/2 text-center text-black font-semibold text-xl"><p>Select File To Upload</p><br /></div>
          <div
            className="self-center w-1/2 text-center"><p>Drop your videos or</p><p>photos here...</p>
          </div>
          <button className="self-center border-1 btn-prime text-white rounded-lg m-2 border-black p-2 ">BROWSE</button>
        </div>
      </div>
      <input type="file"
        className='z-20'
        ref={hiddenFileInput}
        onChange={handleChange}
        accept="image/*,video/*,video/x-matroska"
        style={{ display: 'none' }}
        multiple
      />
    </>
  );
};
export default FileUploader;