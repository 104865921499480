import * as Constants from '../constant';

let singUp = async (userDTO) => {
  return fetch(Constants.API_URL + "/user", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(userDTO),
  }).then((data) => data.json())
    .catch((error) => {
      return {};
    });
}

let login = async (loginDTO) => {
  return fetch(Constants.API_URL + "/user/login", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(loginDTO),
  }).then((data) => data.json())
    .catch((error) => {
      return {};
    });
}

let userRoleAndPermission = async (type, token) => {
  return fetch(Constants.API_URL + "/user/role/permission/" + type, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: "Barear " + token
    },
  }).then((data) => data.json())
    .catch((error) => {
      return {};
    });
};

let forgotPasswordRequest = async (userDTO) => {
  return fetch(Constants.API_URL + "/user/forgot/password", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(userDTO),
  }).then((data) => data.json())
    .catch((error) => {
      return {};
    });
}
export {
  singUp,
  login,
  userRoleAndPermission,
  forgotPasswordRequest
};