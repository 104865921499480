

import React from 'react';

const Link = (props) => {
  let { style } = props;
  return (
      <svg className={style}  width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.6825 1.31743C15.938 -0.426831 13.048 -0.451416 11.279 1.31743L9.14432 3.45207C7.93482 4.66156 7.50773 6.40481 8.00578 8.0057C6.62928 7.54686 4.7746 7.78608 3.45216 9.10846L1.31752 11.2431C-0.430237 12.9906 -0.448088 15.8806 1.31752 17.6466C3.07519 19.404 5.96542 19.4018 7.72105 17.6466L9.85569 15.512C11.0652 14.3025 11.4923 12.559 10.9942 10.9579C12.3628 11.4142 14.2197 11.1829 15.5479 9.8551L17.6825 7.72046C19.4369 5.96698 19.4414 3.07631 17.6825 1.31756V1.31743ZM9.35789 15.0495L7.22326 17.1841C5.71944 18.6882 3.34162 18.7102 1.81576 17.1841C0.321607 15.6902 0.321607 13.2707 1.81576 11.7771L3.9504 9.64247C4.87428 8.7186 6.30834 8.27244 7.79252 8.71758L3.27441 13.2352C2.56297 13.8759 2.56297 15.0846 3.27441 15.7254C4.01393 16.4653 5.15418 16.4039 5.76461 15.7254L10.2827 11.2073C10.7095 12.5237 10.3894 14.0178 9.35783 15.0494L9.35789 15.0495ZM5.40905 13.5908C5.69368 13.8754 6.04928 14.0534 6.40515 14.0888L5.26662 15.2274C4.91126 15.6542 4.12809 15.6547 3.77246 15.2274C3.34564 14.8715 3.34564 14.0894 3.77246 13.7335L4.911 12.5949C4.94648 12.986 5.12442 13.3419 5.40905 13.5908ZM7.4012 13.0928C6.97214 13.5221 6.33566 13.5221 5.90704 13.0928C5.48021 12.6662 5.48021 11.99 5.90704 11.5989L11.5992 5.90671C12.0233 5.48237 12.6685 5.48187 13.0931 5.90671C13.5199 6.33353 13.5199 7.00948 13.0931 7.40087L7.4012 13.0928ZM12.595 4.8749L13.7336 3.73637C14.1994 3.27108 14.9051 3.34975 15.2275 3.73637C15.6938 4.20215 15.6139 4.90889 15.2275 5.23053L14.125 6.40476C14.0537 6.01342 13.9116 5.65756 13.6269 5.40866C13.3423 5.12402 12.9864 4.9461 12.5951 4.87488L12.595 4.8749ZM17.1844 7.22293L15.0497 9.35757C14.1055 10.302 12.7072 10.7673 11.2076 10.3182L15.7257 5.80005C16.4647 5.06154 16.4049 3.92104 15.7257 3.30985C15.0924 2.53609 13.8638 2.5763 13.2355 3.27436L8.6819 7.79222C8.29056 6.476 8.5752 4.98191 9.6425 3.9501L11.7771 1.81546C13.2834 0.308916 15.6619 0.29278 17.1841 1.81546C18.6785 3.30962 18.6785 5.72883 17.1844 7.22293Z" fill="black" />
      </svg>

  )
}

export default Link;

