import React from 'react';

const MyComputerIcon = (props) => {
    let { style } = props;
    return (
        <svg width="32" className={style} height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.4066 23.9238H6.38126C5.61984 23.9238 5 23.304 5 22.5428V12.2301C5 11.4687 5.61984 10.8486 6.38126 10.8486H11.4071C12.1685 10.8486 12.7883 11.4686 12.7883 12.2301V22.5428C12.7883 23.3042 12.1682 23.9238 11.4069 23.9238H11.4066ZM6.38126 11.6349C6.05304 11.6349 5.78606 11.9017 5.78606 12.2301V22.5428C5.78606 22.8707 6.0532 23.1376 6.38126 23.1376H11.4066C11.7348 23.1376 12.0016 22.8705 12.0016 22.5428L12.002 12.2301C12.002 11.9019 11.7349 11.6349 11.4068 11.6349H6.38126Z" fill="black" />
            <path d="M8.8618 22.4384C8.48894 22.4384 8.18555 22.1347 8.18555 21.762C8.18555 21.3893 8.48894 21.0859 8.8618 21.0859C9.23466 21.0859 9.53823 21.3893 9.53823 21.762C9.53841 22.1352 9.23502 22.4384 8.8618 22.4384Z" fill="black" />
            <path d="M9.49704 13.0078H8.22312C8.00615 13.0078 7.83008 12.8319 7.83008 12.6147C7.83008 12.3974 8.00615 12.2217 8.22312 12.2217H9.49704C9.714 12.2217 9.8899 12.3974 9.8899 12.6147C9.8899 12.8319 9.714 13.0078 9.49704 13.0078Z" fill="black" />
            <path d="M20.2883 23.8399H20.2831H15.862C15.7526 23.8399 15.6485 23.7942 15.5736 23.7144C15.4994 23.634 15.4615 23.5267 15.4699 23.4176L15.7215 20.0464C15.7369 19.8414 15.9076 19.6826 16.1131 19.6826H19.8338C20.0306 19.6826 20.1972 19.8285 20.2231 20.0239L20.6646 23.3319C20.6756 23.3685 20.6812 23.4069 20.6812 23.4471C20.6815 23.664 20.5056 23.8399 20.2883 23.8399ZM16.2856 23.0537H19.8342L19.4899 20.4683H16.4788L16.2856 23.0537Z" fill="black" />
            <path d="M20.8827 23.8398H15.1606C14.9437 23.8398 14.7676 23.6639 14.7676 23.4467C14.7676 23.2294 14.9437 23.0537 15.1606 23.0537H20.8822C21.0994 23.0537 21.2753 23.2294 21.2753 23.4467C21.2753 23.6639 21.0994 23.8398 20.8828 23.8398H20.8827Z" fill="black" />
            <path d="M25.7661 8H10.3805C9.72801 8 9.19727 8.53106 9.19727 9.18322V10.8575C9.19727 11.0747 9.37334 11.2504 9.5903 11.2504C9.80727 11.2504 9.98334 11.0747 9.98334 10.8575V9.18322C9.98334 8.96447 10.1616 8.78626 10.3805 8.78626H25.7661C25.9848 8.78626 26.1634 8.96429 26.1634 9.18322V17.0923H12.3965C12.1794 17.0923 12.0035 17.268 12.0035 17.4853C12.0035 17.7024 12.1794 17.8783 12.3965 17.8783H26.1634V19.286C26.1634 19.5048 25.985 19.683 25.7661 19.683L12.3965 19.6832C12.1793 19.6832 12.0034 19.859 12.0034 20.0762C12.0034 20.2933 12.1793 20.4692 12.3965 20.4692H25.7661C26.4184 20.4692 26.9491 19.9383 26.9491 19.2858L26.9489 9.18342C26.9489 8.53108 26.4182 8.0002 25.7661 8.0002V8Z" fill="black" />
        </svg>
    )
}

export default MyComputerIcon;


