import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { React, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { FaRegCheckSquare, FaRegSquare, FaTimes } from "react-icons/fa";
import { MdSearch } from "react-icons/md";
import { DataIsEmpty } from "./inputFieldAndButtons";

const SetToScreenModel = (props) => {
    let { setToScreenModel, closeAll, searchScreenName, selectAllScreen, isSelectAll, screenList, routeTo, onScreenSelected, setToScreenService } = props;

    const [isDisable, setIsDisable] = useState(() => {
        return screenList.some(obj => obj.isSelected);
    });

    return (
        <Modal show={setToScreenModel}
            size="lg">
            <Modal.Body className="p-0 m-0 bg-white rounded-lg">
                <div className="flex m-3">
                    <h1 className="grow text-xl font-semibold">Set To Screen</h1>
                    <FaTimes onClick={closeAll} size={25} className="text-gray-400 hover:text-gray-600" />
                </div>
                <hr />
                <div style={{ height: "70vh" }} className="bg-blue-100 overflow-y-auto">
                    <div className="flex mx-8 my-4 justify-between">
                        <div className="grow px-2 relative ">
                            <MdSearch opacity={0.5} size={25} className="absolute self-center m-1" />
                            <Form.Control
                                type="search"
                                placeholder="Search Screens with name and tags"
                                className="me-2 px-5 text-sm"
                                aria-label="Search"
                                onChange={searchScreenName}
                            />
                        </div>
                        <div className="self-center text-gray-500" onClick={() => {
                            selectAllScreen();
                            setIsDisable(() => {
                                return screenList.some(obj => obj.isSelected);
                            });
                        }}>
                            <TooltipComponent content={"Select All"}>
                                {isSelectAll ? <FaRegCheckSquare size={20} /> : <FaRegSquare size={20} />}
                            </TooltipComponent>
                        </div>
                    </div>
                    {screenList.length == 0 && <DataIsEmpty url={process.env.PUBLIC_URL + "/blankScreenIcon.svg"}
                        title="Add new Screens"
                        body1="This is where you can add multiple screens. you can directly set"
                        body2="the media, playlist and channel to screens."
                        customFunction={routeTo}
                        buttonName="Go To Screen"
                    />}
                    {screenList?.map((s, i) => (
                        <div key={i} className={s.isSelected ? `flex gap-2 bg-gray-200 mx-8 my-2 p-1 rounded-lg` : `flex gap-2 mx-8 my-2 p-1 bg-white rounded-lg`} onClick={() => {
                            onScreenSelected(s, i);
                            setIsDisable(() => {
                                return screenList.some(obj => obj.isSelected);
                            })
                        }}>
                            <div style={{ backgroundColor: "#192438", width: "50px", height: "50px" }}
                                className="rounded-lg self-center cursor-pointer border-solid border-1 flex from-primary justify-center to-blue-500">{s.isHorizontal === true ?
                                    <img className="self-center" src={process.env.PUBLIC_URL + "/comp.svg"}></img> : <img className="self-center" src={process.env.PUBLIC_URL + "/vertical.svg"}></img>
                                }
                            </div>
                            <div className="flex-1 flex flex-col self-center">
                                <div className="flex-1">{s.name}</div>
                                {s.tags.length > 0 && <div className="flex flex-wrap">{s.tags.map((t) => (
                                    <div className="bg-gray-200 rounded-sm shadow-md m-1 rounded px-1 border-1 boder-black text-sm text-bolt">
                                        <span style={{ fontFamily: "Poppins" }}>{t.tag}&nbsp;</span>
                                    </div>))}</div>}
                            </div>
                            <div className="self-center text-gray-500">{s.isSelected ? <FaRegCheckSquare /> : <FaRegSquare />}</div>
                        </div>
                    ))}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="" id="btn-cancel" onClick={closeAll}>CANCEL</Button>
                <Button variant="outline-primary" className="mr-2" id="addbtn" disabled={!isDisable}
                    onClick={setToScreenService}>ADD TO SCREEN</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default SetToScreenModel;