import React from "react";
import { Draggable } from "react-beautiful-dnd";
import Link from "../../appIcons/Link";
import { AppIcon } from "../utilComponents/inputFieldAndButtons";

function DragLinkList(props) {
    const { media, index } = props;

    return (
        <Draggable
            key={"id-" + media.id}
            draggableId={"id-" + media._id}
            index={index}>
            {(provider) => (
                <div className="flex py-2 px-4 bg-white shadow-md my-2 truncate rounded-lg"{...provider.dragHandleProps} {...provider.draggableProps} ref={provider.innerRef}>
                    <div className="flex  basis-1/2 gap-3" >
                        {media.type === "APP" ?
                            <div className={"flex rounded-lg cursor-pointer bg-dark-team border-solid border-1 p-1"}>
                                <AppIcon style={"self-center h-6 w-6 " + (media.appType === "MEET_TEAM" ? "fill-black" : "fill-white")} type={media.appType} />
                            </div> :
                            <div className={"rounded-lg cursor-pointer self-center border-solid border-1 p-2"}>
                                <Link className="self-center" />
                            </div>}
                        <div className="flex flex-col self-center w-10 xl:w-52 lg:w-40 md:w-32 inline-block truncate">
                            <span className="text-black truncate">{media.name}</span>
                            <span className="text-gray-450 text-xs">{media.createdOn}</span>
                        </div>
                    </div>
                </div>
            )}
        </Draggable>
    );
}

export default DragLinkList;