import React from "react";
import { connect } from "react-redux";
import { withRouter } from "../../router";
import { bindActionCreators } from "@reduxjs/toolkit";
import * as commanActionCreator from '../../reducers/commanReduces'
import { fetchAppData, getStockPrice } from "../../services/appServices";
import { DataIsEmptyWithoutButton } from "../utilComponents/inputFieldAndButtons";
import StocksPreview from "../subComponents/stocksPreview";

class StockAppPublicComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            slotStocksList: [],
            duration: 10,
            elementsPerSlot: 8,
        }
    }
    async componentDidMount() {
        if (this.props.router.params.appId) {
            this.props.counterSlice.startLoading();
            let appInfo = await fetchAppData(this.props.router.params.appId);
            if (appInfo.success) {
                this.setState({
                    duration: appInfo.body.timer,
                    stocksType: appInfo.body.stocksType,
                });
                let stocksPriceList = await getStockPrice(appInfo.body.stocksType);
                for (let i = 0; i < stocksPriceList.length; i += this.state.elementsPerSlot) {
                    const slot = stocksPriceList.slice(i, i + this.state.elementsPerSlot);
                    this.state.slotStocksList.push(slot);
                }
                this.setState({ slotStocksList: this.state.slotStocksList });
            }
            this.props.counterSlice.stopLoading();
        }
    }
   
    render() {

        return (
            <div className="flex flex-col flex-1 justify-center items-center bg-gray-800 h-screen w-full gap-4">
                {this.state.slotStocksList.length > 0 ? <StocksPreview stocksPriceList={this.state.slotStocksList} duration={this.state.duration}
                    isLandscape={true} /> :
                    <DataIsEmptyWithoutButton url={process.env.PUBLIC_URL + "/blankScreenIcon.svg"}
                        title="App instance preview"
                        body1="Edit and save the configuration to preview this app instance" />}
            </div>
        )
    };
}

const mapStateToProps = (state) => ({
    isLoading: state.counter.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
    counterSlice: bindActionCreators(commanActionCreator, dispatch)
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StockAppPublicComponent));
