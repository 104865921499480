

import React from 'react';

const Light = (props) => {
  let {style} = props;
  return (
    <><svg className={style}  width="18" height="18" viewBox="0 0 18 18" fill="currentColor" xmlns="http://www.w3.org/2000/svg"  >
<path d="M8.11503 17.3162C8.07985 17.3162 8.04434 17.3117 8.00882 17.3029C7.81706 17.2544 7.68283 17.0818 7.68283 16.8839V10.2644H5.15663C5.00439 10.2644 4.86333 10.1841 4.78546 10.0533C4.70759 9.92227 4.70426 9.76019 4.77662 9.62646L9.49777 0.901298C9.59198 0.727217 9.79257 0.639842 9.98384 0.687865C10.1758 0.736386 10.3102 0.908969 10.3102 1.10689V7.72632H12.8358C12.9881 7.72632 13.1293 7.80636 13.2074 7.93709C13.2852 8.06781 13.2886 8.23023 13.216 8.36395L8.49488 17.0891C8.41817 17.2315 8.27094 17.3161 8.11503 17.3161V17.3162ZM5.88184 9.39997H8.11474C8.35352 9.39997 8.54694 9.59356 8.54694 9.83235V15.1771L12.1106 8.59102H9.8777C9.63892 8.59102 9.44532 8.39742 9.44532 8.15881V2.81401L5.88184 9.39997Z" />
</svg>&nbsp;</>

  )
}

export default Light;
