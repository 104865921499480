import React from "react";
import { Draggable } from "react-beautiful-dnd";
import { FaFilm } from 'react-icons/fa';
import Media from "../../appIcons/Media";

function DragMediaList(props) {
    const { media, index, selectedMediaFile } = props;

    return (
        <Draggable
            key={"id-" + media.id}
            draggableId={"id-" + media._id}
            index={index}>
            {(provider) => (
                <div className="flex py-2 px-4 bg-white shadow-md my-2 truncate rounded-lg"{...provider.dragHandleProps} {...provider.draggableProps} ref={provider.innerRef}>
                    <div className="flex  basis-1/2 gap-3" >
                        {media.type === "FOLDER" ? <div className=" self-center cursor-pointer" onClick={() => selectedMediaFile(media)}>
                            <Media />
                        </div> : media.contentType.includes("video") ? <div className="rounded-2xl flex p-2 border-dashed border-1 w-10 h-10">
                            <FaFilm className="self-center" size={20} opacity={0.8} /></div> : <img className="rounded-2xl border-solid border-1 w-10 h-10" src={media.url} alt="loadding..." />
                        }
                        <div className="flex flex-col self-center w-3/4" onClick={() => media.type === "FOLDER" && selectedMediaFile(media)}>
                            <button className="text-black text-start  truncate">{media.name}</button>
                            <span className="text-gray-450 italic text-xs">{media.type === "FOLDER" ? "Folder" : media.contentType && media.contentType.includes("video") ? "Video" : "Image"}</span>
                        </div>
                    </div>
                </div>
            )}
        </Draggable>
    );
}

export default DragMediaList;