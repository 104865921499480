import React, { useState } from "react";
import { FaFilm, } from "react-icons/fa";
import { MdStayCurrentLandscape, MdStayCurrentPortrait } from "react-icons/md";
import PreviewScreenComponent from "../subComponents/previewScreen";
import Link from "../../appIcons/Link";
import { AppIcon } from "./inputFieldAndButtons";

const ShowPreview = (props) => {
    let { previewList } = props;
    const [isLandscape, setIsLandscape] = useState(true)

    return (
        <div className="flex bg-blue-100">
            <div className="flex flex-col flex-1 m-2 content-center">
                <div className="flex content-end">
                    <button className="p-2 flex bg-black text-white rounded-lg font-bold border-solid border-1" onClick={() => setIsLandscape(!isLandscape)}>
                        {isLandscape ? <MdStayCurrentLandscape className="self-center font-bold mr-1 " size={25} /> :
                            <MdStayCurrentPortrait className="self-center font-bold mr-1 " size={25} />}
                    </button>
                </div>
                <div className="self-center content-start">
                    <PreviewScreenComponent selectedPlayList={previewList} isHorizontal={isLandscape} />
                </div>
            </div>
            <div className="bg-white w-80 overflow-y-auto shadow" style={{ height: "85vh" }}>
                <div className="m-2">
                    {previewList?.map((mpl, i) => (
                        <div key={i} className="flex my-2 bg-gray-100 border-1 p-1 rounded-lg w-full" >
                            {mpl.type === "LINK" ? <div className={"rounded-2xl flex p-2 border-dashed border-1 "}>
                                <Link style="self-center h-6 w-6" />
                            </div> : mpl.type === "APP" ? <div className={"rounded-lg border-solid self-center border-1 flex p-1 bg-dark-team "}>
                                <AppIcon style={"self-center fill-white h-6 w-6"} type={mpl.appType} />
                            </div> : mpl.contentType.includes("video") ? <FaFilm className="self-center rounded-2xl border-solid border-1 w-10 h-10 p-2 bg-white" size={30} opacity={0.6} /> :
                                <img className="rounded-2xl bg-white border-solid border-1 w-10 h-10" src={mpl.url} alt="loadding..." />}
                            <div className="ml-2 self-center flex flex-col ">
                                <div className="w-20 xl:w-52 lg:w-40 md:w-32 inline-block truncate text-sm"><span className="truncate">{mpl.name}</span></div>
                                <span className="text-gray-450 italic text-xs">{mpl.type === "LINK" ? "Link" : mpl.type === "APP" ? "App" : mpl.contentType.includes("video") ? "Video" : "Image"}</span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default ShowPreview;