import React from "react";
import { connect } from "react-redux";

import { withRouter } from "../../router";
import * as commanActionCreator from '../../reducers/commanReduces'
import { bindActionCreators } from "@reduxjs/toolkit";
import { ToastContainer, toast } from 'react-toastify';
import { addTagsService, deleteTagService, fetchDefaultScreen, fetchTagsInfo, mergeTagService, updateTagService } from "../../services/profiledetailsService";
import { Button, Form, Modal, OverlayTrigger, Popover } from "react-bootstrap";
import { FaPencilAlt, FaPlus, FaRegPlusSquare, FaTimes, FaTrashAlt } from "react-icons/fa";
import { MdSearch } from "react-icons/md";
import { removeDefaultMediaService, updateDefaultMedia } from "../../services/mediaService";
import FileSelector from "./defaultImageUploader";
import { fi } from "date-fns/locale";

class GeneralSetting extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isTag: false,
            tagsList: [],
            isDisable: true,
            addNewTag: false,
            mergetagModel: false,
            addMediaModel: false,
            tagName: "",
            allTagList: [],
            defaultMediaList: [],
            selectedFile: "",
            selectedMedia: ""
        }
    }

    async componentDidMount() {
        this.props.counterSlice.startLoading();
        let tagsList = await fetchTagsInfo();
        let defaultScreen = await fetchDefaultScreen();

        tagsList.body.forEach(element => element.isSelected = false);
        this.setState({ tagsList: tagsList.body, allTagList: tagsList.body, defaultMediaList: defaultScreen.body });
        this.props.counterSlice.stopLoading();
    }
    closeAll = () => {
        this.setState({ tagName: "", addMediaModel: false, selectedFile: "", tag: "", mergetagModel: false, updateTagsModel: false, addTagsModel: false, userType: "", invitationEmail: "", inviteUserModel: false, deleteTagModel: false, selectedTag: "" })
    }
    addTags = async () => {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        let tagInfo = {
            tag: this.state.tagName.trim(),
            orgId: userInfo.orgId,
        }
        this.props.counterSlice.startLoading();

        let response = await addTagsService(tagInfo);
        if (response.needToAdd) {
            let tagsList = await fetchTagsInfo();
            tagsList.body.forEach(element => element.isSelected = false);
            this.setState({ tagsList: tagsList.body, allTagList: tagsList.body, tagName: "", addNewTag: false });
        } else {
            toast.error('Same tag is present', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        }
        this.props.counterSlice.stopLoading();

        this.closeAll();
    }
    deleteTag = async () => {
        this.setState({ deleteTagModel: false });
        this.props.counterSlice.startLoading();

        let deletedTagResponse = await deleteTagService(this.state.selectedTag._id);
        if (deletedTagResponse.success) {
            let tagsList = await fetchTagsInfo();
            tagsList.body.forEach(element => element.isSelected = false);

            this.setState({ tagsList: tagsList.body, allTagList: tagsList.body });
            toast.success('Tag deleted successfully.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        } else {
            toast.error('Error while deleting tag.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        }
        this.props.counterSlice.stopLoading();

        this.closeAll();
    }
    updateTag = async () => {
        this.setState({ updateTagsModel: false });
        this.props.counterSlice.startLoading();
        let tagDTO = {
            tag: this.state.tag,
            _id: this.state.selectedTag._id
        }
        let tagUpdate = await updateTagService(tagDTO);
        if (tagUpdate.success) {
            let tagsList = await fetchTagsInfo();
            tagsList.body.forEach(element => element.isSelected = false);

            this.setState({ tagsList: tagsList.body, allTagList: tagsList.body });
            toast.success('Tag has been updated.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        } else {
            toast.error('Failed to update tag.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        }
        this.props.counterSlice.stopLoading();
        this.closeAll()
    }
    searchName = (e) => {
        if (e.target.value != "") {
            this.setState({ tagsList: this.state.allTagList.filter((t) => t.tag.toUpperCase().includes(e.target.value.toUpperCase())) })
        } else
            this.setState({ tagsList: this.state.allTagList })
    }
    mergeTagService = async () => {
        this.setState({ mergetagModel: false });
        let selectedTagList = [];
        this.state.tagsList.forEach(t => t.isSelected && selectedTagList.push(t._id));
        if (selectedTagList.length < 2) {
            toast.error('Please select more than one tag.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
            return;
        }
        this.props.counterSlice.startLoading();
        let tagDTO = {
            tag: this.state.tag,
            selectedTagList
        }
        let tagUpdate = await mergeTagService(tagDTO);
        if (tagUpdate.success) {
            let tagsList = await fetchTagsInfo();
            tagsList.body.forEach(element => element.isSelected = false);

            this.setState({ tagsList: tagsList.body, allTagList: tagsList.body });
            toast.success('Tag has been mergerd.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        } else {
            toast.error('Failed to merge tag.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        }
        this.props.counterSlice.stopLoading();
        this.closeAll()
    }

    removeDefaultMedia = async (dm) => {
        this.props.counterSlice.startLoading();
        let tagUpdate = await removeDefaultMediaService(dm._id);
        if (tagUpdate.success) {
            let defaultScreen = await fetchDefaultScreen();
            this.setState({ defaultMediaList: defaultScreen.body });
            toast.success('Media removed successfully.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        } else {
            toast.error('Failed remove media.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        }
        this.props.counterSlice.stopLoading();
    }
    updateDefaultMedia = async () => {
        this.setState({ addMediaModel: false });
        this.props.counterSlice.startLoading();
        let tagUpdate = await updateDefaultMedia(this.state.selectedFile[0].name, this.state.selectedFile[0], this.state.selectedMedia.isHorizontal, this.state.selectedMedia._id);
        if (tagUpdate.success) {
            let defaultScreen = await fetchDefaultScreen();
            this.setState({ defaultMediaList: defaultScreen.body });
            toast.success('Default Media set Successfully.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        } else {
            toast.error('Failed set default media.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        }
        this.props.counterSlice.stopLoading();
        this.closeAll();
    }
    onFileSelect = (file) => {
        if (file[0].type.includes("image/")) {
            this.setState({ selectedFile: file });
        } else {
            toast.error('Please select image.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        }
    }
    //Create Default Media updateDefaultMedia from Media 
    render() {
        const userRole = JSON.parse(localStorage.getItem("roles"));

        return (
            <div className="bg-gray-25">
                {this.state.isTag ?
                    <div className="" >
                        <div className="m-3 flex gap-4">
                            <div className="grow flex relative">
                                <MdSearch opacity={0.5} size={25} className="absolute self-center ml-2" />
                                <Form.Control
                                    size="lg"
                                    type="search"
                                    placeholder={"Search by tag's"}
                                    className="px-5 text-sm"
                                    aria-label="Search"
                                    onChange={this.searchName}
                                />
                            </div>
                            <div className="self-center basis-1/5 ">
                                {this.state.addNewTag ? <div className="flex justify-end">
                                    <input type="text" placeholder="Enter Tag" value={this.state.tagName} onChange={(e) => this.setState({ tagName: e.target.value, isDisable: e.target.value.trim() === "" ? true : false })}
                                        className="text-black focus:outline-none border-b-blue-500"
                                        style={{ borderBottom: "1px solid #3b82f6" }}
                                        autoFocus={true}
                                        maxLength={30}
                                        onBlur={() => this.setState({ tagName: "", addNewTag: false })}
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter" && e.target.value.trim() !== "") {
                                                this.addTags()
                                            }
                                        }} />
                                    <button className={this.state.isDisable ? "bg-primary cursor-not-allowed bg-darkBlue p-2" : "bg-darkBlue p-2"} disabled={this.state.isDisable}>
                                        <FaPlus className=" text-white self-center" /></button>
                                </div> : <div className={"flex text-darkBlue justify-end cursor-pointer hover:underline " + (userRole.setting["addTag"].read ? "visible" : "invisible")} onClick={() => userRole.setting["addTag"].write && this.setState({ addNewTag: true })}>
                                    <FaPlus className="self-center m-1" />
                                    Add Tag
                                </div>}
                            </div>
                            <div className="self-center">
                                <button className="bg-darkBlue text-white rounded p-2" onClick={() => this.setState({ isTag: false })}>WALLPAPER</button>
                            </div>
                        </div>
                        <div className="m-3 justify-around items-center">
                            {this.state.tagsList?.map((tag, i) =>
                            (<div className={tag.isSelected ? "w-full flex p-2 m-1 group bg-gray-200 text-currentColor rounded-lg drop-shadow-sm text-sm " : "w-full flex p-2 m-1 group bg-white text-currentColor rounded-lg drop-shadow-sm text-sm "}>
                                <div className="flex-1 flex">
                                    <div className="basis-20"><input type="checkbox" className="checked:bg-currentSecondaryColor" checked={tag.isSelected} onChange={() => {
                                        this.state.tagsList[i].isSelected = !this.state.tagsList[i].isSelected;
                                        this.setState({ tagsList: this.state.tagsList });
                                    }} /></div>
                                    <div className="xl:w-36 lg:w-30 w-20 md:text-start inline-block text-gray-600 hover:text-gray-800 truncate cursor-pointer "
                                        title={tag.tag}
                                    ><span className="truncate">{tag.tag}</span></div>
                                </div>
                                <div className="flex-1 text-center">
                                    <p className="text-currentColor text-darkBlue">{tag.screenCount} Screens</p>
                                </div>
                                <div className={tag.isSelected ? "flex-1 text-center" : "flex-1 invisible"}>
                                    <button className="text-currentSecondaryColor text-sm" onClick={() => this.setState({ mergetagModel: true })}>Merge Tag</button>
                                </div>
                                <div className="basis-20 gap-8 flex justify-evenly text-end">
                                    {userRole.setting["editName"].read && <button
                                        type="button"
                                        title="Edit Tag"
                                        className={`text-1xl hover:text-black opacity-50 child group-hover:block hidden`} onClick={() => userRole.setting["editName"].write && this.setState({ updateTagsModel: true, tag: tag.tag, selectedTag: tag })}>
                                        {<FaPencilAlt className="bg-white text-currentColor" size={12} />}
                                    </button>}
                                    {userRole.setting["removeTag"].read && <button
                                        type="button"
                                        title="Delete Tag"
                                        className={`text-1xl hover:text-black opacity-50 child group-hover:block hidden`} onClick={() =>userRole.setting["removeTag"].write && this.setState({ deleteTagModel: true, selectedTag: tag })}>
                                        {<FaTrashAlt className="bg-white text-currentColor" size={12} />}
                                    </button>}
                                </div>
                            </div>)
                            )}
                        </div>
                    </div> : <div>
                        <div className="flex justify-between m-3 ">
                            <div className="flex gap-4"><span className="self-center">Default wallpaper</span>
                                <OverlayTrigger
                                    trigger="hover" placement="bottom" rootClose overlay={
                                        <Popover className="rounded-none" style={{ width: "176px" }}>
                                            <Popover.Body className="p-1 m-1 bg-white rounded-none">
                                                <div className="flex flex-wrap text-xs font-medium">
                                                    This Image will be displayed on Screen when no media is assigned to it.
                                                </div>
                                            </Popover.Body>
                                        </Popover>}>
                                    <svg width="17" className="self-center" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="8.5" cy="8.5" r="7.04167" stroke="#192438" strokeWidth="1.08333" />
                                        <path d="M8.04818 5.63981V5.15951H8.39518V5.63981H8.04818ZM8.04818 11.8556V7.15408H8.39518V11.8556H8.04818Z" fill="#192438" stroke="#192438" strokeWidth="0.541667" />
                                    </svg>
                                </OverlayTrigger>
                            </div>
                            <div className="self-center">
                                <button className="bg-darkBlue text-white rounded p-2" onClick={() => this.setState({ isTag: true })}>MANAGE TAGS</button>
                            </div>
                        </div>
                        <div className="grid grid-cols-2  justify-center">
                            {this.state.defaultMediaList?.map((dm) => (
                                <div className={"bg-currentSecondaryColor bg-opacity-40 m-4 rounded"}>
                                    <div className="bg-white gap-y-2 -translate-y-2 -translate-x-2 shadow p-3 rounded flex flex-col justify-center text-center">
                                        <div className="flex justify-between text-sm">
                                            <span className="text-darkBlue">{dm.isHorizontal ? "1920 * 1080" : "1080 * 1920"}</span>
                                            <div className={userRole.setting["updateDS"].read ? "visible" : "invisible"}>
                                                {dm.status === 2 ?
                                                    <FaRegPlusSquare title="Add Default" className="cursor-pointer" onClick={() => userRole.setting["updateDS"].write && this.setState({ addMediaModel: true, selectedMedia: dm })} /> :
                                                    <FaTrashAlt title="Delete" className="cursor-pointer opacity-60" onClick={() => userRole.setting["updateDS"].write && this.removeDefaultMedia(dm)} />}
                                            </div>
                                        </div>
                                        <div className={dm.isHorizontal ? "border-black m-4 flex rounded border-2 overflow-hidden bg-white-800 h-52 " :
                                            "border-black m-4 flex rounded self-center justify-center border-2 overflow-hidden bg-white-800 w-40 h-52"}>
                                            <img alt="Default Image" className="self-center w-full h-full object-contain" src={dm.url} />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>}
                <Modal show={this.state.deleteTagModel} onHide={this.closeAll}>
                    <Modal.Header >
                        <Modal.Title>Delete tag</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Would you like to delete this <b>{this.state.selectedTag && this.state.selectedTag.tag}</b> tag permanently ?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="" id="btn-cancel" onClick={this.closeAll}>
                            Cancel
                        </Button>
                        <Button variant="outline-primary" className="mr-2" id="addbtn" onClick={this.deleteTag}>
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.updateTagsModel} onHide={this.closeAll} centered>
                    <Modal.Header >
                        <Modal.Title>Edit Tag</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Control
                            value={this.state.tag}
                            onChange={(e) => this.setState({ tag: e.target.value })}
                            placeholder="Enter your tag" />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="" id="btn-cancel" onClick={this.closeAll}>
                            Cancel
                        </Button>
                        <Button variant="outline-primary" className="mr-2" id="addbtn" disabled={this.state.tag === ""} onClick={this.updateTag}>
                            Update
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.mergetagModel} onHide={this.closeAll} centered>
                    <Modal.Body className="p-0 m-0 gap-3">
                        <div className="flex justify-between p-3">
                            <span>Merge Tags</span>
                            <FaTimes className="text-gray-500 hover:text-black" onClick={this.closeAll} />
                        </div>
                        <hr />
                        <div className="text-black text-center">
                            Insert a new name for the merged tags<br /> This Tag will be assigned to the associated screens
                        </div>
                        <div className="m-4">
                            <Form.Control
                                value={this.state.tag}
                                onChange={(e) => this.setState({ tag: e.target.value })}
                                placeholder="Enter your tag" />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" className="mr-2" id="addbtn" disabled={this.state.tag === ""} onClick={this.mergeTagService}>
                            Merge
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.addMediaModel}
                    size="lg"
                    centered
                    aria-labelledby="contained-modal-title-vcenter">
                    <Modal.Body className="p-0 m-0 ">
                        <div className="flex justify-between p-3">
                            <Modal.Title id="contained-modal-title-vcenter" className="text-stone-500" >
                                Select Default Image
                            </Modal.Title>
                            <FaTimes className="text-gray-500 self-center hover:text-black" onClick={this.closeAll} />
                        </div>
                        <hr />
                        <div className="flex">
                            {this.state.selectedFile === "" ? <div className="flex-1 flex p-4 flex-col justify-center text-center bg-blue-100">
                                <FileSelector onFileSelect={this.onFileSelect} />
                            </div> :
                                <div className="flex flex-1 justify-center	m-4">
                                    <div className="border-2 flex justify-center bg-gray-800 border-black rounded overflow-hidden" style={{ width: "70vh", height: "45vh" }}>
                                        <img className="" src={URL.createObjectURL(this.state.selectedFile[0])}></img>
                                    </div>
                                    <div> <FaTimes onClick={() => this.setState({ selectedFile: "" })} /></div>
                                </div>}
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="flex">
                        <Button variant="" id="btn-cancel" onClick={this.closeAll}>Cancel</Button>
                        <Button variant="outline-primary" disabled={this.state.selectedFile === ""} className="mr-2" id="addbtn"
                            onClick={this.updateDefaultMedia}
                        >Upload</Button>
                    </Modal.Footer>
                </Modal>

                <ToastContainer />
            </div >
        );
    }
};

const mapStateToProps = (state) => ({
    isLoading: state.counter.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
    counterSlice: bindActionCreators(commanActionCreator, dispatch)
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(GeneralSetting));
