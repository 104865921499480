import React from "react";
import { connect } from "react-redux";
import { withRouter } from "../../router";
import * as commanActionCreator from '../../reducers/commanReduces'
import { bindActionCreators } from "@reduxjs/toolkit";
import { ToastContainer } from 'react-toastify';

import { downloadMediaDetailsLogs, fetchMediaDetailsLogs } from "../../services/screenService";
import { FaSpinner } from "react-icons/fa";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import ReactPlayer from "react-player";
import ScreenMediaDetailLog from "./screenMediaDetailLog";
import { AppIcon, DataNotFound } from "../utilComponents/inputFieldAndButtons";
import DatePicker from "react-datepicker";
import { convertDate } from "../utilComponents/utilFuncation";


class ScreenMediaLogComponent extends React.Component {

    constructor(props) {
        super(props);
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        let dateCurrent = convertDate(new Date());
        this.state = {
            mediaLogs: [],
            isLogs: true,
            mediaId: "",
            isScreenLogs: false,
            isMediaLogs: false,
            isDownload: false,
            screenLog: true,
            page: 1,
            limit: 10,
            screenMediaLogList: [],
            totalCount: 0,
            totalScreen: 0,
            totalPlayCount: 0,
            duration: 0,
            startDate: dateCurrent,
            endDate: dateCurrent,
            dateCurrent: dateCurrent,
            totalPlayDuration: "00:00:00",
            timeZone: userInfo.timeZone,
            selectedScreen: ""
        }
    }

    async componentDidMount() {
        this.props.counterSlice.startLoading();
        let mediaLogsDetails = await fetchMediaDetailsLogs(this.props.media.media._id, this.state.page, this.state.limit, this.state.duration);
        this.setState({
            screenMediaLogList: mediaLogsDetails.body, totalPlayDuration: mediaLogsDetails.totalPlayDuration,
            totalPlayCount: mediaLogsDetails.totalPlayCount, totalScreen: mediaLogsDetails.totalCount, totalCount: Math.ceil(mediaLogsDetails.totalCount / this.state.limit)
        })
        this.props.counterSlice.stopLoading();
    }
    convertToTimeFormat = (str) => {
        let count = str.split(":").length - 1;
        if (count == 0) {
            return "00:00:" + str;
        } else if (count == 1) {
            return "00:" + str;
        }
        return str;
    }
    onLimitChange = async (e) => {
        this.props.counterSlice.startLoading();
        this.setState({ limit: e.target.value, page: 1 });
        let mediaLogsDetails = await fetchMediaDetailsLogs(this.props.media.media._id, 1, e.target.value, this.state.duration,
            this.state.startDate, this.state.endDate);
        this.setState({
            screenMediaLogList: mediaLogsDetails.body, totalPlayCount: mediaLogsDetails.totalPlayCount,
            totalPlayDuration: mediaLogsDetails.totalPlayDuration,
            totalScreen: mediaLogsDetails.totalCount, totalCount: Math.ceil(mediaLogsDetails.totalCount / this.state.limit)
        })
        this.props.counterSlice.stopLoading();
    }
    onPageChange = async (page) => {

        if (page > 0 && page <= this.state.totalCount) {
            this.props.counterSlice.startLoading();
            this.setState({ page });
            let mediaLogsDetails = await fetchMediaDetailsLogs(this.props.media.media._id, page, this.state.limit, this.state.duration,
                this.state.startDate, this.state.endDate);
            this.setState({
                screenMediaLogList: mediaLogsDetails.body, totalPlayCount: mediaLogsDetails.totalPlayCount,
                totalPlayDuration: mediaLogsDetails.totalPlayDuration,
                totalScreen: mediaLogsDetails.totalCount, totalCount: Math.ceil(mediaLogsDetails.totalCount / this.state.limit)
            })
            this.props.counterSlice.stopLoading();
        }
    }
    ondurationchange = async (e) => {

        this.setState({ duration: e.target.value });
        console.log(e.target.value);
        if (e.target.value != 2) {
            this.props.counterSlice.startLoading();
            let mediaLogsDetails = await fetchMediaDetailsLogs(this.props.media.media._id, 1, this.state.limit, e.target.value);
            this.setState({
                screenMediaLogList: mediaLogsDetails.body, totalPlayCount: mediaLogsDetails.totalPlayCount,
                totalPlayDuration: mediaLogsDetails.totalPlayDuration,
                page: 1,
                totalScreen: mediaLogsDetails.totalCount, totalCount: Math.ceil(mediaLogsDetails.totalCount / this.state.limit)
            })
            this.props.counterSlice.stopLoading();
        } else {
            this.setState({ endDate: this.state.dateCurrent, startDate: this.state.dateCurrent });
        }
    }
    searchScreenMediaLog = async () => {
        this.props.counterSlice.startLoading();
        let mediaLogsDetails = await fetchMediaDetailsLogs(this.props.media.media._id, 1, this.state.limit, this.state.duration,
            this.state.startDate, this.state.endDate);
        this.setState({
            screenMediaLogList: mediaLogsDetails.body, totalPlayCount: mediaLogsDetails.totalPlayCount,
            totalPlayDuration: mediaLogsDetails.totalPlayDuration, page: 1,
            totalScreen: mediaLogsDetails.totalCount, totalCount: Math.ceil(mediaLogsDetails.totalCount / this.state.limit)
        })
        this.props.counterSlice.stopLoading();
    }
    onDateChange = (date, type) => {
        this.setState({ [type]: convertDate(new Date(date)) })
    }
    downloadLogs = async () => {
        this.setState({ isDownload: true })
        await downloadMediaDetailsLogs(this.props.media.media._id, 1, this.state.limit, this.state.duration,
            this.state.startDate, this.state.endDate);
        this.setState({ isDownload: false })
    }
    logDetails = async (s) => {
        this.state.selectedScreen = s;
        this.setState({ selectedScreen: this.state.selectedScreen, screenLog: false })
    }
    render() {
        let { media, onBack } = this.props;
        const userRole = JSON.parse(localStorage.getItem("roles"));

        return (
            <div>
                {this.state.screenLog ? <div>
                    <div className="shadow mt-8 rounded-lg">
                        <div className="m-1 border-1 border-gray-450 rounded-lg">
                            <div className="flex justify-between mx-4 my-2">
                                <button title="Back" onClick={onBack}><IoIosArrowBack size={20} /></button>
                                {this.state.screenMediaLogList.length > 0 && <>{this.state.isDownload ?
                                    <div className="p-2 mx-2 self-center"><FaSpinner className="animate-spin" /></div>
                                    : <button className={"p-2 mx-2 " + (userRole.monitoring["downaloadPOP"].read ? "visible" : "invisible")} title="Dowload Logs" onClick={(e) => userRole.monitoring["downaloadPOP"].write && this.downloadLogs()}>
                                        <svg width="15" height="15" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0.984375 15.9844H17.0156V18H0.984375V15.9844ZM12.9844 9.98438L9 14.0156L5.01562 9.98438H8.01562V0H9.98438V9.98438H12.9844Z" fill="black" />
                                        </svg>
                                    </button>}</>}
                            </div>
                            <div className="flex ">
                                <div className="flex-1 flex justify-evenly">
                                    <div style={{ width: "60vh", height: "30vh" }} className="flex bg-cover bg-gray-900 overflow-hidden mx-4 my-2 w-60 rounded  justify-center bg-center object-cover border-black border-4 shadow">
                                        {media.media.type && media.media.type === "LINK" ? <div className="flex w-full h-full justify-center bg-gray-900  items-center backdrop-blur-md">
                                            <iframe key={""} scrolling="no"
                                                src={media.media.url} />
                                        </div> : media.media.type === "APP" ? <div className="text-center self-center"><AppIcon style={"fill-white w-16 h-16"} type={media.media.appType} /></div> :
                                            media.media.contentType.includes("video") ?
                                                <div className="flex bg-gray-900 justify-center items-center overflow-hidden">
                                                    <ReactPlayer
                                                        url={media.media.url}
                                                        playing={true}
                                                        loop={true}
                                                        controls
                                                    />
                                                </div> :
                                                <img className="self-center object-scale-down" style={{ width: "60vh", height: "30vh" }} src={media.media.url} alt="loadding..." />
                                        }
                                    </div>
                                </div>
                                <div className="flex-1 flex flex-col font-medium text-sm gap-3 text-gray-450">
                                    <div className="flex gap-4">
                                        <div>Media Name :</div>
                                        <div className="w-56 inline-block truncate"><span className="truncate">{media.media.name}</span></div>
                                    </div>
                                    <div className="flex gap-4">
                                        Screen Count :
                                        <span>{this.state.totalScreen}</span>
                                    </div>
                                    <div className="flex gap-10">
                                        Play Count :
                                        <span>{this.state.totalPlayCount}</span>
                                    </div>
                                    <div className="flex gap-2">
                                        Play Time(hr:min:sec) :
                                        <span>{this.convertToTimeFormat(this.state.totalPlayDuration)}</span>
                                    </div>
                                    <div className="flex gap-2">
                                        Log Duration
                                        <select className="bg-white-200 w-40 border-2 text-sm shadow rounded self-center" value={this.state.duration}
                                            onChange={(e) => this.ondurationchange(e)} >
                                            <option value={0}>Lifetime</option>
                                            <option value={1}>Today</option>
                                            <option value={2}>Custom</option>
                                        </select>
                                    </div>
                                    <div className="flex flex-wrap gap-2 mb-2">
                                        <div className="flex ">
                                            <div className={this.state.duration != 2 ? " flex px-1 text-gray-450 self-center border-1 border-solid border-gray-450 rounded-lg" : "flex p-1 text-black text-xs self-center border-1 border-solid border-black rounded-lg"}>
                                                <div className="flex-1"><span className="text-gray-450 text-xs">Start&nbsp;Date:&nbsp;</span></div>
                                                <div>
                                                    <DatePicker
                                                        className={"text-xs max-w-24 " + (this.state.duration != 2 ? "text-gray-450" : "text-black")}
                                                        onChange={(e) => { this.onDateChange(e, "startDate"); this.onDateChange(new Date(), "endDate") }}
                                                        disabled={this.state.duration != 2}
                                                        selected={this.state.startDate}
                                                        maxDate={convertDate(new Date())}
                                                        dateFormat="dd/MM/yyyy" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex ">
                                            <div className={this.state.duration != 2 ? " flex px-1 text-gray-450 self-center border-1 border-solid border-gray-450 rounded-lg" : "flex p-1 text-black text-xs self-center border-1 border-solid border-black rounded-lg"}>
                                                <div className="flex-1"><span className="text-gray-450 text-xs">End&nbsp;Date:&nbsp;</span></div>
                                                <div> <DatePicker
                                                    className={"text-xs max-w-24 " + (this.state.duration != 2 ? "text-gray-450" : "text-black")}
                                                    onChange={(e) => this.onDateChange(e, "endDate")}
                                                    disabled={this.state.duration != 2}
                                                    selected={this.state.endDate}
                                                    maxDate={convertDate(new Date())}
                                                    minDate={this.state.startDate}
                                                    dateFormat="dd/MM/yyyy"
                                                /></div>
                                            </div>
                                        </div>
                                        <button className="self-center" title="Search" onClick={() => (this.state.duration == 2) && this.searchScreenMediaLog()}>  <svg className={this.state.duration != 2 ? "fill-gray-450 w-5 h-5" : "flex fill-black h-5 w-5"}
                                            strokeWidth="0" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M909.6 854.5L649.9 594.8C690.2 542.7 712 479 712 412c0-80.2-31.3-155.4-87.9-212.1-56.6-56.7-132-87.9-212.1-87.9s-155.5 31.3-212.1 87.9C143.2 256.5 112 331.8 112 412c0 80.1 31.3 155.5 87.9 212.1C256.5 680.8 331.8 712 412 712c67 0 130.6-21.8 182.7-62l259.7 259.6a8.2 8.2 0 0 0 11.6 0l43.6-43.5a8.2 8.2 0 0 0 0-11.6zM570.4 570.4C528 612.7 471.8 636 412 636s-116-23.3-158.4-65.6C211.3 528 188 471.8 188 412s23.3-116.1 65.6-158.4C296 211.3 352.2 188 412 188s116.1 23.2 158.4 65.6S636 352.2 636 412s-23.3 116.1-65.6 158.4z"></path></svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="shadow mt-8 rounded-lg">
                        <div className="flex rounded-t-lg bg-white p-3 text-gray-450 text-center text-sm font-medium border-b-1">
                            <div className="flex-1">Screen Name</div>
                            <div className="flex-1">Play Count</div>
                            <div className="flex-1">Play Time(hr:min:sec)</div>
                        </div>
                        {this.state.screenMediaLogList.length > 0 ? <>{this.state.screenMediaLogList?.map((s, i) => (
                            <div key={i} className="flex bg-white p-2 text-gray-450 text-xs text-center text-black border-b-1">
                                <div className="flex flex-1">
                                    <div className="flex basis-28">
                                        <div
                                            className="rounded-lg bg-dark-team flex justify-center border-solid border-1 p-1 text-center">
                                            {s.screenInfo.isHorizontal === true ?
                                                <img className="w-4 h-4" src={process.env.PUBLIC_URL + "/comp.svg"}></img> : <img className="w-4 h-4" src={process.env.PUBLIC_URL + "/vertical.svg"}></img>
                                            }
                                        </div>
                                    </div>
                                    <button className="flex" onClick={() => this.logDetails(s)}><span className="font-medium self-center">{s.screenInfo.name}</span>
                                    </button>
                                </div>
                                <div className="flex-1">
                                    <span className="self-center">{s.playCount}</span>
                                </div>
                                <div className="flex-1">
                                    {this.convertToTimeFormat(s.totalDuration)}
                                </div>
                            </div>
                        ))}
                            <div className="flex justify-end gap-4 rounded-b-lg bg-white p-3 text-gray-450 text-center text-sm font-medium border-b-1">
                                <span className="self-center" >Row per page:</span>
                                <select className="bg-white border-b-1 self-center border-b-black" onChange={(e) => this.onLimitChange(e)} >
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                    <option value="30">30</option>
                                    <option value="40">40</option>
                                    <option value="50">50</option>
                                </select>
                                <span>{this.state.page} of {this.state.totalCount}</span>
                                <IoIosArrowBack className="self-center" onClick={() => this.onPageChange(this.state.page - 1)} />
                                <IoIosArrowForward className="self-center" onClick={() => this.onPageChange(this.state.page + 1)} />
                            </div></> : <DataNotFound />}
                    </div>
                </div> :
                    <ScreenMediaDetailLog screen={this.state.selectedScreen} media={media} onBack={() => this.setState({ screenLog: true })} />}

                <ToastContainer />
            </div>
        );
    }
};

const mapStateToProps = (state) => ({
    isLoading: state.counter.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
    counterSlice: bindActionCreators(commanActionCreator, dispatch)
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ScreenMediaLogComponent));
