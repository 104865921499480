import React from "react";
import { connect } from "react-redux";
import { withRouter } from "../../router";
import { ToastContainer,toast } from "react-toastify";
import { bindActionCreators } from "@reduxjs/toolkit";
import * as commanActionCreator from "../../reducers/commanReduces";
import AdminSidebar from "../admin/subComponents/adminSidebar";
import AdminNavbar from "./subComponents/adminNavbar";
import { getRole,updateRole } from "./services/rolesManagement.service";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { format } from 'date-fns'
import { FaEye, FaEyeSlash, FaRegCheckSquare, FaRegSquare } from "react-icons/fa";
import { MdArrowBackIosNew } from "react-icons/md";
import { Button, Modal } from "react-bootstrap";
class RolesManagementDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      roleInfoList: [],
      showAddRole: true,
      roleName: "",
      selectedRole:"",
      totalCount:null,
      page: 1,
      limit: 10,
      featureList: [],
      saveModel: false,
      selectedRoleId: this.props.router.params.roleId,
    };
  }
  async componentDidMount() {

    this.getRole();
  }

  getRole = async (searchText) => {
    
    this.props.counterSlice.startLoading();
    let rolesInfo = await getRole(this.state.selectedRoleId);
    this.setState({ roleInfoList: rolesInfo.data });
    this.props.counterSlice.stopLoading();

    const r = rolesInfo.data;
    let obj = r?.permissions;
    const rolesList = [];
    for (const key in obj) {
        const innerObj = obj[key];
        for (const innerKey in innerObj) {
            const innerValue = innerObj[innerKey];            
            rolesList.push({ type: key, key: innerKey, value: innerValue });
        }
    }
    this.setState({ selectedRole: r, totalCount: Math.ceil(rolesList.length / this.state.limit), featureList: rolesList.slice(0, this.state.limit), rolesList});
  };

  



  
  onReadChange = (m, i) => {
    this.state.featureList[i].value.read = !m.value.read;
    this.state.selectedRole.permissions[m.type][m.key] = this.state.featureList[i].value;
    console.log(this.state.selectedRole);
    this.setState({ featureList: this.state.featureList });
  }

  onWriteChange = (m, i) => {
    this.state.featureList[i].value.write = !m.value.write;
    if(!m.value.write && m.value.read){
        this.state.featureList[i].value.read = !m.value.read;
        this.state.selectedRole.permissions[m.type][m.key] = this.state.featureList[i].value;
    }

    this.state.selectedRole.permissions[m.type][m.key] = this.state.featureList[i].value;
    this.setState({ featureList: this.state.featureList });
    }

    onPageChange = (page) => {
        if (page > 0 && page <= this.state.totalCount) {
            const startIndex = (page - 1) * this.state.limit;
            const endIndex = startIndex + this.state.limit;
            this.setState({ featureList: this.state.rolesList.slice(startIndex, endIndex), page })
        }
    }
    onLimitChange = (e) => {
        this.setState({
            totalCount: Math.ceil(this.state.rolesList.length / e.target.value), featureList: this.state.rolesList.slice(0, e.target.value),
            limit: e.target.value, page: 1
        })
    }


    updateOrgRole = async () => {
        this.setState({ saveModel: false });
        this.props.counterSlice.startLoading();
        let roleGroup = await updateRole(this.state.selectedRole,this.state.selectedRoleId);

        if (roleGroup.success) {
            // let rolesInfo = await fetchGroupRules();
            // this.setState({ rolesInfoList: rolesInfo.body, });
            toast.success('User role updated successfully.', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        } else {
            toast.error('Error while assign license.', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        }
        this.props.counterSlice.stopLoading();
        setTimeout(() =>{
            this.props.router.navigate("/admin/rolesManagement");
        },500)
    }

  render() {
    return (
      <div className="flex">
        <AdminSidebar type={"admin/rolesManagement"} />
        <div className="h-screen grow md:overflow-hidden overflow-auto md:hover:overflow-auto  shadow">
          <AdminNavbar
            router={this.props.router}
            counterSlice={this.props.counterSlice}
          />
          <div className="flex  shadow-md text-3xl ">
            <div className="px-3 border-r-1 flex items-center cursor-pointer">
              <MdArrowBackIosNew
                opacity={0.5}
                className=" top-1 m-1 "
                style={{ width: "15px" }}
                onClick={async () =>
                  this.props.router.navigate("/admin/rolesManagement")
                }
              />
            </div>
            <h1 className="self-center mr-5 text-black text-xl pr-11 py-4 pl-4">
            Roles Management
            </h1>
            <div className="flex justify-end flex-1  items-center pr-5">
              <button
                type="button"
                className={`text-base  ml-3 px-3 py-2 btn-prime text-white  rounded-lg`}
                onClick={() => this.setState({ saveModel: true })}
              >
                Save
              </button>
            </div>           
          </div> 
          
        
          <br />
          <div>
            <div className="px-11">
            <div className="shadow rounded-lg">                       
                        <div className="flex rounded-t-lg bg-white p-3 text-gray-450 text-center text-sm font-medium border-b-1">
                            <div className="flex-1">Features</div>
                            <div className="flex flex-1">
                                <div className="flex">Visible</div>
                                <div className="flex-1">Access</div>
                            </div>
                        </div>
                        {this.state.featureList?.map((m, i) => (
                            <div key={i} className="flex bg-white p-3 text-gray-450 text-xs text-center text-black border-b-1 text-center">
                                <div className="flex-1 text-center">
                                    {m.value.title}
                                </div>
                                <div className="flex flex-1 justify-center text-center item-center">
                                    <button className="flex-1 text-currentSecondaryColor" onClick={() => this.onReadChange(m, i)}>{m.value.read ? <FaEye size={20}  /> : <FaEyeSlash size={20} />}</button>
                                    <button className="flex-1" onClick={() => this.onWriteChange(m, i)}>{m.value.write ? <FaRegCheckSquare style={{color:"#1B438F"}} size={20}  /> : <FaRegSquare size={20} style={{color:"#1B438F"}} />}</button>
                                </div>
                            </div>
                        ))}
                        <div className="flex justify-end gap-4 rounded-b-lg bg-white p-3 text-gray-450 text-center text-sm font-medium border-b-1">
                            <span className="self-center" >Row per page:</span>
                            <select className="bg-white border-b-1 self-center border-b-black" onChange={(e) => this.onLimitChange(e)} >
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                                <option value="40">40</option>
                                <option value="50">50</option>
                            </select>
                            <span>{this.state.page} of {this.state.totalCount}</span>
                            <IoIosArrowBack className="self-center" onClick={() => this.onPageChange(this.state.page - 1)} />
                            <IoIosArrowForward className="self-center" onClick={() => this.onPageChange(this.state.page + 1)} />
                        </div>
                    </div>
            </div>
          </div>
        </div>

        <Modal show={this.state.saveModel} onHide={() => this.setState({ saveModel: false })}>
                    <Modal.Body className="">
                        <div className="text-center m-3">
                            <div className="text-gray-500">Are you sure, do you want to update this <b>{this.state.selectedRole.roleId && this.state.selectedRole.roleId.name}</b> Role?</div>
                            <br />
                        </div>
                        <div className="flex justify-center m-2">
                            {<Button variant="" id="btn-cancel" onClick={() => this.setState({ saveModel: false })}>No</Button>}
                            {<Button variant="outline-primary" className="mr-2" id="addbtn" onClick={() => this.updateOrgRole()}>Yes</Button>}
                        </div>
                    </Modal.Body>
                </Modal>
        <ToastContainer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.counter.isLoading,
  currentColor: state.counter.currentColor,
});

const mapDispatchToProps = (dispatch) => ({
  counterSlice: bindActionCreators(commanActionCreator, dispatch),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RolesManagementDetails));
