import React from "react";
import { connect } from "react-redux";

import { withRouter } from "../../router";
import * as commanActionCreator from '../../reducers/commanReduces'
import { bindActionCreators } from "@reduxjs/toolkit";

class InstagramLoginComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    async componentDidMount() {
        this.props.counterSlice.startLoading();
        this.props.counterSlice.stopLoading();
    }
  
    //Create Default Media updateDefaultMedia from Media 
    render() {
        return (
            <div className="bg-gray-25">
                Login Successfully.............
            </div >
        );
    }
};

const mapStateToProps = (state) => ({
    isLoading: state.counter.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
    counterSlice: bindActionCreators(commanActionCreator, dispatch)
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(InstagramLoginComponent));
