import React from "react";
import { Modal, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "../../router";
import { ToastContainer, toast } from "react-toastify";
import { bindActionCreators } from "@reduxjs/toolkit";
import * as commanActionCreator from "../../reducers/commanReduces";
import AdminSidebar from "../admin/subComponents/adminSidebar";
import AdminNavbar from "./subComponents/adminNavbar";

import { MdArrowBackIosNew } from "react-icons/md";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import "./assets/css/admin.css";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import USD from "./assets/img/flags/USD.png";
import CAD from "./assets/img/flags/CAD.png";
import EUR from "./assets/img/flags/EUR.png";
import GBP from "./assets/img/flags/GBP.png";
import INR from "./assets/img/flags/INR.png";
import { addPlan } from "./services/plan.service";
import { getPlanDetails } from "./services/plan.service";
import { updatePlanDetails } from "./services/plan.service";

class AddPlan extends React.Component {
  constructor(props) {
    super(props);
    this.hiddenFileInput = React.createRef();
    this.state = {
      planID: this.props.router.params.planId,
      planName: "",
      maxNoOfScreens: null,
      maxNoOfChannels: null,
      code: "",
      maxNoOfApps: null,
      storagePerScreen: null,
      isActive: false,
      isFree: false,
      isVisible: false,
      monthlyPricePerScreen: "",
      annualPricePerScreen: "",
      annualCurrency: { key: "INR", img: INR },
      monthlyCurrency: {
        key: "INR",
        img: INR,
      },
      currencies: [
        {
          key: "INR",
          img: INR,
        },
        {
          key: "USD",
          img: USD,
        },
        {
          key: "EUR",
          img: EUR,
        },
        {
          key: "GBP",
          img: GBP,
        },
        {
          key: "CAD",
          img: CAD,
        },
      ],

      // userProfile: "",
    };
  }
  async componentDidMount() {

    if (this.state.planID != 'newPlan') {
      this.getPlanDetails()
    }

  }

  getPlanDetails = async () => {
    this.props.counterSlice.startLoading();
    let APIRES = await getPlanDetails(this.state.planID);
    console.log(APIRES)
    await this.setState({

      planName: APIRES.body.name,
      maxNoOfScreens: APIRES.body.maxScreen,
      maxNoOfChannels: APIRES.body.maxChannel,
      code: APIRES.body.code,
      maxNoOfApps: APIRES.body.maxApp,
      storagePerScreen: APIRES.body.maxStorage,
      isActive: APIRES.body.isActive,
      isFree: APIRES.body.isFree,
      isVisible: APIRES.body.isPublic,
      monthlyPricePerScreen: APIRES.body.perScreen?.monthly,
      annualPricePerScreen: APIRES.body.perScreen?.yearly,
      monthlyCurrency: {
        key: APIRES.body.currency,
        img: INR,
      },
      annualCurrency: {
        key: APIRES.body.currency,
        img: INR,
      },
    });
    this.props.counterSlice.stopLoading();
  }
  searchName = (e) => {
    console.log("in here", e.target.value);
    // if (e.target.value !== "") {
    //   this.setState({
    //     screenData: this.state.screensDataList.filter((s) =>
    //       s.name.toUpperCase().includes(e.target.value.toUpperCase())
    //     ),
    //   });
    // } else this.setState({ screenData: this.state.screensDataList });
  };

  handlechangedrop = (e) => {
    this.setState({
      monthlyCurrency: e,
    });
  };

  handlechangedropAnnual = (e) => {
    this.setState({
      annualCurrency: e,
    });
  };

  onInputChange = (type, event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onNumberInputChange = (type, e) => {

    const re = /^[0-9\b]+$/;
    console.log(re.test(e.target.value))
    console.log(e.target.name)
    // if value is not blank, then test the regex

    if (e.target.value === '' || re.test(e.target.value)) {
      this.setState({ [e.target.name]: e.target.value });
    } else {
      const value = e.target.value.replace(/\D/g, "");
      this.setState({ [e.target.name]: value });
    }


  }

  handleClick = (event) => {
    this.hiddenFileInput.current.click();
  };



  addPlanDetails = async () => {
    if (
      this.state.planName == " " ||
      this.state.code == "" ||
      this.state.maxNoOfScreens == "" ||
      this.state.maxNoOfApps == "" ||
      this.state.storagePerScreen == "" ||
      this.state.maxNoOfChannels == "" ||
      this.state.monthlyPricePerScreen == "" ||
      this.state.annualPricePerScreen == ""
    ) {
      toast.error("Please fill all the fields", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        theme: "colored",
        progress: undefined,
      });
      return;
    }


    let userDTO = {
      // "_id": "string",
      "name": this.state.planName,
      "code": this.state.code,
      "maxScreen": this.state.maxNoOfScreens,
      "maxApp": this.state.maxNoOfApps,
      "maxStorage": this.state.storagePerScreen,
      "maxChannel": this.state.maxNoOfChannels,
      "isActive": this.state.isActive,
      "isFree": this.state.isFree,
      "currency": this.state.annualCurrency.key,
      "perScreen": {
        "monthly": this.state.monthlyPricePerScreen,
        "yearly": this.state.annualPricePerScreen
      },
      "isPublic": this.state.isVisible,
    }

    console.log(userDTO);

    if (this.state.planID == 'newPlan') {

      this.props.counterSlice.startLoading();
      let response = await addPlan(userDTO);
      this.props.counterSlice.stopLoading();

      if (response.success) {
        // let orgData = await fetchOrganization();
        toast.success("Plan added successfully.", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          theme: "colored",
          progress: undefined,
        });
        this.props.router.navigate("/admin/plans")
      } else {
        console.log(response);
        let msg = "Error while creating plan.";
        toast.error(msg, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          theme: "colored",
          progress: undefined,
        });
      }
    } else {

      userDTO['_id'] = this.state.planID
      this.props.counterSlice.startLoading();
      let response = await updatePlanDetails(userDTO);
      this.props.counterSlice.stopLoading();

      if (response.success) {
        // let orgData = await fetchOrganization();
        toast.success("Plan updated successfully.", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          theme: "colored",
          progress: undefined,
        });
        this.props.router.navigate("/admin/plans")
      } else {
        console.log(response);
        let msg = "Error while updating plan.";
        toast.error(msg, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          theme: "colored",
          progress: undefined,
        });
      }
    }


  };

  render() {
    return (
      <div className="flex">
        <AdminSidebar type={"admin/plans"} />
        <div className="h-screen grow md:overflow-hidden overflow-auto md:hover:overflow-auto  shadow">
          <AdminNavbar
            router={this.props.router}
            counterSlice={this.props.counterSlice}
          />
          <div className="flex  shadow-md text-3xl ">
            <div className="px-3 border-r-1 flex items-center cursor-pointer">
              <MdArrowBackIosNew
                opacity={0.5}
                className=" top-1 m-1 "
                style={{ width: "15px" }}
                onClick={async () =>
                  this.props.router.navigate("/admin/plans")
                }
              />
            </div>
            <h1 className="self-center mr-5 text-black text-xl pr-11 py-4 pl-4">
              Add Plan
            </h1>
            <div className="flex justify-end flex-1  items-center pr-5">
              <button
                type="button"
                className={`text-base  ml-3 px-3 py-2 btn-prime text-white  rounded-lg`}
                onClick={async () => {
                  this.addPlanDetails();
                }}
              >
                Save
              </button>
            </div>
          </div>
          <br />
          <div className="flex w-full px-14">
            <form action="#!" className="w-full">
              <div
                className="w-full flex  px-4 py-4 flex-row md:flex-col lg:flex-col xl:flex-col 2xl:flex-col"
                style={{
                  background: "#FFFFFF",
                  boxShadow: "1px 1px 10px 0px #0000000D",
                }}
              >
                <div className="w-2/4">
                  <div className="">
                    <div>
                      <span className="text-base">Plan Information</span>
                    </div>
                    <div className="mb-3 mt-4">
                      <label
                        class="block text-gray-400 text-sm  mb-2"
                        for="grid-first-name"
                      >
                        Name
                      </label>
                      <input
                        type="text"
                        value={this.state.planName}
                        name="planName"
                        className="form-control placeholder-gray-400 text-sm py-2.5 w-11/12"
                        placeholder="Enter plan name"
                        onChange={(e) => this.onInputChange("planName", e)}
                      />
                    </div>
                    <div className="form-group mb-3">
                      <label
                        class="block text-gray-400 text-sm  mb-2"
                        for="grid-first-name"
                      >
                        Maximum Number of Screens
                      </label>
                      <input
                        type="text"
                        value={this.state.maxNoOfScreens}
                        name="maxNoOfScreens"
                        className="form-control placeholder-gray-400 text-sm py-2.5 w-11/12"
                        placeholder="Enter maxmimum no. of screens"
                        onChange={(e) =>
                          this.onNumberInputChange("maxNoOfScreens", e)
                        }
                      />
                    </div>
                    <div className="mb-3">
                      <label
                        class="block text-gray-400 text-sm  mb-2"
                        for="grid-first-name"
                      >
                        Maximum Number of Channels
                      </label>
                      <input
                        type="text"
                        value={this.state.maxNoOfChannels}
                        name="maxNoOfChannels"
                        className="form-control placeholder-gray-400 text-sm py-2.5 w-11/12"
                        placeholder="Enter maximum no. of channels"
                        onChange={(e) =>
                          this.onNumberInputChange("maxNoOfChannels", e)
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className="w-2/4">
                  <div className="form-group mb-3 mt-5">
                    <label
                      class="block text-gray-400 text-sm  mb-2"
                      for="grid-first-name"
                    >
                      Code
                    </label>
                    <input
                      type="text"
                      value={this.state.code}
                      name="code"
                      className="form-control placeholder-gray-400 text-sm py-2.5 w-11/12"
                      placeholder="Enter code"
                      onChange={(e) => this.onInputChange("code", e)}
                    />
                  </div>

                  <div className="form-group mb-3 ">
                    <label
                      class="block text-gray-400 text-sm  mb-2"
                      for="grid-first-name"
                    >
                      Maximum Number of Apps
                    </label>
                    <input
                      type="text"
                      value={this.state.maxNoOfApps}
                      name="maxNoOfApps"
                      className="form-control placeholder-gray-400 text-sm py-2.5 w-11/12"
                      placeholder="Enter maximum no. of apps"
                      onChange={(e) => this.onNumberInputChange("maxNoOfApps", e)}
                    />
                  </div>

                  <div className="form-group mb-3 relative">
                    <label
                      class="block text-gray-400 text-sm  mb-2"
                      for="grid-first-name"
                    >
                      Storage per Screen
                    </label>
                    <input
                      type="text"
                      value={this.state.storagePerScreen}
                      name="storagePerScreen"
                      className="form-control placeholder-gray-400 text-sm py-2.5 w-11/12"
                      placeholder="Enter storage per screen"
                      onChange={(e) =>
                        this.onNumberInputChange("storagePerScreen", e)
                      }
                    />
                    <span className="absolute " style={{ top: "37px", right: "11%" }}>Mb</span>
                  </div>
                </div>
              </div>

              <div
                className="w-full flex flex-row px-4 py-4 mt-4"
                style={{
                  background: "#FFFFFF",
                  boxShadow: "1px 1px 10px 0px #0000000D",
                }}
              >
                <div className="w-full flex flex-row">
                  <div className="w-1/5 ">
                    <span className="text-base">Access Permission</span>
                  </div>
                  <div className="w-4/5 flex justify-between px-5">
                    <div class="flex items-center mr-4">
                      <input
                        type="checkbox"
                        value=""
                        checked={this.state.isActive}
                        onChange={(e) => {
                          console.log(e);
                          this.setState({ isActive: !this.state.isActive });
                        }}
                        class="red-input w-6 h-6 "
                      />
                      <label class="ml-9 text-sm font-medium text-gray-900 dark:text-gray-300">
                        Active
                      </label>
                    </div>
                    <div class="flex items-center mr-4">
                      <input
                        type="checkbox"
                        value=""
                        checked={this.state.isFree}
                        onChange={(e) => {
                          this.setState({ isFree: !this.state.isFree, monthlyPricePerScreen: !this.state.isFree ? "0" : "", annualPricePerScreen: !this.state.isFree ? "0" : "" });
                        }}
                        class="red-input w-6 h-6 "
                      />
                      <label class="ml-9 text-sm font-medium text-gray-900 dark:text-gray-300">
                        Free
                      </label>
                    </div>
                    <div class="flex items-center mr-4">
                      <input
                        type="checkbox"
                        value=""
                        checked={this.state.isVisible}
                        onChange={(e) => {
                          this.setState({ isVisible: !this.state.isVisible });
                        }}
                        class="red-input w-6 h-6 "
                      />
                      <label class="ml-9 text-sm font-medium text-gray-900 dark:text-gray-300">
                        Visible during SIGNUP
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="w-full flex flex-col px-4 py-4 mt-4"
                style={{
                  background: "#FFFFFF",
                  boxShadow: "1px 1px 10px 0px #0000000D",
                }}
              >
                <div className=" flex 2-full">
                  <span className="text-base">Price per Screen</span>
                </div>
                <div className="w-full flex flex-row">
                  <div className="form-group mb-3 mt-4 w-1/2">
                    <label
                      class="block text-gray-400 text-sm  mb-2"
                      for="grid-first-name"
                    >
                      Monthly
                    </label>
                    <div className="flex">
                      <Dropdown style={{ width: "170px" }}>
                        <Dropdown.Toggle
                          id="input-group-dropdown"
                          className="p-0"
                          disabled={this.state.isFree}
                          style={{
                            borderTopRightRadius: "0",
                            borderBottomRightRadius: "0",
                          }}
                        >
                          <div className="flex items-center pl-4 py-2 ">
                            <img
                              src={this.state.monthlyCurrency.img}
                              style={{ width: "35px" }}
                            />
                            <span className="pl-2">
                              {this.state.monthlyCurrency.key}
                            </span>
                          </div>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          {this.state.currencies?.map((m, i) => (
                            <Dropdown.Item
                              style={{ textDecoration: "none", padding: "0" }}
                              className="w-full"

                              onClick={(e) => this.handlechangedrop(m)}
                            >
                              <div className="flex items-center pl-4  border-b-1 py-2">
                                <img src={m.img} style={{ width: "35px" }} />
                                <span className="pl-2">{m.key}</span>
                              </div>
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                      <div className="w-full relative">
                        <input
                          type="text"
                          value={this.state.monthlyPricePerScreen}
                          name="monthlyPricePerScreen"
                          disabled={this.state.isFree}
                          className="form-control placeholder-gray-400 text-sm w-11/12"
                          style={{
                            paddingTop: "0.75rem",
                            paddingBottom: "0.75rem",
                            borderTopLeftRadius: "0",
                            borderBottomLeftRadius: "0",
                            paddingLeft: "35px",
                          }}
                          placeholder="Enter Price"
                          onChange={(e) =>
                            this.onNumberInputChange("monthlyPricePerScreen", e)
                          }
                        />
                        <span
                          style={{
                            position: "absolute",
                            top: "12px",
                            left: "15px",
                          }}
                        >
                          &#8377;
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="form-group mb-3 mt-4 w-1/2">
                    <label
                      class="block text-gray-400 text-sm  mb-2"
                      for="grid-first-name"
                    >
                      Annual
                    </label>
                    <div className="flex">
                      <Dropdown style={{ width: "160px" }}>
                        <Dropdown.Toggle
                          id="input-group-dropdown"
                          className="p-0"
                          disabled={this.state.isFree}
                          style={{
                            borderTopRightRadius: "0",
                            borderBottomRightRadius: "0",
                          }}
                        >
                          <div className="flex items-center pl-4 py-2 ">
                            <img
                              src={this.state.annualCurrency.img}
                              style={{ width: "35px" }}
                            />
                            <span className="pl-2">
                              {this.state.annualCurrency.key}
                            </span>
                          </div>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          {this.state.currencies?.map((m, i) => (
                            <Dropdown.Item
                              style={{ textDecoration: "none", padding: "0" }}
                              className="w-full"
                              onClick={(e) => this.handlechangedropAnnual(m)}
                            >
                              <div className="flex items-center pl-4  border-b-1 py-2">
                                <img src={m.img} style={{ width: "35px" }} />
                                <span className="pl-2">{m.key}</span>
                              </div>
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                      <div className="w-full relative">
                        <input
                          type="text"
                          disabled={this.state.isFree}
                          value={this.state.annualPricePerScreen}
                          name="annualPricePerScreen"
                          className="form-control placeholder-gray-400 text-sm w-11/12"

                          style={{
                            paddingTop: "0.75rem",
                            paddingBottom: "0.75rem",
                            borderTopLeftRadius: "0",
                            borderBottomLeftRadius: "0",
                            paddingLeft: "35px",
                          }}
                          placeholder="Enter Price"
                          onChange={(e) =>
                            this.onNumberInputChange("annualPricePerScreen", e)
                          }
                        />
                        <span
                          style={{
                            position: "absolute",
                            top: "12px",
                            left: "15px",
                          }}
                        >
                          &#8377;
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <ToastContainer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.counter.isLoading,
  currentColor: state.counter.currentColor,
});

const mapDispatchToProps = (dispatch) => ({
  counterSlice: bindActionCreators(commanActionCreator, dispatch),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AddPlan));
