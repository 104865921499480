import React from "react";
import { connect } from "react-redux";
import { withRouter } from "../../router";
import { ToastContainer } from "react-toastify";
import { bindActionCreators } from "@reduxjs/toolkit";
import * as commanActionCreator from "../../reducers/commanReduces";
import AdminSidebar from "../admin/subComponents/adminSidebar";
import AdminNavbar from "./subComponents/adminNavbar";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import SortIcon from "./adminIcons/SortIcon"
import correct from './assets/img/correct.png';
import wrong from './assets/img/wrong.png';
import DeleteIcon from "../../appIcons/deleteIcon";
import { getPlan } from "./services/customer.service";
import debounce from "lodash.debounce";

const moment = require('moment-timezone');
class Plans extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      planList: [],
      page: 1,
      limit: 10,
      totalCount: 0,
    };
  }
  async componentDidMount() {

    this.getPlan();

    // this.props.counterSlice.stopLoading();
  }

  getPlan = async (searchText) => {

    this.props.counterSlice.startLoading();
    let data = await getPlan(this.state.page, this.state.limit, searchText);
    this.setState({ planList: data.body, totalCount: Math.ceil(data.totalCount / this.state.limit) });
    this.props.counterSlice.stopLoading();

  }

  onLimitChange = async (e) => {
    this.props.counterSlice.startLoading();
    this.setState({ limit: e.target.value, page: 1 });
    let data = await getPlan(1, e.target.value);
    this.setState({ planList: data.body, totalCount: Math.ceil(data.totalCount / this.state.limit) });
    this.props.counterSlice.stopLoading();

  }
  onPageChange = async (page) => {

    if (page > 0 && page <= this.state.totalCount) {
      this.props.counterSlice.startLoading();
      this.setState({ page });
      let data = await getPlan(page, this.state.limit);
      this.setState({ planList: data.body, totalCount: Math.ceil(data.totalCount / this.state.limit) });
      this.props.counterSlice.stopLoading();
    }
  }

  searchName = debounce(async (e) => {
    this.props.counterSlice.startLoading();
    this.setState({ limit: 10, page: 1 });
    let data = await getPlan(1, 10, e.target.value.trim());
    this.setState({ planList: data.body, totalCount: Math.ceil(data.totalCount / this.state.limit) });
    this.props.counterSlice.stopLoading();
  }, 500);

  render() {
    return (
      <div className="flex">
        <AdminSidebar type={"admin/plans"} />
        <div className="h-screen grow md:overflow-hidden overflow-auto md:hover:overflow-auto  shadow">
          <AdminNavbar
            router={this.props.router}
            counterSlice={this.props.counterSlice}
          />
          <div className="flex px-11 py-4 shadow-md text-3xl ">
            <h1 className="self-center mr-5 text-black text-xl">Plans</h1>
            <div className="flex justify-end flex-1">
              <button
                type="button"
                className={`text-base  ml-3 px-3 py-2 btn-prime text-white  rounded-lg`}
                onClick={async () => this.props.router.navigate("/admin/addPlan/" + "newPlan")}
              >
                Add Plan
              </button>
            </div>
          </div>
          <br />
          <div>
            <div className="px-11">
              <div className="flex">
                <form style={{ width: "300px" }}>
                  <label
                    for="search"
                    class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
                  >
                    Search
                  </label>
                  <div class="relative">
                    <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      <svg
                        class="w-4 h-4 text-gray-500 dark:text-gray-400"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 20 20"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          strokeWidth="2"
                          d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                        />
                      </svg>
                    </div>
                    <input
                      type="search"
                      id="search"
                      class="block w-full py-2 pl-10 text-sm text-gray-900 border-bottom border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Search Name"
                      onChange={(e) => this.searchName(e)}
                    />
                  </div>
                </form>
              </div>
            </div>
            {<div className="px-11">
              {
                <div className="shadow mt-8 rounded-lg">
                  <div className="flex rounded-t-lg bg-white p-3  text-center text-sm font-medium border-b-1">
                    <div className="flex items-center justify-center flex-1" >Name<div className="px-2 cursor-pointer"><SortIcon></SortIcon></div></div>
                    <div className="flex items-center justify-center flex-1" >Code<div className="px-2 cursor-pointer"><SortIcon></SortIcon></div></div>
                    <div className="flex items-center justify-center flex-1" style={{ maxWidth: '120px' }}>No. of Channel<div className="px-2 cursor-pointer"><SortIcon></SortIcon></div> </div>
                    <div className="flex items-center justify-center flex-1" style={{ maxWidth: '120px' }}>No. of Screens<div className="px-2 cursor-pointer"><SortIcon></SortIcon></div> </div>
                    <div className="flex items-center justify-center flex-1" style={{ maxWidth: '100px' }}>No. of Apps<div className="px-2 cursor-pointer"><SortIcon></SortIcon></div> </div>
                    <div className="flex items-center justify-center flex-1" >Storage Per screen<div className="px-2 cursor-pointer"><SortIcon></SortIcon></div> </div>
                    <div className="flex items-center justify-center flex-1" >Active<div className="px-2 cursor-pointer"><SortIcon></SortIcon></div> </div>
                    <div className="flex items-center justify-center flex-1" >Free<div className="px-2 cursor-pointer"><SortIcon></SortIcon></div> </div>
                    <div className="flex items-center justify-center flex-1" >Visible During Signup<div className="px-2 cursor-pointer"><SortIcon></SortIcon></div> </div>
                    <div className="flex items-center justify-center flex-1" >Last Modified at<div className="px-2 cursor-pointer"><SortIcon></SortIcon></div> </div>
                    <div className="flex items-center justify-center flex-1" ><div className="px-2 cursor-pointer"></div>Action </div>
                  </div>
                  {this.state.planList?.map((m, i) => (
                    <div key={i} className="flex bg-white p-3 text-gray-450 text-xs text-center text-black border-b-1 cursor-pointer"
                      onClick={async () => this.props.router.navigate("/admin/addPlan/" + m._id)}
                    >
                      <div className=" flex-1">
                        <span className="self-center" >{m.name}</span>

                      </div>
                      <div className="flex-1">
                        <span className="self-center" >{m.code}</span>
                      </div>
                      <div className="flex-1" style={{ maxWidth: '120px' }}>
                        <span className="self-center">{m.maxChannel}</span>
                      </div>
                      <div className="flex-1" style={{ maxWidth: '120px' }}>
                        <span className="self-center">{m.maxScreen}</span>
                      </div>
                      <div className="flex-1" style={{ maxWidth: '100px' }}>
                        <span className="self-center">{m.maxApp}</span>
                      </div>
                      <div className="flex-1" >
                        <span className="self-center">{m.maxStorage}</span>
                      </div>
                      <div className="flex-1" >
                        <span className="self-center flex justify-center">
                          {m.isActive && <img className="" style={{ width: "20", height: "20px" }} src={correct} alt="ChannelLogo" />}
                          {!m.isActive && <img className="" style={{ width: "20", height: "20px" }} src={wrong} alt="ChannelLogo" />}
                        </span>
                      </div>
                      <div className="flex-1" >
                        <span className="self-center flex justify-center">
                          {m.isFree && <img className="" style={{ width: "20", height: "20px" }} src={correct} alt="ChannelLogo" />}
                          {!m.isFree && <img className="" style={{ width: "20", height: "20px" }} src={wrong} alt="ChannelLogo" />}
                        </span>
                      </div>
                      <div className="flex-1" >
                        <span className="self-center flex justify-center" >
                          {m.isPublic && <img className="" style={{ width: "20", height: "20px" }} src={correct} alt="ChannelLogo" />}
                          {!m.isPublic && <img className="" style={{ width: "20", height: "20px" }} src={wrong} alt="ChannelLogo" />}
                        </span>
                      </div>
                      <div className="flex-1" >
                        <span className="self-center">{moment(m.modifiedOn).format('D MMM YYYY, h:mm a')}</span>
                      </div>
                      <div className="flex-1 flex justify-center" >
                        {m.name !== 'FREE' && <DeleteIcon />}
                      </div>
                    </div>
                  ))}
                  <div className="flex justify-end gap-4 rounded-b-lg bg-white p-3 text-gray-450 text-center text-sm font-medium border-b-1">
                    <span className="self-center" >Row per page:</span>
                    <select className="bg-white border-b-1 self-center border-b-black" onChange={(e) => this.onLimitChange(e)} >
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="30">30</option>
                      <option value="40">40</option>
                      <option value="50">50</option>
                    </select>
                    <span>{this.state.page} of {this.state.totalCount}</span>
                    <IoIosArrowBack className="self-center" onClick={() => this.onPageChange(this.state.page - 1)} />
                    <IoIosArrowForward className="self-center" onClick={() => this.onPageChange(this.state.page + 1)} />
                  </div>
                </div>
              }

              <ToastContainer />
            </div>}
          </div>
        </div>
        <ToastContainer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.counter.isLoading,
  currentColor: state.counter.currentColor,
});

const mapDispatchToProps = (dispatch) => ({
  counterSlice: bindActionCreators(commanActionCreator, dispatch),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Plans));
