import React from "react";
import { connect } from "react-redux";
import { withRouter } from "../../router";
import { bindActionCreators } from "@reduxjs/toolkit";
import * as commanActionCreator from '../../reducers/commanReduces'
import { fetchAppData, fetchMatchInfo} from "../../services/appServices";
import { format } from 'date-fns'

class CricketAppPublicComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currenciesList: [],
            appInfo: "",
            matchDetails: ""
        }
    }
    async componentDidMount() {
        if (this.props.router.params.appId) {
            this.props.counterSlice.startLoading();
            let appInfo = await fetchAppData(this.props.router.params.appId);
            if (appInfo.success) {
                this.populateInfo(appInfo.body);
            }
            this.props.counterSlice.stopLoading();
        }
    }
    populateInfo = async (data) => {
        let matchDetails = await fetchMatchInfo(data.matchId);
        this.setState({ matchDetails });
    }

    render() {

        return (
            <div className="flex flex-col flex-1 justify-center items-center bg-gray-800 h-screen w-full gap-4">
                <div className="bg-cover flex bg-center object-cover border-black border-4 shadow overflow-hidden text-white" style={{ "backgroundImage": `url(${process.env.PUBLIC_URL + "/cricket2.svg"})` }}>
                    {this.state.matchDetails != "" && <>
                        <div className="flex flex-col flex-1 text-2xl font-bold justify-center text-center items-center">
                            <span>{this.state.matchDetails.matchHeader.team1.name}</span>
                        </div>
                        <div className="flex flex-col flex-1 text-center justify-center items-center font-bold">
                            <span className="text-lg ">at</span>
                            {<span>{format(new Date(this.state.matchDetails.matchHeader.matchStartTimestamp), 'EEEE, dd MMM, yyyy HH:mm')}</span>}
                            <br />
                            <span className="text-tahiti">{this.state.matchDetails.matchHeader.status}</span>
                            <br />
                            {this.state.matchDetails.scoreCard.length > 0 && <div className="flex flex-col">
                                {this.state.matchDetails.scoreCard?.map((sd) => (<span>
                                    {sd.batTeamDetails.batTeamShortName} {sd.scoreDetails.runs}-{sd.scoreDetails.wickets}({sd.scoreDetails.overs})

                                </span>))}
                            </div>}
                        </div>
                        <div className="flex flex-col flex-1 text-2xl font-bold justify-center text-center items-center">
                            <span>{this.state.matchDetails.matchHeader.team2.name}</span>
                        </div>
                    </>}
                </div>
            </div>
        )
    };
}

const mapStateToProps = (state) => ({
    isLoading: state.counter.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
    counterSlice: bindActionCreators(commanActionCreator, dispatch)
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CricketAppPublicComponent));
