import React from "react";
import { connect } from "react-redux";
import { withRouter } from "../../router";
import { format } from 'date-fns'

class SoccerMatchPreview extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isAllAppsSelected: true,
            matchInfo: this.props.soccerMatchList[0],
            index: 0,
            timer: "",
            scheduledTime: this.getMatchtime(this.props.soccerMatchList[0])
        }
    }
    async componentDidMount() {
        this.timerList();
    }

    componentWillUnmount() {
        clearTimeout(this.state.timer);
    }

    getMatchtime = (mediaInfoObj) => {
        let time = mediaInfoObj.time.scheduled.toString();
        console.log(time);
        var year = parseInt(time.substring(0, 4));
        var month = parseInt(time.substring(4, 6)) - 1; // Months are 0-indexed in JavaScript (0 = January, 11 = December)
        var day = parseInt(time.substring(6, 8));
        var hour = parseInt(time.substring(8, 10));
        var minute = parseInt(time.substring(10, 12));
        var second = parseInt(time.substring(12, 14));
        console.log(year, month, day, hour, minute);

        return new Date(year, month, day, hour, minute);
    }

    timerList = () => {
        this.setState({
            matchInfo: this.props.soccerMatchList[this.state.index],
            scheduledTime: this.getMatchtime(this.props.soccerMatchList[this.state.index])
        })
        this.state.timer = setTimeout(function () {
            if ((this.props.soccerMatchList.length - 1) === this.state.index)
                this.state.index = 0
            else this.state.index += 1;
            this.timerList();
        }.bind(this), this.props.duration * 1000);
    }

    render() {
        return (<div className="text-white w-full h-full flex justify-center text-center text-xl font-bold">
            <div className="flex-1 flex flex-col self-center">
                <img src={this.state.matchInfo.team_1.logo} className="object-contain h-20 w-30" />
                <span >{this.state.matchInfo.team_1.name}</span>
            </div>
            <div className="flex-1 flex flex-col self-center">
                <span className="text-6xl">VS</span>
                {format(this.state.scheduledTime, 'EEEE, dd MMM, yyyy HH:mm')}
            </div>
            <div className="flex-1 flex flex-col self-center">
                <img src={this.state.matchInfo.team_2.logo} className="object-contain h-20 w-30" />
                <span>{this.state.matchInfo.team_2.name}</span>
            </div>

        </div>)
    };
}


const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SoccerMatchPreview));
