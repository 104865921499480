import React from "react";
import { connect } from "react-redux";
import { withRouter } from "../../router";
import { ToastContainer, toast } from "react-toastify";
import { bindActionCreators } from "@reduxjs/toolkit";
import * as commanActionCreator from "../../reducers/commanReduces";
import AdminSidebar from "../admin/subComponents/adminSidebar";
import AdminNavbar from "./subComponents/adminNavbar";

import { MdArrowBackIosNew } from "react-icons/md";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import "./assets/css/admin.css";

class AddSubscription extends React.Component {
  constructor(props) {
    super(props);
    let dateCurrent = new Date();
    let month = dateCurrent.getMonth() + 1;
    let day = dateCurrent.getDate();
    dateCurrent =
      dateCurrent.getFullYear() +
      "-" +
      (month < 10 ? "0" + month : month) +
      "-" +
      (day < 10 ? "0" + day : day);
    this.hiddenFileInput = React.createRef();
    this.state = {
      selectedCustomerId: this.props.router.params.subId,
      invoiceNumber: "",
      customers: "",
      customerEmail: "",
      frequency: "Select Frequency",
      startDate: "",
      expiryDate: "",
      cost: "",
      numberofLicense: "",
      frequencies: [
        {
          key: "1",
          value: "Monthly",
        },
        {
          key: "2",
          value: "Annual",
        },
      ],
      dateCurrent: dateCurrent,
      // userProfile: "",
    };
  }
  async componentDidMount() {
   
  }



  handlechangedrop = (e) => {
    console.log(e);
    this.setState({
      frequency: e.value,
    });
  };

  onInputChange = (type, event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onNumberInputChange = (type, e) => {
    const re = /^[0-9\b]+$/;
    console.log(re.test(e.target.value));
    console.log(e.target.name);
    // if value is not blank, then test the regex

    if (e.target.value === "" || re.test(e.target.value)) {      
      this.setState({ [e.target.name]: e.target.value });
    } else {
      const value = e.target.value.replace(/\D/g, "");
      this.setState({ [e.target.name]: value });
    }
  };

  

  addNewSubscription = async () => {};

  render() {
    return (
      <div className="flex">
        <AdminSidebar type={"admin/plans"} />
        <div className="h-screen grow md:overflow-hidden overflow-auto md:hover:overflow-auto  shadow">
          <AdminNavbar
            router={this.props.router}
            counterSlice={this.props.counterSlice}
          />
          <div className="flex  shadow-md text-3xl ">
            <div className="px-3 border-r-1 flex items-center cursor-pointer">
              <MdArrowBackIosNew
                opacity={0.5}
                className=" top-1 m-1 "
                style={{ width: "15px" }}
                onClick={async () =>
                  this.props.router.navigate("/admin/viewCustomer/" + this.state.selectedCustomerId)
                }
              />
            </div>
            <h1 className="self-center mr-5 text-black text-xl pr-11 py-4 pl-4">
              Add New Subscription
            </h1>
            <div className="flex justify-end flex-1  items-center pr-5">
              <button
                type="button"
                className={`text-base  ml-3 px-3 py-2 btn-prime text-white  rounded-lg`}
                onClick={async () => {
                  this.addNewSubscription();
                }}
              >
                Save
              </button>
            </div>
          </div>
          <br />
          <div className="flex w-full px-14 justify-center items-center">
            <form action="#!" className="w-2/4">
              <div
                className="w-full flex  px-4 py-4 flex-col "
                style={{
                  background: "#FFFFFF",
                  boxShadow: "1px 1px 10px 0px #0000000D",
                }}
              >
                {/* <div className=""> */}
                {/* <div className=""> */}
                <div className="mb-3">
                  <label
                    class="block text-gray-400 text-sm  mb-2"
                    for="grid-first-name"
                  >
                    Invoice Number
                  </label>
                  <input
                    type="text"
                    value={this.state.invoiceNumber}
                    name="invoiceNumber"
                    className="form-control placeholder-gray-400 text-sm py-2.5 w-full"
                    placeholder="Enter invoice number"
                    onChange={(e) => this.onInputChange("invoiceNumber", e)}
                  />
                </div>
                <div className="form-group mb-3">
                  <label
                    class="block text-gray-400 text-sm  mb-2"
                    for="grid-first-name"
                  >
                    Customers
                  </label>
                  <input
                    type="text"
                    value={this.state.customers}
                    name="customers"
                    className="form-control placeholder-gray-400 text-sm py-2.5 w-full"
                    placeholder="Enter Customer"
                    onChange={(e) => this.onInputChange("customers", e)}
                  />
                </div>
                <div className="mb-3">
                  <label
                    class="block text-gray-400 text-sm  mb-2"
                    for="grid-first-name"
                  >
                    Customer E-mail
                  </label>
                  <input
                    type="text"
                    value={this.state.customerEmail}
                    name="customerEmail"
                    className="form-control placeholder-gray-400 text-sm py-2.5 w-full"
                    placeholder="Enter customer email"
                    onChange={(e) => this.onInputChange("customerEmail", e)}
                  />
                </div>
                {/* </div> */}
                {/* </div> */}

                {/* <div className=""> */}
                <div className="form-group mb-3">
                  <label
                    class="block text-gray-400 text-sm  mb-2"
                    for="grid-first-name"
                  >
                    Select Frequency
                  </label>

                  <DropdownButton
                    variant=""
                    title={this.state.frequency}
                    id="input-group-dropdown-add-subscription"
                    className="w-full text-gray-400"
                  >
                    {this.state.frequencies?.map((m, i) => (
                      <Dropdown.Item
                        key={i}
                        style={{ textDecoration: "none" }}
                        className="w-full text-gray-400"
                        onClick={(e) => this.handlechangedrop(m)}
                      >
                        {m.value}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                </div>

                <div className="form-group mb-3 ">
                  <label
                    class="block text-gray-400 text-sm  mb-2"
                    for="grid-first-name">
                    Start Date
                  </label>

                  <div className=" addScriptionDates">
                    <input
                      type="date"
                      value={this.state.startDate}
                      className="self-center text-gray-400 text-sm"
                      min={this.state.dateCurrent}
                      onChange={(e) => {
                        if (e.target.value === "") return;
                        this.setState({ startDate: e.target.value });
                      }}
                    />
                  </div>                
                </div>

                <div className="form-group mb-3">
                  <label
                    class="block text-gray-400 text-sm  mb-2"
                    for="grid-first-name"
                  >
                    Expiry Date
                  </label>
                  <div className=" addScriptionDates">
                    <input
                      type="date"
                      value={this.state.endDate}
                      className="self-center text-gray-400 text-sm"
                      min={this.state.startDate }
                      onChange={(e) => {
                        if (e.target.value === "") return;
                        this.setState({ endDate: e.target.value });
                      }}
                    />
                  </div>  
                </div>

                <div className="form-group mb-3">
                  <label
                    class="block text-gray-400 text-sm  mb-2"
                    for="grid-first-name"
                  >
                    Number of License
                  </label>
                  <input
                    type="text"
                    value={this.state.numberofLicense}
                    name="numberofLicense"
                    className="form-control placeholder-gray-400 text-sm py-2.5 w-full"
                    placeholder="Enter no. of license"
                    onChange={(e) =>
                      this.onNumberInputChange("numberofLicense", e)
                    }
                  />
                </div>

                <div className="form-group mb-3">
                  <label
                    class="block text-gray-400 text-sm  mb-2"
                    for="grid-first-name"
                  >
                    Cost
                  </label>
                  <input
                    type="text"
                    value={this.state.cost}
                    name="cost"
                    className="form-control placeholder-gray-400 text-sm py-2.5 w-full"
                    placeholder="Enter cost"
                    onChange={(e) => this.onNumberInputChange("cost", e)}
                  />
                </div>
                {/* </div> */}
              </div>
            </form>
          </div>
        </div>
        <ToastContainer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.counter.isLoading,
  currentColor: state.counter.currentColor,
});

const mapDispatchToProps = (dispatch) => ({
  counterSlice: bindActionCreators(commanActionCreator, dispatch),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AddSubscription));
