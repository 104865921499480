import React from "react";
import { connect } from "react-redux";
import { withRouter } from "../../router";
import { format } from 'date-fns'
import { loadImageFromDrive } from "../../services/appServices";

class RssPreview extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            newsInfo: this.props.rssNewsList[0],
            index: 0,
            timer: "",
            imageURL: ""
        }
    }
    async componentDidMount() {
        this.timerList();
    }

    componentWillUnmount() {
        clearTimeout(this.state.timer);
    }

    timerList = async () => {
        this.setState({ newsInfo: this.props.rssNewsList[this.state.index] })
        this.state.timer = setTimeout(function () {
            if ((this.props.rssNewsList.length - 1) === this.state.index)
                this.state.index = 0
            else this.state.index += 1;
            this.timerList();
        }.bind(this), this.props.duration * 1000);
    }

    render() {
        return (<div className="flex w-full h-full">
            <div className="flex-1 h-full">
                <img src={this.state.newsInfo.url} className="object-cover w-full h-full" alt="Loding..." />
            </div>
            <div className="flex-1 p-2 flex flex-col gap-2">
                <div className="flex flex-col gap-1">
                    <span className="font-bold">{this.state.newsInfo.title}</span>
                    <span className="text-xs text-gray-500 italic">{this.state.newsInfo.publishDate}</span>
                    <div class="w-full h-1">
                        <div class="w-2/3 scroll-smooth h-full text-center text-xs text-white bg-darkBlue">
                        </div>
                    </div>
                </div>
                <div className="text-xs" dangerouslySetInnerHTML={{ __html: this.state.newsInfo.description }}></div>
            </div>
        </div>)
    };
}


const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RssPreview));
