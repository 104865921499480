import React from "react";
import { Modal, Button, Breadcrumb, InputGroup, Form, OverlayTrigger, Popover } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "../router";
import { ToastContainer, toast } from 'react-toastify';
import * as commanActionCreator from '../reducers/commanReduces'

import { MdArrowBackIos } from 'react-icons/md';
import { FaTimes, FaBars, FaFilm, FaPlus, FaFolder } from 'react-icons/fa';

import "../css/screen.css";
import { fetchScreenDetails, setToScreenService } from "../services/screenService";

import { fetchAllMedia, fetchAllPlayList, addPlayList, updatePlayList, fetchPlayListDetails, attachedDataList, deletePlayList } from "../services/playListServices";
import { fetchAllLinksData } from "../services/linkservice";

import { AddContentButtons, AppIcon, IconButton, InputFieldWithEdit, ToggalButton } from "./utilComponents/inputFieldAndButtons";
import { addTimes, substractTimes, } from "./utilComponents/utilFuncation";
import { bindActionCreators } from "@reduxjs/toolkit";
import ChannelAddContent from "./subComponents/channelAddContent";
import { fetchRecentMedia } from "../services/mediaService";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import Sidebar from "./subComponents/Sidebar";
import Navbar from "./subComponents/Navbar";
import { format } from "date-fns";
import Link from "../appIcons/Link";
import Appss from "../appIcons/Appss";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Mediaa from "../appIcons/Mediaa";
import SetToScreenModel from "./utilComponents/setToScreenModel";
import ShowPreview from "./utilComponents/showPreview";
import { fetchAllAppData } from "../services/appServices";
import InputDuration from "./utilComponents/inputDurationModel";
import debounce from "lodash.debounce";

class PlayListDetailComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedPlayListId: this.props.router.params.playListId,
            addPlaylistModel: false,
            deletePlaylistModel: false,
            isSelectedTime: "00:00:10",
            defaultImageTime: "00:00:10",
            defaultAppTime: "00:01:10",
            defaultLinkTime: "00:01:10",
            totalDuration: "00:00:00",
            currentPath: "/",
            selectedPlayListInfo: {},
            playList: [],
            isUpdating: false,
            isPreview: false,
            setToScreenModel: false,
            selectedPlayList: [],
            selectAll: false,
            screenList: [],
            screenTempList: [],
            playListName: "New PlayList",
            searchContent: "",
            isPlaylistNameEdit: false,
            attachedList: {},
            playListId: "",
            status: "DRAFT",
            selectedLink: "",
            showLinkModel: false,
            setting: false,
            onclick: false,
            content: true,
            mediaAllList: [],
            linkList: [],
            appList: [],
            pathList: [{
                folderName: "Library",
                path: "/",
                isActive: true
            }],
            mediaList: [],
            tempMediaList: [],
            addContent: false,
            addContentSlider: {
                isPlayList: false,
                isMedia: true,
                isLinks: false,
                isChannel: false
            },
            selectedTab: {
                isrecent: true,
                isMedia: false,
                isLinks: false,
                isCanvas: false,
                isApps: false
            },
            selectedTempRecent: [],
            selectedTempMedia: [],
            selectedTempLink: [],
            mediaAllList: [],
            playListAllList: [],
            channelAllList: [],
            linkAllList: [],
            appAllList: [],
            searchMediaName: "",
            cloneOfPlayList: ""
        }
    }
    async componentDidMount() {
        this.props.counterSlice.startLoading();

        let mediaRes = await fetchRecentMedia();
        this.setState({ mediaList: mediaRes.body, selectedTempRecent: mediaRes.body });
        let mediaAllRes = await fetchAllMedia(this.state.currentPath);
        this.setState({ mediaAllList: mediaAllRes.body, mediaListData: mediaAllRes.body, selectedTempMedia: mediaAllRes.body });
        let playListDetails = await fetchPlayListDetails(this.props.router.params.playListId);
        let dasboardResponse = await fetchAllLinksData();
        let appResponse = await fetchAllAppData();
        this.setState({ linkList: dasboardResponse.body, linkAllList: dasboardResponse.body, appList: appResponse.body, appAllList: appResponse.body });
        this.setState({
            selectedPlayListInfo: playListDetails.body, cloneOfPlayList: playListDetails.body, playListName: playListDetails.body.name,
            selectedPlayList: playListDetails.body.playlist, totalDuration: playListDetails.body.totalDuration,
        });
        this.props.counterSlice.stopLoading();

    }
    searchName = (e) => {
        if (e.target.value != "") {
            this.setState({ playList: this.state.playListTemp.filter((s) => s.name.toUpperCase().includes(e.target.value.toUpperCase())) })
        } else
            this.setState({ playList: this.state.playListTemp })
    }
    closeAll = () => {
        this.setState({
            deletePlaylistModel: false, setToScreenModel: false, showLinkModel: false, selectedLink: "", addPlaylistModel: false, addContent: false, screenList: []
        });
    }
    populatePlayListData = async (p) => {
        let playListDetails = await fetchPlayListDetails(this.props.router.params.playListId);
        if (playListDetails.success) {
            this.setState({
                addPlaylistModel: true, selectedPlayListInfo: playListDetails.body,
                cloneOfPlayList: playListDetails.body,
                isPlaylistNameEdit: false,
                totalDuration: playListDetails.body.totalDuration,
                selectedPlayList: playListDetails.body.playlist,
                playListName: playListDetails.body.name,
                status: playListDetails.body.status
            })
        } else {
            toast.error('Playlist details not found.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        }
    }
    handleDragEnd = (val) => {
        const userRole = JSON.parse(localStorage.getItem("roles"));

        if (!userRole.playList["editPlayList"].write) return;

        const { source, destination } = val;
        if (!destination) return;
        if (source.droppableId === "mediaPlayList" && destination.droppableId === "mediaPlayList") {
            let tempData = Array.from(this.state.selectedPlayList);
            let [source_data] = tempData.splice(source.index, 1);
            tempData.splice(destination.index, 0, source_data);
            this.state.selectedPlayList = tempData;
            this.setState({ selectedPlayList: tempData });
            this.updatePlayList();
        } else if (source.droppableId === "mediaList" && destination.droppableId === "mediaPlayList") {
            let mediaObject = JSON.parse(JSON.stringify(this.state.mediaAllList[source.index]));
            if (mediaObject.type === "FOLDER")
                return;
            if (!mediaObject.contentType.includes("video")) {
                mediaObject.duration = this.state.selectedPlayListInfo.defaultTime.imageDuaration;
            }

            this.state.selectedPlayList.splice(destination.index, 0, mediaObject);
            let totalTime = addTimes(this.state.totalDuration, mediaObject.duration);
            this.state.totalDuration = totalTime;
            this.setState({ selectedPlayList: this.state.selectedPlayList, totalDuration: totalTime });
            this.updatePlayList();
        } else if (source.droppableId === "recentMediaList" && destination.droppableId === "mediaPlayList") {
            let mediaObject = JSON.parse(JSON.stringify(this.state.mediaList[source.index]));
            if (mediaObject.type === "FOLDER")
                return;
            if (mediaObject.type === "LINK") {
                mediaObject.duration = this.state.selectedPlayListInfo.defaultTime.linkDuaration;
            } else if (mediaObject.type === "APP") {
                if (mediaObject.appType != "YOUTUBE")
                    mediaObject.duration = this.state.selectedPlayListInfo.defaultTime.appDuaration;
            } else if (!mediaObject.contentType.includes("video")) {
                mediaObject.duration = this.state.selectedPlayListInfo.defaultTime.imageDuaration;
            }
            this.state.selectedPlayList.splice(destination.index, 0, mediaObject);
            let totalTime = addTimes(this.state.totalDuration, mediaObject.duration);
            this.state.totalDuration = totalTime;
            this.setState({ selectedPlayList: this.state.selectedPlayList, totalDuration: totalTime });
            this.updatePlayList();
        } else if (source.droppableId === "linkInfo" && destination.droppableId === "mediaPlayList") {
            let mediaObject = JSON.parse(JSON.stringify(this.state.linkList[source.index]));
            if (mediaObject.type === "FOLDER")
                return;

            mediaObject.duration = this.state.selectedPlayListInfo.defaultTime.linkDuaration;
            this.state.selectedPlayList.splice(destination.index, 0, mediaObject);
            let totalTime = addTimes(this.state.totalDuration, mediaObject.duration);
            this.state.totalDuration = totalTime;
            this.setState({ selectedPlayList: this.state.selectedPlayList, totalDuration: totalTime });
            this.updatePlayList();
        } else if (source.droppableId === "appDropInfo" && destination.droppableId === "mediaPlayList") {
            let mediaObject = JSON.parse(JSON.stringify(this.state.appList[source.index]));
            if (mediaObject.appType != "YOUTUBE")
                mediaObject.duration = this.state.selectedPlayListInfo.defaultTime.appDuaration;
            this.state.selectedPlayList.splice(destination.index, 0, mediaObject);
            let totalTime = addTimes(this.state.totalDuration, mediaObject.duration);
            this.state.totalDuration = totalTime;
            this.setState({ selectedPlayList: this.state.selectedPlayList, totalDuration: totalTime });
            this.updatePlayList();
        }
        return;
    }
    onMediaTimeChange = debounce(async (value, i) => {
        let totalTime = "00:00:00";
        if (value === totalTime) {
            toast.error("You can't set the time to 00:00:00", {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
            this.state.selectedPlayList[i].duration = this.state.cloneOfPlayList.playlist[i].duration;
            this.setState({ selectedPlayList: this.state.selectedPlayList });
            return;
        }
        this.state.selectedPlayList[i].duration = value;
        this.setState({ selectedPlayList: this.state.selectedPlayList });
        this.state.selectedPlayList.forEach((a) => {
            totalTime = addTimes(totalTime, a.duration)
        });
        this.state.totalDuration = totalTime;
        this.setState({ totalDuration: totalTime });
        this.updatePlayList();
    }, 1000);
    removeSelectedPlayList = async (i) => {
        this.props.counterSlice.startLoading();
        let totalTime = substractTimes(this.state.totalDuration, this.state.selectedPlayList[i].duration);
        this.state.totalDuration = totalTime;
        this.state.selectedPlayList.splice(i, i !== -1 ? 1 : 0);
        this.setState({ selectedPlayList: this.state.selectedPlayList, totalDuration: totalTime });
        await this.updatePlayList();
        this.props.counterSlice.stopLoading();

    }
    savePlayList = async () => {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        let playListDTO = {
            name: this.state.playListName,
            totalDuration: this.state.totalDuration,
            orgId: userInfo.orgId
        }
        let response = await addPlayList(playListDTO);
        if (response.success) {

            let dasboardResponse = await fetchAllPlayList();
            this.populatePlayListData(response.body);
            this.setState({ playList: dasboardResponse.body });
        } else {
            toast.error('Error while adding playlist.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        }
    }
    copyPlayList = async (copyData) => {
        copyData._id = undefined;
        copyData.status = "DRAFT";

        let response = await addPlayList(copyData);
        if (response.success) {

            let dasboardResponse = await fetchAllPlayList();
            this.setState({ playList: dasboardResponse.body });
            toast.success('Playlist created successfully.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        } else {
            toast.error('Error while adding playlist.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        }
    }
    deletePlayList = async () => {
        this.props.counterSlice.startLoading();

        let response = await deletePlayList(this.state.playListId);
        if (response.success) {
            toast.success('PlayList deleted successfully.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
            this.props.router.navigate(-1);
        } else {
            toast.error('Error while deleting screen.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        }
        this.props.counterSlice.stopLoading();

        this.setState({ playListId: "", deletePlaylistModel: false })
    };

    updatePlaylistName = async () => {
        if (this.state.playListName.trim() === "") {
            toast.error('Playlist name is required.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
            return;
        }
        let playListDTO = {
            _id: this.state.selectedPlayListInfo._id,
            name: this.state.playListName
        }
        let response = await updatePlayList(playListDTO);
        if (response.success) {
            let playListDetails = await fetchPlayListDetails(this.props.router.params.playListId);
            this.setState({ selectedPlayListInfo: playListDetails.body, cloneOfPlayList: playListDetails.body, playListName: playListDetails.body.name })
        } else {
            toast.error('Error while updating playlist name.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        }
    }
    updatePlayList = async () => {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        let playlistData = [];
        this.setState({ isUpdating: true });

        this.state.selectedPlayList.forEach(sp => playlistData.push({
            duration: sp.duration,
            mediaId: sp._id,
            isAuditLog: sp.isAuditLog
        }));
        let playListDTO = {
            _id: this.state.selectedPlayListInfo._id,
            totalDuration: this.state.totalDuration,
            orgId: userInfo.orgId,
            playlist: playlistData,
            status: "DRAFT",
            defaultTime: this.state.selectedPlayListInfo.defaultTime
        }
        let response = await updatePlayList(playListDTO);
        if (response.success) {
            let playListDetails = await fetchPlayListDetails(this.props.router.params.playListId);
            this.setState({ selectedPlayListInfo: playListDetails.body, cloneOfPlayList: playListDetails.body, playListName: playListDetails.body.name })
        } else {
            toast.error('Error while adding playlist.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        }
        this.setState({
            isUpdating: false, tempMediaList: [], isPlaylistNameEdit: false
        })
    }

    onContentTabChange = (type) => {
        let addContentSlider = {
            isPlayList: false,
            isMedia: false,
            isLinks: false,
            isChannel: false
        }
        addContentSlider[type] = true;
        this.setState({ addContentSlider: addContentSlider });
    }
    publishPlayList = async () => {
        this.setState({ isUpdating: true });
        let playListDTO = {
            _id: this.state.selectedPlayListInfo._id,
            status: "PUBLISHED"
        };
        this.props.counterSlice.startLoading();

        let response = await updatePlayList(playListDTO);
        if (response.success) {
            let playListDetails = await fetchPlayListDetails(this.props.router.params.playListId);
            this.setState({ selectedPlayListInfo: playListDetails.body, cloneOfPlayList: playListDetails.body, playListName: playListDetails.body.name })
            toast.success('Playlist published.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        } else {
            toast.error('Error while publishing.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        }
        this.props.counterSlice.stopLoading();

        this.setState({ isUpdating: false })
    }
    onPreview = () => {
        this.setState({ isPreview: !this.state.isPreview })
    }
    addDuplicate = (mpl, i) => {
        this.state.selectedPlayList.splice(i, 0, mpl);
        let totalTime = "00:00:00";
        this.state.selectedPlayList.forEach((a) => {
            totalTime = addTimes(totalTime, a.duration)
        });
        this.state.totalDuration = totalTime;
        this.setState({ totalDuration: totalTime });
        this.updatePlayList();
    }
    selectedMediaFile = (m, i) => {

        switch (m.type) {
            case "APP":
                this.state.appList[i].isSelected = !m.isSelected;
                if (this.state.appList[i].isSelected) {
                    m.schedulingList = this.state.scheduledInfo;
                    this.state.tempMediaList.push(m);
                } else {
                    this.state.tempMediaList = this.state.tempMediaList.filter((item) => item._id !== m._id);
                }
                this.setState({ appList: this.state.appList, tempMediaList: this.state.tempMediaList });
                break;
            case "LINK":
                this.state.linkList[i].isSelected = !m.isSelected;
                if (this.state.linkList[i].isSelected) {
                    m.schedulingList = this.state.scheduledInfo;
                    this.state.tempMediaList.push(m);
                } else {
                    this.state.tempMediaList = this.state.tempMediaList.filter((item) => item._id !== m._id);
                }
                this.setState({ linkList: this.state.linkList, tempMediaList: this.state.tempMediaList });
                break;
            case "FOLDER":
                this.onFolderCilck(m);
                break;
            case "MEDIA":
                this.state.mediaAllList[i].isSelected = !m.isSelected;
                if (this.state.mediaAllList[i].isSelected) {
                    m.schedulingList = this.state.scheduledInfo;
                    this.state.tempMediaList.push(m);
                } else {
                    this.state.tempMediaList = this.state.tempMediaList.filter((item) => item._id !== m._id);
                }
                this.setState({ mediaAllList: this.state.mediaAllList, tempMediaList: this.state.tempMediaList });
                break;
        }
    }
    onTabChange = (type) => {
        this.setState({
            content: false,
            setting: false
        });
        this.setState({ [type]: true })
    }
    addMediaPlayList = () => {
        this.props.counterSlice.startLoading();
        let selectedAddCList = this.state.selectedPlayList.concat(this.state.tempMediaList);
        this.state.selectedPlayList = selectedAddCList;
        this.setState({
            addContent: false,
            selectedPlayList: selectedAddCList,
            tempMediaList: []
        });
        let totalTime = "00:00:00";
        selectedAddCList.forEach((a) => {
            totalTime = addTimes(totalTime, a.duration)
        });
        this.state.totalDuration = totalTime;
        this.setState({ totalDuration: totalTime });
        this.updatePlayList();
        this.props.counterSlice.stopLoading();

    }
    addContentModel = async () => {
        let dasboardResponse = await fetchAllMedia(this.state.currentPath);
        let linkResponse = await fetchAllLinksData();

        dasboardResponse.body.forEach((m) => m.isSelected = false);
        linkResponse.body.forEach((m) => m.isSelected = false);

        this.setState({ addContent: true, mediaAllList: dasboardResponse.body, linkList: linkResponse.body })
    }
    onBreadCrumbChnage = async (m, i) => {
        let dasboardResponse = await fetchAllMedia(m.path);
        this.setState({
            mediaAllList: dasboardResponse.body, currentPath: m.path,
            pathList: this.state.pathList.slice(0, i + 1)
        });
    }
    onFolderCilck = async (m) => {
        if (this.state.onclick)
            return;
        this.state.onclick = true;
        this.props.counterSlice.startLoading();
        let path = this.state.currentPath + m._id + "/";
        let dasboardResponse = await fetchAllMedia(path);
        this.state.pathList.forEach((pl) => pl.isActive = false);
        this.state.pathList.push({
            folderName: m.name,
            path: path,
            isActive: true
        });
        this.setState({ mediaAllList: dasboardResponse.body, currentPath: path });
        this.props.counterSlice.stopLoading();
        setTimeout(() => this.setState({ onclick: false }), 600);

    }
    onClickDeletePlayList = async (p) => {
        this.props.counterSlice.startLoading();
        let attachedData = await attachedDataList(p._id);
        this.props.counterSlice.stopLoading();
        this.setState({ deletePlaylistModel: true, attachedList: attachedData.body, playListId: p._id })
    }
    addAuditLogs = async (auditLogs, i) => {
        this.state.selectedPlayList[i].isAuditLog = !auditLogs;
        this.setState({ selectedPlayList: this.state.selectedPlayList });
        this.updatePlayList();
    }
    onMediaContentTabChange = (type) => {
        let selectedTab = {
            isrecent: false,
            isMedia: false,
            isLinks: false,
            isCanvas: false,
            isApps: false
        }
        selectedTab[type] = true;
        this.setState({ selectedTab: selectedTab });
    }
    onSetToScreenClick = async () => {
        this.props.counterSlice.startLoading();
        let screenDetails = await fetchScreenDetails();
        let attachedData = await attachedDataList(this.props.router.params.playListId);

        this.props.counterSlice.stopLoading();
        this.setState({ setToScreenModel: true });
        if (screenDetails.success) {
            let screenList = screenDetails.body;
            if (attachedData.success && attachedData.body.screenList.length > 0) {
                let attachedScreenInfo = attachedData.body;
                screenList.forEach((s) => {
                    s.isSelected = false;
                    for (let i = 0; i < attachedScreenInfo.screenList.length; i++) {
                        if (s._id === attachedScreenInfo.screenList[i]._id)
                            s.isSelected = true;
                    }
                });

                if (attachedScreenInfo.screenList.length === screenList)
                    this.setState({ selectAll: true })
            } else
                screenList.forEach((s) => s.isSelected = false);

            this.setState({ screenList: screenList, screenTempList: screenList });
        }
    }
    setToScreenService = async () => {
        let screenList = [];
        this.setState({ setToScreenModel: false });
        this.props.counterSlice.startLoading();

        this.state.screenList.forEach((s) => {
            if (s.isSelected)
                screenList.push(s._id);
        });

        let screenDTO = {
            type: "PLAYLIST",
            refrenceId: this.state.selectedPlayListInfo._id,
            screenIdList: screenList
        };
        let screenDetails = await setToScreenService(screenDTO);
        if (screenDetails.success) {
            toast.success('Screens has been updated.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        } else {
            toast.error(screenDetails.message, {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        }
        this.props.counterSlice.stopLoading();

        this.closeAll();
    }
    searchScreenName = (e) => {
        if ((e.target.value != "")) {
            let screenList = [];
            for (let i = 0; i < this.state.screenTempList.length; i++) {
                let s = this.state.screenTempList[i]
                for (let j = 0; j < s.tags.length; j++) {
                    if (s.tags[j] && s.tags[j].tag.toUpperCase().includes(e.target.value.toUpperCase())) {
                        screenList.push(s)
                        break;
                    }
                }
            }
            let screenNameSort = this.state.screenTempList.filter((s) => s.name.toUpperCase().includes(e.target.value.toUpperCase()));
            let screenNameList = screenList.concat(screenNameSort);
            this.setState({ screenList: this.removeDuplicates(screenNameList) });

        } else
            this.setState({ screenList: this.state.screenTempList })
    }
    removeDuplicates = (arr) => {
        const uniqueArray = [];
        const idTracker = {};

        for (let obj of arr) {
            const id = obj._id;
            if (!idTracker[id]) {
                uniqueArray.push(obj);
                idTracker[id] = true;
            }
        }
        return uniqueArray;
    }
    onSearchContent = (searchContent) => {
        this.setState({ searchContent });
        if (searchContent != "") {
            if (this.state.selectedTab.isrecent) {
                this.setState({ mediaList: this.state.mediaList.filter((s) => s.name.toUpperCase().includes(searchContent.toUpperCase())) })
            } else if (this.state.selectedTab.isMedia) {
                this.setState({ mediaAllList: this.state.mediaAllList.filter((s) => s.name.toUpperCase().includes(searchContent.toUpperCase())) })
            } else if (this.state.selectedTab.isLinks) {
                this.setState({ linkList: this.state.linkAllList.filter((s) => s.name.toUpperCase().includes(searchContent.toUpperCase())) })
            } else if (this.state.selectedTab.isApps) {
                this.setState({ appList: this.state.appAllList.filter((s) => s.name.toUpperCase().includes(searchContent.toUpperCase())) })
            }
        } else {
            this.setState({
                mediaList: this.state.selectedTempRecent, mediaAllList: this.state.selectedTempMedia,
                linkList: this.state.linkAllList, appList: this.state.appAllList
            })
        }
    }
    searchMediaList = (term, listName, listNameAll) => {
        const filtered = this.state[listNameAll].filter((item) =>
            item.name.toLowerCase().includes(term.toLowerCase())
        );
        this.setState({ [listName]: filtered, searchMediaName: term })

        if (term == "")
            this.setState({ [listName]: this.state[listNameAll] })

    }
    handleTimeChange = (newTime, i) => {
        const userRole = JSON.parse(localStorage.getItem("roles"));
        userRole.playList["editDuration"].write && this.onMediaTimeChange(newTime, i)
    };
    updateSettingDuration = debounce(async (value, type) => {
        const userRole = JSON.parse(localStorage.getItem("roles"));

        if (!userRole.playList["setting"].write)
            return

        if (value === "00:00:00") {
            toast.error("You can't set the duration to 00:00:00", {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
            this.state.selectedPlayListInfo.defaultTime[type] = this.state.cloneOfPlayList.defaultTime[type]; 
            this.setState({ selectedPlayListInfo: this.state.selectedPlayListInfo })
            return;
        }
        this.state.selectedPlayListInfo.defaultTime[type] = value; this.setState({ selectedPlayListInfo: this.state.selectedPlayListInfo })
        this.updatePlayList()
    }, 1000)
    render() {
        const userRole = JSON.parse(localStorage.getItem("roles"));
        let EmptyPlaylist = () => (
            <div className="flex flex-col flex-wrap">
                <img className="w-1/2 h-1/2 self-center" src={process.env.PUBLIC_URL + "/emptyPlaylist.svg"} alt={"Lodding..."} />
                <div className="self-center text-center text-black font-semibold text-xl"><p>This playlist needs feeding</p><br /></div>
                <div className="self-center text-center"><p>Drag or add content from your library in the right sidebar.<br /> You can move things up or down in order and change the duration.</p>
                </div>
            </div>
        );
        return (
            <div className="flex">
                <Sidebar type={"playlist"} />
                <div className="h-screen grow md:overflow-hidden overflow-auto md:hover:overflow-auto">
                    <Navbar router={this.props.router} counterSlice={this.props.counterSlice} />
                    <div className="flex bg-white border-b-1 border-slate-300 w-full ">
                        <div className="flex-none p-3 border-1"
                            onClick={() => this.props.router.navigate("/playlist")}>
                            <MdArrowBackIos size={20} style={{ marginLeft: "12px", marginTop: "8px", color: "#474747" }} />
                        </div>
                        <div className="px-3 flex self-center flex-col font-bold grow">
                            <InputFieldWithEdit
                                isEdit={this.state.isPlaylistNameEdit}
                                isRead={userRole.playList["rename"].read}
                                onKeyDown={(e) => {
                                    if (userRole.playList["rename"].write) {
                                        if (this.state.playListName.trim() !== "" && (e.key === "Enter" || e.key === "Tab" || e.key === undefined)) {
                                            this.state.playListName = this.state.playListName.trim();
                                            this.setState({ isPlaylistNameEdit: false });
                                            this.updatePlaylistName();
                                        } else
                                            this.setState({ isPlaylistNameEdit: true });
                                    }
                                }}
                                inputValue={this.state.playListName}
                                placeholder="Enter playlist name"
                                onInputchange={(e) => userRole.playList["rename"].write && this.setState({ playListName: e.target.value })}
                                onEditClick={() => { userRole.playList["rename"].write && this.setState({ isPlaylistNameEdit: !this.state.isPlaylistNameEdit }) }} />
                            {!this.state.isUpdating && <span className="text-gray-400 text-sm font-normal">{this.state.selectedPlayListInfo.modifiedOn && format(new Date(this.state.selectedPlayListInfo.modifiedOn), 'dd/MM/yyyy HH:mm')}, modified by {this.state.selectedPlayListInfo.modifiedBy && this.state.selectedPlayListInfo.modifiedBy.firstName + " " + this.state.selectedPlayListInfo.modifiedBy.lastName}</span>}
                        </div>
                        {this.state.isUpdating && <div className="self-center p-2">
                            Updating Playlist...
                        </div>}
                        <div className="flex basis-96 self-center gap-3	">
                            {userRole.playList["setToScreen"].read && <button className="self-center" onClick={userRole.playList["setToScreen"].write && this.onSetToScreenClick} >
                                <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.6708 17.5491L17.9324 15.2365C17.9557 15.2065 17.9907 15.1886 18.0253 15.1827C18.0774 15.1768 18.1243 15.2007 18.1592 15.2365L20.4207 17.5491M21.7694 18.9294L22.5017 19.6763C22.6005 19.7721 22.5191 19.9691 22.3853 19.9691H20.525V25.2813C20.525 25.371 20.4494 25.4545 20.3621 25.4545C20.2749 25.4545 20.1934 25.3712 20.1934 25.2813V19.802C20.1934 19.7124 20.2749 19.6287 20.3621 19.6287H21.9843L18.0428 15.589L14.1009 19.6282H15.7228C15.81 19.6282 15.8915 19.7119 15.8915 19.8014V25.2808C15.8915 25.3705 15.8102 25.454 15.7228 25.454C15.6354 25.454 15.5599 25.3706 15.5599 25.2808V19.969H13.7054C13.5718 19.969 13.4904 19.7719 13.5892 19.6762L14.3218 18.9292M15.3393 17.8896L14.6474 18.5947M21.4437 18.5949L20.7518 17.8897M13.5395 23.6364H6V10H30V23.6364H22.0658M13.5 22.9091H6.81818V10.6364H29.2727V22.9091H22.0455" stroke="black" strokeWidth="0.212638" />
                                    <rect x="0.5" y="0.5" width="34" height="34" rx="3.5" stroke="#DCDCDC" />
                                </svg>
                            </button>}
                            {userRole.playList["preview"].read && <button className="self-center p-2 rounded-lg text-darkBlue border-solid border-1  shadow-sm border-darkBlue hover:bg-neutral-300" disabled={this.state.selectedPlayList.length <= 0} onClick={userRole.playList["preview"].write && this.onPreview} >
                                {this.state.isPreview ? "EDIT" : "PREVIEW"}
                            </button>}
                            {userRole.playList["publish"].read && <Button
                                disabled={this.state.selectedPlayListInfo.status !== "DRAFT" || this.state.selectedPlayList.length === 0}
                                className="self-center p-2  rounded-lg border-solid border-1 text-white bg-darkBlue shadow-sm" onClick={userRole.playList["publish"].write && this.publishPlayList}>
                                {"PUBLISH"}
                            </Button>}
                            {userRole.playList["deletePlayList"].read && <button style={{ marginRight: "15px" }} onClick={() => userRole.playList["deletePlayList"].write && this.onClickDeletePlayList(this.state.selectedPlayListInfo)}>
                                <svg width="40" height="40" viewBox="0 0 24 24"
                                    style={{ border: "1px solid #C4C4C4", borderRadius: "3px", padding: "7px", fontSize: "25px" }}
                                    fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.0226 18.5346C17.0043 18.9706 16.6306 19.3282 16.1934 19.3282H7.81411C7.37684 19.3282 7.00293 18.9699 6.98488 18.5346L6.50467 7.01029C6.49864 6.86581 6.37662 6.75366 6.23215 6.75969C6.08767 6.76572 5.97534 6.88773 5.98136 7.03221L6.46158 18.5565C6.49154 19.2723 7.0961 19.8521 7.81414 19.8521H16.1934C16.9111 19.8521 17.516 19.2734 17.546 18.5565L18.0262 7.03221C18.0322 6.88773 17.9199 6.76572 17.7754 6.75969C17.6309 6.75366 17.5089 6.86581 17.5029 7.01029L17.0226 18.5346ZM11.7416 17.4953C11.7416 17.6399 11.8589 17.7572 12.0035 17.7572C12.1482 17.7572 12.2655 17.6399 12.2655 17.4953V8.5923C12.2655 8.44763 12.1482 8.33037 12.0035 8.33037C11.8589 8.33037 11.7416 8.44763 11.7416 8.5923V17.4953ZM9.12328 17.5029C9.12748 17.6476 9.24821 17.7612 9.39269 17.757C9.53735 17.7528 9.65096 17.6321 9.64676 17.4876L9.38484 8.58463C9.38063 8.44015 9.26008 8.32636 9.11542 8.33074C8.97095 8.33494 8.85715 8.45549 8.86135 8.60015L9.12328 17.5029ZM14.3603 17.4876C14.3561 17.6321 14.4697 17.7528 14.6144 17.757C14.7589 17.7612 14.8796 17.6476 14.8838 17.5029L15.1457 8.59997C15.1499 8.4553 15.0361 8.33475 14.8917 8.33055C14.747 8.32617 14.6264 8.43996 14.6222 8.58444L14.3603 17.4876ZM14.7994 5.44988H18.5498C18.6945 5.44988 18.8118 5.56715 18.8118 5.71181C18.8118 5.85647 18.6945 5.97374 18.5498 5.97374H5.45724C5.31258 5.97374 5.19531 5.85647 5.19531 5.71181C5.19531 5.56715 5.31258 5.44988 5.45724 5.44988H9.20766L9.42209 4.91398C9.59616 4.4789 10.0957 4.14062 10.5633 4.14062H13.4438C13.9128 4.14062 14.4104 4.47762 14.585 4.91398L14.7994 5.44988ZM14.2354 5.44988L14.0988 5.1085C14.0036 4.87087 13.6986 4.66447 13.4438 4.66447H10.5633C10.3098 4.66447 10.0029 4.87215 9.9083 5.1085L9.77168 5.44988H14.2354Z" fill="#800001" />
                                </svg>
                            </button>}
                        </div>
                    </div>
                    {!this.state.isPreview ?
                        <DragDropContext onDragEnd={this.handleDragEnd} >
                            <div className="flex height90vh">
                                <div className="flex-1 flex-col overflow-y-auto grow h-full">
                                    <Droppable droppableId="mediaPlayList">
                                        {(provider) => (
                                            <div className="mx-10 px-10" ref={provider.innerRef} {...provider.droppableProps}>
                                                <div className="flex mt-3 ">
                                                    <div className="grow flex flex-col">
                                                        <span className="text-sm text-gray-700">Total Duration</span>
                                                        <span className="text-xl font-semibold">{this.state.totalDuration}</span>
                                                    </div>
                                                    {userRole.playList["addContent"].read && <button className="flex text-darkBlue cursor-pointer hover:underline p-2"
                                                        onClick={() => userRole.playList["addContent"].write ? this.addContentModel() : null} >
                                                        <FaPlus className="self-end m-1 self-center" size={12} />
                                                        <span className="self-end self-center">{"Add Content"}</span>
                                                    </button>}
                                                </div>
                                                {this.state.selectedPlayList.length <= 0 && <EmptyPlaylist />}
                                                <div className="overflow-y-auto"> {this.state.selectedPlayList?.map((mpl, i) => {
                                                    return (
                                                        <Draggable
                                                            key={"id_" + i + "_" + mpl._id}
                                                            draggableId={"id_" + i + "_" + mpl._id}
                                                            index={i}>
                                                            {(mplProvider) => (
                                                                <div className="flex border-1 m-1 p-2 bg-white hover:shadow-md justify-between rounded-lg group"{...mplProvider.dragHandleProps} {...mplProvider.draggableProps} ref={mplProvider.innerRef}>
                                                                    <div className="flex justify-between">
                                                                        <FaBars size={18} className="self-center text-gray-400 mr-2" />
                                                                        {mpl.contentType && mpl.contentType.includes("video") ? <div
                                                                            className="rounded-lg flex p-2 border-solid border-1 w-10 h-10">
                                                                            <FaFilm className="self-center" size={30} opacity={0.8} /></div> :
                                                                            mpl.type === "LINK" ? <div className={"rounded-lg cursor-pointer self-center border-solid border-1 p-2"}
                                                                                onClick={(l) => { this.setState({ selectedLink: l, showLinkModel: true }) }}>
                                                                                <Link className="self-center w-8 h-8" />
                                                                            </div> : mpl.type === "APP" ? <div className="rounded-lg self-center border-solid border-1 p-1">
                                                                                <AppIcon style={"self-center fill-black w-7 h-7"} type={mpl.appType} />
                                                                            </div> :
                                                                                <img className="rounded-lg border-solid border-1 w-10 h-10" src={mpl.url} alt="loadding..." />}
                                                                        <div className="ml-2 self-center xl:w-36 lg:w-24 w-10 inline-block truncate flex flex-col" title={mpl.name}>
                                                                            <span className="text-black text-sm truncate">{mpl.name}</span>
                                                                            <span className="text-gray-450 italic text-xs">{mpl.type === "LINK" ? "Link" : mpl.type === "APP" ? "APP" : mpl.contentType.includes("video") ? "Video" : "Image"}</span>
                                                                        </div>
                                                                    </div>
                                                                    {/* <TimeInput/> */}
                                                                    {userRole.playList["editDuration"].read &&
                                                                        <InputDuration value={mpl.duration ? mpl.duration : "00:00:00"}
                                                                            isDisabled={(mpl.contentType && mpl.contentType.includes("video")) || (mpl.type === "APP" && mpl.appType == "YOUTUBE")}
                                                                            onChange={(value) => this.handleTimeChange(value, i)} />}
                                                                    {userRole.playList["mediaLogs"].read && <div className="self-center">
                                                                        <TooltipComponent content={"Toggle will be tracking all media logs."} >
                                                                            <ToggalButton value={mpl.isAuditLog} label={"Logs"} customFunction={() => userRole.playList["mediaLogs"].write && this.addAuditLogs(mpl.isAuditLog, i)} />
                                                                        </TooltipComponent></div>}
                                                                    <div className="flex basis-28 self-center justify-end" >
                                                                        {userRole.playList["duplicateMedia"].read && <IconButton icon={<svg className="self-center fill-gray-600	text-gray-800" width="20" height="20" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M24.0016 6H9.97266V20.0289H24.0016V6ZM23.3858 19.4817L10.4583 19.4465V6.51342L23.3858 6.54849V19.4817Z" fill="#979797" />
                                                                            <path d="M19.0289 21.6162H18.55V24.4057H5.60105V11.4567H8.40958V10.9707H5V24.9996H19.0289V21.6162Z" fill="#979797" />
                                                                        </svg>} toolTip="Duplicate" customFunc={() => userRole.playList["duplicateMedia"].write && this.addDuplicate(mpl, i)} />}
                                                                        {userRole.playList["deleteMedia"].read && <IconButton icon={<FaTimes size={20} />} toolTip="Delete" customFunc={() => userRole.playList["deleteMedia"].write && this.removeSelectedPlayList(i)} />}
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    )
                                                })}</div>
                                                {provider.placeholder}
                                            </div>)}
                                    </Droppable>
                                </div>
                                <div className="basis-96 border-l-1 border-slate-300 bg-white overflow-y-auto" style={{ height: "90vh" }}>
                                    <div className="flex text-center">
                                        <div className={"flex-1"}  >
                                            <button
                                                className={this.state.content ? "p-2 text-currentSecondaryColor" : "p-2"}
                                                onClick={() => this.onTabChange("content")}>Content</button>
                                            {this.state.content && <hr className="border-4 rounded-lg mx-3" style={{ borderColor: "#00807F", opacity: "inherit" }} />}
                                        </div>
                                        {userRole.playList["setting"].read && <div className="flex-1" >
                                            <button
                                                className={this.state.setting ? "p-2 text-currentSecondaryColor" : "p-2"}
                                                onClick={() => this.onTabChange("setting")} >Settings</button>
                                            {this.state.setting && <hr className="border-4 rounded-lg mx-3" style={{ borderColor: "#00807F", opacity: "inherit" }} />}
                                        </div>}
                                    </div>
                                    <hr />

                                    {this.state.content &&
                                        <>
                                            <div className="bg-white-100 flex content-center self-center mx-4 my-2 p-2 text-black rounded-lg">
                                                <InputGroup className="my-2">
                                                    <Form.Control
                                                        placeholder="Search Content"
                                                        value={this.state.searchContent}
                                                        onChange={(e) => this.onSearchContent(e.target.value)}
                                                    />

                                                </InputGroup>
                                            </div>
                                            <div className="bg-white-100 mx-4 my-2 p-2 text-black rounded-lg">
                                                <div className="flex p-2 justify-center text-xs font-medium">
                                                    <button className={this.state.selectedTab.isrecent ?
                                                        "border-1 w-20 bg-gray-300 rounded-l-lg p-1 text-black" :
                                                        "border-1 w-20 bg-white rounded-l-lg p-1 text-black"}
                                                        onClick={() => this.onMediaContentTabChange("isrecent")}
                                                    >
                                                        Favourite
                                                    </button>
                                                    <button onClick={() => this.onMediaContentTabChange("isMedia")}
                                                        className={
                                                            this.state.selectedTab.isMedia ? "border-1 w-20 text-xs  bg-gray-300  p-1 text-black" : "border-1 w-20 text-xs  bg-white  p-1 text-black"}>
                                                        Media
                                                    </button><button className={
                                                        this.state.selectedTab.isLinks ? "border-1 w-20 text-xs  bg-gray-300  p-1 text-black" : "border-1 w-20 text-xs  bg-white  p-1 text-black"}
                                                        onClick={() => this.onMediaContentTabChange("isLinks")}>
                                                        Link
                                                    </button>
                                                    <button className={
                                                        this.state.selectedTab.isApps ? "border-1 w-20 text-xs  bg-gray-300  p-1 text-black rounded-r-lg" : "border-1 w-20 text-xs  bg-white  p-1 text-black rounded-r-lg"}
                                                        onClick={() => this.onMediaContentTabChange("isApps")}>
                                                        Apps
                                                    </button>

                                                </div>
                                                {this.state.selectedTab.isrecent && <Droppable droppableId="recentMediaList">
                                                    {(provider) => (
                                                        <div ref={provider.innerRef}
                                                            {...provider.droppableProps}>
                                                            <span className="my-3 text-sm">All</span>
                                                            {this.state.mediaList?.map((media, index) => (
                                                                <Draggable
                                                                    key={"id-" + media.id}
                                                                    draggableId={"id-" + media._id}
                                                                    index={index}>
                                                                    {(provider) => (
                                                                        <div className="flex mx-1 border-gray-450 pb-1 border-b-1 my-2 truncate"{...provider.dragHandleProps} {...provider.draggableProps} ref={provider.innerRef}>
                                                                            <div className="flex flex-1" >
                                                                                {media.type === "LINK" ?
                                                                                    <div className=" self-center">
                                                                                        <div className={"rounded-2xl flex p-2 bg-white border-dashed border-1 "}>
                                                                                            <Link style="self-center" />
                                                                                        </div>
                                                                                    </div> : media.type === "APP" ? <AppIcon style={"self-center fill-black w-8 h-8"} type={media.appType} /> : media.contentType.includes("video") ? <div className="rounded-2xl bg-white flex p-2 border-dashed border-1 w-10 h-10">
                                                                                        <FaFilm className="self-center" size={25} opacity={0.8} /></div> :
                                                                                        <img className="rounded-2xl border-solid self-center border-1 w-10 h-10" src={media.url} alt="loadding..." />
                                                                                }
                                                                                <div className="ml-3 self-center flex flex-col ">
                                                                                    <div className="w-20 xl:w-52 lg:w-40 md:w-32 inline-block truncate text-sm"><span className="truncate">{media.name}</span></div>
                                                                                    <span className="text-gray-450 italic text-xs">{media.type === "LINK" ? "Link" : media.type === "APP" ? "App" : media.contentType.includes("video") ? "Video" : "Image"}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </Draggable>
                                                            )
                                                            )}
                                                            {provider.placeholder}
                                                        </div>)}
                                                </Droppable>}
                                                {this.state.selectedTab.isMedia &&
                                                    <Droppable droppableId="mediaList">
                                                        {(provider) => (
                                                            <div ref={provider.innerRef}
                                                                {...provider.droppableProps}>
                                                                <Breadcrumb className="p-0 m-0 text-sm">
                                                                    {this.state.pathList.map((pl, i) => (
                                                                        <Breadcrumb.Item key={i} className={!pl.isActive ? "text-gray-800 hover:bg-gray-200 p-0 m-0" :
                                                                            "text-black hover:bg-gray-200 p-0 m-0"} onClick={() => this.onBreadCrumbChnage(pl, i)}>{pl.folderName}</Breadcrumb.Item>
                                                                    ))}
                                                                </Breadcrumb>
                                                                {this.state.mediaAllList?.map((media, index) => (
                                                                    <Draggable
                                                                        key={"id-" + media.id}
                                                                        draggableId={"id-" + media._id}
                                                                        index={index}>
                                                                        {(provider) => (
                                                                            <div className="flex mx-1  border-gray-450 pb-1 border-b-1 my-2 "{...provider.dragHandleProps} {...provider.draggableProps} ref={provider.innerRef}>
                                                                                <div className="flex flex-1" >
                                                                                    {media.type === "FOLDER" ? <div className=" self-center">
                                                                                        <div className={"rounded-lg cursor-pointer bg-white self-center border-solid border-1 p-2"} onClick={() => this.selectedMediaFile(media, index)}>
                                                                                            <FaFolder className="self-center" />
                                                                                        </div>
                                                                                    </div> : media.contentType.includes("video") ? <div className="rounded-2xl flex p-2 border-dashed bg-white border-1 w-10 h-10">
                                                                                        <FaFilm className="self-center" size={25} opacity={0.8} /></div> :
                                                                                        <img className="rounded-2xl border-solid self-center border-1 w-10 h-10" src={media.url} alt="loadding..." />
                                                                                    }
                                                                                    <div className="ml-2 self-center flex flex-col " onClick={() => media.type === "FOLDER" && this.selectedMediaFile(media, index)}>
                                                                                        <div className="w-20 xl:w-52 lg:w-40 md:w-32 inline-block truncate"><span className="text-black text-sm">{media.name}</span></div>
                                                                                        <span className="text-gray-450 italic text-xs">{media.type === "FOLDER" ? "Folder" : media.contentType && media.contentType.includes("video") ? "Video" : "Image"}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </Draggable>)
                                                                )}
                                                                {provider.placeholder}
                                                            </div>)}
                                                    </Droppable>}
                                                {this.state.selectedTab.isLinks && <Droppable droppableId="linkInfo">
                                                    {(provider) => (
                                                        <div ref={provider.innerRef}
                                                            {...provider.droppableProps}>
                                                            <span className="my-3 text-sm">Links</span>
                                                            {this.state.linkList?.map((media, index) => (
                                                                <Draggable
                                                                    key={"id-" + media.id}
                                                                    draggableId={"id-" + media._id}
                                                                    index={index}>
                                                                    {(provider) => (
                                                                        <div className="flex border-gray-450 pb-1 border-b-1 mx-1 my-2 truncate"{...provider.dragHandleProps} {...provider.draggableProps} ref={provider.innerRef}>
                                                                            <div className="flex basis-32" >
                                                                                <div className=" self-center ">
                                                                                    <div className={"rounded-lg flex p-2 bg-white border-solid border-1 "} onClick={() => { }}>
                                                                                        <Link style="self-center" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="ml-2 self-center flex flex-col">
                                                                                    <div className="w-20 xl:w-52 lg:w-40 md:w-32 inline-block truncate text-sm"><span className="truncate">{media.name}</span></div>
                                                                                    <span className="text-gray-450 italic text-xs">{format(new Date(media.createdOn), 'dd/MM/yyyy HH:mm')}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </Draggable>
                                                            )
                                                            )}
                                                            {provider.placeholder}
                                                        </div>)}
                                                </Droppable>}
                                                {this.state.selectedTab.isApps && <Droppable droppableId="appDropInfo">
                                                    {(provider) => (
                                                        <div ref={provider.innerRef}
                                                            {...provider.droppableProps}>
                                                            <span className="my-3 text-sm">App's</span>
                                                            {this.state.appList?.map((media, index) => (
                                                                <Draggable
                                                                    key={"id-" + media.id}
                                                                    draggableId={"id-" + media._id}
                                                                    index={index}>
                                                                    {(provider) => (
                                                                        <div className="flex border-gray-450 pb-1 border-b-1 mx-1 my-2 truncate"{...provider.dragHandleProps} {...provider.draggableProps} ref={provider.innerRef}>
                                                                            <div className="flex basis-32" >
                                                                                <div className=" self-center ">
                                                                                    <div className={"rounded-lg border-solid self-center border-1 flex p-1 bg-dark-team "}>
                                                                                        <AppIcon style={"self-center h-6 w-6 " + (media.appType === "MEET_TEAM" ? "fill-black" : "fill-white")} type={media.appType} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="ml-2 self-center flex flex-col ">
                                                                                    <div className="w-20 xl:w-52 lg:w-40 md:w-32 inline-block truncate text-sm"><span className="truncate">{media.name}</span></div>
                                                                                    <span className="text-gray-450 italic text-xs">{media.appType}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </Draggable>
                                                            )
                                                            )}
                                                            {provider.placeholder}
                                                        </div>)}
                                                </Droppable>}
                                            </div>
                                        </>}

                                    {this.state.setting && <div className="flex-col mx-4 my-2 p-2">
                                        <div className="bg-white-100 text-black p-2 rounded-lg h-1/2">
                                            <span className="flex justify-between p-2">
                                                <span className="text-sm">Default Duration </span>
                                                <OverlayTrigger
                                                    trigger="hover"
                                                    rootClose
                                                    className="rounded-none"
                                                    placement="left" overlay={
                                                        <Popover className="rounded-none" style={{ width: "176px" }}>
                                                            <Popover.Body className="p-1 m-1 bg-white rounded-none">
                                                                <div className="flex flex-wrap text-xs font-medium">
                                                                    Set default duration to all content added into current playlist
                                                                </div>
                                                            </Popover.Body>
                                                        </Popover>}>
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <circle cx="10" cy="10" r="8.72727" stroke="#1B438F" strokeWidth="1.45455" />
                                                        <path d="M9.47496 6.44984V5.9193H9.84144V6.44984H9.47496ZM9.47496 14.1V8.37416H9.84144V14.1H9.47496Z" fill="#1B438F" stroke="#1B438F" strokeWidth="0.727273" />
                                                    </svg>
                                                </OverlayTrigger>

                                            </span>
                                            <div className="flex p-1 justify-between text-sm">
                                                <span className="self-center pl-2 grow">Images</span>
                                                <InputDuration value={this.state.selectedPlayListInfo.defaultTime.imageDuaration} onChange={(value) => this.updateSettingDuration(value, "imageDuaration")} />
                                            </div>

                                            <hr />
                                            <div className="flex p-1 justify-between text-sm">
                                                <span className="self-center pl-2 grow">Link</span>
                                                <InputDuration value={this.state.selectedPlayListInfo.defaultTime.linkDuaration} onChange={(value) => this.updateSettingDuration(value, "linkDuaration")} />
                                            </div>
                                            <hr />

                                            <div className="flex p-1 justify-between text-sm">
                                                <span className="self-center pl-2 grow">APPS</span>
                                                <InputDuration value={this.state.selectedPlayListInfo.defaultTime.appDuaration} onChange={(value) => this.updateSettingDuration(value, "appDuaration")} />
                                            </div>
                                        </div>
                                    </div>}
                                </div>
                            </div></DragDropContext> : <ShowPreview previewList={this.state.selectedPlayList} />}
                </div>
                <Modal show={this.state.addContent}
                    size="lg"
                    centered>
                    <Modal.Body className="m-0 p-0 rounded-md">
                        <div className="flex m-3 " >
                            <h1 id="medpic" className="grow text-xl font-semibold" >Media Picker</h1>
                            <FaTimes onClick={() => this.setState({ addContent: false, tempMediaList: [] })} size={25} className="text-gray-400 hover:text-gray-600" />
                        </div>
                        <hr />
                        <div className="flex" style={{ height: "75vh" }}>
                            <div className="basis-1/5 bg-white" >
                                <br />
                                <AddContentButtons isSelected={this.state.addContentSlider.isMedia} customFunction={() => this.onContentTabChange("isMedia")} label="Media" icon={<Mediaa className="self-center mr-2" size={20} />} />
                                <AddContentButtons isSelected={this.state.addContentSlider.isLinks} customFunction={() => this.onContentTabChange("isLinks")} label="Links" icon={<Link style="self-center mr-2" size={20} />} />
                                <AddContentButtons isSelected={this.state.addContentSlider.isApps} customFunction={() => this.onContentTabChange("isApps")} label="Apps" icon={<Appss className="self-center mr-2" size={20} />} />
                            </div>
                            <div className="flex-auto" style={{ backgroundColor: "#F3F6FA", paddingTop: "12px", paddingLeft: "20px", paddingRight: "20px" }}>
                                {this.state.addContentSlider.isMedia ?
                                    <div>
                                        <Breadcrumb className="px-2">
                                            {this.state.pathList.map((pl, i) => (
                                                <Breadcrumb.Item key={i} className={!pl.isActive ? "text-gray-800" :
                                                    "text-black"} onClick={() => this.onBreadCrumbChnage(pl, i)}>{pl.folderName}</Breadcrumb.Item>
                                            ))}
                                        </Breadcrumb>
                                        <ChannelAddContent selectedMediaFile={this.selectedMediaFile} mediaList={this.state.mediaAllList} routeTo={() => this.props.router.navigate("/media")} type="Media"
                                            searchMedia={this.state.searchMediaName} onMediasearch={(e) => this.searchMediaList(e.target.value, "mediaAllList", "mediaListData")} /> </div> :
                                    this.state.addContentSlider.isLinks ? <ChannelAddContent selectedMediaFile={this.selectedMediaFile} mediaList={this.state.linkList} routeTo={() => this.props.router.navigate("/links")} type="Link"
                                        searchMedia={this.state.searchMediaName} onMediasearch={(e) => this.searchMediaList(e.target.value, "linkList", "linkAllList")} /> :
                                        <ChannelAddContent selectedMediaFile={this.selectedMediaFile} mediaList={this.state.appList} routeTo={() => this.props.router.navigate("/app")} type="App"
                                            searchMedia={this.state.searchMediaName} onMediasearch={(e) => this.searchMediaList(e.target.value, "appList", "appAllList")} />
                                }
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="justify-end">
                        <div className="grow">{this.state.tempMediaList.length !== 0 && <p>Selected Files: {this.state.tempMediaList.length}</p>}</div>
                        {<Button variant="" className="hover:bg-gray-300" onClick={() => this.setState({ addContent: false, tempMediaList: [] })}>CANCEL</Button>}
                        {userRole.playList["editPlayList"].write && <Button variant="outline-primary" className="mr-2" id="addbtn" disabled={(this.state.tempMediaList.length !== 0) ? false : true}
                            onClick={() => this.addMediaPlayList()}>ADD</Button>}

                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.deletePlaylistModel} onHide={this.closeAll}>
                    <Modal.Header closeButton closeVariant={"#ffff"}>
                        <Modal.Title>Delete Playlist</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>Are you sure, you would like to delete this playlist permanently?</div>
                        <br />
                        {this.state.attachedList && this.state.attachedList.screenList && this.state.attachedList.screenList.length > 0 && <div>
                            <span className="text-gray font-bold text-sm">Attached to screen</span>
                            {this.state.attachedList.screenList?.map((s) => (
                                <div className="text-xs">- {s.name}</div>
                            ))}
                        </div>}
                        {this.state.attachedList && this.state.attachedList.channelList && this.state.attachedList.channelList.length > 0 && <div>
                            <span className="text-gray-500 font-bold text-sm">Attached to channel</span>
                            {this.state.attachedList.channelList?.map((c) => (
                                <div className="text-xs">- {c.name}</div>
                            ))}
                        </div>}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-pr" onClick={this.closeAll}>
                            Cancel
                        </Button>
                        <Button className="btn btn-prime" onClick={this.deletePlayList}>
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>
                <SetToScreenModel
                    setToScreenModel={this.state.setToScreenModel}
                    closeAll={this.closeAll}
                    searchScreenName={this.searchScreenName}
                    selectAllScreen={() => {
                        let isSelected = !this.state.selectAll ? true : false;
                        this.state.screenList.forEach((sl) => sl.isSelected = isSelected);
                        this.setState({ screenList: this.state.screenList, selectAll: !this.state.selectAll })
                    }}
                    isSelectAll={this.state.selectAll}
                    screenList={this.state.screenList}
                    routeTo={() => this.props.router.navigate("/screens")}
                    onScreenSelected={(s, i) => {
                        this.state.screenList[i].isSelected = !this.state.screenList[i].isSelected;
                        let isAllSelected = true;
                        this.state.screenList.forEach((si) => {
                            if (!si.isSelected)
                                isAllSelected = false;
                        })
                        this.setState({ screenList: this.state.screenList, selectAll: isAllSelected });
                    }}
                    setToScreenService={this.setToScreenService}
                />
                <ToastContainer />
            </div>
        )
    };
}

const mapStateToProps = (state) => ({
    isLoading: state.counter.isLoading,
    userRole: state.counter.userRole

});

const mapDispatchToProps = (dispatch) => ({
    counterSlice: bindActionCreators(commanActionCreator, dispatch)

})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PlayListDetailComponent));
