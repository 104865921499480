








import React from 'react';

const Currency = (props) => {
    let { style } = props;
    return (
        <svg width="30" height="30" className={style} viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.02957 5.02866C10.2147 -0.296625 18.7282 -0.506834 24.1936 4.50314H21.6361C21.3908 4.50314 21.2156 4.71335 21.2156 4.92356C21.2156 5.13377 21.4259 5.34398 21.6361 5.34398H25.2447C25.4899 5.34398 25.6651 5.13377 25.6651 4.92356V1.35001C25.6651 1.10477 25.4549 0.929593 25.2447 0.929593C25.0344 0.929593 24.7542 1.10477 24.7542 1.35001V3.83748C18.9384 -1.48781 9.93444 -1.24257 4.39895 4.39804C-0.540961 9.30291 -1.41683 16.9755 2.26182 22.8614C2.33189 23.0015 2.47203 23.0716 2.64721 23.0716C2.89245 23.0716 3.06762 22.8614 3.06762 22.6161C3.06762 22.5461 3.03259 22.441 2.99755 22.3709C-0.435857 16.8354 0.369943 9.65326 5.02957 5.02866Z"   />
            <path d="M27.6967 6.88492C27.5566 6.67471 27.3113 6.60464 27.1011 6.74478C26.8909 6.88492 26.8208 7.13017 26.961 7.34038C30.4294 12.9109 29.6236 20.1631 24.999 24.8227C19.8139 30.148 11.3004 30.3582 5.83499 25.3132H8.42756C8.67281 25.3132 8.84798 25.103 8.84798 24.8928C8.84798 24.6826 8.63777 24.4724 8.42756 24.4724H4.85401C4.60877 24.4724 4.43359 24.6826 4.43359 24.9278V28.5014C4.43359 28.7466 4.6438 28.9218 4.85401 28.9218C5.06422 28.9218 5.27443 28.7116 5.27443 28.5014V26.0139C11.0902 31.3392 20.0942 31.094 25.6297 25.4534C30.5696 20.5135 31.4104 12.8058 27.6967 6.88492Z"   />
            <path d="M22.7237 7.55147C22.3032 7.13105 21.8478 6.71063 21.3573 6.36029C21.1471 6.18511 20.9018 6.22015 20.7267 6.39532C20.5515 6.60553 20.5865 6.88581 20.8318 7.06098C21.2872 7.37629 21.7076 7.76168 22.093 8.14706C22.2682 8.32223 22.5485 8.32223 22.7237 8.14706C22.8988 7.97189 22.8988 7.72664 22.7237 7.55147Z"   />
            <path d="M21.6346 22.1609C21.2492 22.4762 20.8638 22.8266 20.3733 23.1419C20.1631 23.282 20.1281 23.5623 20.2332 23.7375C20.3032 23.8426 20.4434 23.9477 20.5835 23.9477C20.6536 23.9477 20.7587 23.9126 20.8288 23.8776C21.3543 23.5272 21.8097 23.1769 22.2652 22.7565C22.4404 22.5813 22.4404 22.301 22.2652 22.1259C21.9499 21.8456 21.6346 22.1609 21.6346 22.1609Z"   />
            <path d="M5.27511 18.0268C5.55539 17.9918 5.66049 17.7115 5.59042 17.5013C5.45028 16.9758 5.34518 16.4152 5.31014 15.8547C5.27511 15.6094 5.0649 15.4343 4.85469 15.4693C4.60945 15.5043 4.43427 15.7146 4.46931 15.9598C4.53938 16.5554 4.64448 17.151 4.78462 17.7466C4.78462 17.9217 4.99483 18.0619 5.27511 18.0268Z"   />
            <path d="M23.3189 20.9343C23.5642 21.0744 23.8444 20.9343 23.9145 20.7941C24.2649 20.2686 24.5451 19.7431 24.7904 19.1825C24.8955 18.9723 24.7553 18.692 24.5451 18.622C24.3349 18.5519 24.0897 18.622 23.9846 18.8322C23.7744 19.3577 23.4941 19.8482 23.1788 20.3036C23.0737 20.5139 23.0386 20.7591 23.3189 20.9343Z"   />
            <path d="M18.272 24.1569C17.7464 24.3321 17.1859 24.4722 16.6604 24.5773C16.4151 24.6124 16.275 24.8576 16.31 25.1028C16.3801 25.5233 16.8005 25.4532 16.8005 25.4532C17.3961 25.3481 17.9917 25.2079 18.5522 24.9977C18.7975 24.9277 18.9026 24.6824 18.8325 24.4372C18.7274 24.227 18.4822 24.0868 18.272 24.1569Z"   />
            <path d="M24.6146 11.8251C24.6847 11.8251 24.7197 11.8251 24.7547 11.79C24.9649 11.72 25.1051 11.4397 25 11.2295C24.7898 10.6689 24.5095 10.1084 24.2292 9.58284C24.1241 9.37263 23.8438 9.30256 23.6336 9.40766C23.4234 9.51277 23.3533 9.79305 23.4584 10.0033C23.7387 10.4937 23.984 10.9842 24.1591 11.5098C24.2642 11.72 24.4394 11.8251 24.6146 11.8251Z"   />
            <path d="M25.5257 16.765C25.6308 16.1694 25.6659 15.5738 25.6659 14.9432C25.6659 14.5228 25.6308 14.1374 25.5958 13.717C25.5608 13.4718 25.3506 13.2966 25.1053 13.3316C24.8601 13.3666 24.6849 13.5769 24.7199 13.8221C24.755 14.2075 24.79 14.5578 24.79 14.9432C24.79 15.5038 24.755 16.0643 24.6499 16.6249C24.6148 16.8701 24.755 17.0803 25.0002 17.1154C25.3155 17.1504 25.4907 16.9752 25.5257 16.765Z"   />
            <path d="M9.05694 22.6861C8.84673 22.5459 8.60149 22.581 8.42632 22.7562C8.28618 22.9664 8.32121 23.2116 8.49639 23.3868C8.98687 23.7722 9.47736 24.0875 10.0379 24.3678C10.108 24.4028 10.4233 24.5429 10.6335 24.1926C10.8788 23.8072 10.4583 23.597 10.4583 23.597C9.96785 23.3167 9.51239 23.0364 9.05694 22.6861Z"   />
            <path d="M9.51561 6.85055C9.97106 6.53524 10.4616 6.25496 10.952 6.00971C11.1973 5.90461 11.3374 5.6944 11.2323 5.44916C11.0221 5.02874 10.7068 5.16888 10.6017 5.20391C10.0411 5.44916 9.51561 5.76447 9.02513 6.11482C8.81492 6.25496 8.77988 6.53524 8.92002 6.71041C9.06016 6.99069 9.37547 6.95565 9.51561 6.85055Z"   />
            <path d="M6.74614 9.75837C7.06145 9.26788 7.37677 8.84746 7.76215 8.42705C7.93732 8.25187 7.93732 7.97159 7.76215 7.79642C7.58697 7.62125 7.3067 7.62125 7.13152 7.79642L7.09649 7.83145C6.67607 8.28691 6.32572 8.77739 6.01041 9.30292C5.87027 9.51312 5.94034 9.7934 6.15055 9.89851C6.36076 10.0386 6.64104 9.89851 6.74614 9.75837Z"   />
            <path d="M5.45127 11.3006C5.20602 11.2305 4.96078 11.3356 4.89071 11.5809C4.71554 12.1765 4.5754 12.7721 4.50533 13.3677C4.47029 13.6129 4.61043 13.8231 4.85568 13.8581C4.85568 13.8581 5.27609 13.9282 5.34616 13.4728C5.41623 12.9122 5.55637 12.3867 5.73155 11.8261C5.80162 11.6159 5.69651 11.4057 5.45127 11.3006Z"   />
            <path d="M13.1911 5.30898C13.7517 5.20387 14.3122 5.1338 14.8728 5.1338C15.118 5.1338 15.2932 4.92359 15.2932 4.71339C15.2932 4.50318 15.083 4.29297 14.8728 4.29297C14.2772 4.29297 13.6466 4.36304 13.051 4.46814C12.8057 4.50318 12.6656 4.74842 12.7006 4.99366C12.7707 5.37905 13.1911 5.30898 13.1911 5.30898Z"   />
            <path d="M6.71286 21.6004C6.78293 21.7056 7.02817 21.8457 7.34348 21.6705C7.69383 21.4253 7.41355 21.0399 7.41355 21.0399C7.0632 20.5844 6.74789 20.129 6.50265 19.6385C6.39754 19.4283 6.11726 19.3582 5.90706 19.4633C5.69685 19.5684 5.62678 19.8487 5.73188 20.0589C5.97713 20.5844 6.32747 21.11 6.71286 21.6004Z"   />
            <path d="M12.6686 24.438C12.4233 24.3679 12.2131 24.508 12.143 24.7533C12.073 24.9985 12.2131 25.2087 12.4584 25.2788C13.0539 25.4189 13.6495 25.524 14.2451 25.5591C14.2451 25.5591 14.6655 25.5941 14.7006 25.1036C14.7356 24.8934 14.5254 24.7182 14.3152 24.6832C13.7546 24.6482 13.1941 24.5781 12.6686 24.438Z"   />
            <path d="M18.798 5.90451C18.868 5.93955 18.9031 5.93955 18.9731 5.93955C19.2184 5.93955 19.3935 5.72934 19.3935 5.48409C19.3935 5.30892 19.2884 5.16878 19.1133 5.09871C18.5527 4.85347 17.9571 4.67829 17.3615 4.53815C17.1163 4.46808 16.9061 4.64326 16.836 4.85347C16.7659 5.09871 16.9411 5.30892 17.1513 5.37899C17.7119 5.51913 18.2724 5.6943 18.798 5.90451Z"   />
            <path d="M13.4375 12.4911V13.402C13.4375 13.6472 13.7878 13.9275 13.9981 14.0676L14.6637 14.3129V11.5802H14.4185C13.928 11.5451 13.4725 11.9655 13.4375 12.4911Z"   />
            <path d="M15.5391 18.2369H15.6091C16.1347 18.2719 16.5901 17.8515 16.6251 17.326V16.4151C16.6251 16.2048 16.4149 15.9596 16.0296 15.7494L15.5391 15.5742V18.2369Z"   />
            <path d="M15.0163 7.23633C10.742 7.23633 7.30859 10.6697 7.30859 14.944C7.30859 19.2182 10.742 22.6516 15.0163 22.6516C19.2905 22.6516 22.7239 19.2182 22.7239 14.944C22.7239 10.6697 19.2905 7.23633 15.0163 7.23633ZM17.5037 12.912C17.5037 13.1572 17.2935 13.3324 17.0833 13.3324C16.8731 13.3324 16.6629 13.1222 16.6629 12.912V12.4915C16.6279 11.966 16.1724 11.5456 15.6469 11.5806H15.5768V14.6287L16.4527 14.944C17.1534 15.3644 17.5037 15.8549 17.5037 16.3804V17.2913C17.4687 18.3073 16.6279 19.1131 15.6118 19.0781H15.5418V19.3233C15.5418 19.5686 15.3316 19.7438 15.1214 19.7438C14.9111 19.7438 14.6659 19.5686 14.6659 19.3233V19.1131H14.4207C13.4046 19.1131 12.5638 18.3424 12.5638 17.3264V16.8709C12.5638 16.6257 12.774 16.4505 12.9842 16.4505C13.1944 16.4505 13.4046 16.6607 13.4046 16.8709V17.2913C13.4397 17.8168 13.8951 18.2373 14.4207 18.2022H14.6659V15.2243C14.6659 15.2243 13.6149 14.8389 13.5798 14.8389C12.8791 14.4185 12.5288 13.963 12.5288 13.4025V12.4565C12.5638 11.4405 13.4046 10.6347 14.4207 10.6697H14.6659V10.5646C14.6659 10.3194 14.8761 10.1442 15.0863 10.1442C15.2965 10.1442 15.5067 10.3544 15.5067 10.5646V10.7048H15.5768C16.5928 10.6697 17.4337 11.4755 17.4687 12.4915L17.5037 12.912Z"   />
        </svg>
    )
}

export default Currency;