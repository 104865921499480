import React from "react";
import { connect } from "react-redux";
import { withRouter } from "../router";
import { ToastContainer, toast } from 'react-toastify';
import { bindActionCreators } from "@reduxjs/toolkit";
import * as commanActionCreator from '../reducers/commanReduces'
import Navbar from "./subComponents/Navbar";
import { format } from 'date-fns'
import { DataIsEmptyWithoutButton, InputFieldWithEdit, InputFiledSelect } from "./utilComponents/inputFieldAndButtons";
import { MdArrowBackIos, MdStayCurrentLandscape, MdStayCurrentPortrait } from "react-icons/md";
import Currency from "../appIcons/Currency";
import { createApp, fetchAppData, fetchCurrencyData, fetchCurrencyList } from "../services/appServices";
import Select from 'react-select';
import { Button, Form } from "react-bootstrap";
import Multiselect from "multiselect-react-dropdown";
import { updateMedia } from "../services/mediaService";
import CurrencyPreview from "./subComponents/currencyPreview";

class CurrencyAppDetailsComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isNameEdit: false,
            appName: "CurrencyApp",
            isCity: true,
            isLandscape: true,
            baseCurrency: "",
            baseCurrencyName: "",
            title: "Exchange Rate",
            decimal: 0,
            duration: 4,
            elementsPerSlot: 8,
            currencyList: [],
            currenciesNameList: [],
            currencyDataList: {},
            currenciesList: [],
            currenciesSlotList: [],
            isUpdate: false
        }
    }
    async componentDidMount() {
        this.props.counterSlice.startLoading();
        let currencyList = await fetchCurrencyList();
        for (const key in currencyList) {
            this.state.currencyList.push({ id: key, name: currencyList[key], fullName: `(${key}) ` + currencyList[key] })
        }
        if (this.props.router.params.appId) {
            let appInfo = await fetchAppData(this.props.router.params.appId);
            this.setState({
                appInfo: appInfo.body,
                appName: appInfo.body.name,
                baseCurrency: appInfo.body.baseCurrency,
                title: appInfo.body.pageTitle,
                isUpdate: true,
                decimal: appInfo.body.decimal,
                currenciesNameList: appInfo.body.currencyList
            });
            let currencyDataList = await fetchCurrencyData(appInfo.body.baseCurrency);
            this.setState({ currencyDataList });
            appInfo.body.currencyList.forEach(a => this.state.currenciesList.push({ ...a, rate: currencyDataList.rates[a.id] }));
            let slotData = [];
            for (let i = 0; i < this.state.currenciesList.length; i += this.state.elementsPerSlot) {
                const slot = this.state.currenciesList.slice(i, i + this.state.elementsPerSlot);
                slotData.push(slot);
            }
            this.setState({ currenciesSlotList: slotData });

        }
        this.props.counterSlice.stopLoading();
    }
    updateCurrencyApp = async () => {
        if (this.state.appName.trim() == "") {
            toast.error('App name should not be empty.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
            return;
        }
        this.props.counterSlice.startLoading();
        let appDTO = {
            _id: this.state.appInfo._id,
            name: this.state.appName.trim(),
            pageTitle: this.state.title,
            baseCurrency: this.state.baseCurrency,
            currencyList: this.state.currenciesNameList,
            decimal: this.state.decimal,
        }
        let response = await updateMedia(appDTO);
        if (response.success) {

            toast.success('Currency app updated successfully.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        } else {
            toast.error('Error while updating currency app.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        }
        this.props.counterSlice.stopLoading();
    }

    baseCurrencyChange = async (value) => {
        this.setState({ baseCurrency: value });
        let currencyDataList = await fetchCurrencyData(value);
        this.setState({ currencyDataList, currenciesNameList: [], currenciesList: [], currenciesSlotList: [] });
        if (currencyDataList.error)
            toast.error('Base currency information not found.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
    }
    onCurrenciesSelect = (e, a) => {
        this.state.currenciesList.push({ ...a, rate: this.state.currencyDataList.rates[a.id] });
        this.setState({ currenciesNameList: e });

        let slotData = [];
        for (let i = 0; i < this.state.currenciesList.length; i += this.state.elementsPerSlot) {
            const slot = this.state.currenciesList.slice(i, i + this.state.elementsPerSlot);
            slotData.push(slot);
        }
        this.setState({ currenciesSlotList: slotData });

    }
    onCurrenciesRemove = (e, a) => {
        this.setState({ currenciesNameList: e, currenciesList: this.state.currenciesList.filter(item => item.id !== a.id) });
        let slotData = [];
        for (let i = 0; i < this.state.currenciesList.length; i += this.state.elementsPerSlot) {
            const slot = this.state.currenciesList.slice(i, i + this.state.elementsPerSlot);
            slotData.push(slot);
        }
        this.setState({ currenciesSlotList: slotData });
    }

    roundDecimal = (number, n) => {
        const multiplier = Math.pow(10, n);
        const roundedNumber = Math.round(number * multiplier) / multiplier;
        return roundedNumber;
    }
    createCurrencyApp = async () => {

        this.props.counterSlice.startLoading();
        let appDTO = {
            name: this.state.appName.trim(),
            pageTitle: this.state.title,
            baseCurrency: this.state.baseCurrency,
            currencyList: this.state.currenciesNameList,
            decimal: this.state.decimal,
            appType: "CURRENCY"
        }
        let response = await createApp(appDTO);
        if (response.success) {

            toast.success('Currency app saved successfully.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
            this.props.router.navigate("/apps");

        } else {
            toast.error(response.message, {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        }
        this.props.counterSlice.stopLoading();
    }
    render() {
        const userRole = JSON.parse(localStorage.getItem("roles"));

        let screenSize = { height: "410px", width: "717px" }
        if (!this.state.isLandscape)
            screenSize = { width: "50vh", height: "65vh" }

        return (
            <div>
                <Navbar router={this.props.router} counterSlice={this.props.counterSlice} />
                <div className="flex bg-white border-b-1 border-slate-300 w-full min-h-min	">
                    <div className="flex-none p-4 border-1"
                        onClick={() => { this.props.router.navigate(-1) }}>
                        <MdArrowBackIos size={20} />
                    </div>
                    <div className="self-center bg-dark-team rounded p-2 m-2">
                        <Currency style="fill-white" />
                    </div>
                    <div className="px-2 py-3 flex flex-col self-center grow">
                        <InputFieldWithEdit
                            isEdit={this.state.isNameEdit}
                            isRead={userRole.app["editName"].read}
                            onKeyDown={(e) => {
                                if (this.state.appName.trim() !== "" && (e.key === "Enter" || e.key === "Tab" || e.key === undefined)) {
                                    this.setState({ isNameEdit: false });
                                } else this.setState({ isNameEdit: true });
                            }}
                            inputValue={this.state.appName}
                            placeholder="Enter Currency App Name"
                            onInputchange={(e) => {userRole.app["editName"].write && this.setState({ appName: e.target.value }); }}
                            onEditClick={() => {userRole.app["editName"].write && this.setState({ isNameEdit: !this.state.isNameEdit }) }} />
                        {this.state.isUpdate ? <span className="order-last text-sm text-gray-450">{format(new Date(), 'dd/MM/yyyy HH:mm')}, modified by {this.state.appInfo != "" && this.state.appInfo.modifiedBy && <span>{this.state.appInfo.modifiedBy.firstName} {this.state.appInfo.modifiedBy.lastName}</span>} </span> :
                            <span className="order-last text-sm text-gray-450">{format(new Date(), 'dd/MM/yyyy HH:mm')}</span>}
                    </div>
                    <div className="flex gap-4 self-center" >
                        <Button disabled={this.state.title.trim() === "" || this.state.baseCurrency === "" || this.state.currenciesNameList.length === 0} className="bg-darkBlue text-white rounded p-2 mr-4"
                            onClick={this.state.isUpdate ? this.updateCurrencyApp : this.createCurrencyApp}>{this.state.isUpdate ? "Update" : "Save"}</Button>
                    </div>
                </div>
                <div className="flex" style={{ height: "82vh" }}>
                    <div className="bg-white h-full w-1/3 px-12 pt-5 gap-4 flex flex-col">
                        <div className="flex flex-col gap-1 mr-4">
                            <label>{"Page Title*"}</label>
                            <div className="flex gap-2">
                                <input type="text" id="inputo"
                                    maxLength={30}
                                    className="border-1 border-gray-300 rounded p-2 text-black my-select"
                                    value={this.state.title} onChange={(e) => this.setState({ title: e.target.value })}
                                    style={{ backgroundColor: "inherit", color: "white" }}
                                    placeholder="Enter page title" />
                            </div>
                        </div>
                        <div className="flex flex-col gap-1 mr-4">
                            <label>{"Base Currency*"}</label>
                            <div className="flex">
                                <Form.Select className="flex flex-1" placeholder="Select Base Currency"
                                    value={this.state.baseCurrency}
                                    onChange={(e) => this.baseCurrencyChange(e.target.value)}>
                                    <option value="" key={""}>Select Base Currency</option>
                                    {this.state.currencyList?.map(c => (
                                        <option value={c.id} key={c.id}>{c.fullName}</option>
                                    ))}
                                </Form.Select>
                            </div>
                        </div>
                        <div className="flex flex-col gap-1 mr-4">
                            <label>{"Currencies*"}</label>
                            <div className="flex">
                                <Multiselect
                                    style={{
                                        inputField: { color: "black" }, chips: { background: "gray" }                             
                                    }}
                                    options={this.state.currencyList}
                                    // selectionLimit={!this.state.currencyDataList.error ? this.state.isLandscape ? 8 : 4 : 0}
                                    selectedValues={this.state.currenciesNameList}
                                    onSelect={(list, selectedObj) => this.onCurrenciesSelect(list, selectedObj)}
                                    onRemove={(list, selectedObj) => this.onCurrenciesRemove(list, selectedObj)}
                                    displayValue="id"
                                    placeholder="Select your currencies"
                                />
                            </div>
                        </div>
                        <div className="flex flex-col gap-1 mr-4">
                            <label>{"Decimals*"}</label>
                            <div className="flex gap-2">
                                <input type="number" id="inputo"
                                    max={6}
                                    min={0}
                                    className="border-1 border-gray-300 rounded p-2 text-black my-select"
                                    value={this.state.decimal} onChange={(e) => e.target.value < 6 &&
                                        this.setState({ decimal: e.target.value })}
                                    style={{ backgroundColor: "inherit", color: "white" }}
                                    placeholder="Enter last decimals" />
                            </div>
                        </div>
                    </div>
                    <div className="flex-1">
                        <div className="flex content-end m-4">
                            <button className="p-2 flex bg-black text-white rounded-lg font-bold border-solid border-1" onClick={() => {
                                let slotData = [];
                                this.props.counterSlice.startLoading();

                                let elementsPerSlot = this.state.isLandscape ? 4 : 8;
                                for (let i = 0; i < this.state.currenciesList.length; i += elementsPerSlot) {
                                    const slot = this.state.currenciesList.slice(i, i + elementsPerSlot);
                                    slotData.push(slot);
                                }
                                this.setState({ currenciesSlotList: slotData, isLandscape: !this.state.isLandscape, elementsPerSlot })
                                this.props.counterSlice.stopLoading();
                            }}>
                                {this.state.isLandscape ? <MdStayCurrentLandscape className="self-center font-bold mr-1 " size={25} /> :
                                    <MdStayCurrentPortrait className="self-center font-bold mr-1 " size={25} />}
                            </button>
                        </div>
                        <div className="flex justify-center">
                            <div className="bg-cover flex bg-white items-center justify-center object-cover border-black border-4 shadow overflow-hidden" style={screenSize}>
                                {this.state.currenciesSlotList.length > 0 ?
                                    <CurrencyPreview title={this.state.title} baseCurrency={this.state.baseCurrency} currenciesSlotList={this.state.currenciesSlotList && this.state.currenciesSlotList} duration={this.state.duration}
                                        isLandscape={this.state.isLandscape} decimal={this.state.decimal} /> :
                                    <DataIsEmptyWithoutButton url={process.env.PUBLIC_URL + "/blankScreenIcon.svg"}
                                        title="App instance preview"
                                        body1="Edit and save the configuration to preview this app instance" />}
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </div>)
    };
}

const mapStateToProps = (state) => ({
    isLoading: state.counter.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
    counterSlice: bindActionCreators(commanActionCreator, dispatch)
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CurrencyAppDetailsComponent));
