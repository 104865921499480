import React from "react";
import { connect } from "react-redux";
import { Modal, Button } from "react-bootstrap";
import { FaEyeSlash, FaEye } from 'react-icons/fa'
import { withRouter } from "../router";
import { bindActionCreators } from "@reduxjs/toolkit";
import { ToastContainer, toast } from 'react-toastify';

import Loginbg from "../images/Signaxe_signup.svg"

import { login, userRoleAndPermission, forgotPasswordRequest } from '../services/login.service';
import * as commanActionCreator from '../reducers/commanReduces'

class LoginComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      password: "",
      usernameError: "",
      passwordError: "",
      loading: false,
      isRegisterDoneModel: false,
      showPassword: false,
      email: "",
      forgotPasswordModel: false,
      forgotEmail: "",
    };
  }
  onInputChange = (type, event) => {
    this.setState({ [type]: event.target.value });
  }

  login = async () => {
    const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    let loginDTO = {
      email: this.state.email.toLowerCase().trim(),
      password: this.state.password,
      timeZone: currentTimeZone
    };
    this.props.counterSlice.startLoading();
    let response = await login(loginDTO);
    if (response.success) {
      let roleDetails = await userRoleAndPermission(response.body.userType, response.body.accessToken);
      if (roleDetails.success) {
        localStorage.setItem("roles", JSON.stringify(roleDetails.body.permissions));
      }
    }
    this.props.counterSlice.stopLoading();

    if (response.success) {
      localStorage.setItem("accessToken", response.body.accessToken);
      localStorage.setItem("isLogin", true);
      localStorage.setItem("userInfo", JSON.stringify(response.body));
      if (response.body.status === 2) {
        this.props.router.navigate("/setting?updatePassword=1", { replace: true });
      } else {
        this.props.router.navigate("/screens", { replace: true });
      }
    } else {
      toast.error(response.message, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        theme: "colored",
        progress: undefined,
      });
    }
  }

  sendPasswordRequest = async () => {
    let userDTO = {
      email: this.state.forgotEmail.toLowerCase()
    };
    this.setState({ forgotPasswordModel: false })

    this.props.counterSlice.startLoading();
    let response = await forgotPasswordRequest(userDTO);
    this.props.counterSlice.stopLoading();

    if (response.success) {
      toast.success(response.message, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        theme: "colored",
        progress: undefined,
      });
    } else {
      toast.error(response.message, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        theme: "colored",
        progress: undefined,
      });
    }
    this.setState({ forgotPasswordModel: false, forgotEmail: "" })
  }

  render() {
    return (
      <div className="h-screen w-screen flex items-center justify-center bg-cover" style={{ backgroundImage: `url(${Loginbg})` }}>
        <div className="bg-white p-8 rounded-md shadow-md sm:w-2/5 md:w-2/5 lg:w-4/12 xl:w-4/12">
            <div className="flex flex-col justify-center items-center bg-white gap-8">
              <div className="mb-8 flex flex-col text-center gap-6">
                <h1 className="text-3xl font-medium">Welcome Back To</h1>
                <img className="self-center h-8" src={process.env.PUBLIC_URL + "/signaxe_logo.svg"} alt="..." />
              </div>
              <div className="flex flex-col gap-4 text-sm">
                <div className="relative sm:w-full md:w-full lg:w-60 xl:w-96 border-b-black border-opacity-50 border-2 rounded-md flex gap-2">
                  <span className="absolute inset-y-0 left-0 flex items-center pl-2 text-gray-500">
                    <svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M19 4H5C4.20435 4 3.44129 4.31607 2.87868 4.87868C2.31607 5.44129 2 6.20435 2 7V17C2 17.7956 2.31607 18.5587 2.87868 19.1213C3.44129 19.6839 4.20435 20 5 20H19C19.7956 20 20.5587 19.6839 21.1213 19.1213C21.6839 18.5587 22 17.7956 22 17V7C22 6.20435 21.6839 5.44129 21.1213 4.87868C20.5587 4.31607 19.7956 4 19 4ZM18.33 6L12 10.75L5.67 6H18.33ZM19 18H5C4.73478 18 4.48043 17.8946 4.29289 17.7071C4.10536 17.5196 4 17.2652 4 17V7.25L11.4 12.8C11.5731 12.9298 11.7836 13 12 13C12.2164 13 12.4269 12.9298 12.6 12.8L20 7.25V17C20 17.2652 19.8946 17.5196 19.7071 17.7071C19.5196 17.8946 19.2652 18 19 18Z" fill="black" fill-opacity="0.5" />
                    </svg>
                  </span>
                  <input type="text" placeholder="Enter your Email ID" name="email" value={this.state.email} onChange={(e) => this.onInputChange("email", e)}
                    className="pl-12 pr-4 py-2 w-full focus:outline-none placeholder:text-sm text-black" />
                </div>
                <div className="relative  border-b-black border-opacity-50 border-2 rounded-md flex gap-2 text-sm">
                  <span className="absolute inset-y-0 left-0 flex items-center pl-2 text-gray-500">
                    <svg width="20" height="22" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M2.75 24C1.99375 24 1.34658 23.7764 0.8085 23.3291C0.270416 22.8819 0.000916667 22.3436 0 21.7143V10.2857C0 9.65714 0.2695 9.11924 0.8085 8.672C1.3475 8.22476 1.99467 8.00076 2.75 8H4.125V5.71429C4.125 4.13333 4.79554 2.7859 6.13663 1.672C7.47771 0.558095 9.09883 0.000761905 11 0C12.9021 0 14.5237 0.557333 15.8647 1.672C17.2058 2.78667 17.8759 4.1341 17.875 5.71429V8H19.25C20.0062 8 20.6539 8.224 21.1929 8.672C21.7319 9.12 22.0009 9.6579 22 10.2857V21.7143C22 22.3429 21.731 22.8811 21.1929 23.3291C20.6548 23.7771 20.0072 24.0008 19.25 24H2.75ZM2.75 21.7143H19.25V10.2857H2.75V21.7143ZM11 18.2857C11.7562 18.2857 12.4039 18.0621 12.9429 17.6149C13.4819 17.1676 13.7509 16.6293 13.75 16C13.75 15.3714 13.481 14.8335 12.9429 14.3863C12.4048 13.939 11.7572 13.715 11 13.7143C10.2437 13.7143 9.59658 13.9383 9.0585 14.3863C8.52042 14.8343 8.25092 15.3722 8.25 16C8.25 16.6286 8.5195 17.1669 9.0585 17.6149C9.5975 18.0629 10.2447 18.2865 11 18.2857ZM6.875 8H15.125V5.71429C15.125 4.7619 14.724 3.95238 13.9219 3.28571C13.1198 2.61905 12.1458 2.28571 11 2.28571C9.85417 2.28571 8.88021 2.61905 8.07812 3.28571C7.27604 3.95238 6.875 4.7619 6.875 5.71429V8Z" fill="black" fill-opacity="0.5" />
                    </svg>
                  </span>
                  <input
                    type={this.state.showPassword ? "text" : "password"} name="password" value={this.state.password} placeholder="Enter your Password"
                    id="logipwd"
                    onChange={(e) => this.onInputChange("password", e)}
                    className="pl-12 pr-4 py-2 w-full focus:outline-none placeholder:text-sm text-black"
                  />
                  {this.state.showPassword ? <FaEye className="absolute top-1 m-1 right-1" size={20} opacity={0.7} style={{ marginLeft: "-30px", cursor: "pointer" }} onClick={() => this.setState({ showPassword: !this.state.showPassword })} /> :
                    <FaEyeSlash className="absolute top-1 right-1 m-1  " size={20} opacity={0.7} style={{ marginLeft: "-30px", cursor: "pointer" }} onClick={() => this.setState({ showPassword: !this.state.showPassword })} />}
                </div>
                <div className='flex justify-end mb-6 -mt-5'>
                  <div><button className='text-darkBlue cursor-pointer text-sm  hover:underline' onClick={() => this.setState({ forgotPasswordModel: true })}>Forgot Password?</button></div>
                </div>
                <div className="flex flex-col gap-1">
                  <Button disabled={this.state.email.trim() === "" || this.state.password.trim() === ""} className="block bg-darkBlue hover:bg-blue-800 cursor-pointer w-full text-white py-1 px-8 rounded " onClick={this.login}>
                    Sign In
                  </Button>
                  <div className='text-center text-black text-opacity-50 text-sm font-medium	'>
                    Don't have an account yet? <span className='text-blue-700 cursor-pointer hover:underline hover:underline-offset-2' onClick={() => this.props.router.navigate("/signup")}>Sign Up</span>
                  </div>
                </div>
              </div>
            </div> 
        </div>

        <Modal
          show={this.state.forgotPasswordModel}
          aria-labelledby="contained-modal-title-vcenter"
          centered>
          <Modal.Body className="m-0 p-0">
            <div className="m-3">
              <label className='font-medium mb-2 flex'>Email</label>
              <input type="text" placeholder="Enter your Email" name="forgotEmail" value={this.state.forgotEmail} onChange={(e) => this.onInputChange("forgotEmail", e)} className='w-full border form-control rounded-md bg-transparent border-gray-400 p-3' />
            </div>
          </Modal.Body>
          <Modal.Footer className="justify-center">
            <Button variant="" className="border-1 text-black rounded shadow" onClick={() => this.setState({ forgotPasswordModel: false, forgotEmail: "" })}>
              CANCEL
            </Button>
            <Button className="btn btn-prime" disabled={this.state.forgotEmail.trim() == ""} onClick={() => this.sendPasswordRequest()}>
              SUBMIT
            </Button>
          </Modal.Footer>
        </Modal>
        <ToastContainer />
      </div>
    );
  }
};

const mapStateToProps = (state) => ({
  isLoading: state.counter.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  counterSlice: bindActionCreators(commanActionCreator, dispatch)
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LoginComponent));
