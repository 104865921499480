import React from "react";
import { Modal, Button, Breadcrumb, Dropdown, ButtonGroup } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "../router";
import { ToastContainer, toast } from 'react-toastify';
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { fetchScreenDetails, setToScreenService } from "../services/screenService";

import { MdArrowBackIos } from 'react-icons/md';
import { FaStar, FaFilm, FaTimes, FaPlus } from 'react-icons/fa';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { fetchAllLinksData } from "../services/linkservice";

import ChannelAddContent from "./subComponents/channelAddContent";
import DragMediaList from "./subComponents/dragMediaList";
import { fetchAllMedia } from "../services/mediaService";
import { fetchAllPlayList, fetchPlayListDetails } from "../services/playListServices";
import { fetchChannelDetails, fetchChannelDetailsWithId, updateChannelList, deleteChannel, attachedChannelDataList, revertChannelPublish } from "../services/channelServices";
import { AddContentButtons, AppIcon, DataIsEmpty, IconButton, InputFieldWithEdit, ToggalButton, ToggalSetButton } from "./utilComponents/inputFieldAndButtons";
import DragPlayList from "./subComponents/dragPlayList";
import ChannelAddPlaylistContent from "./subComponents/channelAddPlaylistContent";
import { bindActionCreators } from "@reduxjs/toolkit";
import * as commanActionCreator from '../reducers/commanReduces';
import Sidebar from "./subComponents/Sidebar";
import Navbar from "./subComponents/Navbar";
import DragLinkList from "./subComponents/dragLinkList";
import { format } from "date-fns";
import Playee from "../appIcons/Playee";
import Link from "../appIcons/Link";
import SetToScreenModel from "./utilComponents/setToScreenModel";
import ShowPreview from "./utilComponents/showPreview";
import Mediaa from "../appIcons/Mediaa";
import Appss from "../appIcons/Appss";
import TableDragSelect from "./subComponents/TableDragSelect";
import Clock from "../appIcons/clock";
import Calender from "../appIcons/calender";
import { fetchAllAppData } from "../services/appServices";
import InputDuration from "./utilComponents/inputDurationModel";
import debounce from "lodash.debounce";
import DatePicker from "react-datepicker";
import { convertDate } from "./utilComponents/utilFuncation";

class ChannelsDetailsComponent extends React.Component {

    constructor(props) {
        super(props);
        let dateCurrent = new Date();
        let month = (dateCurrent.getMonth() + 1);
        let day = dateCurrent.getDate();
        dateCurrent = dateCurrent.getFullYear() + "-" + (month < 10 ? "0" + month : month) + "-" + (day < 10 ? "0" + day : day);
        this.state = {
            addChannelModel: false,
            isNameEdit: false,
            isUpdating: false,
            isPreview: false,
            setToScreenModel: false,
            selectAll: false,
            isVideoSelected: false,
            isImageSelected: false,
            isPlayListSelected: true,
            islinkSelected: false,
            isAppSelected: false,
            addContent: false,
            revertPublishModel: false,
            onclick: false,
            addContentSlider: {
                isPlayList: true,
                isMedia: false,
                isLinks: false,
                isApps: false
            },
            channelInfo: {},
            selectedChannelList: [],
            screenTempList: [],
            screenList: [],
            tempMediaList: [],
            channelName: "",
            mediaList: [],
            appList: [],
            currentPath: "/",
            dragCurrentPath: "/",
            schedulingData: [],
            dateCurrent: dateCurrent,
            dragPathList: [{
                folderName: "Library",
                path: "/",
                isActive: true
            }],
            pathList: [{
                folderName: "Library",
                path: "/",
                isActive: true
            }],
            mediaAllList: [],
            playListAllList: [],
            linkAllList: [],
            scheduledInfo: {
                "isEveryDay": true,
                "isAllDate": true,
                "isAllTime": true,
                "scheduledData": "111111111111111111111111|111111111111111111111111|111111111111111111111111|111111111111111111111111|111111111111111111111111|111111111111111111111111|111111111111111111111111",
                "setPriority": false,
                "selectedDate": []
            },
        }
    }

    async componentDidMount() {
        this.props.counterSlice.startLoading();
        const queryParams = new URLSearchParams(this.props.router.location.search);
        let channelInfo = await fetchChannelDetailsWithId(this.props.router.params.channelId);
        this.setState({ channelInfo: channelInfo.body, channelName: channelInfo.body.name });
        const userRole = JSON.parse(localStorage.getItem("roles"));

        let addContentSlider = {
            isPlayList: true,
            isMedia: false,
            isLinks: false,
            isApps: false
        };
        let isImageSelected = false;
        let isPlayListSelected = true;
        let islinkSelected = false;
        let isAppSelected = false;
        if (!userRole.channel["selectPlayListChannel"].read) {
            addContentSlider.isPlayList = false;
            addContentSlider.isMedia = true;
            isPlayListSelected = false;
            isImageSelected = true;
        }
        if (!userRole.channel["selectPlayListChannel"].read && !userRole.channel["selectMediaChannel"].read) {
            addContentSlider.isMedia = false;
            addContentSlider.isLinks = true;
            isImageSelected = false;
            islinkSelected = true;
        } if (!userRole.channel["selectPlayListChannel"].read && !userRole.channel["selectMediaChannel"].read && !userRole.channel["selectLinkChannel"].read) {
            addContentSlider.isLinks = false;
            addContentSlider.isApps = true;
            islinkSelected = false;
            isAppSelected = true;
        } if (!userRole.channel["selectPlayListChannel"].read && !userRole.channel["selectMediaChannel"].read && !userRole.channel["selectLinkChannel"].read && !userRole.channel["selectAppChannel"].read) {
            addContentSlider.isApps = false;
            isAppSelected = true;
        }

        let playListRes = await fetchAllPlayList();
        let mediaRes = await fetchAllMedia(this.state.currentPath);
        let linkRepo = await fetchAllLinksData();
        let appResponse = await fetchAllAppData();
        this.setState({
            isPlayListSelected, isImageSelected, islinkSelected, isAppSelected,
            mediaList: mediaRes.body, playListInfo: playListRes.body,
            mediaAllList: mediaRes.body,
            playListAllList: playListRes.body,
            linkAllList: linkRepo.body,
            appDataList: appResponse.body, appList: appResponse.body,
            linkListData: linkRepo.body, selectedChannelList: channelInfo.body.channelPlayList,
            addContentSlider
        });
        this.props.counterSlice.stopLoading();
        if (queryParams.get('isPreview') == 1 && channelInfo.body.channelPlayList.length !== 0) {
            this.state.selectedChannelList = channelInfo.body.channelPlayList;
            this.onPreviewChange();
        }
    }

    fetchChannelData = async () => {
        let channelInfo = await fetchChannelDetailsWithId(this.props.router.params.channelId);
        this.setState({ channelInfo: channelInfo.body, channelName: channelInfo.body.name, selectedChannelList: channelInfo.body.channelPlayList })
    }
    updateChannelName = async () => {
        if (this.state.channelName.trim() === "") {
            toast.error('Channel name is required.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
            return;
        }

        let channelDTO = {
            _id: this.state.channelInfo._id,
            name: this.state.channelName,
        }
        let response = await updateChannelList(channelDTO);
        if (response.success) {
            this.setState({ channelName: response.body.name })
        } else {
            toast.error('Error while updating channel name.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        }
    }
    updateChannelList = async () => {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        let channelPlayList = [];
        this.setState({ isUpdating: true });

        this.state.selectedChannelList.forEach(sp => {
            let refObj = {
                duration: sp.duration,
                refrenceId: sp._id,
                type: sp.type,
                schedulingList: sp.schedulingList,
                isAuditLog: sp.isAuditLog
            };
            channelPlayList.push(refObj);
        });
        let channelDTO = {
            _id: this.state.channelInfo._id,
            orgId: userInfo.orgId,
            channelPlayList: channelPlayList,
            status: "DRAFT"
        }
        let response = await updateChannelList(channelDTO);
        if (response.success) {
            this.fetchChannelData();
        } else {
            toast.error('Error while updating channel.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        }
        this.setState({ isUpdating: false })
    }
    publishChannelList = async () => {
        this.props.counterSlice.startLoading();

        this.setState({ isUpdating: true });
        let channelDTO = {
            _id: this.state.channelInfo._id,
            status: "PUBLISHED"
        };
        let response = await updateChannelList(channelDTO);

        if (response.success) {
            this.fetchChannelData();
            toast.success('Channel published.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        } else {
            toast.error('Error while publishing.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        }
        this.props.counterSlice.stopLoading();
        this.setState({ isUpdating: false })
    }

    revertLastPublish = async () => {
        this.props.counterSlice.startLoading();
        this.setState({ isUpdating: true });
        let response = await revertChannelPublish(this.state.channelInfo._id);
        if (response.success) {
            this.fetchChannelData();
            toast.success('Channel reverted successfully..', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        } else {
            toast.error('Error while reverting publish.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        }
        this.props.counterSlice.stopLoading();
        this.setState({ isUpdating: false })
    }
    onSetToScreenClick = async () => {
        this.props.counterSlice.startLoading();
        let screenDetails = await fetchScreenDetails();
        let attachedData = await attachedChannelDataList(this.state.channelInfo._id);

        this.props.counterSlice.stopLoading();

        this.setState({ setToScreenModel: true });
        if (screenDetails.success) {
            let screenList = screenDetails.body;

            if (attachedData.success && attachedData.body.screenList.length > 0) {
                let attachedScreenInfo = attachedData.body;
                screenList.forEach((s) => {
                    s.isSelected = false;
                    for (let i = 0; i < attachedScreenInfo.screenList.length; i++) {
                        if (s._id === attachedScreenInfo.screenList[i]._id)
                            s.isSelected = true;
                    }
                });
                if (attachedScreenInfo.screenList.length === screenList)
                    this.setState({ selectAll: true })
            } else
                screenList.forEach((s) => s.isSelected = false);

            this.setState({ screenList: screenList, screenTempList: screenList });
        }
    }
    searchScreenName = (e) => {
        if ((e.target.value != "")) {
            console.log(e.target.value.length);
            if (e.target.value.length % 2 === 0) {
                let screenList = [];
                for (let i = 0; i < this.state.screenTempList.length; i++) {
                    let s = this.state.screenTempList[i]
                    for (let j = 0; j < s.tags.length; j++) {
                        if (s.tags[j] && s.tags[j].tag.toUpperCase().includes(e.target.value.toUpperCase())) {
                            screenList.push(s)
                            break;
                        }
                    }
                }
                let screenNameSort = this.state.screenTempList.filter((s) => s.name.toUpperCase().includes(e.target.value.toUpperCase()));
                let screenNameList = screenList.concat(screenNameSort);
                this.setState({ screenList: this.removeDuplicates(screenNameList) });
            }
        } else
            this.setState({ screenList: this.state.screenTempList })
    }
    removeDuplicates = (arr) => {
        const uniqueArray = [];
        const idTracker = {};

        for (let obj of arr) {
            const id = obj._id;
            if (!idTracker[id]) {
                uniqueArray.push(obj);
                idTracker[id] = true;
            }
        }
        return uniqueArray;
    }
    setToScreenService = async () => {
        let screenList = [];
        this.setState({ setToScreenModel: false });
        this.props.counterSlice.startLoading();

        this.state.screenList.forEach((s) => {
            if (s.isSelected)
                screenList.push(s._id);
        });

        let screenDTO = {
            type: "CHANNEL",
            refrenceId: this.state.channelInfo._id,
            screenIdList: screenList
        };
        let screenDetails = await setToScreenService(screenDTO);
        if (screenDetails.success) {
            toast.success('Screens has been updated.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        } else {
            toast.error(screenDetails.message, {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        }
        this.props.counterSlice.stopLoading();

        this.closeAll();
    }
    closeAll = () => {
        this.setState({ deleteChannelModel: false, revertPublishModel: false, selectAll: false, setToScreenModel: false, screenList: [], updateScedulerModel: false });
    }
    attachedChannelData = async (c) => {
        let channelId = this.state.channelInfo._id;
        this.props.counterSlice.startLoading();
        let attachedData = await attachedChannelDataList(channelId);
        this.props.counterSlice.stopLoading();
        this.setState({ deleteChannelModel: true, channelId: channelId, attachedList: attachedData.body, addChannelScreen: false })
    }
    deleteChannel = async () => {
        this.props.counterSlice.startLoading();

        let response = await deleteChannel(this.state.channelInfo._id);
        if (response.success) {
            this.props.router.navigate(-1)
            let dasboardResponse = await fetchChannelDetails();
            this.setState({ channelData: dasboardResponse.body });
            toast.success('Channel deleted successfully.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        } else {
            toast.error('Error while deleting channel.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        }
        this.props.counterSlice.stopLoading();

        this.closeAll()
    }
    onTabChange = (type) => {
        this.setState({ isImageSelected: false, isAppSelected: false, isVideoSelected: false, isPlayListSelected: false, islinkSelected: false });
        this.setState({ [type]: true })
    }
    onDragFolderCilck = async (m) => {
        this.props.counterSlice.startLoading();
        let path = this.state.dragCurrentPath + m._id + "/";
        let dasboardResponse = await fetchAllMedia(path);
        this.state.dragPathList.forEach((pl) => pl.isActive = false);
        this.state.dragPathList.push({
            folderName: m.name,
            path: path,
            isActive: true
        });
        this.setState({ mediaList: dasboardResponse.body, dragCurrentPath: path });
        this.props.counterSlice.stopLoading();

    }
    onDragBreadCrumbChnage = async (m, i) => {
        this.props.counterSlice.startLoading();
        let dasboardResponse = await fetchAllMedia(m.path);
        this.setState({
            mediaList: dasboardResponse.body, dragCurrentPath: m.path,
            dragPathList: this.state.pathList.slice(0, i + 1)
        });
        this.props.counterSlice.stopLoading();
    }
    handleDragEnd = async (val) => {
        const userRole = JSON.parse(localStorage.getItem("roles"));
        if (!userRole.channel["editChannel"].write)
            return;

        const { source, destination } = val;
        if (!destination) return;
        if (source.droppableId === "channelList" && destination.droppableId === "channelList") {
            let tempData = Array.from(this.state.selectedChannelList);
            let [source_data] = tempData.splice(source.index, 1);
            tempData.splice(destination.index, 0, source_data);
            this.state.selectedChannelList = tempData;
            this.setState({ selectedChannelList: tempData })
            this.updateChannelList();
        } else if (source.droppableId === "mediaList" && destination.droppableId === "channelList") {
            if (!userRole.channel["selectMediaChannel"].write) return;
            let mediaObject = JSON.parse(JSON.stringify(this.state.mediaList[source.index]));
            if (mediaObject.type === "FOLDER")
                return;
            mediaObject.schedulingList = this.state.scheduledInfo;
            this.state.selectedChannelList.splice(destination.index, 0, mediaObject);
            this.setState({ selectedChannelList: this.state.selectedChannelList });
            this.updateChannelList();
        } else if (source.droppableId === "playListData" && destination.droppableId === "channelList") {
            if (!userRole.channel["selectPlayListChannel"].write) return;

            let channelObject = JSON.parse(JSON.stringify(this.state.playListInfo[source.index]));
            let playListDetails = await fetchPlayListDetails(channelObject._id);
            playListDetails = playListDetails.body;
            if (playListDetails.playlist.length === 0) {
                toast.error("Can not set empty playlist", {
                    position: "top-right",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    theme: "colored",
                    progress: undefined,
                });
                return;
            }
            playListDetails.type = "PLAYLIST";
            playListDetails.schedulingList = this.state.scheduledInfo;
            this.state.selectedChannelList.splice(destination.index, 0, playListDetails);
            this.setState({ selectedChannelList: this.state.selectedChannelList });
            this.updateChannelList();
        } if (source.droppableId === "linkListData" && destination.droppableId === "channelList") {
            if (!userRole.channel["selectLinkChannel"].write) return;

            let linkObject = JSON.parse(JSON.stringify(this.state.linkListData[source.index]));
            linkObject.schedulingList = this.state.scheduledInfo;
            this.state.selectedChannelList.splice(destination.index, 0, linkObject);
            this.setState({ selectedChannelList: this.state.selectedChannelList });
            console.log(this.state.selectedChannelList);
            this.updateChannelList();
        } if (source.droppableId === "appListData" && destination.droppableId === "channelList") {
            if (!userRole.channel["selectAppChannel"].write) return;

            let linkObject = JSON.parse(JSON.stringify(this.state.appDataList[source.index]));
            linkObject.schedulingList = this.state.scheduledInfo;
            this.state.selectedChannelList.splice(destination.index, 0, linkObject);
            this.setState({ selectedChannelList: this.state.selectedChannelList });
            this.updateChannelList();
        }
        return;
    }
    openScheduler = (schObj, index) => {
        let scheduleInfo = schObj.schedulingList;
        let data = this.convertPipeStream(scheduleInfo.scheduledData);
        this.setState({ updateScedulerModel: true, schedulingData: data, selectedIndex: index, selectedschedulingInfo: scheduleInfo })
    }
    convertPipeStream = (str) => {
        var parts = str.split("|");
        var result = [];
        for (var i = 0; i < parts.length; i++) {
            var chars = parts[i].split("");
            var row = [];
            for (var j = 0; j < chars.length; j++) {
                row.push(chars[j] === "1");
            }
            result.push(row);
        }
        return result;
    }
    convertArrayToPipeStrem(arr) {
        var result = "";
        for (var i = 0; i < arr.length; i++) {
            for (var j = 0; j < arr[i].length; j++) {
                var char = arr[i][j] ? "1" : "0";
                result += char;
            }
            if (i < arr.length - 1) {
                result += "|";
            }
        }
        return result;
    }
    onSchedulerSave = () => {
        let scheduledData = this.convertArrayToPipeStrem(this.state.schedulingData);
        if (scheduledData !== this.state.selectedschedulingInfo.scheduledData) {
            let isAllTime = true;
            let everyDay = true;

            for (let i = 0; i < this.state.schedulingData.length; i++) {
                let scheduledDayData = this.state.schedulingData[i];
                if (isAllTime && scheduledDayData.includes(false)) {
                    if (scheduledDayData.every(value => value === false)) {
                        isAllTime = true;
                    } else {
                        isAllTime = false;
                    }
                }
                if (!scheduledDayData.includes(true)) {
                    everyDay = false;
                }
            }
            let data = "000000000000000000000000|000000000000000000000000|000000000000000000000000|000000000000000000000000|000000000000000000000000|000000000000000000000000|000000000000000000000000";
            if (scheduledData == data) {
                toast.error("Schedule details must filled in.", {
                    position: "top-right",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    theme: "colored",
                    progress: undefined,
                });
                return;
            }
            this.state.selectedschedulingInfo.isAllTime = isAllTime;
            this.state.selectedschedulingInfo.scheduledData = scheduledData;
            this.state.selectedschedulingInfo.isEveryDay = everyDay;
        }
        this.setState({ updateScedulerModel: false });
        this.state.selectedChannelList[this.state.selectedIndex].schedulingList = this.state.selectedschedulingInfo;
        this.updateChannelList();
    }
    addContentModel = async () => {
        this.props.counterSlice.startLoading();

        let dasboardResponse = await fetchAllMedia(this.state.currentPath);
        let playListRes = await fetchAllPlayList();
        let linkResponse = await fetchAllLinksData();

        if (linkResponse.body)
            linkResponse.body.forEach((m) => m.isSelected = false);

        if (playListRes.body) {
            playListRes.body.forEach((p) => p.isSelected = false);
        }
        dasboardResponse.body.forEach((m) => m.isSelected = false);
        this.props.counterSlice.stopLoading();
        console.log(linkResponse.body);
        this.setState({ addContent: true, mediaAllList: dasboardResponse.body, playListList: playListRes.body, linkList: linkResponse.body })
    }
    selectedMediaFile = (m, i) => {
        const userRole = JSON.parse(localStorage.getItem("roles"));

        switch (m.type) {
            case "APP":
                if (!userRole.channel["selectAppChannel"].write)
                    break;
                this.state.appList[i].isSelected = !m.isSelected;
                if (this.state.appList[i].isSelected) {
                    m.schedulingList = this.state.scheduledInfo;
                    this.state.tempMediaList.push(m);
                } else {
                    this.state.tempMediaList = this.state.tempMediaList.filter((item) => item._id !== m._id);
                }
                this.setState({ appList: this.state.appList, tempMediaList: this.state.tempMediaList });
                break;
            case "LINK":
                if (!userRole.channel["selectLinkChannel"].write)
                    break;
                this.state.linkList[i].isSelected = !m.isSelected;
                if (this.state.linkList[i].isSelected) {
                    m.schedulingList = this.state.scheduledInfo;
                    this.state.tempMediaList.push(m);
                } else {
                    this.state.tempMediaList = this.state.tempMediaList.filter((item) => item._id !== m._id);
                }
                this.setState({ linkList: this.state.linkList, tempMediaList: this.state.tempMediaList });
                break;
            case "FOLDER":
                this.onFolderCilck(m);
                break;
            case "MEDIA":
                if (!userRole.channel["selectMediaChannel"].write)
                    break
                this.state.mediaAllList[i].isSelected = !m.isSelected;
                if (this.state.mediaAllList[i].isSelected) {
                    m.schedulingList = this.state.scheduledInfo;
                    this.state.tempMediaList.push(m);
                } else {
                    this.state.tempMediaList = this.state.tempMediaList.filter((item) => item._id !== m._id);
                }
                this.setState({ mediaAllList: this.state.mediaAllList, tempMediaList: this.state.tempMediaList });
                break;
        }
    }
    onFolderCilck = async (m) => {
        if (this.state.onclick)
            return;
        this.state.onclick = true;

        let path = this.state.currentPath + m._id + "/";
        let dasboardResponse = await fetchAllMedia(path);
        this.state.pathList.forEach((pl) => pl.isActive = false);
        this.state.pathList.push({
            folderName: m.name,
            path: path,
            isActive: true
        });
        this.setState({ mediaAllList: dasboardResponse.body, currentPath: path });
        setTimeout(() => this.setState({ onclick: false }), 600);

    }
    onBreadCrumbChanage = async (m, i) => {
        this.props.counterSlice.startLoading();
        let dasboardResponse = await fetchAllMedia(m.path);
        this.setState({
            mediaAllList: dasboardResponse.body, currentPath: m.path,
            pathList: this.state.pathList.slice(0, i + 1)
        });
        this.props.counterSlice.stopLoading();

    }
    onContentTabChange = (type) => {
        let addContentSlider = {
            isPlayList: false,
            isMedia: false,
            isLinks: false
        }
        addContentSlider[type] = true;
        this.setState({ addContentSlider: addContentSlider });
    }
    selectedPlayListMediaFile = (m, i) => {
        const userRole = JSON.parse(localStorage.getItem("roles"));
        if (this.state.playListList[i].playlist.length === 0) {
            toast.error("Can not set empty playlist", {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
            return;
        }
        if (!userRole.channel["selectPlayListChannel"].write) {
            toast.error("You do not have access", {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
            return;
        }
        this.state.playListList[i].isSelected = !m.isSelected;

        if (this.state.playListList[i].isSelected) {
            m.schedulingList = this.state.scheduledInfo;
            m.type = "PLAYLIST";
            this.state.tempMediaList.push(m);
        } else {
            this.state.tempMediaList = this.state.tempMediaList.filter((item) => item.id !== m.id);
        }
        this.setState({ playListList: this.state.playListList, tempMediaList: this.state.tempMediaList });
    }
    closeMediaPicker = () => {
        this.setState({
            addContent: false, tempMediaList: []
        })
    }
    onPreviewChange = () => {
        let previewList = [];
        this.state.selectedChannelList.forEach((data) => {
            if (data.type === "PLAYLIST") {
                previewList = previewList.concat(data.playList);
            } else {
                previewList.push(data);
            }
        });
        this.setState({ isPreview: !this.state.isPreview, previewList: previewList });
    }
    onTimeChange = debounce(async (value, i) => {
        if (value === "00:00:00") {
            toast.error("You can't set the time to 00:00:00", {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
            return;
        }
        this.state.selectedChannelList[i].duration = value;
        this.setState({ selectedChannelList: this.state.selectedChannelList });
        this.updateChannelList();
    }, 1000);
    searchMediaList = (term, listName, listNameAll) => {
        const filtered = this.state[listNameAll].filter((item) =>
            item.name.toLowerCase().includes(term.toLowerCase())
        );
        this.setState({ [listName]: filtered, searchMediaName: term })

        if (term == "")
            this.setState({ [listName]: this.state[listNameAll] })

    }
    handleTimeChange = (newTime, i) => {
        const userRole = JSON.parse(localStorage.getItem("roles"));
        userRole.channel["editDuration"].write && this.onTimeChange(newTime, i)
    };
    render() {

        const userRole = JSON.parse(localStorage.getItem("roles"));
        return (
            <div className="flex">
                <Sidebar type={"channels"} />
                <div className="h-screen grow md:overflow-hidden overflow-auto md:hover:overflow-auto">
                    <ToastContainer />
                    <Navbar router={this.props.router} counterSlice={this.props.counterSlice} />
                    <div className="flex bg-white border-b-1 border-slate-300 w-full ">
                        <div className="flex-none p-3 border-1"
                            onClick={() => { this.props.router.navigate(-1) }}>
                            <MdArrowBackIos size={20} style={{ marginLeft: "12px", marginTop: "8px", color: "#474747" }} />
                        </div>
                        <div className="px-3 flex self-center flex-col grow">
                            <InputFieldWithEdit
                                isEdit={this.state.isNameEdit}
                                isRead={userRole.channel["renameChannel"].read}
                                onKeyDown={(e) => {
                                    if (userRole.channel["renameChannel"].write) {
                                        if (this.state.channelName.trim() !== "" && (e.key === "Enter" || e.key === "Tab" || e.key === undefined)) {
                                            this.setState({ isNameEdit: false });
                                            this.updateChannelList();
                                        } else this.setState({ isNameEdit: true });
                                    }
                                }}
                                inputValue={this.state.channelName}
                                placeholder="Enter channel name"
                                onInputchange={(e) => { userRole.channel["renameChannel"].write && this.setState({ channelName: e.target.value }); }}
                                onEditClick={() => userRole.channel["renameChannel"].write && this.setState({ isNameEdit: !this.state.isNameEdit })} />
                            {!this.state.isUpdating && this.state.channelInfo && <span className="order-last text-sm text-gray-450">{this.state.channelInfo.modifiedOn && format(new Date(this.state.channelInfo.modifiedOn), 'dd/MM/yyyy HH:mm')}, modified by {this.state.channelInfo.modifiedBy && this.state.channelInfo.modifiedBy.firstName + " " + this.state.channelInfo.modifiedBy.lastName}</span>}
                        </div>
                        {this.state.isUpdating && <div className="self-center p-2">
                            Saving Channel...
                        </div>}
                        <div className={this.state.isPreview ? "flex basis-96 self-center gap-3	" : "flex basis-80 self-center gap-3	"}>
                            {userRole.channel["setToScreen"].read && <button className="self-center"
                                onClick={() => userRole.channel["setToScreen"].write && this.onSetToScreenClick(this.state.selectedChannelObject)}
                            >
                                <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.6708 17.5491L17.9324 15.2365C17.9557 15.2065 17.9907 15.1886 18.0253 15.1827C18.0774 15.1768 18.1243 15.2007 18.1592 15.2365L20.4207 17.5491M21.7694 18.9294L22.5017 19.6763C22.6005 19.7721 22.5191 19.9691 22.3853 19.9691H20.525V25.2813C20.525 25.371 20.4494 25.4545 20.3621 25.4545C20.2749 25.4545 20.1934 25.3712 20.1934 25.2813V19.802C20.1934 19.7124 20.2749 19.6287 20.3621 19.6287H21.9843L18.0428 15.589L14.1009 19.6282H15.7228C15.81 19.6282 15.8915 19.7119 15.8915 19.8014V25.2808C15.8915 25.3705 15.8102 25.454 15.7228 25.454C15.6354 25.454 15.5599 25.3706 15.5599 25.2808V19.969H13.7054C13.5718 19.969 13.4904 19.7719 13.5892 19.6762L14.3218 18.9292M15.3393 17.8896L14.6474 18.5947M21.4437 18.5949L20.7518 17.8897M13.5395 23.6364H6V10H30V23.6364H22.0658M13.5 22.9091H6.81818V10.6364H29.2727V22.9091H22.0455" stroke="black" strokeWidth="0.212638" />
                                    <rect x="0.5" y="0.5" width="34" height="34" rx="3.5" stroke="#DCDCDC" />
                                </svg>
                            </button>}
                            {userRole.channel["previewChannel"].read && <button className="self-center p-2 rounded-lg text-darkBlue border-solid border-1  shadow-sm border-darkBlue hover:bg-neutral-300	" disabled={(this.state.selectedChannelList.length === 0)} onClick={() => userRole.channel["previewChannel"].write && this.onPreviewChange()}>
                                {this.state.isPreview ? "EDIT" : "PREVIEW"}
                            </button>}
                            {userRole.channel["publishChannel"].read && <Dropdown as={ButtonGroup}>
                                <Button className="self-center p-2 rounded-lg border-solid border-1 text-white bg-darkBlue shadow-sm"
                                    disabled={this.state.channelInfo.status !== "DRAFT" || this.state.selectedChannelList.length === 0}
                                    onClick={() => userRole.channel["publishChannel"].write && this.publishChannelList()}>PUBLISH</Button>
                                <Dropdown.Toggle split className="self-center p-2 rounded-lg border-solid border-1 text-white bg-darkBlue shadow-sm" />
                                <Dropdown.Menu className="bg-gray-20 p-0 m-0 overflow-hidden">
                                    <Dropdown.Item style={{ textDecoration: "none" }} disabled={this.state.channelInfo.status !== "DRAFT" || this.state.selectedChannelList.length === 0} onClick={() => userRole.channel["publishChannel"].write && this.publishChannelList()} className="p-2 text-sm" >Publish Channel</Dropdown.Item>
                                    <hr />
                                    <Dropdown.Item style={{ textDecoration: "none" }} disabled={this.state.channelInfo.status === "PUBLISHED"} onClick={() => this.setState({ revertPublishModel: true })} className="p-2 text-sm">Revert Channel</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>}
                            {userRole.channel["deleteChannel"].read && <button style={{ marginRight: "15px" }} onClick={() => userRole.channel["deleteChannel"].write && this.attachedChannelData(this.state.channelInfo)}>
                                <svg width="40" height="40" viewBox="0 0 24 24"
                                    style={{ border: "1px solid #C4C4C4", borderRadius: "3px", padding: "7px", fontSize: "25px" }}
                                    fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M17.0226 18.5346C17.0043 18.9706 16.6306 19.3282 16.1934 19.3282H7.81411C7.37684 19.3282 7.00293 18.9699 6.98488 18.5346L6.50467 7.01029C6.49864 6.86581 6.37662 6.75366 6.23215 6.75969C6.08767 6.76572 5.97534 6.88773 5.98136 7.03221L6.46158 18.5565C6.49154 19.2723 7.0961 19.8521 7.81414 19.8521H16.1934C16.9111 19.8521 17.516 19.2734 17.546 18.5565L18.0262 7.03221C18.0322 6.88773 17.9199 6.76572 17.7754 6.75969C17.6309 6.75366 17.5089 6.86581 17.5029 7.01029L17.0226 18.5346ZM11.7416 17.4953C11.7416 17.6399 11.8589 17.7572 12.0035 17.7572C12.1482 17.7572 12.2655 17.6399 12.2655 17.4953V8.5923C12.2655 8.44763 12.1482 8.33037 12.0035 8.33037C11.8589 8.33037 11.7416 8.44763 11.7416 8.5923V17.4953ZM9.12328 17.5029C9.12748 17.6476 9.24821 17.7612 9.39269 17.757C9.53735 17.7528 9.65096 17.6321 9.64676 17.4876L9.38484 8.58463C9.38063 8.44015 9.26008 8.32636 9.11542 8.33074C8.97095 8.33494 8.85715 8.45549 8.86135 8.60015L9.12328 17.5029ZM14.3603 17.4876C14.3561 17.6321 14.4697 17.7528 14.6144 17.757C14.7589 17.7612 14.8796 17.6476 14.8838 17.5029L15.1457 8.59997C15.1499 8.4553 15.0361 8.33475 14.8917 8.33055C14.747 8.32617 14.6264 8.43996 14.6222 8.58444L14.3603 17.4876ZM14.7994 5.44988H18.5498C18.6945 5.44988 18.8118 5.56715 18.8118 5.71181C18.8118 5.85647 18.6945 5.97374 18.5498 5.97374H5.45724C5.31258 5.97374 5.19531 5.85647 5.19531 5.71181C5.19531 5.56715 5.31258 5.44988 5.45724 5.44988H9.20766L9.42209 4.91398C9.59616 4.4789 10.0957 4.14062 10.5633 4.14062H13.4438C13.9128 4.14062 14.4104 4.47762 14.585 4.91398L14.7994 5.44988ZM14.2354 5.44988L14.0988 5.1085C14.0036 4.87087 13.6986 4.66447 13.4438 4.66447H10.5633C10.3098 4.66447 10.0029 4.87215 9.9083 5.1085L9.77168 5.44988H14.2354Z" fill="#800001" />
                                </svg>
                            </button>}
                        </div>
                    </div>
                    {!this.state.isPreview ? <DragDropContext onDragEnd={this.handleDragEnd}>
                        <div className="flex height90vh">
                            <div className="overflow-y-auto grow"><Droppable droppableId="channelList">
                                {(provider) => (
                                    <div className="flex-1 mx-2" ref={provider.innerRef} {...provider.droppableProps}>
                                        {userRole.channel["addContent"].read && (userRole.channel["selectPlayListChannel"].read || userRole.channel["selectAppChannel"].read ||
                                            userRole.channel["selectMediaChannel"].read || userRole.channel["selectLinkChannel"].read
                                        ) && <div className={"flex justify-end my-2 mx-2 px-10 "}>
                                                <button className="flex text-darkBlue cursor-pointer hover:underline p-2"
                                                    onClick={() => userRole.channel["addContent"].write ? this.addContentModel() : null}>
                                                    <FaPlus className="self-end self-center m-1" size={12} />
                                                    <span className="self-end self-center">{"Add Content"}</span>
                                                </button>
                                            </div>}
                                        {(this.state.selectedChannelList.length === 0) && <DataIsEmpty url={process.env.PUBLIC_URL + "/channel.svg"}
                                            title="This channel is nothing without content"
                                            body1="Add your playlists or individual pieces of content and schedule them for something great."
                                            buttonName={"Add Content"}
                                            customFunction={() => userRole.channel["addContent"].write ? this.addContentModel() : null}
                                        />}
                                        <div className="mx-2 px-10">
                                            {this.state.selectedChannelList?.map((mpl, i) => (
                                                <Draggable
                                                    key={"id_" + i + "_" + mpl._id}
                                                    draggableId={"id_" + i + "_" + mpl._id}
                                                    index={i}>
                                                    {(mplProvider) => (
                                                        <div className="group flex">
                                                            <div className="flex-1 flex border-b-2 p-1 m-1 bg-white hover:shadow-md rounded-lg	"{...mplProvider.dragHandleProps} {...mplProvider.draggableProps} ref={mplProvider.innerRef}>
                                                                <div className="flex flex-1 self-center" >
                                                                    {mpl.type === "MEDIA" ?
                                                                        mpl.contentType.includes("video") ? <div className="rounded-lg flex p-2 border-1 w-10 h-10">
                                                                            <FaFilm className="self-center" size={20} opacity={0.8} />
                                                                        </div> :
                                                                            <img className="rounded-lg border-solid border-1 w-10 h-10 flex items-center justify-center" src={mpl.url} alt="loadding..." />
                                                                        : <button className={"rounded-lg cursor-pointer border-solid w-10 h-10 border-1 flex items-center justify-center"}
                                                                            onClick={() => mpl.type === "PLAYLIST" && this.props.router.navigate("/playlist/" + mpl._id)}>
                                                                            {mpl.type === "LINK" ? <Link /> :
                                                                                mpl.type === "APP" ?
                                                                                    <AppIcon style={"self-center fill-black"} type={mpl.appType} />
                                                                                    : <Playee size={25} opacity={0.8} color="white" />
                                                                            }
                                                                        </button>
                                                                    }

                                                                    <div className="ml-2 self-center xl:w-36 lg:w-24 w-10 inline-block truncate flex flex-col" title={mpl.name}>
                                                                        <span className="text-black text-sm truncate">{mpl.name}</span>
                                                                        {mpl.type === "MEDIA" && <div className="flex justify-between text-gray-450 text-xs"><span>{mpl.contentType && mpl.contentType.includes("video") ? "Video" : "Image"}</span><span>{mpl.size}</span></div>}
                                                                        {mpl.type === "APP" && <div className="flex justify-between text-gray-450 text-xs"><span>{mpl.appType}</span></div>}
                                                                        {mpl.type === "LINK" && <div className="flex justify-between text-gray-450 text-xs"><span>{"LINK"}</span></div>}
                                                                        {mpl.type === "PLAYLIST" && <div className="flex justify-between text-gray-450 text-xs"><span>{"PLAYLIST"}</span></div>}

                                                                    </div>

                                                                </div>
                                                                <div className={"flex flex-1 self-center justify-center child group-hover:block hidden " + (userRole.channel["editDuration"].read ? "visible" : "invisible")} >
                                                                    {mpl.type === "MEDIA" ?
                                                                        <InputDuration value={mpl.duration ? mpl.duration : "00:00:00"}
                                                                            isDisabled={mpl.contentType.includes("video")}
                                                                            onChange={(value) => this.handleTimeChange(value, i)} /> :
                                                                        mpl.type === "LINK" || mpl.type === "APP" ?
                                                                            <InputDuration value={mpl.duration ? mpl.duration : "00:00:00"}
                                                                                isDisabled={(mpl.type === "APP" && mpl.appType == "YOUTUBE")}
                                                                                onChange={(value) => this.handleTimeChange(value, i)} />
                                                                            :
                                                                            <InputDuration value={mpl.totalDuration ? mpl.totalDuration : "00:00:00"}
                                                                                isDisabled={true}
                                                                                onChange={(value) => { }} />}
                                                                </div>
                                                                <div className="flex-col flex-1 justify-center mx-2">
                                                                    {userRole.channel["scheduler"].read && mpl.schedulingList && (
                                                                        <div className="flex child group-hover:block hidden">
                                                                            {<div className="hover:bg-gray-100 text-xs cursor-pointer rounded-lg w-auto h-auto p-1 border-1 border-gray-300 hover:border-black" onClick={() => userRole.channel["scheduler"].write && this.openScheduler(mpl, i)}>
                                                                                <div className="flex gap-1 justify-center text-center text-white font-medium">
                                                                                    {mpl.schedulingList.isEveryDay ? <div className="flex-1 rounded-lg bg-darkBlue">Every Day</div> :
                                                                                        <div className="flex-1 rounded-lg bg-currentSecondaryColor">Custom Days</div>}
                                                                                    {mpl.schedulingList.setPriority && <div className="flex-1 rounded-lg bg-red-800 flex justify-center gap-2"><FaStar className=" self-center text-white" size={8} /> Priority</div>}
                                                                                </div>
                                                                                <div className="flex justify-between text-gray-450">
                                                                                    <div className="flex gap-1">
                                                                                        <Clock style={!mpl.schedulingList.isAllTime ? "fill-black self-center" : "fill-gray-450 self-center"} /> {!mpl.schedulingList.isAllTime ? <span className="text-gray-700">Custom</span> : <span>24 Hours</span>}
                                                                                    </div>
                                                                                    <div className="flex gap-1 text-xs">
                                                                                        <Calender style={!mpl.schedulingList.isAllDate ? "fill-black self-center" : "fill-gray-450 self-center"} /> {!mpl.schedulingList.isAllDate ? <span className="text-gray-700">Scheduled</span> : <span>Lifetime</span>}
                                                                                    </div>
                                                                                </div>
                                                                            </div>}
                                                                        </div>)
                                                                    }
                                                                </div>
                                                                <div className="flex flex-1 self-center justify-end gap-3" >
                                                                    {(mpl.type === "MEDIA" || mpl.type === "LINK" || mpl.type === "APP") && userRole.channel["mediaLogs"].read && <TooltipComponent className="self-center" content={"Toggle will be tracking all media logs."}>
                                                                        <ToggalButton value={mpl.isAuditLog} label={"Logs"} customFunction={() => {
                                                                            if (userRole.channel["mediaLogs"].write) {
                                                                                this.state.selectedChannelList[i].isAuditLog = !mpl.isAuditLog;
                                                                                this.setState({ selectedChannelList: this.state.selectedChannelList });
                                                                                this.updateChannelList();
                                                                            }
                                                                        }} /></TooltipComponent>}
                                                                    <div className="flex">
                                                                        <svg width="24" height="14" className="self-center" viewBox="0 0 24 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <rect width="24" height="2" rx="1" fill="#D9D9D9" />
                                                                            <rect y="4" width="24" height="2" rx="1" fill="#D9D9D9" />
                                                                            <rect y="8" width="24" height="2" rx="1" fill="#D9D9D9" />
                                                                            <rect y="12" width="24" height="2" rx="1" fill="#D9D9D9" />
                                                                        </svg>
                                                                    </div>
                                                                    {userRole.channel["removeContent"].read && <div className="w-2 self-center"><IconButton icon={<FaTimes size={15} />} toolTip={""} customFunc={() => {
                                                                        if (userRole.channel["removeContent"].write) {
                                                                            this.state.selectedChannelList.splice(i, i !== -1 ? 1 : 0);
                                                                            this.setState({ selectedChannelList: this.state.selectedChannelList });
                                                                            this.updateChannelList();
                                                                        }
                                                                    }} /></div>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                        </div>
                                        {provider.placeholder}
                                    </div>)}
                            </Droppable></div>
                            <div className={"basis-80 border-l-1 text-sm font-medium border-slate-300 bg-white overflow-y-auto "
                                + ((userRole.channel["selectPlayListChannel"].read || userRole.channel["selectAppChannel"].read ||
                                    userRole.channel["selectMediaChannel"].read || userRole.channel["selectLinkChannel"].read
                                ) ? "visible" : "collapse")
                            }>
                                <div className="flex text-center">
                                    {userRole.channel["selectPlayListChannel"].read && <div className={"flex-1"}  >
                                        <button
                                            className={this.state.isPlayListSelected ? "p-2 text-currentSecondaryColor" : "p-2"}
                                            onClick={() => this.onTabChange("isPlayListSelected")}>Playlist</button>
                                        {this.state.isPlayListSelected && <hr className="border-4 rounded-lg mx-3" style={{ borderColor: "#00807F", opacity: "inherit" }} />}
                                    </div>}
                                    {userRole.channel["selectMediaChannel"].read && <div className={"flex-1"}  >
                                        <button
                                            className={this.state.isImageSelected ? "p-2 text-currentSecondaryColor" : "p-2"}
                                            onClick={() => this.onTabChange("isImageSelected")}>Media</button>
                                        {this.state.isImageSelected && <hr className="border-4 rounded-lg mx-3" style={{ borderColor: "#00807F", opacity: "inherit" }} />}
                                    </div>}
                                    {userRole.channel["selectLinkChannel"].read && <div className={"flex-1"}  >
                                        <button
                                            className={this.state.islinkSelected ? "p-2 text-currentSecondaryColor" : "p-2"}
                                            onClick={() => this.onTabChange("islinkSelected")}>Links</button>
                                        {this.state.islinkSelected && <hr className="border-4 rounded-lg mx-3" style={{ borderColor: "#00807F", opacity: "inherit" }} />}
                                    </div>}
                                    {userRole.channel["selectAppChannel"].read && <div className={"flex-1"}  >
                                        <button
                                            className={this.state.isAppSelected ? "p-2 text-currentSecondaryColor" : "p-2"}
                                            onClick={() => this.onTabChange("isAppSelected")}>Apps</button>
                                        {this.state.isAppSelected && <hr className="border-4 rounded-lg mx-3" style={{ borderColor: "#00807F", opacity: "inherit" }} />}
                                    </div>}
                                </div>
                                <hr />
                                {<div>
                                    {userRole.channel["selectPlayListChannel"].read && this.state.isPlayListSelected ? <Droppable droppableId="playListData">
                                        {(provider) => (
                                            <div className="divide-y m-2 " ref={provider.innerRef}
                                                {...provider.droppableProps}>
                                                {this.state.playListInfo?.map((m, index) => {
                                                    return (
                                                        <DragPlayList media={m} index={index} />
                                                    )
                                                })}
                                                {provider.placeholder}
                                            </div>)}
                                    </Droppable> : (userRole.channel["selectMediaChannel"].read && this.state.isImageSelected) ? <Droppable droppableId="mediaList">
                                        {(provider) => (
                                            <div className="divide-y m-2 divide-gray-200 " ref={provider.innerRef}
                                                {...provider.droppableProps}>
                                                <span className="m-1 flex"><Breadcrumb className="p-0 m-0 truncate">
                                                    {this.state.dragPathList.map((pl, i) => (
                                                        <Breadcrumb.Item key={i} className={!pl.isActive ? "text-gray-800 hover:bg-gray-100 " :
                                                            "text-black hover:bg-gray-200 "} onClick={() => this.onDragBreadCrumbChnage(pl, i)}>{pl.folderName}</Breadcrumb.Item>
                                                    ))}
                                                </Breadcrumb></span>
                                                {this.state.mediaList?.map((m, index) => {
                                                    return (
                                                        <DragMediaList media={m} index={index} selectedMediaFile={() => this.onDragFolderCilck(m)} />
                                                    )
                                                })}
                                                {provider.placeholder}
                                            </div>)}
                                    </Droppable> : (userRole.channel["selectLinkChannel"].read && this.state.islinkSelected) ? <div>
                                        <Droppable droppableId="linkListData">
                                            {(provider) => (
                                                <div className="divide-y m-2 divide-gray-200 " ref={provider.innerRef}
                                                    {...provider.droppableProps}>
                                                    {this.state.linkListData?.map((m, index) => {
                                                        return (
                                                            <DragLinkList media={m} index={index} />
                                                        )
                                                    })}
                                                    {provider.placeholder}
                                                </div>)}
                                        </Droppable>
                                    </div> : (userRole.channel["selectAppChannel"].read) && <Droppable droppableId="appListData">
                                        {(provider) => (
                                            <div className="divide-y m-2 divide-gray-200 " ref={provider.innerRef}
                                                {...provider.droppableProps}>
                                                {this.state.appDataList?.map((m, index) => {
                                                    return (
                                                        <DragLinkList media={m} index={index} />
                                                    )
                                                })}
                                                {provider.placeholder}
                                            </div>)}
                                    </Droppable>}
                                </div>}
                            </div>
                        </div>
                    </DragDropContext> :
                        <ShowPreview previewList={this.state.previewList} />}
                </div>
                <Modal show={this.state.deleteChannelModel} onHide={this.closeAll}>
                    <Modal.Header closeButton>
                        <Modal.Title>Delete Channel</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>Are you sure, you would like to delete this channel permanently?</div>
                        <br />
                        {this.state.attachedList && this.state.attachedList.screenList && this.state.attachedList.screenList.length > 0 && <div>
                            <span className="text-gray font-bold text-sm">Attached to screen</span>
                            {this.state.attachedList.screenList?.map((s) => (
                                <div className="text-xs">- {s.name}</div>
                            ))}
                        </div>}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-pr" onClick={this.closeAll}>
                            Cancel
                        </Button>
                        <Button className="btn btn-prime" onClick={this.deleteChannel}>
                            Delete
                        </Button>

                    </Modal.Footer>
                </Modal>
                <Modal
                    show={this.state.updateScedulerModel}
                    aria-labelledby="contained-modal-title-vcenter"
                    size="lg"
                    centered>
                    <Modal.Body>
                        <div className="flex justify-between my-2">
                            <div className="flex gap-2"><span>Play Scheduled</span>
                                <img
                                    className="self-center w-5 h-5"
                                    src={process.env.PUBLIC_URL + "/info.svg"} alt="Info" /></div>
                            <FaTimes onClick={this.closeAll} size={25} className="text-gray-400 hover:text-gray-600" />
                        </div>
                        <div style={{ height: "40vh" }}>
                            <TableDragSelect
                                columnHeaders={["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"]}
                                rowHeaders={["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"]}
                                value={this.state.schedulingData} onChange={(e) => {

                                    console.log(e);

                                    this.setState({ schedulingData: e })
                                }} />
                        </div>
                        <div className="flex justify-end gap-2 text-xs m-2">
                            <div className="bg-currentSecondaryColor opacity-30	 w-3 h-3 self-center"></div>
                            <span>Scheduled Hours</span>
                        </div>
                        {this.state.selectedschedulingInfo && <div className="flex justify-center gap-4 text-xs m-2">
                            <div className="flex gap-2">
                                <span className={this.state.selectedschedulingInfo.isAllDate ? "text-black self-center text-sm" : "self-center text-gray-450 text-sm"}>Lifetime</span>
                                <ToggalSetButton value={!this.state.selectedschedulingInfo.isAllDate} customFunction={() => {
                                    this.state.selectedschedulingInfo.isAllDate = !this.state.selectedschedulingInfo.isAllDate;
                                    if (this.state.selectedschedulingInfo.isAllDate) {
                                        this.state.selectedschedulingInfo.selectedDate = [];
                                    } else {
                                        this.state.selectedschedulingInfo.selectedDate.push({
                                            from: this.state.dateCurrent,
                                            to: this.state.dateCurrent
                                        });
                                    }
                                    this.setState({ selectedschedulingInfo: this.state.selectedschedulingInfo });
                                }} />
                            </div>
                            <button className={this.state.selectedschedulingInfo.isAllDate ? "flex gap-2 text-gray-450" : "flex gap-2 text-black"} disabled={this.state.selectedschedulingInfo.isAllDate}
                            >
                                <span className="text-sm self-center">Scheduled</span>
                                <Calender style={this.state.selectedschedulingInfo.isAllDate ? "self-center w-6 h-6 fill-gray-450" : "self-center h-6 w-6 fill-black"} />
                            </button>
                        </div>}
                        <div className="m-1">{this.state.selectedschedulingInfo &&
                            this.state.selectedschedulingInfo.selectedDate?.map((d, di) => {
                                return (<div className="flex gap-2 justify-center text-xs text-gray-450 ">
                                    <div className="flex gap-1">
                                        <div className="text-gray-450">Start Date : </div>
                                        <div>
                                            <DatePicker
                                                className="self-center text-black"
                                                onChange={(e) => {
                                                    let date = convertDate(new Date(e))
                                                    this.state.selectedschedulingInfo.selectedDate[di].from = date;
                                                    this.state.selectedschedulingInfo.selectedDate[di].to = date;
                                                    this.setState({ selectedschedulingInfo: this.state.selectedschedulingInfo });
                                                }}
                                                selected={d.from}
                                                minDate={convertDate(new Date())}
                                                dateFormat="dd/MM/yyyy"
                                            />
                                        </div>
                                    </div>
                                    <div className="flex gap-1"><div className="self-center text-gray-450">End Date : </div>
                                        <div>
                                            <DatePicker
                                                className="self-center text-black"
                                                onChange={(e) => {
                                                    this.state.selectedschedulingInfo.selectedDate[di].to = convertDate(new Date(e));
                                                    this.setState({ selectedschedulingInfo: this.state.selectedschedulingInfo });
                                                }}
                                                selected={d.to}
                                                minDate={d.from}
                                                dateFormat="dd/MM/yyyy"
                                            />
                                        </div>
                                    </div>
                                </div>)
                            })
                        }</div>
                        <hr />
                        <div className="mx-2">
                            <div><span className="font-medium">Set as Priority</span><br /></div>
                            <div className="flex self-center justify-between">
                                <div className="flex-1"><span className="text-sm text-gray-450">This content will override any other content<br /> scheduled for the same time period</span>
                                </div>
                                {this.state.selectedschedulingInfo && <ToggalSetButton value={this.state.selectedschedulingInfo.setPriority} customFunction={() => {
                                    this.state.selectedschedulingInfo.setPriority = !this.state.selectedschedulingInfo.setPriority;
                                    this.setState({ selectedschedulingInfo: this.state.selectedschedulingInfo });
                                }} />}
                            </div>
                        </div>
                        <div className="flex justify-end">
                            <button className="rounded-lg p-2 shadow bg-darkBlue text-white" onClick={() => this.onSchedulerSave()}>Save</button>
                        </div>
                    </Modal.Body>
                </Modal>
                <SetToScreenModel
                    setToScreenModel={this.state.setToScreenModel}
                    closeAll={this.closeAll}
                    searchScreenName={this.searchScreenName}
                    selectAllScreen={() => {
                        let isSelected = !this.state.selectAll ? true : false;
                        this.state.screenList.forEach((sl) => sl.isSelected = isSelected);
                        this.setState({ screenList: this.state.screenList, selectAll: !this.state.selectAll })
                    }}
                    isSelectAll={this.state.selectAll}
                    screenList={this.state.screenList}
                    routeTo={() => this.props.router.navigate("/screens")}
                    onScreenSelected={(s, i) => {
                        this.state.screenList[i].isSelected = !this.state.screenList[i].isSelected;
                        let isAllSelected = true;
                        this.state.screenList.forEach((si) => {
                            if (!si.isSelected)
                                isAllSelected = false;
                        })
                        this.setState({ screenList: this.state.screenList, selectAll: isAllSelected });
                    }}
                    setToScreenService={this.setToScreenService}
                />
                <Modal show={this.state.addContent}
                    size="lg"
                    centered>
                    <Modal.Body className="m-0 p-0 rounded-md">
                        <div className="flex m-3 " >
                            <h1 id="medpic" className="grow text-xl font-semibold" >Media Picker</h1>
                            <FaTimes onClick={() => this.closeMediaPicker()} size={25} className="text-gray-400 hover:text-gray-600" />
                        </div>
                        <hr />
                        <div className="flex " style={{ height: "75vh" }}>
                            <div className="basis-1/5	bg-white" >
                                <br />
                                {userRole.channel["selectPlayListChannel"].read && <AddContentButtons isSelected={this.state.addContentSlider.isPlayList} customFunction={() => this.onContentTabChange("isPlayList")} label="PlayList" icon={<Playee className="self-center mr-2 " size={20} />} />}
                                {userRole.channel["selectMediaChannel"].read && <AddContentButtons isSelected={this.state.addContentSlider.isMedia} customFunction={() => this.onContentTabChange("isMedia")} label="Media" icon={<Mediaa className="self-center mr-2" size={20} />} />}
                                {userRole.channel["selectLinkChannel"].read && <AddContentButtons isSelected={this.state.addContentSlider.isLinks} customFunction={() => this.onContentTabChange("isLinks")} label="Links" icon={<Link style="self-center mr-2" size={20} />} />}
                                {userRole.channel["selectAppChannel"].read && <AddContentButtons isSelected={this.state.addContentSlider.isApps} customFunction={() => this.onContentTabChange("isApps")} label="Apps" icon={<Appss className="self-center mr-2" size={20} />} />}
                            </div>
                            <div className="flex-auto" style={{ backgroundColor: "#F3F6FA", paddingTop: "12px", paddingLeft: "20px", paddingRight: "20px" }}>
                                {userRole.channel["selectPlayListChannel"].read && this.state.addContentSlider.isPlayList ?
                                    <ChannelAddPlaylistContent mediaList={this.state.playListList} selectedMediaFile={this.selectedPlayListMediaFile} type={"PLAYLIST"}
                                        searchMedia={this.state.searchMediaName} onMediasearch={(e) => this.searchMediaList(e.target.value, "playListList", "playListAllList")} /> :
                                    userRole.channel["selectMediaChannel"].read && this.state.addContentSlider.isMedia ?
                                        <div>
                                            <Breadcrumb className="px-2">
                                                {this.state.pathList.map((pl, i) => (
                                                    <Breadcrumb.Item key={i} className={!pl.isActive ? "text-gray-800" :
                                                        "text-black"} onClick={() => this.onBreadCrumbChanage(pl, i)}>{pl.folderName}</Breadcrumb.Item>
                                                ))}
                                            </Breadcrumb>
                                            <ChannelAddContent selectedMediaFile={this.selectedMediaFile} mediaList={this.state.mediaAllList} routeTo={() => this.props.router.navigate("/media")} type="Media"
                                                searchMedia={this.state.searchMediaName} onMediasearch={(e) => this.searchMediaList(e.target.value, "mediaAllList", "mediaList")} /> </div> :
                                        this.state.addContentSlider.isLinks ? <ChannelAddContent selectedMediaFile={this.selectedMediaFile} mediaList={this.state.linkList} routeTo={() => this.props.router.navigate("/links")} type="Link"
                                            searchMedia={this.state.searchMediaName} onMediasearch={(e) => this.searchMediaList(e.target.value, "linkList", "linkAllList")} /> :
                                            <ChannelAddContent selectedMediaFile={this.selectedMediaFile} mediaList={this.state.appList} routeTo={() => this.props.router.navigate("/app")} type="App"
                                                searchMedia={this.state.searchMediaName} onMediasearch={(e) => this.searchMediaList(e.target.value, "appList", "appDataList")} />
                                }
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="justify-end">
                        <div className="grow">{this.state.tempMediaList.length !== 0 && <p>Selected Files: {this.state.tempMediaList.length}</p>}</div>
                        {<Button variant="" className="hover:bg-gray-300" onClick={() => this.closeMediaPicker()}>CANCEL</Button>}
                        {<Button variant="outline-primary" className="mr-2" id="addbtn" disabled={(this.state.tempMediaList.length !== 0) ? false : true}
                            onClick={() => {
                                this.state.selectedChannelList = this.state.selectedChannelList.concat(this.state.tempMediaList);
                                this.setState({ selectedChannelList: this.state.selectedChannelList });
                                this.updateChannelList();
                                this.closeMediaPicker();
                            }}>ADD</Button>}

                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.revertPublishModel} onHide={this.closeAll}>
                    <Modal.Body className="">
                        <div className="text-center m-2">
                            <div className="text-gray-500">Are you sure, do you want to revert last publish ?</div>
                            <br />
                        </div>
                        <div className="flex justify-center m-2">
                            {<Button variant="" id="btn-cancel" onClick={this.closeAll}>No</Button>}
                            {<Button variant="outline-primary" className="mr-2" id="addbtn" onClick={() => {
                                this.closeAll()
                                this.revertLastPublish();
                            }}>Yes</Button>}
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        )
    };
}

const mapStateToProps = (state) => ({
    isLoading: state.counter.isLoading,
    userRole: state.counter.userRole
});

const mapDispatchToProps = (dispatch) => ({
    counterSlice: bindActionCreators(commanActionCreator, dispatch)
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ChannelsDetailsComponent));
