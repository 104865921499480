import React from "react";
import { connect } from "react-redux";
import { withRouter } from "../../router";
import { bindActionCreators } from "@reduxjs/toolkit";
import * as commanActionCreator from '../../reducers/commanReduces'
import { fetchAppData, getRssLinkData } from "../../services/appServices";
import RssPreview from "../subComponents/rssPreview";
import { DataIsEmptyWithoutButton } from "../utilComponents/inputFieldAndButtons";

class RssAppPublicComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            rssNewsList: [],
            duration: 10
        }
    }
    async componentDidMount() {
        if (this.props.router.params.appId) {
            this.props.counterSlice.startLoading();
            let appInfo = await fetchAppData(this.props.router.params.appId);
            if (appInfo.success) {
                this.setState({
                    appInfo: appInfo.body,
                    appName: appInfo.body.name,
                    duration: appInfo.body.timer,
                    rssNewUrl: appInfo.body.link,
                });
                let rssNewsData = await getRssLinkData(appInfo.body.link);
                let newsList = [];
                if (rssNewsData !== "Error") {
                    for (let i = 0; i < rssNewsData.children[0].children.length; i++) {
                        let items = rssNewsData.children[0].children[i];
                        if (items.name === "item") {
                            let newsInfo = {};
                            for (let j = 0; j < items.children.length; j++) {
                                let innerdetails = items.children[j];
                                if (innerdetails.name === "title")
                                    newsInfo.title = innerdetails.value;

                                if (innerdetails.name === "pubDate")
                                    newsInfo.publishDate = innerdetails.value;

                                if (innerdetails.name === "description") {
                                    newsInfo.description = innerdetails.value.replace(/<\/?p>/g, '');
                                }

                                if (innerdetails.name === "media:content")
                                    newsInfo.url = innerdetails.attributes.url;
                            }
                            newsList.push(newsInfo);
                        }
                    }
                    this.setState({ rssNewsList: newsList });
                }

            }
            this.props.counterSlice.stopLoading();
        }
    }

    render() {

        return (
            <div className="flex flex-col flex-1 justify-center items-center bg-white h-screen w-full gap-4">
                {this.state.rssNewsList.length > 0 ? <RssPreview rssNewsList={this.state.rssNewsList} duration={this.state.duration} /> :
                    <DataIsEmptyWithoutButton url={process.env.PUBLIC_URL + "/blankScreenIcon.svg"}
                        title="App instance preview"
                        body1="Edit and save the configuration to preview this app instance" />}
            </div>
        )
    };
}

const mapStateToProps = (state) => ({
    isLoading: state.counter.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
    counterSlice: bindActionCreators(commanActionCreator, dispatch)
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RssAppPublicComponent));
