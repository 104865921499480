import React, { useState, useRef, useCallback, useEffect } from "react";
import debounce from "lodash/debounce";

const InputDuration = (props) => {
    const { onChange, value, isDisabled = false } = props;
    const cssStyle = `no-arrows text-black text-center text-xs border-black border-solid rounded border-1 p-1 border-black w-8 ${isDisabled ? "bg-gray-300 cursor-not-allowed" : ""}`;
    const [hours, setHours] = useState("00");
    const [minutes, setMinutes] = useState("00");
    const [seconds, setSeconds] = useState("00");

    useEffect(() => {
        const inputValue = value.split(":");
        setHours(inputValue[0]);
        setMinutes(inputValue[1]);
        setSeconds(inputValue[2]);
    }, [value]);

    const debouncedOnChange = useRef(debounce((newValue) => {
        if (onChange) {
            onChange(newValue);
        }
    }, 300)).current;

    const handleHoursChange = (e) => {
        let value = e.target.value.padStart(2, '0');
        setHours(value);
        debouncedOnChange(`${value}:${minutes}:${seconds}`);
    };

    const handleMinutesChange = (e) => {
        let value = parseInt(e.target.value);
        if (value >= 60) {
            setMinutes("00");
            setHours((prevHours) => {
                let newHours = (parseInt(prevHours) + 1).toString().padStart(2, '0');
                debouncedOnChange(`${newHours}:00:${seconds}`);
                return newHours;
            });
        } else {
            let formattedValue = value.toString().padStart(2, '0');
            setMinutes(formattedValue);
            debouncedOnChange(`${hours}:${formattedValue}:${seconds}`);
        }
    };

    const handleSecondsChange = (e) => {
        let value = parseInt(e.target.value);
        if (value >= 60) {
            setSeconds("00");
            setMinutes((prevMinutes) => {
                let newMinutes = parseInt(prevMinutes) + 1;
                if (newMinutes === 60) {
                    newMinutes = 0;
                    setHours((prevHours) => {
                        let newHours = (parseInt(prevHours) + 1).toString().padStart(2, '0');
                        debouncedOnChange(`${newHours}:00:00`);
                        return newHours;
                    });
                } else {
                    let formattedMinutes = newMinutes.toString().padStart(2, '0');
                    debouncedOnChange(`${hours}:${formattedMinutes}:00`);
                    return formattedMinutes;
                }
                return newMinutes.toString().padStart(2, '0');
            });
        } else {
            let formattedValue = value.toString().padStart(2, '0');
            setSeconds(formattedValue);
            debouncedOnChange(`${hours}:${minutes}:${formattedValue}`);
        }
    };

    return (
        <div className="flex gap-1 items-center">
            <input
                className={cssStyle}
                type="number"
                min={0}
                max={100}
                value={hours}
                disabled={isDisabled}
                onChange={handleHoursChange}
            />
            <span>:</span>
            <input
                className={cssStyle}
                type="number"
                min={0}
                max={60}
                value={minutes}
                disabled={isDisabled}
                onChange={handleMinutesChange}
            />
            <span>:</span>
            <input
                className={cssStyle}
                type="number"
                min={0}
                max={60}
                value={seconds}
                disabled={isDisabled}
                onChange={handleSecondsChange}
            />
        </div>
    );
};

export default InputDuration;
