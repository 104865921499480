import React from "react";

const GridViewIcon = (props) => {
  let { style } = props;
  return ( props.isSelected ? <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="8" y="8" width="4" height="4" fill="#1B438F"/>
  <rect x="15" y="8" width="4" height="4" fill="#1B438F"/>
  <rect x="22" y="8" width="4" height="4" fill="#1B438F"/>
  <rect x="8" y="15" width="4" height="4" fill="#1B438F"/>
  <rect x="15" y="15" width="4" height="4" fill="#1B438F"/>
  <rect x="22" y="15" width="4" height="4" fill="#1B438F"/>
  <rect x="8" y="22" width="4" height="4" fill="#1B438F"/>
  <rect x="15" y="22" width="4" height="4" fill="#1B438F"/>
  <rect x="22" y="22" width="4" height="4" fill="#1B438F"/>
  <rect x="0.5" y="0.5" width="33" height="33" stroke="#1B438F" stroke-opacity="0.5"/>
  </svg> :
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="8" y="8" width="4" height="4" fill="#C4C4C4" />
      <rect x="15" y="8" width="4" height="4" fill="#C4C4C4" />
      <rect x="22" y="8" width="4" height="4" fill="#C4C4C4" />
      <rect x="8" y="15" width="4" height="4" fill="#C4C4C4" />
      <rect x="15" y="15" width="4" height="4" fill="#C4C4C4" />
      <rect x="22" y="15" width="4" height="4" fill="#C4C4C4" />
      <rect x="8" y="22" width="4" height="4" fill="#C4C4C4" />
      <rect x="15" y="22" width="4" height="4" fill="#C4C4C4" />
      <rect x="22" y="22" width="4" height="4" fill="#C4C4C4" />
    </svg> 
  )
};

export default GridViewIcon;
