import React from "react";
import { connect } from "react-redux";
import { withRouter } from "../router";

import * as commanActionCreator from '../reducers/commanReduces';
import ScreenlogMonitor from "./subComponents/screenlogMonitor";
import MediaLogs from "./subComponents/MediaLogs";
import Sidebar from "./subComponents/Sidebar";
import Navbar from "./subComponents/Navbar";
import Monitoring from "../appIcons/Monitoring";
import { bindActionCreators } from "@reduxjs/toolkit";
import UserActivityComponent from "./subComponents/userActivityComponent";

class ScreensMonitorComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            userPage: 1,
            userTotalCount: 0,
            userActivityList: [],
            totalCount: 0,
            screenHealth: true,
            userActivity: false,
            proofOfPlay: false,
        }
    }
    onTabChange = (type) => {
        this.setState({ screenHealth: false, userActivity: false, proofOfPlay: false, });
        this.setState({ [type]: true })
    }

    render() {
        const userRole = JSON.parse(localStorage.getItem("roles"));

        return (
            <div className="flex">
                <Sidebar type={"monitor"} />
                <div className="h-screen grow md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10 shadow">
                    <Navbar router={this.props.router} counterSlice={this.props.counterSlice} />
                    <div className="p-3 flex bg-white border-b-1 border-slate-300 h-auto gap-2">
                        <Monitoring style="h-8 w-8 fill-black" />
                        <span className="self-center text-xl font-medium">Monitoring</span>
                    </div>
                    <div className="mt-2 mx-32">
                        <div className="basis-1/4 border-b-1 border-gray-300 ">
                            <div className="flex text-center">
                                <div className={"flex-1"}>
                                    <button
                                        className={this.state.screenHealth ? "p-2 text-currentSecondaryColor" : "p-2"}
                                        onClick={() => this.onTabChange("screenHealth")}>Screen Health</button>
                                    {this.state.screenHealth && <hr className="border-4 rounded-lg mx-24" style={{ borderColor: "#00807F", opacity: "inherit" }} />}
                                </div>
                                <div className={"flex-1"}>
                                    <button
                                        className={this.state.proofOfPlay ? "p-2 text-currentSecondaryColor" : "p-2"}
                                        onClick={() => this.onTabChange("proofOfPlay")}>Proof Of Play</button>
                                    {this.state.proofOfPlay && <hr className="border-4 rounded-lg mx-24" style={{ borderColor: "#00807F", opacity: "inherit" }} />}
                                </div>
                                <div className={"flex-1"}>
                                    <button
                                        className={this.state.userActivity ? "p-2 text-currentSecondaryColor" : "p-2"}
                                        onClick={() => this.onTabChange("userActivity")}>User Activity</button>
                                    {this.state.userActivity && <hr className="border-4 rounded-lg mx-24" style={{ borderColor: "#00807F", opacity: "inherit" }} />}
                                </div>
                            </div>
                        </div>
                        {this.state.screenHealth && <>
                            {userRole.monitoring["detailScreenLogSH"].write ?
                                <ScreenlogMonitor /> : <div className="text-center flex justify-center mt-20">You do not have access for this page, please contact admin for more details.</div>
                            }
                        </>}
                        {this.state.proofOfPlay && <>{userRole.monitoring["detaiPOPLogs"].write ? <MediaLogs /> : <div className="text-center flex justify-center mt-20">You do not have access for this page, please contact admin for more details.</div>
                        }</>}
                        {this.state.userActivity && <>{userRole.monitoring["downaloadUA"].write ? <UserActivityComponent /> : <div className="text-center flex justify-center mt-20">You do not have access for this page, please contact admin for more details.</div>
                        }</>}
                    </div >
                </div>
            </div>
        )
    };
}
const mapStateToProps = (state) => ({
    isLoading: state.counter.isLoading
});

const mapDispatchToProps = (dispatch) => ({
    counterSlice: bindActionCreators(commanActionCreator, dispatch)

})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ScreensMonitorComponent));
