import React from "react";
import { connect } from "react-redux";
import { withRouter } from "../router";
import { ToastContainer, toast } from 'react-toastify';
import { bindActionCreators } from "@reduxjs/toolkit";
import * as commanActionCreator from '../reducers/commanReduces'
import Navbar from "./subComponents/Navbar";
import { format } from 'date-fns'
import { InputFieldWithEdit, } from "./utilComponents/inputFieldAndButtons";
import { MdArrowBackIos, MdStayCurrentLandscape, MdStayCurrentPortrait } from "react-icons/md";
import { Button, Form } from "react-bootstrap";
import { updateMedia } from "../services/mediaService";
import Soccer from "../appIcons/Soccer";
import { createApp, fetchSoccerMatchesService, fetchAppData, fetchSoccerCountryList, fetchSoccerLeaguesService } from "../services/appServices";
import SoccerMatchPreview from "./subComponents/soccerMatchPreview";

class SoccerAppDetailsComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isNameEdit: false,
            isLandscape: true,
            appName: "Soccer App",
            idDisabled: true,
            isUpdate: false,
            duration: 1,
            countryList: [],
            leaguesList: [],
            matchList: [],
        }
    }
    async componentDidMount() {
        this.props.counterSlice.startLoading();
        let countryList = await fetchSoccerCountryList();
        if (countryList && countryList.status == 200) {
            this.setState({ countryList: countryList.data })
        }
        if (this.props.router.params.appId) {
            let appInfo = await fetchAppData(this.props.router.params.appId);
            this.setState({
                appInfo: appInfo.body,
                appName: appInfo.body.name,
                isUpdate: true,
                duration: appInfo.body.soccerSlideduration,
                selectedCountry: appInfo.body.countryCode,
                selectedLeague: appInfo.body.leagueCode,
            });
            this.state.selectedCountry = appInfo.body.countryCode;
            this.state.selectedLeague = appInfo.body.leagueCode;

            setTimeout(() => {
                this.onCountryCode(appInfo.body.countryCode);
            }, 3000)
            setTimeout(() => {
                this.onLeagueChange(appInfo.body.leagueCode);
            }, 2000)
        }
        this.props.counterSlice.stopLoading();
    }

    updateSoccerApp = async () => {
        if (this.state.appName.trim() == "") {
            toast.error('App name should not be empty.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
            return;
        }
        this.props.counterSlice.startLoading();
        let appDTO = {
            _id: this.state.appInfo._id,
            name: this.state.appName.trim(),
            countryCode: this.state.selectedCountry,
            leagueCode: this.state.selectedLeague,
            soccerSlideduration: this.state.duration
        }
        let response = await updateMedia(appDTO);
        if (response.success) {
            toast.success('Soccer app updated successfully.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        } else {
            toast.error('Error while updating app.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        }
        this.props.counterSlice.stopLoading();
    }

    onCountryCode = async (countryCode) => {
        this.props.counterSlice.startLoading();
        let leaguesList = await fetchSoccerLeaguesService(countryCode);
        if (leaguesList.status == 200) {
            this.setState({ leaguesList: leaguesList.data, selectedCountry: countryCode })
        } else {
            toast.error('Leagues not started.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        }
        this.props.counterSlice.stopLoading();
    }

    onLeagueChange = async (leagueCode) => {
        this.props.counterSlice.startLoading();
        let matchList = await fetchSoccerMatchesService(this.state.selectedCountry, leagueCode);
        if (matchList && matchList.data && matchList.data.length > 0) {
            let matchListData = matchList.data.filter((data) => data.time.finish == null);
            this.setState({ matchList: matchListData, selectedLeague: leagueCode, idDisabled: (matchListData.length > 0) ? false : true })
        } else {
            toast.error('Leagues not started.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
            this.setState({ matchList: [] })

        }
        this.props.counterSlice.stopLoading();
    }

    createSoccerApp = async () => {

        this.props.counterSlice.startLoading();
        let appDTO = {
            name: this.state.appName.trim(),
            countryCode: this.state.selectedCountry,
            leagueCode: this.state.selectedLeague,
            soccerSlideduration: this.state.duration,
            appType: "SOCCER"
        }
        let response = await createApp(appDTO);
        if (response.success) {

            toast.success('Soccer app saved successfully.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
            this.props.router.navigate("/apps");

        } else {
            toast.error(response.message, {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        }
        this.props.counterSlice.stopLoading();
    }

    render() {
        const userRole = JSON.parse(localStorage.getItem("roles"));

        let screenSize = {height: "410px", width: "717px", "backgroundImage": `url(${process.env.PUBLIC_URL + "/soccer.svg"})`};
        let blankScreenSize = { height: "410px", width: "717px" }
        if (!this.state.isLandscape) {
            screenSize = { width: "55vh", height: "68vh", "backgroundImage": `url(${process.env.PUBLIC_URL + "/soccer.svg"})` };
            blankScreenSize = { width: "55vh", height: "68vh"};
        }
        return (
            <div>
                <Navbar router={this.props.router} counterSlice={this.props.counterSlice} />
                <div className="flex bg-white border-b-1 border-slate-300 w-full min-h-min	">
                    <div className="flex-none p-4 border-1"
                        onClick={() => { this.props.router.navigate(-1) }}>
                        <MdArrowBackIos size={20} />
                    </div>
                    <div className="self-center bg-dark-team rounded p-2 m-2">
                        <Soccer style="fill-white" />
                    </div>
                    <div className="px-2 py-3 flex flex-col self-center grow">
                        <InputFieldWithEdit
                            isEdit={this.state.isNameEdit}
                            isRead={userRole.app["editName"].read}

                            onKeyDown={(e) => {
                                if (this.state.appName.trim() !== "" && (e.key === "Enter" || e.key === "Tab" || e.key === undefined)) {
                                    this.setState({ isNameEdit: false });
                                } else this.setState({ isNameEdit: true });
                            }}
                            inputValue={this.state.appName}
                            placeholder="Enter Soccer App Name"
                            onInputchange={(e) => {userRole.app["editName"].write && userRole.app["editName"].write && this.setState({ appName: e.target.value }); }}
                            onEditClick={() => {userRole.app["editName"].write && userRole.app["editName"].write && this.setState({ isNameEdit: !this.state.isNameEdit }) }} />
                        {this.state.isUpdate ? <span className="order-last text-sm text-gray-450">{format(new Date(), 'dd/MM/yyyy HH:mm')}, modified by {this.state.appInfo != "" && this.state.appInfo.modifiedBy && <span>{this.state.appInfo.modifiedBy.firstName} {this.state.appInfo.modifiedBy.lastName}</span>} </span> :
                            <span className="order-last text-sm text-gray-450">{format(new Date(), 'dd/MM/yyyy HH:mm')}</span>}
                    </div>
                    <div className="flex gap-4 self-center" >
                        <Button className="bg-darkBlue text-white rounded p-2 mr-4"
                            disabled={this.state.idDisabled}
                            onClick={this.state.isUpdate ? this.updateSoccerApp : this.createSoccerApp}>{this.state.isUpdate ? "Update" : "Save"}</Button>
                    </div>
                </div>
                <div className="flex" style={{ height: "82vh" }}>
                    <div className="bg-white h-full w-1/3 px-12 pt-5 gap-4 flex flex-col">
                        <div className="flex flex-col mr-4">
                            <label>{"Leagues"}</label>
                            <div className="flex flex-1 gap-2">
                                <Form.Select className="flex flex-1" placeholder="Select clock type"
                                    value={this.state.selectedCountry}
                                    onChange={(e) => this.onCountryCode(e.target.value)}>
                                    <option value={""} key={""}>{"Select leagues type"}</option>
                                    {this.state.countryList?.map(c => (
                                        <option value={c.country_code} key={c.country_code}>{c.country_name}</option>
                                    ))}
                                </Form.Select>
                            </div>
                        </div>
                        <div className="flex flex-col mr-4">
                            <label>{"Season"}</label>
                            <div className="flex flex-1 gap-2">
                                <Form.Select className="flex flex-1" placeholder="Select clock type"
                                    value={this.state.diplayContent}
                                    onChange={(e) => this.onLeagueChange(e.target.value)}>
                                    <option value={""} key={""}>{"Select season"}</option>
                                    {this.state.leaguesList?.map(c => (
                                        <option value={c.league_code} key={c.league_code}>{c.league_name}</option>
                                    ))}
                                </Form.Select>
                            </div>
                        </div>
                        <div className="flex flex-col gap-1 mr-4">
                            <label>{"Duration"}</label>
                            <div className="flex gap-2">
                                <input type="number" id="inputo"
                                    max={20}
                                    min={1}
                                    className="border-1 border-gray-300 rounded p-2 text-black my-select"
                                    value={this.state.duration} onChange={(e) => (e.target.value < 21 && e.target.value > 0) && this.setState({ duration: e.target.value })}
                                    style={{ backgroundColor: "inherit", color: "white" }}
                                    placeholder="Enter duration" />
                            </div>
                        </div>
                    </div>
                    <div className="flex-1">
                        <div className="flex content-end m-4">
                            <button className="p-2 flex bg-black text-white rounded-lg font-bold border-solid border-1" onClick={() => {
                                this.setState({ isLandscape: !this.state.isLandscape })
                            }}>
                                {this.state.isLandscape ? <MdStayCurrentLandscape className="self-center font-bold mr-1 " size={25} /> :
                                    <MdStayCurrentPortrait className="self-center font-bold mr-1 " size={25} />}
                            </button>
                        </div>
                        <div className="flex justify-center">
                            {this.state.matchList.length > 0 ? <div className="bg-cover flex bg-center object-cover border-black border-4 shadow overflow-hidden text-white" style={screenSize}>
                                <SoccerMatchPreview soccerMatchList={this.state.matchList} duration={this.state.duration} />
                            </div> :
                                <div className="flex items-center bg-gray-800 justify-center border-black border-4 w-full shadow text-white" style={blankScreenSize}>
                                    <spna className="font-semibold text-2xl">End Of Season </spna>
                                </div>}
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </div >)
    };
}

const mapStateToProps = (state) => ({
    isLoading: state.counter.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
    counterSlice: bindActionCreators(commanActionCreator, dispatch)
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SoccerAppDetailsComponent));
