import React from 'react';

const ClockIcon = (props) => {
  let { style } = props;
  return (
    <svg width="26" height="26" className={style} viewBox="0 0 26 26"  xmlns="http://www.w3.org/2000/svg">
      <path d="M16.9729 12.6558H13.6331C13.5702 12.5478 13.4811 12.4586 13.373 12.3957V7.61586C13.373 7.41718 13.2117 7.25586 13.013 7.25586C12.8143 7.25586 12.653 7.41718 12.653 7.61586V12.3957C12.4386 12.5204 12.293 12.7501 12.293 13.0159C12.293 13.4136 12.6152 13.7359 13.013 13.7359C13.2788 13.7359 13.5084 13.5902 13.6331 13.3759H16.973C17.1717 13.3759 17.333 13.2145 17.333 13.0159C17.333 12.8172 17.1716 12.6558 16.9729 12.6558Z"   />
      <path d="M13.0147 0.0546875C5.86869 0.0546875 0.0546875 5.86869 0.0546875 13.0147C0.0546875 20.1607 5.86869 25.9747 13.0147 25.9747C20.1607 25.9747 25.9747 20.1607 25.9747 13.0147C25.9747 5.86869 20.1607 0.0546875 13.0147 0.0546875ZM13.0147 24.5347C6.66274 24.5347 1.49469 19.3666 1.49469 13.0147C1.49469 6.66274 6.66274 1.49469 13.0147 1.49469C19.3666 1.49469 24.5347 6.66274 24.5347 13.0147C24.5347 19.3666 19.3666 24.5347 13.0147 24.5347Z"   />
      <path d="M13.0136 2.93555C7.45571 2.93555 2.93359 7.45746 2.93359 13.0155C2.93359 18.5736 7.4555 23.0955 13.0136 23.0955C18.5717 23.0955 23.0936 18.5736 23.0936 13.0155C23.0936 7.45746 18.5717 2.93555 13.0136 2.93555ZM13.0136 22.3755C7.85274 22.3755 3.65359 18.1765 3.65359 13.0155C3.65359 7.85459 7.85263 3.65555 13.0136 3.65555C18.1746 3.65555 22.3736 7.85459 22.3736 13.0155C22.3736 18.1765 18.1746 22.3755 13.0136 22.3755Z"   />
      <path d="M9.18385 5.66446C9.08441 5.49229 8.86382 5.43323 8.69186 5.53267C8.51969 5.63191 8.46063 5.85229 8.55988 6.02446L8.91988 6.64803C8.98657 6.76354 9.10771 6.82803 9.23186 6.82803C9.29293 6.82803 9.35481 6.81256 9.41166 6.78002C9.58383 6.68057 9.64289 6.46019 9.54345 6.28803L9.18385 5.66446Z"   />
      <path d="M17.1057 19.3812C17.0061 19.2091 16.7857 19.1502 16.6137 19.2492C16.4416 19.3487 16.3825 19.5691 16.4818 19.7412L16.8418 20.3648C16.9084 20.4803 17.0296 20.5448 17.1537 20.5448C17.2148 20.5448 17.2767 20.5291 17.3335 20.4966C17.5057 20.3973 17.5648 20.177 17.4653 20.0048L17.1057 19.3812Z"   />
      <path d="M6.64803 8.92192L6.02446 8.56192C5.85249 8.46267 5.63231 8.52134 5.53267 8.6939C5.43323 8.86607 5.49229 9.08625 5.66446 9.18589L6.28803 9.54589C6.34468 9.57863 6.40676 9.5939 6.46783 9.5939C6.59218 9.5939 6.71312 9.52942 6.77961 9.4139C6.87906 9.24173 6.81999 9.02156 6.64803 8.92192Z"   />
      <path d="M20.3629 16.8438L19.7394 16.4838C19.5674 16.3845 19.347 16.4432 19.2474 16.6158C19.1481 16.7879 19.2072 17.0081 19.3794 17.1078L20.0029 17.4678C20.0596 17.5005 20.1215 17.5158 20.1825 17.5158C20.3071 17.5158 20.428 17.4513 20.4945 17.3358C20.5942 17.1636 20.5351 16.9434 20.3629 16.8438Z"   />
      <path d="M6.28803 16.4837L5.66446 16.8437C5.49229 16.9431 5.43323 17.1635 5.53267 17.3357C5.59917 17.4512 5.7203 17.5157 5.84446 17.5157C5.90553 17.5157 5.9674 17.5002 6.02426 17.4677L6.64783 17.1077C6.82 17.0082 6.87906 16.7879 6.77961 16.6157C6.67997 16.4435 6.45959 16.3845 6.28803 16.4837Z"   />
      <path d="M19.2475 9.41373C19.314 9.52924 19.4354 9.59373 19.5593 9.59373C19.6204 9.59373 19.6825 9.57826 19.7391 9.54571L20.3627 9.18571C20.5348 9.08627 20.5939 8.86589 20.4945 8.69373C20.3948 8.52156 20.1746 8.4627 20.0027 8.56174L19.3791 8.92174C19.2071 9.02138 19.1481 9.24156 19.2475 9.41373Z"   />
      <path d="M9.41186 19.2495C9.23989 19.1501 9.01952 19.2089 8.91987 19.3813L8.55987 20.0049C8.46063 20.177 8.5197 20.3972 8.69186 20.4969C8.74852 20.5296 8.81059 20.5449 8.87166 20.5449C8.99601 20.5449 9.11695 20.4804 9.18345 20.3649L9.54345 19.7413C9.64309 19.5691 9.58403 19.3488 9.41186 19.2495Z"   />
      <path d="M17.3337 5.53271C17.1622 5.43327 16.9414 5.49213 16.8417 5.6645L16.4817 6.28807C16.3825 6.46024 16.4416 6.68042 16.6137 6.78006C16.6704 6.8128 16.7325 6.82807 16.7935 6.82807C16.9179 6.82807 17.0388 6.76359 17.1053 6.64807L17.4653 6.0245C17.565 5.85233 17.5059 5.63196 17.3337 5.53271Z"   />
      <path d="M12.293 5.095C12.293 4.69723 12.6152 4.375 13.013 4.375C13.4107 4.375 13.733 4.69723 13.733 5.095C13.733 5.49277 13.4107 5.815 13.013 5.815C12.6152 5.815 12.293 5.49277 12.293 5.095Z"   />
      <path d="M12.293 20.9339C12.293 20.5361 12.6152 20.2139 13.013 20.2139C13.4107 20.2139 13.733 20.5361 13.733 20.9339C13.733 21.3316 13.4107 21.6539 13.013 21.6539C12.6152 21.6539 12.293 21.3316 12.293 20.9339Z"   />
      <path d="M20.2148 13.0149C20.2148 12.6172 20.5371 12.2949 20.9348 12.2949C21.3326 12.2949 21.6548 12.6172 21.6548 13.0149C21.6548 13.4127 21.3326 13.7349 20.9348 13.7349C20.5371 13.7349 20.2148 13.4127 20.2148 13.0149Z"   />
      <path d="M4.375 13.0149C4.375 12.6172 4.69723 12.2949 5.095 12.2949C5.49277 12.2949 5.815 12.6172 5.815 13.0149C5.815 13.4127 5.49277 13.7349 5.095 13.7349C4.69723 13.7349 4.375 13.4127 4.375 13.0149Z"   />
    </svg>
  )
}
export default ClockIcon;






