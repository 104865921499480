import * as Constants from '../../../constant';



async function addPlan(userDTO) {
    const token = localStorage.getItem("accessToken");
    return fetch(Constants.API_URL + "/admin/plan", {
        method: "POST",
        headers: { "Content-Type": "application/json" ,
    "authorization": "Barear " + token},
        body: JSON.stringify(userDTO),
    }).then((data) => data.json())
        .catch((error) => {
            return {};
        });
}


let getPlanDetails = async (id) => {
    const token = localStorage.getItem("accessToken");
    const url = `/admin/plan/${id}`
    return fetch(Constants.API_URL + url,  {
        method: "GET",
        headers: { "Content-Type": "application/json" ,
        authorization: "Barear " + token},
    }).then((data) => data.json())
        .catch((error) => {
            return {};
        });
}

let updatePlanDetails = async (playListDTO) => {
    const token = localStorage.getItem("accessToken");
  
    return fetch(Constants.API_URL + "/admin/plan", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        authorization: "Barear " + token
      },
      body: JSON.stringify(playListDTO),
    }).then((data) => data.json())
      .catch((error) => {
        return {};
      });
  };

 



export {
    addPlan,
    getPlanDetails,
    updatePlanDetails,    
};