import * as Constants from '../constant';

let AddMedia = async (fileName, file, orgId, path, duration, base64Thumbnail) => {
  const token = localStorage.getItem("accessToken");
  const data = new FormData();
  data.append('fileName', fileName)
  data.append('file', file);
  data.append('orgId', orgId);
  data.append('path', path);
  data.append('duration', duration);
  return fetch(Constants.API_URL + "/media", {
    method: 'POST',
    headers: {
      'accept': 'application/json',
      'authorization': "Barear " + token
    },
    body: data
  }).then((data) => data.json())
    .catch((error) => {
      return {};
    });
}

let addFolderService = async (folderDTO) => {
  const token = localStorage.getItem("accessToken");

  return fetch(Constants.API_URL + "/media/folder", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: "Barear " + token
    },
    body: JSON.stringify(folderDTO),
  }).then((data) => data.json())
    .catch((error) => {
      return {};
    });
}

let fetchAllMedia = async (path) => {
  const token = localStorage.getItem("accessToken");

  return fetch(Constants.API_URL + "/media?path=" + path, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: "Barear " + token
    },
  }).then((data) => data.json())
    .catch((error) => {
      return {};
    });
}

let updateMedia = async (mediaDTO) => {
  const token = localStorage.getItem("accessToken");

  return fetch(Constants.API_URL + "/media/update", {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      authorization: "Barear " + token
    },
    body: JSON.stringify(mediaDTO),
  }).then((data) => data.json())
    .catch((error) => {
      return {};
    });
};

let fetchRecentMedia = async () => {
  const token = localStorage.getItem("accessToken");

  return fetch(Constants.API_URL + "/media/recent", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: "Barear " + token
    },
  }).then((data) => data.json())
    .catch((error) => {
      return {};
    });
}
async function getDuration(file) {

  return new Promise((resolve) => {
    const audio = document.createElement("audio");
    audio.muted = true;
    const source = document.createElement("source");
    source.src = file; //--> blob URL
    audio.preload = "metadata";
    audio.appendChild(source);
    audio.onloadedmetadata = function () {
      resolve(audio.duration)
    };
  });
}

let deleteMedia = async (mediaId) => {
  const token = localStorage.getItem("accessToken");

  return fetch(Constants.API_URL + "/media?mediaId=" + mediaId, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      authorization: "Barear " + token
    },
  }).then((data) => data.json())
    .catch((error) => {
      return {};
    });
};

let deleteFolder = async (mediaId) => {
  const token = localStorage.getItem("accessToken");

  return fetch(Constants.API_URL + "/media/folder?mediaId=" + mediaId, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      authorization: "Barear " + token
    },
  }).then((data) => data.json())
    .catch((error) => {
      return {};
    });
};

let attachedDataList = async (id) => {
  const token = localStorage.getItem("accessToken");

  return fetch(Constants.API_URL + "/media/attached/" + id, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: "Barear " + token
    },
  }).then((data) => data.json())
    .catch((error) => {
      return {};
    });
};
let removeDefaultMediaService = async (mediaId) => {
  const token = localStorage.getItem("accessToken");

  return fetch(Constants.API_URL + "/media/default/media/" + mediaId, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      authorization: "Barear " + token
    },
  }).then((data) => data.json())
    .catch((error) => {
      return {};
    });
};

let updateDefaultMedia = async (fileName, file, isHorizontal, mediaId) => {
  const token = localStorage.getItem("accessToken");
  const data = new FormData();
  data.append('fileName', fileName)
  data.append('file', file);
  data.append('isHorizontal', isHorizontal);
  return fetch(Constants.API_URL + "/media/default/media/" + mediaId, {
    method: 'POST',
    headers: {
      'accept': 'application/json',
      'authorization': "Barear " + token
    },
    body: data
  }).then((data) => data.json())
    .catch((error) => {
      return {};
    });
}
export {
  fetchAllMedia,
  addFolderService,
  AddMedia,
  updateMedia,
  fetchRecentMedia,
  getDuration,
  deleteMedia,
  attachedDataList,
  removeDefaultMediaService,
  updateDefaultMedia,
  deleteFolder
};