import React from "react";
import { Button, Form, Modal, Breadcrumb, OverlayTrigger, Popover } from "react-bootstrap";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { format } from 'date-fns'

import { connect } from "react-redux";
import { withRouter } from "../router";
import { ToastContainer, toast } from 'react-toastify';
import { bindActionCreators } from "@reduxjs/toolkit";
import { fromBlob } from 'image-resize-compress'; // ES6
import ReactPlayer from 'react-player';

import FileUploader from "./subComponents/fileUpload";
import { FaStar, FaTrashAlt, FaFilm, FaPlus, FaPencilAlt, FaTimes } from 'react-icons/fa';

import * as commanActionCreator from '../reducers/commanReduces';
import { AddMedia, fetchAllMedia, addFolderService, updateMedia, getDuration, deleteMedia, attachedDataList, deleteFolder } from "../services/mediaService";
import { IconButton, DataIsEmpty } from './utilComponents/inputFieldAndButtons';
import Sidebar from "./subComponents/Sidebar";
import Navbar from "./subComponents/Navbar";
import Media from "../appIcons/Media";
import MyComputerIcon from "../appIcons/myComputerIcon";
import EditIcon from "../appIcons/editIcon";
import { MdSearch } from "react-icons/md";

class MediaComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      addMediaModel: false,
      isUploadMore: false,
      selectedFileList: [],
      mediaList: [],
      deletePlaylistModel: false,
      showMediaModel: false,
      isNameEdit: false,
      selectedMedia: "",
      selectedId: "",
      currentPath: "/",
      addFolderModel: false,
      isDisable: true,
      newFolderName: "",
      folderNameEdit: "",
      mediaName: "",
      isFileUploadLoader: false,
      editFolderModel: false,
      pathList: [{
        folderName: "Library",
        path: "/",
        isActive: true
      }]
    };
  }
  async componentDidMount() {
    this.props.counterSlice.startLoading();
    let mediaRes = await fetchAllMedia(this.state.currentPath);
    this.setState({ mediaList: mediaRes.body, mediaListTemp: mediaRes.body });
    this.props.counterSlice.stopLoading();

  }
  searchName = (e) => {
    if (e.target.value != "") {
      this.setState({ mediaList: this.state.mediaListTemp.filter((s) => s.name.toUpperCase().includes(e.target.value.toUpperCase())) })
    } else
      this.setState({ mediaList: this.state.mediaListTemp })
  }
  closeAll = () => {
    this.setState({
      addMediaModel: false, selectedFileList: [], newFolderName: "", editFolderModel: false, isFileUploadLoader: false,
      deletePlaylistModel: false, addFolderModel: false, selectedId: "", showMediaModel: false, isNameEdit: false
    });
  }
  handleChange = event => {
    let files = event.target.files;
    for (const file of files) {
      this.state.selectedFileList.push({
        imageUrl: URL.createObjectURL(file),
        fileName: file.name,
        size: this.returnFileSize(file.size),
        fileData: file,
        type: file.type,
        isUpload: false
      });
    }
    this.setState({ selectedFileList: this.state.selectedFileList, isUploadMore: false })
  };
  returnFileSize = (number) => {
    if (number < 1024) {
      return `${number} bytes`;
    } else if (number >= 1024 && number < 1048576) {
      return `${(number / 1024).toFixed(1)} KB`;
    } else if (number >= 1048576) {
      return `${(number / 1048576).toFixed(1)} MB`;
    }
  }
  hms = (seconds) => {
    return [3600, 60]
      .reduceRight(
        (p, b) => r => [Math.floor(r / b)].concat(p(r % b)),
        r => [r]
      )(seconds)
      .map(a => a.toString().padStart(2, '0'))
      .join(':');
  }

  uploadAllMedia = async () => {
    await this.state.selectedFileList.forEach(item => {
      this.props.counterSlice.addMediaToObject(item);
    });
    this.closeAll()
    this.props.counterSlice.showFileUploading(true);
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.setState({ isFileUploadLoader: true, addMediaModel: false });
    for (let i = 0; i < this.props.selectedMediaFileList.length; i++) {
      let fileinfo = { ...this.props.selectedMediaFileList[i] };
      if (this.props.selectedMediaFileList[i].isUpload === false) {
        let duration = "00:00:10";
        if (fileinfo.type.includes("video")) {
          duration = await getDuration(fileinfo.imageUrl);
          console.log(duration);
          duration = Math.floor(duration);
          duration = this.hms(duration);
        } else {
          const quality = 80;
          const width = 0;
          const height = 0;
          const format = null;
          fileinfo.fileData = await fromBlob(
            fileinfo.fileData,
            quality,
            width,
            height,
            format
          );
        }
        // return
        fileinfo.fileName = fileinfo.fileName.replace(/ /g, "_");
        let response = await AddMedia(
          fileinfo.fileName,
          fileinfo.fileData,
          userInfo.orgId,
          this.state.currentPath,
          duration
        );
        if (response.success) {
          // this.props.selectedMediaFileList[i].isUpload = true;
          this.props.counterSlice.updateMediaToObject({
            index: i,
            isUpload: true,
          });
        } else {
          // this.props.selectedMediaFileList[i].isUpload = false;
          this.props.counterSlice.updateMediaToObject({
            index: i,
            isUpload: true,
          });
          toast.error("Error while uploading", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            theme: "colored",
            progress: undefined,
          });
        }
      }
      // this.setState({ selectedFileList: this.state.selectedFileList });
    }
    let dasboardResponse = await fetchAllMedia(this.state.currentPath);
    this.setState({ mediaList: dasboardResponse.body, isFileUploadLoader: false, mediaListTemp: dasboardResponse.body });
    this.props.counterSlice.showFileUploading(false);
    this.props.counterSlice.clearMediaToObject();
    // this.closeAll();
  }
  fetchFolderData = async (m) => {
    this.props.counterSlice.startLoading();

    let path = this.state.currentPath + m._id + "/";
    let dasboardResponse = await fetchAllMedia(path);
    this.state.pathList.forEach((pl) => pl.isActive = false);
    this.state.pathList.push({
      folderName: m.name,
      path: path,
      isActive: true
    });
    this.setState({ mediaList: dasboardResponse.body, mediaListTemp: dasboardResponse.body, currentPath: path });
    this.props.counterSlice.stopLoading();

  }
  createFolder = async () => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.setState({ addFolderModel: false });
    let folderDTO = {
      name: this.state.newFolderName,
      orgId: userInfo.orgId,
      folderMediaCount: 0,
      path: this.state.currentPath,
      type: "FOLDER"
    };
    this.props.counterSlice.startLoading();
    let response = await addFolderService(folderDTO);
    if (response.success) {
      let dasboardResponse = await fetchAllMedia(this.state.currentPath);
      this.setState({ mediaList: dasboardResponse.body, mediaListTemp: dasboardResponse.body });
      toast.success('Folder created successfully.', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        theme: "colored",
        progress: undefined,
      });
    } else {
      toast.error('Error while creating folder.', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        theme: "colored",
        progress: undefined,
      });
    }
    this.props.counterSlice.stopLoading();

    this.closeAll();
  }

  updateName = async (e) => {
    this.setState({ mediaName: e.target.value });
  }
  onBreadCrumbChnage = async (m, i) => {
    this.props.counterSlice.startLoading();

    let dasboardResponse = await fetchAllMedia(m.path);
    this.setState({
      mediaList: dasboardResponse.body, currentPath: m.path,
      mediaListTemp: dasboardResponse.body,
      pathList: this.state.pathList.slice(0, i + 1)
    });
    this.props.counterSlice.stopLoading();
  }

  updateMedia = async (m) => {
    let mediaDTO = {
      _id: m._id,
      name: m.name,
      isMarked: m.isMarked,
      path: m.path
    }
    this.props.counterSlice.startLoading();
    let updated = await updateMedia(mediaDTO);
    if (updated.success) {
      let dasboardResponse = await fetchAllMedia(this.state.currentPath);
      this.setState({ mediaList: dasboardResponse.body, mediaListTemp: dasboardResponse.body });
    }
    this.props.counterSlice.stopLoading();
  }
  handleDragEnd = async (val) => {
    const userRole = JSON.parse(localStorage.getItem("roles"));
    if (!userRole.media["move"].write) return;

    const { source, destination } = val;
    if (!destination) return;
    if (source.droppableId === "mediaList" && destination.droppableId === "mediaList"
      && this.state.mediaList[destination.index].type === "FOLDER" && this.state.mediaList[source.index].type !== "FOLDER") {
      let media = this.state.mediaList[source.index];
      media.path = media.path + this.state.mediaList[destination.index]._id + "/";
      this.updateMedia(media);
    }
    return;
  }
  deleteMediaList = async () => {
    this.setState({ deletePlaylistModel: false });
    let response;
    this.props.counterSlice.startLoading();
    if (this.state.media.type === "FOLDER") {
      response = await deleteFolder(this.state.selectedId);
    } else {
      response = await deleteMedia(this.state.selectedId);
    }
    if (response.success) {
      let dasboardResponse = await fetchAllMedia(this.state.currentPath);

      this.setState({ mediaList: dasboardResponse.body, mediaListTemp: dasboardResponse.body });
      toast.success('Media deleted successfully.', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        theme: "colored",
        progress: undefined,
      });
    } else {
      toast.error('Error while deleting media.', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        theme: "colored",
        progress: undefined,
      });
    }
    this.props.counterSlice.stopLoading();

    this.closeAll();
  }
  attachedMediaList = async (m) => {
    let attachedData = await attachedDataList(m._id);
    this.setState({ deletePlaylistModel: true, selectedId: m._id, media: m, attachedList: attachedData.body });
  }

  sortWithDate = async () => {
    this.props.counterSlice.startLoading();
    let dasboardResponse = await fetchAllMedia(this.state.currentPath);
    this.setState({ mediaList: dasboardResponse.body, mediaListTemp: dasboardResponse.body });
    this.props.counterSlice.stopLoading();

  }
  sortWithName = () => {
    this.props.counterSlice.startLoading();
    this.state.mediaList.sort((a, b) => a.name.localeCompare(b.name));
    this.setState({ mediaList: this.state.mediaList });
    this.props.counterSlice.stopLoading();

  }
  popoverBottom = (
    <Popover id="popover-positioned-bottom" title="Popover bottom">
      <div style={{ boxShadow: "3px 3px 20px -10px grey" }}>
        <ul style={{ padding: "5px" }}>
          <li className="hover:bg-gray-200"><button onClick={this.sortWithName} style={{ padding: "7px" }} >Sort By Name</button></li><hr />
          <li className="hover:bg-gray-200"><button onClick={this.sortWithDate} style={{ padding: "7px" }} >Sort By Date</button></li><hr />
        </ul>
      </div>
    </Popover>
  );
  render() {
    const userRole = JSON.parse(localStorage.getItem("roles"));

    let FileDetails = ({ lable, value }) => (
      <>
        <div className="flex p-1 justify-between">
          <span className="text-gray-600">{lable}</span>
          <span className="text-black text-end">{value}</span>
        </div>
        <hr />
      </>
    );
    return (<div className="flex relative bg-gray-100">
      <Sidebar type={"media"} />
      <div className="h-screen grow md:overflow-hidden overflow-auto md:hover:overflow-auto">
        <ToastContainer />
        <Navbar router={this.props.router} counterSlice={this.props.counterSlice} />
        <div className="flex shadow-lg px-16 py-3 text-3xl	">
          <div className="flex">
            <Media style={"stroke-2 fill-black self-center m-2"} />
            <h1 className="self-center mr-5 text-black font-medium text-lg">
              Media
            </h1>
          </div>
          <div className="grow px-2 flex relative">
            <MdSearch opacity={0.5} className="absolute self-center ml-2" />
            <Form.Control
              size="lg"
              type="search"
              placeholder="Search media"
              className="px-5 text-base"
              aria-label="Search"
              onChange={(e) => this.searchName(e)}
            />
          </div>
          <div className="flex-none self-center">
            <OverlayTrigger trigger="click" placement="bottom" rootClose overlay={this.popoverBottom}>
              <button
                type="button"
                className={`ml-3 p-2 rounded-md bg-white shadow-sm`}
              ><img src="./filter.svg" />
              </button>
            </OverlayTrigger>
          </div>
          {userRole.media["addMedia"].read && <div className="flex-none self-center">
            <button
              type="button"
              disabled={this.props.isMediaFileUploadLoader}
              className={this.props.isMediaFileUploadLoader ? "text-base  disabled:opacity-25 ml-3 px-3 py-2 btn-prime text-white  rounded-lg" : "text-base  ml-3 px-3 py-2 btn-prime text-white  rounded-lg"}
              onClick={() => userRole.media["addMedia"].write && this.setState({ addMediaModel: true })}>ADD MEDIA</button>
          </div>}
        </div>
        <div className="flex mx-16 ">
          <div className="flex-1 self-center cursor-pointer">
            <Breadcrumb className="pt-2">
              {this.state.pathList.map((pl, i) => (
                <Breadcrumb.Item key={i} className={!pl.isActive ? "text-gray-800 hover:bg-gray-200 rounded-lg " :
                  "text-black	hover:bg-gray-200 rounded-lg "} onClick={() => this.onBreadCrumbChnage(pl, i)}>{pl.folderName}</Breadcrumb.Item>
              ))}
            </Breadcrumb>
          </div>
          <div className="self-center">
            {userRole.media["addFolder"].read && this.state.pathList.length < 3 && (this.state.addFolderModel ? <div className="flex">
              <input type="text" placeholder="Enter folder name" value={this.state.newFolderName} onChange={(e) => this.setState({ newFolderName: e.target.value, isDisable: e.target.value === "" ? true : false })}
                className="text-black focus:outline-none border-b-blue-500"
                style={{ borderBottom: "1px solid #3b82f6" }}
                autoFocus={true}
                maxLength={30}
                onBlur={() => this.setState({ newFolderName: "", addFolderModel: false })}
                onKeyDown={(e) => {
                  if (userRole.media["addFolder"].write && e.key === "Enter" && this.state.newFolderName.trim() !== "") {
                    this.createFolder()
                  }
                }} />
              <button className={this.state.isDisable ? "bg-primary cursor-not-allowed bg-darkBlue p-2" : "bg-darkBlue p-2"} disabled={this.state.isDisable} onClick={this.createFolder}
              ><FaPlus className=" text-white self-center" /></button>
            </div> : <div className="flex text-darkBlue cursor-pointer hover:underline" onClick={() => userRole.media["addFolder"].write && this.setState({ addFolderModel: true })}>
              <FaPlus className="self-center m-1" />
              Add Folder
            </div>)}
          </div>
        </div>
        <div className="mx-3">
          {this.state.mediaList.length === 0 && <DataIsEmpty url={process.env.PUBLIC_URL + "/mediaEmpty.svg"}
            title="Upload your content"
            body2="This is where you can upload all the content you want to show on your screens."
            customFunction={() => userRole.media["addMedia"].write && this.setState({ addMediaModel: true })}
            buttonName="ADD MEDIA"
          />}
        </div>
        <DragDropContext onDragEnd={this.handleDragEnd}>
          <div className="mx-16">
            <Droppable droppableId="mediaList">
              {(provider) => (
                <div ref={provider.innerRef} {...provider.droppableProps}>
                  {this.state.mediaList?.map((m, i) => (
                    <Draggable
                      key={"id_" + i + "_" + m._id}
                      draggableId={"id_" + i + "_" + m._id}
                      index={i}>
                      {(mplProvider) => (<div
                        {...mplProvider.dragHandleProps} {...mplProvider.draggableProps} ref={mplProvider.innerRef}>
                        {m.type === "FOLDER" ? <div className="flex px-6 py-1 bg-white hover:bg-gray-500 my-2  rounded-lg	group" key={m._id}>
                          <div className="flex basis-full cursor-pointer" onClick={() => this.fetchFolderData(m)}>
                            <div className="flex self-center">
                              <Media style={"stroke-2 h-8 w-8 fill-black self-center m-2"} />
                            </div>
                            <div className="flex self-center flex-col ml-4 cursor-pointer">
                              <div className="xl:w-80 lg:w-40 md:w-24 w-20 inline-block truncate text-gray-500 font-medium flex-auto"><span className="truncate">{m.name}</span></div>

                              <span className="text-gray-400 text-xs">{new Date(m.createdOn).toLocaleString()}, created By {m.createdBy.firstName} {m.createdBy.lastName}</span>
                            </div>
                          </div>
                          {userRole.media["editName"].read && <div className={`basis-1/6 px-3 text-justify self-center child group-hover:block hidden`} onClick={() => userRole.media["editName"].write && this.setState({ editFolderModel: true, folderNameEdit: m.name, selectedFolderMedia: m })}>
                            <EditIcon /></div>}
                          {userRole.media["deleteMedia"].read && <div className={`self-center  child group-hover:block hidden`}>
                            <IconButton icon={<FaTrashAlt size={15} className="text-gray-500" />} toolTip="" customFunc={() => userRole.media["deleteMedia"].write && this.attachedMediaList(m)} />
                          </div>}
                        </div> :
                          <div className={`flex px-6 py-1  hover:bg-gray-500 my-2 cursor-pointer bg-white rounded-lg group`}>
                            <div className="flex basis-full" onClick={() => this.setState({ showMediaModel: true, selectedMedia: m, mediaName: m.name })}>
                              {m.contentType.includes("video") ? m.thumbnailUrl ? <img className="rounded-lg self-center border-solid border-1 w-10 h-10" src={m.thumbnailUrl} alt="loading..." /> : <div className="rounded-2xl flex p-2 border-dashed border-1 w-12 h-12">
                                <FaFilm className="self-center" size={30} opacity={0.8} /></div> :
                                <img className="rounded-lg self-center border-solid border-1 w-10 h-10" src={m.url} alt="loading..." />}
                              <div className="flex flex-col ml-4 self-center">
                                <div className="xl:w-80 lg:w-40 md:w-24 w-20 inline-block truncate text-gray-500 font-medium flex-auto"><span className="truncate">{m.name}</span></div>
                                <span className="text-gray-400 text-xs">{new Date(m.createdOn).toLocaleString()}, created By {m.createdBy.firstName} {m.createdBy.lastName}</span>
                              </div>
                            </div>
                            <div className="basis-1/5 self-center cursor-pointer child group-hover:block hidden" onClick={() => this.setState({ showMediaModel: true, selectedMedia: m })}><span className="text-sm">{m.size}</span>
                            </div>
                            <div className="basis-1/5 self-center cursor-pointer child group-hover:block hidden" onClick={() => this.setState({ showMediaModel: true, selectedMedia: m })}><span className="text-sm">{m.contentType.includes("video") ? "video" : "Image"}</span>
                            </div>
                            {userRole.media["starMedia"].read && <div className={`basis-1/5 px-3 text-justify self-center child group-hover:block hidden`} onClick={() => { if (userRole.media["starMedia"].write) { m.isMarked = !m.isMarked; this.updateMedia(m) } }}>
                              <FaStar className={m.isMarked ? "text-cyan-500" : "text-gray-500"} size={15} /></div>}
                            {userRole.media["deleteMedia"].read && <div className={`self-center child group-hover:block hidden`}>
                              <IconButton icon={<FaTrashAlt size={15} className="text-gray-500" />} toolTip="" customFunc={() => userRole.media["deleteMedia"].write && this.attachedMediaList(m)} />
                            </div>}
                          </div>}
                      </div>)}
                    </Draggable>))}
                </div>)}
            </Droppable>
          </div>
        </DragDropContext>
      </div>
      <Modal show={this.state.showMediaModel} onHide={this.closeAll}
        size="lg" centered>
        <Modal.Body className="p-0 m-0" >
          <div className="flex justify-between p-3">{this.state.isNameEdit ?
            <input className="focus:outline-none grow text-2xl p-1 font-bold border-b-black text-black"
              style={{ borderBottom: "1px solid" }} placeholder="Media name"
              maxLength={60}
              onKeyDown={(e) => {
                if (this.state.mediaName.trim() !== "" && (e.key === "Enter" || e.key === "Tab")) {
                  this.setState({ isNameEdit: false });
                  this.state.selectedMedia.name = this.state.mediaName.trim();
                  this.updateMedia(this.state.selectedMedia);
                }
                else
                  this.setState({ isNameEdit: true });

              }}
              value={this.state.mediaName}
              onChange={(e) => this.updateName(e)} />
            : <div className="flex grow">
              <span className="mr-3 text-ls">{this.state.mediaName}</span>
              {userRole.media["editName"].read && <FaPencilAlt className="self-center text-gray-400 hover:text-gray-600" size={20} onClick={() => userRole.media["editName"].write && this.setState({ isNameEdit: true })} />}
            </div>}
            <FaTimes className="text-gray-500 hover:text-black" onClick={this.closeAll} />
          </div>
          <hr />
          <div className="flex">
            <div className="self-center flex-1 flex justify-center bg-blue-100 p-3 border-r-2" style={{ height: "60vh" }}>
              {this.state.selectedMedia && this.state.selectedMedia.contentType.includes("video") ?
                <ReactPlayer
                  url={this.state.selectedMedia.url}
                  width="640"
                  height="360"
                  playing={true}
                  controls
                /> :
                <div className="flex flex-1"  >
                  <img className="self-center object-contain" style={{ width: "60vh", height: "60vh" }} src={this.state.selectedMedia.url} alt="loadding..." />
                </div>

              }
            </div>
            <div className="basis-80 flex-col text-center bg-white">
              <div className="m-2 p-2"><span className="font-semibold">Details</span></div>
              <hr />
              <div className="flex flex-col rounded border-solid  m-2 p-2 text-sm bg-gray-100">
                <FileDetails lable="Format" value={this.state.selectedMedia.contentType} />
                <FileDetails lable="File size" value={this.state.selectedMedia.size} />
                <FileDetails lable="Uploaded By" value={this.state.selectedMedia && this.state.selectedMedia.createdBy.firstName + " " + this.state.selectedMedia.createdBy.lastName} />
                <FileDetails lable="Updated Date" value={this.state.selectedMedia.createdOn && format(new Date(this.state.selectedMedia.createdOn), 'dd/MM/yyyy HH:MM')} />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {<Button variant="" id="btn-cancel" onClick={this.closeAll}>Cancel</Button>}
        </Modal.Footer>
      </Modal>
      <Modal show={this.state.deletePlaylistModel}
        onHide={this.closeAll}>
        <Modal.Body className="">
          <div className="flex justify-end">
            <FaTimes className="text-gray-500 hover:text-black" onClick={this.closeAll} />
          </div>
          <div className="text-center m-2">
            <div className="text-xl font-medium	">Are you sure?</div>
            <div className="text-gray-500">Do you would like to <br /> delete this permanently?</div>
            <br />
            {this.state.attachedList && this.state.attachedList.screenList && this.state.attachedList.screenList.length > 0 && <div>
              <span className="text-gray font-bold text-sm">Attached to screen</span>
              {this.state.attachedList.screenList?.map((s) => (
                <div className="text-xs" key={s.name}>- {s.name}</div>
              ))}
            </div>}
            {this.state.attachedList && this.state.attachedList.channelList && this.state.attachedList.channelList.length > 0 && <div>
              <span className="text-gray font-bold text-sm">Attached to channel</span>
              {this.state.attachedList.channelList?.map((c) => (
                <div className="text-xs" key={c.name}>- {c.name}</div>
              ))}
            </div>}
            {this.state.attachedList && this.state.attachedList.playList && this.state.attachedList.playList.length > 0 && <div>
              <span className="text-gray font-bold text-sm">Attached to playList</span>
              {this.state.attachedList.playList?.map((c) => (
                <div className="text-xs">- {c.name}</div>
              ))}
            </div>}
          </div>

          <div className="flex justify-center m-2">
            {<Button variant="" id="btn-cancel" onClick={this.closeAll}>Cancel</Button>}
            {<Button variant="outline-primary" className="mr-2" id="addbtn"
              onClick={() => this.deleteMediaList()}>Delete</Button>}
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={this.state.addMediaModel}
        size="lg"
        centered
        aria-labelledby="contained-modal-title-vcenter">
        <Modal.Body className="p-0 m-0">
          <div className="flex justify-between p-3">
            <Modal.Title id="contained-modal-title-vcenter" className="text-stone-500" >
              Media Upload
            </Modal.Title>
            <FaTimes className="text-gray-500 self-center hover:text-black" onClick={this.closeAll} />
          </div>
          <hr />
          <div className="flex">
            <div className=" p-2">
              <div className="p-2 m-1 hover:bg-gray-200">
                <MyComputerIcon />
              </div>
            </div>
            <div className="flex-1 bg-blue-100"  >
              {(this.state.selectedFileList.length == 0 || this.state.isUploadMore) ?
                <div className="m-3 p-10 border-dashed border-2 h-full  rounded-lg">
                  <FileUploader handleChange={this.handleChange} /></div> :
                <div className="grow flex-col rounded-lg overflow-y-auto" style={{ height: "60vh" }} >
                  {this.state.selectedFileList.map((f, index) => {
                    return (
                      <div key={index} className="flex mx-10 my-2 p-1 bg-white hover:shadow-md  rounded-lg	group">
                        <div className="flex flex-1">
                          {f.type.includes("video") ? <div className="rounded-2xl flex border-solid p-2 border-1 w-10 h-10">
                            <FaFilm className="self-center" size={30} opacity={0.6} />
                          </div> :
                            <img className="rounded-2xl border-solid border-1 w-10 h-10" src={f.imageUrl} alt="loadding..." />}
                          <div className="w-10 xl:w-52 lg:w-40 md:w-32 inline-block truncate text-black text-sm text-gray-500 ml-2 w-20 self-center"><span>{f.fileName}</span></div>
                        </div>
                        <div className=" self-center"><span className="text-gray-400 text-sm">{f.size}</span>
                        </div>
                        <div className="flex basis-10 self-center">
                          <IconButton icon={<FaTimes className="text-gray-600 hover:text-rose-900" />} toolTip="Delete"
                            customFunc={() => { this.setState({ selectedFileList: this.state.selectedFileList.filter((item) => item.fileName !== f.fileName) }) }} />
                        </div>
                      </div>)
                  })}
                </div>
              }
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="flex">
          <div className="grow">{this.state.selectedFileList.length !== 0 && <p>Selected Files: {this.state.selectedFileList.length}</p>}</div>
          <div>
            {(this.state.selectedFileList.length !== 0) &&
              <><Button variant="" id="btn-cancel" onClick={() => this.setState({ isUploadMore: true })}>Upload More</Button>
                <Button variant="outline-primary" className="mr-2" id="addbtn"
                  onClick={this.uploadAllMedia}>Upload</Button></>}
          </div>
        </Modal.Footer>
      </Modal>
      <Modal show={this.state.editFolderModel} onHide={this.closeAll}>
        <Modal.Body className="p-0 m-0" >
          <div className="flex justify-between p-3">
            <Modal.Title id="contained-modal-title-vcenter" className="text-stone-500" >
              Edit Folder Name
            </Modal.Title>
            <FaTimes className="text-gray-500 self-center hover:text-black" onClick={this.closeAll} />
          </div>
          <hr />
          <div className="m-4">  <Form.Control className=""
            placeholder="Folder name"
            maxLength={30}
            value={this.state.folderNameEdit}
            onChange={(e) => this.setState({ folderNameEdit: e.target.value })} /></div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-pr" onClick={this.closeAll}>Close</Button>
          <Button className="btn btn-prime" disabled={this.state.folderNameEdit.trim() == ""} onClick={() => {
            this.state.selectedFolderMedia.name = this.state.folderNameEdit.trim();
            this.updateMedia(this.state.selectedFolderMedia);
            this.closeAll()
          }}>Update</Button>
        </Modal.Footer>
      </Modal>
    </div>)
  };
}


const mapStateToProps = (state) => ({
  isLoading: state.counter.isLoading,
  userRole: state.counter.userRole,
  selectedMediaFileList: state.counter.selectedMediaFileList,
  isMediaFileUploadLoader: state.counter.isMediaFileUploadLoader
});

const mapDispatchToProps = (dispatch) => ({
  counterSlice: bindActionCreators(commanActionCreator, dispatch)
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MediaComponent));
