import React from 'react';

const Channels = (props) => {
  let { style } = props;
  return (
    <svg className={style} width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.91924 21.1647C9.87624 21.1647 9.83283 21.1592 9.78942 21.1484C9.55505 21.0891 9.39099 20.8782 9.39099 20.6363V12.5458H6.30342C6.11735 12.5458 5.94493 12.4476 5.84976 12.2878C5.75459 12.1276 5.75051 11.9296 5.83896 11.7661L11.6093 1.10202C11.7244 0.889255 11.9696 0.782463 12.2033 0.841157C12.4379 0.900462 12.6022 1.1114 12.6022 1.3533V9.44371H15.6891C15.8752 9.44371 16.0478 9.54154 16.1432 9.70132C16.2384 9.8611 16.2424 10.0596 16.1538 10.223L10.3835 20.8871C10.2897 21.0612 10.1098 21.1645 9.91924 21.1645V21.1647ZM7.18978 11.4893H9.91888C10.2107 11.4893 10.4471 11.7259 10.4471 12.0177V18.5503L14.8027 10.5006H12.0736C11.7818 10.5006 11.5452 10.264 11.5452 9.97232V3.43978L7.18978 11.4893Z" fill="#C4C4C4"/>
    </svg>


  )
}

export default Channels;