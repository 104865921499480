import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "@reduxjs/toolkit";
import { FaEllipsisV, FaStar } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';

import { withRouter } from "../../router";
import * as commanActionCreator from '../../reducers/commanReduces'
import { attachedDataList, updateMedia } from "../../services/mediaService";
import { createApp, fetchAllAppData } from "../../services/appServices";
import { Button, Modal, OverlayTrigger, Popover } from "react-bootstrap";
import { deleteLink } from "../../services/linkservice";
import { fetchScreenDetails, setToScreenService } from "../../services/screenService";
import SetToScreenModel from "../utilComponents/setToScreenModel";
import { AppIcon } from "../utilComponents/inputFieldAndButtons";

class MyAppComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            addLinkModel: false,
            isDisabled: true,
            appList: [],
            screenList: [],
            appAllList: [],
            screenTempList: [],
            linkName: "",
            attachedList: {},
            showLinkModel: false,
            deleteAppModel: false,
            setToScreenModel: false,
            selectedApp: "",
            selectedMedia: "",
            isNameEdit: false,
            selectAll: false,
            createduplicateModel: false
        }
    }
    async componentDidMount() {
        this.props.counterSlice.startLoading();
        let dasboardResponse = await fetchAllAppData();
        this.setState({ appList: dasboardResponse.body, appAllList: dasboardResponse.body });
        this.props.counterSlice.stopLoading();
    }
    closeAll = () => this.setState({ deleteAppModel: false, setToScreenModel: false, createduplicateModel: false, selectedApp: "" });
    attachedData = async (l) => {
        let attachedData = await attachedDataList(l._id);
        this.setState({ deleteAppModel: true, selectedApp: l, attachedList: attachedData.body })
    }
    deleteMediaList = async () => {
        this.setState({ deleteAppModel: false });
        this.props.counterSlice.startLoading();
        let response = await deleteLink(this.state.selectedApp._id);
        if (response.success) {
            let dasboardResponse = await fetchAllAppData();
            this.setState({ appList: dasboardResponse.body, appAllList: dasboardResponse.body });
            toast.success('App deleted successfully.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        } else {
            toast.error('Error while deleting App.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        }
        this.props.counterSlice.stopLoading();

        this.closeAll();
    }

    updateMedia = async (m) => {
        let mediaDTO = {
            _id: m._id,
            isMarked: m.isMarked
        }
        let updated = await updateMedia(mediaDTO);
        if (updated.success) {
            let dasboardResponse = await fetchAllAppData();
            this.setState({ appList: dasboardResponse.body, appAllList: dasboardResponse.body });
        }
    }
    onSetToScreenClick = async (app) => {
        this.props.counterSlice.startLoading();
        let screenDetails = await fetchScreenDetails();
        let attachedData = await attachedDataList(app._id);

        this.props.counterSlice.stopLoading();
        this.setState({ setToScreenModel: true, selectedApp: app });
        if (screenDetails.success) {
            let screenList = screenDetails.body;
            if (attachedData.success && attachedData.body.screenList.length > 0) {
                let attachedScreenInfo = attachedData.body;
                screenList.forEach((s) => {
                    s.isSelected = false;
                    for (let i = 0; i < attachedScreenInfo.screenList.length; i++) {
                        if (s._id === attachedScreenInfo.screenList[i]._id)
                            s.isSelected = true;
                    }
                });
            } else
                screenList.forEach((s) => s.isSelected = false);
            this.setState({ screenList: screenList, screenTempList: screenList });
        }
    }
    searchScreenName = (e) => {
        if ((e.target.value != "")) {
            console.log(e.target.value.length);
            if (e.target.value.length % 2 === 0) {
                let screenList = [];
                for (let i = 0; i < this.state.screenTempList.length; i++) {
                    let s = this.state.screenTempList[i]
                    for (let j = 0; j < s.tags.length; j++) {
                        if (s.tags[j] && s.tags[j].tag.toUpperCase().includes(e.target.value.toUpperCase())) {
                            screenList.push(s)
                            break;
                        }
                    }
                }
                let screenNameSort = this.state.screenTempList.filter((s) => s.name.toUpperCase().includes(e.target.value.toUpperCase()));
                let screenNameList = screenList.concat(screenNameSort);
                this.setState({ screenList: this.removeDuplicates(screenNameList) });
            }
        } else
            this.setState({ screenList: this.state.screenTempList })
    }
    removeDuplicates = (arr) => {
        const uniqueArray = [];
        const idTracker = {};

        for (let obj of arr) {
            const id = obj._id;
            if (!idTracker[id]) {
                uniqueArray.push(obj);
                idTracker[id] = true;
            }
        }
        return uniqueArray;
    }
    setToScreenService = async () => {
        let screenList = [];
        this.setState({ setToScreenModel: false });
        this.props.counterSlice.startLoading();

        this.state.screenList.forEach((s) => {
            if (s.isSelected)
                screenList.push(s._id);
        });

        let screenDTO = {
            type: "MEDIA",
            refrenceId: this.state.selectedApp._id,
            screenIdList: screenList
        };
        let screenDetails = await setToScreenService(screenDTO);
        if (screenDetails.success) {
            toast.success('Screens has been updated.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        } else {
            toast.error(screenDetails.message, {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        }
        this.props.counterSlice.stopLoading();

        this.closeAll();
    }
    createDuplicateApp = (app) => {
        this.setState({ selectedApp: app, createduplicateModel: true });
    }
    createDuplicateService = async (app) => {
        let appData = JSON.parse(JSON.stringify(app));

        appData._id = undefined;
        appData.name = appData.name + "-(copy)";
        this.setState({ createduplicateModel: false });
        this.props.counterSlice.startLoading();
        let response = await createApp(appData);
        if (response.success) {
            let dasboardResponse = await fetchAllAppData();
            this.setState({ appList: dasboardResponse.body, appAllList: dasboardResponse.body });
            toast.success('App created successfully.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        } else {
            toast.error(response.message, {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        }
        this.props.counterSlice.stopLoading();
        this.closeAll();
    }
    render() {
        const userRole = JSON.parse(localStorage.getItem("roles"));
        if (this.props.appSearchName.trim() && this.props.appSearchName != "") {
            this.state.appList = this.state.appAllList.filter((s) => s.name.toUpperCase().includes(this.props.appSearchName.toUpperCase()));
        } else {
            this.state.appList = this.state.appAllList;
        }
        return (
            <div className="flex">
                <div className="grow md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10 m-2">
                    <ToastContainer />
                    {this.state.appList.length > 0 ? <div className="grid sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-6 lx:grid-cols-7 gap-3">
                        {this.state.appList?.map((app, i) => {
                            return (
                                <div key={i} className="flex text-center shadow rounded flex-col bg-gradient-to-b from-whiteGray to-white p-2 ">
                                    <div className="flex justify-between">
                                        <button title="Mark Favourite" className={userRole.app["starApp"].read ? "visible" : "invisible"} onClick={() => {
                                            if (userRole.app["starApp"].write) { app.isMarked = !app.isMarked; this.updateMedia(app) }
                                        }}>
                                            <FaStar className={app.isMarked ? "text-cyan-500" : "text-gray-500"} size={15} />
                                        </button>
                                        <OverlayTrigger
                                            trigger="click"
                                            rootClose
                                            placement="left"
                                            className="z-0"
                                            overlay={<Popover className="flex flex-col z-0 overflow-hidden">
                                                <button className="p-1.5 hover:bg-gray-300 rounded-t" onClick={() => this.onSetToScreenClick(app)}>Set to screen</button>
                                                <hr /><button className="p-1.5 hover:bg-gray-300 text-start" onClick={() => this.createDuplicateApp(app)}>Duplicate</button>
                                                {userRole.app["deleteApp"].read && <><hr /><button className="p-1.5 hover:bg-gray-300 rounded-b text-start" onClick={() => userRole.app["deleteApp"].write && this.attachedData(app)}>Delete</button></>}
                                            </Popover>}>
                                            <button>
                                                <FaEllipsisV />
                                            </button>
                                        </OverlayTrigger>
                                    </div>
                                    <button className="self-center m-2 bg-dark-team rounded p-2" onClick={() =>
                                        this.props.router.navigate(`/app/${app.appType.toLowerCase()}/` + app._id)}>
                                        <AppIcon style={"w-12 h-12 " + (app.appType === "MEET_TEAM" ? "fill-black" : "fill-white")} type={app.appType} />
                                    </button>
                                    <div onClick={() =>
                                        this.props.router.navigate(`/app/${app.appType.toLowerCase()}/` + app._id)} className="w-10 xl:w-40 lg:w-30 md:w-24 inline-block truncate text-black text-sm text-gray-500 self-center cursor-pointer px-2" title={app.name}><span>{app.name}</span></div>
                                </div>);
                        })}
                    </div> :
                        <div className="flex flex-col items-center justify-center mt-16">
                            <img src={process.env.PUBLIC_URL + "/blankWeather.svg"} alt="App" />
                            <span className="text-black font-semibold text-xl">You haven't used app yet</span>
                            <span className="text-black font-medium text-sm">Create you first App</span>
                        </div>}
                </div>

                <Modal show={this.state.deleteAppModel} onHide={this.closeAll}>
                    <Modal.Header closeButton closeVariant={"#ffff"}>
                        <Modal.Title>Delete App</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>Are you sure, you would like to delete this App permanently?</div>
                        <br />
                        {this.state.attachedList && this.state.attachedList.screenList && this.state.attachedList.screenList.length > 0 && <div>
                            <span className="text-gray font-bold text-sm">Attached to screen</span>
                            {this.state.attachedList.screenList?.map((s) => (
                                <div className="text-xs">- {s.name}</div>
                            ))}
                        </div>}
                        {this.state.attachedList && this.state.attachedList.channelList && this.state.attachedList.channelList.length > 0 && <div>
                            <span className="text-gray font-bold text-sm">Attached to channel</span>
                            {this.state.attachedList.channelList?.map((c) => (
                                <div className="text-xs">- {c.name}</div>
                            ))}
                        </div>}
                        {this.state.attachedList && this.state.attachedList.playList && this.state.attachedList.playList.length > 0 && <div>
                            <span className="text-gray font-bold text-sm">Attached to playList</span>
                            {this.state.attachedList.playList?.map((c) => (
                                <div className="text-xs">- {c.name}</div>
                            ))}
                        </div>}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="" className="border-1 text-black rounded shadow" onClick={this.closeAll}>
                            Cancel
                        </Button>
                        <Button className="btn btn-prime" onClick={this.deleteMediaList}>
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.createduplicateModel} onHide={this.closeAll}>
                    <Modal.Header closeButton closeVariant={"#ffff"}>
                        <Modal.Title>Create Duplicate App</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>Are you sure, do you want to created Duplicate of <b>{this.state.selectedApp.name}</b>?</div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="" className="border-1 text-black rounded shadow" onClick={this.closeAll}>
                            NO
                        </Button>
                        <Button className="btn btn-prime" onClick={() => this.createDuplicateService(this.state.selectedApp)}>
                            YES
                        </Button>
                    </Modal.Footer>
                </Modal>

                <SetToScreenModel
                    setToScreenModel={this.state.setToScreenModel}
                    closeAll={this.closeAll}
                    searchScreenName={this.searchScreenName}
                    selectAllScreen={() => {
                        let isSelected = !this.state.selectAll ? true : false;
                        this.state.screenList.forEach((sl) => sl.isSelected = isSelected);
                        this.setState({ screenList: this.state.screenList, selectAll: !this.state.selectAll })
                    }}
                    isSelectAll={this.state.selectAll}
                    screenList={this.state.screenList}
                    routeTo={() => this.props.router.navigate("/screens")}
                    onScreenSelected={(s, i) => {
                        this.state.screenList[i].isSelected = !this.state.screenList[i].isSelected;
                        let isAllSelected = true;
                        this.state.screenList.forEach((si) => {
                            if (!si.isSelected)
                                isAllSelected = false;
                        })
                        this.setState({ screenList: this.state.screenList, selectAll: isAllSelected });
                    }}
                    setToScreenService={this.setToScreenService}
                />
            </div>)
    };
}

const mapStateToProps = (state) => ({
    isLoading: state.counter.isLoading,
    userRole: state.counter.userRole

});

const mapDispatchToProps = (dispatch) => ({
    counterSlice: bindActionCreators(commanActionCreator, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MyAppComponent));