import { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';

const CustomeCarousel = (props) => {
    let { imageList } = props;
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };
    return (
        <div className='flex-1 justify-center text-center h-auto bg-light-black'>
            <Carousel activeIndex={index} onSelect={handleSelect} style={{ height: "300px" }} controls={false} indicators={true}>
                {
                    imageList?.map((imageName,i) => (
                        <Carousel.Item interval={2000} key={i}>
                            <div className='flex justify-center'>
                                <img src={imageName} key={i} alt='Loding...'></img>
                            </div>
                        </Carousel.Item>
                    ))
                }
            </Carousel>
        </div>
    );
}

export default CustomeCarousel;