import React from "react";
import { connect } from "react-redux";
import { withRouter } from "../../router";
import { format } from 'date-fns'
import { loadImageFromDrive } from "../../services/appServices";

class MeetTeamPreview extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      userInfo: this.props.sheetData[0],
      index: 0,
      timer: "",
      imageURL: ""
    }
  }
  async componentDidMount() {
    this.timerList();
  }

  componentWillUnmount() {
    clearTimeout(this.state.timer);
  }

  getPathParameter = (url) => {
    var startIndex = url.indexOf('/d/') + 3; // Find the index after '/d/'
    var endIndex = url.indexOf('/', startIndex); // Find the next '/' after '/d/'
    return url.substring(startIndex, endIndex); // Assuming the path parameter is always before 'edit'
  }
  timerList = async () => {
    let userInfo = this.props.sheetData[this.state.index];
    this.setState({ userInfo })
    if (userInfo[userInfo.length - 1] != "loaded") {
      let blob = await loadImageFromDrive(this.getPathParameter(userInfo[2]), this.props.accessToken);
      const reader = new FileReader();
      reader.onload = () => {
        this.props.sheetData[this.state.index].push("loaded");
        this.props.sheetData[this.state.index][2] = reader.result;
        this.setState({ userInfo: this.props.sheetData[this.state.index] });
      };
      reader.readAsDataURL(blob);
    }

    this.state.timer = setTimeout(function () {
      if ((this.props.sheetData.length - 1) === this.state.index)
        this.state.index = 0
      else this.state.index += 1;
      this.timerList();
    }.bind(this), this.props.duration * 1000);
  }

  render() {
    let style = "border p-4 gap-5 flex w-4/5 h-3/4 bg-blueTeam justify-center items-center ";
    if (!this.props.isLandscape)
      style = style + " flex-col"
    return (<div className={style}>
      <div className="w-40 h-40 self-center rounded-full border-dashed overflow-hidden border-darkBlue border-2">
        <img src={this.state.userInfo[2]} className="w-40 h-40 object-cover bg-blueTeam" />
      </div>
      <div className="flex-1 flex flex-col">
        <span className="text-2xl font-medium">{this.state.userInfo[0]}</span>
        <span className="text-base font-normal">{this.state.userInfo[1]}</span>
        <span className="text-sm mt-3">{this.state.userInfo[3]}</span>
      </div>
    </div>)
  };
}


const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MeetTeamPreview));
