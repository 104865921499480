import React from "react";
import { connect } from "react-redux";
import { withRouter } from "../../router";
import { bindActionCreators } from "@reduxjs/toolkit";
import * as commanActionCreator from '../../reducers/commanReduces'
import { fetchInstaAppData } from "../../services/appServices";
import { DataIsEmptyWithoutButton } from "../utilComponents/inputFieldAndButtons";
import InstagramPreviewScreen from "../subComponents/instagramPreviewScreen";

class InstaAppPublicComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            instaPostData: [],
            duration: 10
        }
    }

    async componentDidMount() {
        this.props.counterSlice.startLoading();
        console.log(this.props.router.params.appId)
        if (this.props.router.params.appId) {
            let appInfo = await fetchInstaAppData(this.props.router.params.appId);
            this.setState({
                appInfo: appInfo.body,
                duration: appInfo.body.timer,
                layoutType: appInfo.body.layoutType,
                barerToken: appInfo.body.instaUserId,
                instaPostData: appInfo.data,
                idDisabled: false
            });
        }
        this.props.counterSlice.stopLoading();
    }

    render() {
        return (
            <div className="flex flex-col flex-1 justify-center items-center bg-white h-screen w-full gap-4">
                {this.state.instaPostData.length > 0 ? <InstagramPreviewScreen type={this.state.layoutType} postList={this.state.instaPostData} duration={this.state.duration} /> :
                    <DataIsEmptyWithoutButton url={process.env.PUBLIC_URL + "/blankScreenIcon.svg"}
                        title="App instance preview"
                        body1="Edit and save the configuration to preview this app instance" />}
            </div>
        )
    };
}

const mapStateToProps = (state) => ({
    isLoading: state.counter.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
    counterSlice: bindActionCreators(commanActionCreator, dispatch)
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(InstaAppPublicComponent));
