import React from "react";
import { connect } from "react-redux";
import { withRouter } from "../router";
import { ToastContainer, toast } from 'react-toastify';
import { bindActionCreators } from "@reduxjs/toolkit";
import * as commanActionCreator from '../reducers/commanReduces'
import Navbar from "./subComponents/Navbar";
import { format } from 'date-fns'
import { DataIsEmptyWithoutButton, InputFieldWithEdit, ToggalSetButton } from "./utilComponents/inputFieldAndButtons";
import { MdArrowBackIos, MdStayCurrentLandscape, MdStayCurrentPortrait } from "react-icons/md";
import { createApp, fetchAppData } from "../services/appServices";
import { Button } from "react-bootstrap";
import { updateMedia } from "../services/mediaService";
import ReactPlayer from 'react-player/lazy'
import YouTubeIcon from "../appIcons/YouTubeIcon";

class YoutubeAppDetailsComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isNameEdit: false,
            appName: "YouTube App",
            isUpdate: false,
            isLandscape: true,
            appInfo: "",
            youtubeURL: "",
            isMute: false,
            isControl: false,
            isError: false,
            isLoop: false,
            duration: ""
        }
    }
    async componentDidMount() {
        this.props.counterSlice.startLoading();

        if (this.props.router.params.appId) {
            let appInfo = await fetchAppData(this.props.router.params.appId);
            this.setState({
                appInfo: appInfo.body,
                appName: appInfo.body.name,
                isMute: appInfo.body.muteVideo,
                isLoop: appInfo.body.loopVideo,
                isControl: appInfo.body.showControl,
                youtubeURL: appInfo.body.link,
                duration: appInfo.body.duration,
                isUpdate: true
            });
        }
        this.props.counterSlice.stopLoading();
    }
    updateYouTubeApp = async () => {
        if (this.state.appName.trim() == "") {
            toast.error('App name should not be empty.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
            return;
        }
        this.props.counterSlice.startLoading();
        let appDTO = {
            _id: this.state.appInfo._id,
            name: this.state.appName.trim(),
            muteVideo: this.state.isMute,
            loopVideo: this.state.isLoop,
            showControl: this.state.isControl,
            link: this.state.youtubeURL,
            duration: this.state.duration,
        }
        let response = await updateMedia(appDTO);
        if (response.success) {
            toast.success('Youtube app updated successfully.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        } else {
            toast.error('Error while updating youtube app.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        }
        this.props.counterSlice.stopLoading();
    }

    createYouTubeApp = async () => {

        this.props.counterSlice.startLoading();
        let appDTO = {
            name: this.state.appName.trim(),
            muteVideo: this.state.isMute,
            loopVideo: this.state.isLoop,
            showControl: this.state.isControl,
            link: this.state.youtubeURL,
            duration: this.state.duration,
            appType: "YOUTUBE"
        }
        let response = await createApp(appDTO);
        if (response.success) {

            toast.success('App saved successfully.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
            this.props.router.navigate("/apps");

        } else {
            toast.error(response.message, {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        }
        this.props.counterSlice.stopLoading();
    }
    hms = (seconds) => {
        return [3600, 60]
            .reduceRight(
                (p, b) => r => [Math.floor(r / b)].concat(p(r % b)),
                r => [r]
            )(seconds)
            .map(a => a.toString().padStart(2, '0'))
            .join(':');
    }
    onControlChange = () => {
        this.props.counterSlice.startLoading();

        let url = this.state.youtubeURL;
        this.setState({ youtubeURL: "", isControl: !this.state.isControl })

        setTimeout(() => {
            this.setState({ youtubeURL: url });
        }, 500);
        this.props.counterSlice.stopLoading();

    }
    render() {
        const userRole = JSON.parse(localStorage.getItem("roles"));

        let screenSize = { height: "410px", width: "717px" }
        if (!this.state.isLandscape)
            screenSize = { width: "45vh", height: "65vh" }
        return (
            <div>
                <Navbar router={this.props.router} counterSlice={this.props.counterSlice} />
                <div className="flex bg-white border-b-1 border-slate-300 w-full min-h-min	">
                    <div className="flex-none p-4 border-1"
                        onClick={() => { this.props.router.navigate(-1) }}>
                        <MdArrowBackIos size={20} />
                    </div>
                    <div className="self-center bg-dark-team rounded p-2 m-2">
                        <YouTubeIcon style="fill-white w-10 h-10" />
                    </div>
                    <div className="px-2 py-3 flex flex-col self-center grow">
                        <InputFieldWithEdit
                            isEdit={this.state.isNameEdit}
                            isRead={userRole.app["editName"].read}

                            onKeyDown={(e) => {
                                if (this.state.appName.trim() !== "" && (e.key === "Enter" || e.key === "Tab" || e.key === undefined)) {
                                    this.setState({ isNameEdit: false });
                                } else this.setState({ isNameEdit: true });
                            }}
                            inputValue={this.state.appName}
                            placeholder="Enter app name"
                            onInputchange={(e) => {userRole.app["editName"].write && this.setState({ appName: e.target.value }); }}
                            onEditClick={() => {userRole.app["editName"].write && this.setState({ isNameEdit: !this.state.isNameEdit }) }} />
                        {this.state.isUpdate ? <span className="order-last text-sm text-gray-450">{format(new Date(), 'dd/MM/yyyy HH:mm')}, modified by {this.state.appInfo != "" && this.state.appInfo.modifiedBy && <span>{this.state.appInfo.modifiedBy.firstName} {this.state.appInfo.modifiedBy.lastName}</span>} </span> :
                            <span className="order-last text-sm text-gray-450">{format(new Date(), 'dd/MM/yyyy HH:mm')}</span>}
                    </div>
                    <div className="flex gap-4 self-center" >
                        <Button className="bg-darkBlue text-white rounded p-2 mr-4"
                            disabled={this.state.youtubeURL.trim() === "" || this.state.appName.trim() === "" || this.state.isError}
                            onClick={this.state.isUpdate ? this.updateYouTubeApp : this.createYouTubeApp}>{this.state.isUpdate ? "Update" : "Save"}</Button>
                    </div>
                </div>
                <div className="flex" style={{ height: "82vh" }}>
                    <div className="bg-white h-full w-1/3 px-12 pt-5 gap-4 flex flex-col">
                        <div className="flex flex-col gap-1 mr-4">
                            <label>{"URL*"}</label>
                            <div className="flex gap-2">
                                <input type="url" id="inputo"
                                    className="border-1 border-gray-300 rounded p-2 text-black my-select"
                                    value={this.state.youtubeURL} onChange={(e) => this.setState({ youtubeURL: e.target.value })}
                                    style={{ backgroundColor: "inherit", color: "white" }}
                                    placeholder="URL" />
                            </div>
                        </div>
                        <div className="flex justify-between mr-4">
                            <label className="text-black font-medium">{"Mute videos"}</label>
                            <ToggalSetButton value={this.state.isMute} customFunction={() => this.setState({ isMute: !this.state.isMute })} />
                        </div>
                        <div className="flex justify-between mr-4">
                            <label className="text-black font-medium">{"Show Controls"}</label>
                            <ToggalSetButton value={this.state.isControl} customFunction={this.onControlChange} />
                        </div>
                        <div className="flex justify-between mr-4">
                            <label className="text-black font-medium">{"Loop Video"}</label>
                            <ToggalSetButton value={this.state.isLoop} customFunction={() => this.setState({ isLoop: !this.state.isLoop })} />
                        </div>
                    </div>
                    <div className="flex-1">
                        <div className="flex content-end m-4">
                            <button className="p-2 flex bg-black text-white rounded-lg font-bold border-solid border-1" onClick={() => {
                                this.setState({ isLandscape: !this.state.isLandscape })
                            }}>
                                {this.state.isLandscape ? <MdStayCurrentLandscape className="self-center font-bold mr-1 " size={25} /> :
                                    <MdStayCurrentPortrait className="self-center font-bold mr-1 " size={25} />}
                            </button>
                        </div>
                        <div className="flex justify-center">
                            <div className="bg-cover bg-center object-cover border-black border-4 shadow overflow-hidden" style={screenSize}>
                                {this.state.youtubeURL.trim() != ""? <ReactPlayer url={this.state.youtubeURL} width={screenSize.width} height={screenSize.height}
                                    muted={this.state.isMute} controls={this.state.isControl}
                                    playing={true} loop={this.state.isLoop}
                                    onDuration={(e) => this.setState({ duration: this.hms(e) })}
                                    onReady={() => this.setState({ isError: false })}
                                    onError={(e) => {
                                        this.setState({ isError: true });
                                        toast.error('Invalid Youtube URL.', {
                                            position: "top-right",
                                            autoClose: 1000,
                                            hideProgressBar: true,
                                            closeOnClick: true,
                                            pauseOnHover: false,
                                            draggable: true,
                                            theme: "colored",
                                            progress: undefined,
                                        });
                                    }} />:<DataIsEmptyWithoutButton url={process.env.PUBLIC_URL + "/blankScreenIcon.svg"}
                                    title="App instance preview"
                                    body1="Edit and save the configuration to preview this app instance" />}
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </div>)
    };
}

const mapStateToProps = (state) => ({
    isLoading: state.counter.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
    counterSlice: bindActionCreators(commanActionCreator, dispatch)
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(YoutubeAppDetailsComponent));
