import React from "react";
import { connect } from "react-redux";
import { withRouter } from "../../router";
import { bindActionCreators } from "@reduxjs/toolkit";
import * as commanActionCreator from '../../reducers/commanReduces'
import { fetchAppData } from "../../services/appServices";
import '@pqina/flip/dist/flip.min.css';
import { FlipClock12 } from "../subComponents/flipClock";
import AnaLogClock from "../subComponents/analogClock";
const moment = require('moment-timezone');

class ClockAppPublicComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedClockType: "",
            selectedMode: "",
            is12Format: true,
            displayDate: true,
            displaySecond: false,
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            isLandscape: true,
            time: new Date(),
            appInfo: ""
        }
    }
    async componentDidMount() {
        if (this.props.router.params.appId) {
            this.props.counterSlice.startLoading();
            let appInfo = await fetchAppData(this.props.router.params.appId);
            if (appInfo.success) {
                this.setState({
                    appInfo: appInfo.body,
                    appName: appInfo.body.name,
                    appName: appInfo.body.name,
                    selectedClockType: appInfo.body.clockType,
                    selectedMode: appInfo.body.viewMode,
                    is12Format: appInfo.body.is12Format,
                    displayDate: appInfo.body.displayDate,
                    displaySecond: appInfo.body.displaySecond,
                });
            }
            this.props.counterSlice.stopLoading();
        }
        this.intervalID = setInterval(() => {
            this.setState({ time: new Date() });
        }, 1000);
    }
    componentWillUnmount() {
        clearInterval(this.intervalID);
    }

    render() {
        const { time } = this.state;
        const date = moment.tz(time, this.state.timeZone).format("DD");
        const month = moment.tz(time, this.state.timeZone).format("MMM");
        const year = moment.tz(time, this.state.timeZone).format("YYYY");
        const day = moment.tz(time, this.state.timeZone).format("ddd");
        const hour = moment.tz(time, this.state.timeZone).format(this.state.is12Format ? "hh" : "HH");
        const minute = moment.tz(time, this.state.timeZone).format("mm");
        const seconds = moment.tz(time, this.state.timeZone).format("ss");

        const am = moment.tz(time, this.state.timeZone).format("A");
        let style = { fontFamily: 'DS-Digital' };

        let DateText = ({ lable, value }) => (
            <div className="flex flex-col">
                <span className="text-gray-20 text-base">{lable}</span>
                <span>{value}</span>
            </div>
        )
        return (
            <div className={"flex flex-col flex-1 justify-center items-center h-screen w-full gap-4 " + (this.state.selectedMode === "DAY" ? "bg-white text-black " : "bg-gray-800 text-white ")}>
                {this.state.selectedClockType === "SIMPLE" && <div className={"flex sm:text-base xl:text-5xl lg:text-2xl md:text-xl items-center justify-center gap-2 font-bold " + (!this.state.isLandscape && "flex-col")}>
                    {this.state.is12Format && <div className="inline-block align-top "><span className="sm:text-sm md:text-lg lg:text-xl xl:text-2xl font-medium ">{am}</span></div>}
                    <div className={"flex sm:text-xl md:text-3xl lg:text-5xl xl:text-7xl border-black p-2 text-6xl font-bold " + (!this.state.isLandscape ? "border-b-2" : "border-r-2")}>
                        <span>{hour}</span>
                        <span>:{minute}</span>
                        {this.state.displaySecond && <span className="w-24">:{seconds}</span>}
                    </div>
                    {this.state.displayDate && <div className="flex sm:text-lg md:text-xl lg:text-3xl xl:text-5xl flex-col uppercase gap-1  font-semibold">
                        <span className="sm:text-base md:text-lg lg:text-2xl xl:text-3xl">{day}, {date}</span>
                        <span className="">{month}, {year}</span>
                    </div>}
                </div>}
                {(this.state.selectedClockType === "DIGITAL") && <div className={"flex sm:text-base xl:text-5xl lg:text-2xl md:text-xl items-center justify-center gap-2 font-bold " + (!this.state.isLandscape && "flex-col")}>
                    {this.state.is12Format && <div className="inline-block align-top "><span className="sm:text-sm md:text-lg lg:text-xl xl:text-2xl font-medium " style={style}>{am}</span></div>}
                    <div className={"flex sm:text-xl md:text-3xl lg:text-5xl xl:text-7xl  border-black p-2  font-bold " + (!this.state.isLandscape ? "border-b-2" : "border-r-2")} >
                        <span className="text-6xl" style={style}>{hour}</span>
                        <span className="text-6xl" style={style}>:{minute}</span>
                        {this.state.displaySecond && <span className="w-24 text-6xl" style={style}>:{seconds}</span>}
                    </div>
                    {this.state.displayDate && <div className="flex sm:text-lg md:text-xl lg:text-3xl xl:text-5xl flex-col uppercase gap-1  font-semibold">
                        <span className="sm:text-base md:text-lg lg:text-2xl xl:text-3xl" style={style}>{day}, {date}</span>
                        <span style={style}>{month}, {year}</span>
                    </div>}
                </div>}
                {this.state.selectedClockType === "FLIP" &&
                    <div className={"flex items-center justify-center gap-2 font-bold " + (this.state.selectedMode === "DAY" ? "bg-white text-black " : "bg-gray-800 text-white ") + (!this.state.isLandscape && "flex-col")}>
                        {this.state.is12Format && <div className="inline-block align-top "><span className="sm:text-sm md:text-lg lg:text-xl xl:text-2xl font-medium ">{am}</span></div>}

                        <FlipClock12 value={hour} />:
                        <FlipClock12 value={minute} />
                        {this.state.displaySecond && ":"}
                        {this.state.displaySecond && (<FlipClock12 value={seconds} />)}
                        {this.state.displayDate && <div className="flex sm:text-base md:text-lg lg:text-2xl xl:text-3xl flex-col uppercase gap-1  font-semibold">
                            <span className="sm:text-base md:text-lg lg:text-xl xl:text-2xl">{day}, {date}</span>
                            <span className="">{month}, {year}</span>
                        </div>}
                    </div>
                }
                {this.state.selectedClockType === "ANALOG" &&
                    <div className={"flex relative items-center gap-2 font-bold sm:w-full md:w-full lg:w-full xl:w-1/2"}>
                        {this.state.displayDate && <div className={"basis-1/4 flex text-center font-bold text-xl gap-2 " + (this.state.isLandscape && "flex-col")}>
                            <DateText lable={"DATE"} value={date} />
                            <DateText lable={"MONTH"} value={month} />
                            <DateText lable={"YEAR"} value={year} />
                        </div>}
                        <AnaLogClock />
                    </div>
                }
            </div>
        )
    };
}

const mapStateToProps = (state) => ({
    isLoading: state.counter.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
    counterSlice: bindActionCreators(commanActionCreator, dispatch)
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ClockAppPublicComponent));
