import React from "react";
import { Modal, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "../router";
import { ToastContainer, toast } from 'react-toastify';
import { fetchScreenDetails, setToScreenService } from "../services/screenService";

import { MdResetTv } from 'react-icons/md';
import { FaRegCopy, FaEye, FaTrashAlt, FaTimes } from 'react-icons/fa';

import { fetchChannelDetails, createChannel, deleteChannel, attachedChannelDataList } from "../services/channelServices";
import SearchWithButton from "./utilComponents/searchWithButton";
import { DataIsEmpty, IconButton } from "./utilComponents/inputFieldAndButtons";
import { bindActionCreators } from "@reduxjs/toolkit";
import * as commanActionCreator from '../reducers/commanReduces';
import Sidebar from "./subComponents/Sidebar";
import Navbar from "./subComponents/Navbar";
import Channels from "../appIcons/Channels";
import SetToScreenModel from "./utilComponents/setToScreenModel";

class ChannelsComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      addChannelModel: false,
      deleteChannelModel: false,
      channelName: "",
      addChannelScreen: false,
      setToScreenModel: false,
      selectAll: false,
      attachedList: {},
      screenTempList: [],
      selectedChannelObject: "",
      addContentSlider: {
        isPlayList: false,
        isMedia: true,
        isLinks: false,
        isApps: false
      },
      channelData: [],
      screenList: [],
    }
  }
  async componentDidMount() {
    this.props.counterSlice.startLoading();
    let channelData = await fetchChannelDetails();
    this.setState({ channelDataList: channelData.body, channelData: channelData.body });
    this.props.counterSlice.stopLoading();
  }
  closeAll = () => {
    this.setState({ deleteChannelModel: false, selectAll: false, setToScreenModel: false, screenList: [], addChannelModel: false, channelName: "", channelId: "", addChannelScreen: false });
  }
  saveChannelList = async () => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    let channelDTO = {
      name: this.state.channelName.trim(),
      orgId: userInfo.orgId,
      status: "DRAFT"
    };
    let response = await createChannel(channelDTO);
    if (response.success) {
      this.props.router.navigate("/channels/" + response.body._id);
    } else {
      toast.error(response.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        theme: "colored",
        progress: undefined,
      });
    }
  }
  copyChannelList = async (copyData) => {
    copyData._id = undefined;
    copyData.status = "DRAFT";
    copyData.name = copyData.name + "-(Copy)"
    let response = await createChannel(copyData);
    if (response.success) {

      let dasboardResponse = await fetchChannelDetails();
      this.setState({ channelData: dasboardResponse.body });
      toast.success('Channel created successfully.', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        theme: "colored",
        progress: undefined,
      });
    } else {
      toast.error(response.message, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        theme: "colored",
        progress: undefined,
      });
    }
  }

  deleteChannel = async () => {
    this.props.counterSlice.startLoading();

    let response = await deleteChannel(this.state.channelId);
    if (response.success) {

      let dasboardResponse = await fetchChannelDetails();
      this.setState({ channelData: dasboardResponse.body });
      toast.success('Channel deleted successfully.', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        theme: "colored",
        progress: undefined,
      });
    } else {
      toast.error('Error while deleting channel.', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        theme: "colored",
        progress: undefined,
      });
    }
    this.props.counterSlice.stopLoading();

    this.closeAll()
  }
  attachedChannelData = async (c) => {
    this.props.counterSlice.startLoading();
    let attachedData = await attachedChannelDataList(c._id);
    this.props.counterSlice.stopLoading();
    this.setState({ deleteChannelModel: true, channelId: c._id, attachedList: attachedData.body })
    this.setState({ deleteChannelModel: true, addChannelScreen: false })
  }
  onSetToScreenClick = async (p) => {
    this.props.counterSlice.startLoading();
    let screenDetails = await fetchScreenDetails();
    let attachedData = await attachedChannelDataList(p._id);

    this.props.counterSlice.stopLoading();

    this.setState({ setToScreenModel: true, selectedChannelObject: p });
    if (screenDetails.success) {
      let screenList = screenDetails.body;

      if (attachedData.success && attachedData.body.screenList.length > 0) {
        let attachedScreenInfo = attachedData.body;
        screenList.forEach((s) => {
          s.isSelected = false;
          for (let i = 0; i < attachedScreenInfo.screenList.length; i++) {
            if (s._id === attachedScreenInfo.screenList[i]._id)
              s.isSelected = true;
          }
        });
        if (attachedScreenInfo.screenList.length === screenList)
          this.setState({ selectAll: true })
      } else
        screenList.forEach((s) => s.isSelected = false);

      this.setState({ screenList: screenList, screenTempList: screenList });
    }
  }
  setToScreenService = async () => {
    let screenList = [];
    this.setState({ setToScreenModel: false });
    this.props.counterSlice.startLoading();

    this.state.screenList.forEach((s) => {
      if (s.isSelected)
        screenList.push(s._id);
    });

    let screenDTO = {
      type: "CHANNEL",
      refrenceId: this.state.selectedChannelObject._id,
      screenIdList: screenList
    };
    let screenDetails = await setToScreenService(screenDTO);
    if (screenDetails.success) {
      toast.success('Screens has been updated.', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        theme: "colored",
        progress: undefined,
      });
    } else {
      toast.error(screenDetails.message, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        theme: "colored",
        progress: undefined,
      });
    }
    this.props.counterSlice.stopLoading();

    this.closeAll();
  }
  searchScreenName = (e) => {
    if ((e.target.value != "")) {
      console.log(e.target.value.length);
      if (e.target.value.length % 2 === 0) {
        let screenList = [];
        for (let i = 0; i < this.state.screenTempList.length; i++) {
          let s = this.state.screenTempList[i]
          for (let j = 0; j < s.tags.length; j++) {
            if (s.tags[j] && s.tags[j].tag.toUpperCase().includes(e.target.value.toUpperCase())) {
              screenList.push(s)
              break;
            }
          }
        }
        let screenNameSort = this.state.screenTempList.filter((s) => s.name.toUpperCase().includes(e.target.value.toUpperCase()));
        let screenNameList = screenList.concat(screenNameSort);
        this.setState({ screenList: this.removeDuplicates(screenNameList) });
      }
    } else
      this.setState({ screenList: this.state.screenTempList })
  }
  removeDuplicates = (arr) => {
    const uniqueArray = [];
    const idTracker = {};

    for (let obj of arr) {
      const id = obj._id;
      if (!idTracker[id]) {
        uniqueArray.push(obj);
        idTracker[id] = true;
      }
    }
    return uniqueArray;
  }
  searchName = (e) => {
    if (e.target.value !== "") {
      this.setState({ channelData: this.state.channelDataList.filter((s) => s.name.toUpperCase().includes(e.target.value.toUpperCase())) })
    } else
      this.setState({ channelData: this.state.channelDataList })
  }

  render() {
    const userRole = JSON.parse(localStorage.getItem("roles"));

    return (
      <div className="flex">
        <Sidebar type={"channels"} />
        <div className="h-screen grow md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10 shadow">
          <Navbar router={this.props.router} counterSlice={this.props.counterSlice} />
          <div>
            <SearchWithButton
              title="Channels"
              icon={<svg width="30" className="self-center" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.5257 28.8607C13.4671 28.8607 13.4079 28.8532 13.3487 28.8385C13.0291 28.7576 12.8054 28.47 12.8054 28.1401V17.1077H8.59504C8.34131 17.1077 8.1062 16.9737 7.97641 16.7559C7.84663 16.5374 7.84108 16.2673 7.96169 16.0444L15.8303 1.50249C15.9873 1.21235 16.3216 1.06673 16.6404 1.14677C16.9603 1.22764 17.1843 1.51527 17.1843 1.84515V12.8775H21.3937C21.6475 12.8775 21.8828 13.0109 22.0129 13.2288C22.1427 13.4467 22.1482 13.7174 22.0274 13.9402L14.1588 28.4822C14.0309 28.7195 13.7855 28.8604 13.5257 28.8604V28.8607ZM9.80371 15.6669H13.5252C13.9232 15.6669 14.2455 15.9896 14.2455 16.3876V25.2956L20.185 14.3187H16.4635C16.0655 14.3187 15.7429 13.996 15.7429 13.5983V4.69035L9.80371 15.6669Z" fill="black" />
              </svg>
              }
              placeholder="Search channel"
              onChangeSearch={(e) => this.searchName(e)}
              buttonName="ADD CHANNEL"
              isRead={userRole.channel["addChannel"].read}
              onButtonClick={async () => userRole.channel["addChannel"].write && this.setState({ addChannelModel: true })} />
            <br />
            {(this.state.channelData.length === 0) ?
              <DataIsEmpty url={process.env.PUBLIC_URL + "/channel.svg"}
                title="Channels are your way to organize everything"
                body="Add content and playlists, schedule what plays when and give everyone a voice."
                buttonName={"ADD CHANNEL"} customFunction={() => userRole.channel["addChannel"].write && this.setState({ addChannelModel: true })} /> :
              <div className="mx-16">
                {this.state.channelData?.map((p, i) => {
                  return (<div className="flex bg-white p-2 hover:shadow-md my-2 rounded-lg group" key={p._id}>
                    <div className={"rounded-lg self-center cursor-pointer border-solid border-1 p-2 bg-dark-team"} onClick={() => this.props.router.navigate("/channels/" + p._id)}>
                      <Channels style="self-center h-8 w-8" />
                    </div>
                    <div className="grow px-3 cursor-pointer self-center" onClick={() => this.props.router.navigate("/channels/" + p._id)}>
                      <span className="text-gray-800 text-bold flex-auto">{p.name}</span>
                      <p className={(p.status === "DRAFT") ? "text-currentSecondaryColor text-xs italic" : "text-gray-400 lowercase text-xs italic"}>{(p.status === "DRAFT") ? "Draft Saved. Publish required." : p.status}</p>
                    </div>
                    <div className=" self-center flex mt-2 z-10">
                      {userRole.channel["duplicateChannel"].read && <IconButton toolTip={"Duplicate"} icon={<FaRegCopy size={20} />} customFunc={() => userRole.channel["duplicateChannel"].write && this.copyChannelList(p)} />}
                      {userRole.channel["previewChannel"].read && <IconButton toolTip={"Preview"} icon={<FaEye size={20} />} customFunc={() => userRole.channel["previewChannel"].write && this.props.router.navigate("/channels/" + p._id + "?isPreview=1")} />}
                      {userRole.channel["setToScreen"].read && <IconButton toolTip={"Set To screen"} icon={<MdResetTv size={20} />} customFunc={() => userRole.channel["setToScreen"].write && this.onSetToScreenClick(p)} />}
                      {userRole.channel["deleteChannel"].read && <IconButton toolTip={"Delete"} icon={<FaTrashAlt size={20} />} customFunc={() => userRole.channel["deleteChannel"].write && this.attachedChannelData(p)} />}
                    </div>
                  </div>);
                })}</div>}
          </div>
        </div>
        <Modal
          show={this.state.addChannelModel}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered>
          <Modal.Body className="p-0 m-0">
            <div className="flex m-3 shadow-b-lg">
              <span className="flex-1 text-xl font-semibold">Create a new channel</span>
              <div onClick={this.closeAll}>
                <FaTimes size={25} className="self-center text-gray-400 hover:text-gray-600" />
              </div>
            </div>
            <hr />
            <div className="bg-blue-100 py-4">
              <div className="flex justify-center px-5 text-center text-sm"><span>Create a Channel to play individual media, show live URL feeds, Dashboards, integrated apps, Canvas creations and Playlists.</span></div>
              <div className="text-center  bg-blue-100 flex justify-center ">
                <img src={process.env.PUBLIC_URL + "/channel.svg"} alt="ChannelLogo" />
              </div>
              <div className=" bg-blue-100 flex justify-center ">
                <input size="lg" value={this.state.channelName}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && e.target.value.trim() !== "") {
                      this.setState({ addChannelModel: false })
                      this.saveChannelList()
                    }
                  }}
                  onChange={(e) => { this.setState({ channelName: e.target.value }) }} type="text" placeholder="Channel Name" className="w-3/5 focus:outline-none text-6xl text-currentSecondaryColor font-bold	text-center bg-transparent border-b-currentSecondaryColor" style={{ borderBottom: "2px solid" }} />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-primary" className="mr-2" id="addbtn" disabled={this.state.channelName.trim() !== "" ? false : true}
              onClick={() => {
                this.setState({ addChannelModel: false })
                this.saveChannelList()
              }}>Continue</Button>
          </Modal.Footer>
        </Modal>
        <Modal show={this.state.deleteChannelModel} onHide={this.closeAll}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Channel</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>Are you sure, you would like to delete this channel permanently?</div>
            <br />
            {this.state.attachedList && this.state.attachedList.screenList && this.state.attachedList.screenList.length > 0 && <div>
              <span className="text-gray font-bold text-sm">Attached to screen</span>
              {this.state.attachedList.screenList?.map((s, i) => (
                <div key={i} className="text-xs">- {s.name}</div>
              ))}
            </div>}
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn btn-pr" onClick={this.closeAll}>
              Cancel
            </Button>
            <Button className="btn btn-prime" onClick={this.deleteChannel}>
              Delete
            </Button>

          </Modal.Footer>
        </Modal>
        <SetToScreenModel
          setToScreenModel={this.state.setToScreenModel}
          closeAll={this.closeAll}
          searchScreenName={this.searchScreenName}
          selectAllScreen={() => {
            let isSelected = !this.state.selectAll ? true : false;
            this.state.screenList.forEach((sl) => sl.isSelected = isSelected);
            this.setState({ screenList: this.state.screenList, selectAll: !this.state.selectAll })
          }}
          isSelectAll={this.state.selectAll}
          screenList={this.state.screenList}
          routeTo={() => this.props.router.navigate("/screens")}
          onScreenSelected={(s, i) => {
            this.state.screenList[i].isSelected = !this.state.screenList[i].isSelected;
            let isAllSelected = true;
            this.state.screenList.forEach((si) => {
              if (!si.isSelected)
                isAllSelected = false;
            })
            this.setState({ screenList: this.state.screenList, selectAll: isAllSelected });
          }}
          setToScreenService={this.setToScreenService}
        />
        <ToastContainer />
      </div>
    )
  };
}

const mapStateToProps = (state) => ({
  isLoading: state.counter.isLoading,
  userRole: state.counter.userRole
});

const mapDispatchToProps = (dispatch) => ({
  counterSlice: bindActionCreators(commanActionCreator, dispatch)
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ChannelsComponent));
