import React from "react";
import { connect } from "react-redux";
import { withRouter } from "../../router";
import { bindActionCreators } from "@reduxjs/toolkit";
import * as commanActionCreator from '../../reducers/commanReduces'
import { fetchMeetTheTeamAppData } from "../../services/appServices";
import { DataIsEmptyWithoutButton } from "../utilComponents/inputFieldAndButtons";
import MeetTeamPreview from "../subComponents/meetTeamPreview";

class MeetTheTeamAppPublicComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            sheetData: [],
            duration: 10,
            isSessionExpired: false
        }
    }

    async componentDidMount() {
        this.props.counterSlice.startLoading();
        console.log(this.props.router.params.appId);
        if (this.props.router.params.appId) {
            let appInfo = await fetchMeetTheTeamAppData(this.props.router.params.appId);
            if (appInfo.success)
                this.setState({
                    appInfo: appInfo.body,
                    duration: appInfo.body.timer,
                    barerToken: appInfo.body.userToken,
                    googleSheetURL: appInfo.body.link,
                    sheetData: appInfo.data,
                    isSessionExpired: appInfo.isSessionExpired
                });
        }
        this.props.counterSlice.stopLoading();
    }

    render() {
        return (
            <div className="flex flex-col flex-1 justify-center items-center bg-white h-screen w-full gap-4">
                {this.state.sheetData.length > 0 ? <MeetTeamPreview sheetData={this.state.sheetData} duration={this.state.duration}
                    accessToken={this.state.barerToken} isLandscape={true} /> :
                    <DataIsEmptyWithoutButton url={process.env.PUBLIC_URL + "/blankScreenIcon.svg"}
                        title={this.state.isSessionExpired ? "Your Session has expired please try with login" : "App instance preview"}
                        body1={!this.state.isSessionExpired && "Edit and save the configuration to preview this app instance"} />}
            </div>
        )
    };
}

const mapStateToProps = (state) => ({
    isLoading: state.counter.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
    counterSlice: bindActionCreators(commanActionCreator, dispatch)
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MeetTheTeamAppPublicComponent));
