import React from "react";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import * as Constants from '../constant';
import { Button } from "react-bootstrap";

class PasswordFunctionClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validateToken: this.props.validateToken,
      password: "",
      confirmPassword: "",
      showPassword: false,
      isError: false,
      isUpperCase: false,
      isSymbols: false,
      isNumber: false,
      isLowerCase: false
    }
  }

  updatePassword = async () => {
    if (this.state.password !== this.state.confirmPassword) {
      this.setState({ isError: true });
      return;
    }
    let userDTO = {
      token: this.state.validateToken,
      newPassword: this.state.password
    };
    let response = await resetPassword(userDTO);

    if (response.success) {
      toast.success(response.message, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        theme: "colored",
        progress: undefined,
      });
    } else {
      toast.error(response.message, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        theme: "colored",
        progress: undefined,
      });
    }
  }

  validatePassword = (password) => {
    let symbolRegex = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    let upperCaseRegex = /[A-Z]/;
    let numberRegex = /[0-9]/;
    let lowercaseRegex = /[a-z]/;
    this.setState({
      password,
      isSymbols: symbolRegex.test(password),
      isUpperCase: upperCaseRegex.test(password),
      isNumber: numberRegex.test(password),
      isLowerCase: lowercaseRegex.test(password)
    })
  }
  render() {
    return (<div className="bg-white">
      <table width="100%" cellPadding="0" cellSpacing="0" role="presentation">
        <tr>
          <td align="center">
            <table className="email-content" width="100%" cellPadding="0" cellSpacing="0" role="presentation">
              <tr>
                <td className="text-center text-gray-500 p-4 text-lg font-bold">
                  SIGNAXE
                </td>
              </tr>
              <tr>
                <td width="570" cellPadding="0" cellSpacing="0">
                  <table className="bg-white rounded-xl shadow-lg" align="center" width="570" cellPadding="0" cellSpacing="0" role="presentation">
                    <tr>
                      <td className="p-20">
                        <div className="p-2">
                          <h1 className="font-extrabold text-center m-2">UPDATE PASSWORD</h1>
                          <table className="" align="center" width="100%" cellPadding="0" cellSpacing="0" role="presentation">
                            <tr>
                              <td align="center">
                                <table width="100%" border="0" cellSpacing="0" cellPadding="0" role="presentation">
                                  <tr>
                                    <td align="center">
                                      <div>
                                        <div className="m-4">
                                          <input type="password" value={this.state.password} className="form-control" min={6} placeholder="New password" onChange={(e) => this.validatePassword(e.target.value)} />
                                        </div>
                                        <div className="m-4">
                                          <div className="relative">
                                            <input type={this.state.showPassword ? "text" : "password"} value={this.state.confirmPassword} className="form-control "
                                              placeholder="Confirm password" onChange={(e) => {
                                                this.setState({ confirmPassword: e.target.value, isError: (this.state.password !== e.target.value) });
                                              }} />
                                            {this.state.showPassword ? <FaEye className="absolute top-1 right-1 m-1" size={20} opacity={0.7} style={{ marginLeft: "-30px", cursor: "pointer" }} onClick={() => this.setState({ showPassword: !this.state.showPassword })} /> :
                                              <FaEyeSlash className="absolute top-1 right-1 m-1" size={20} opacity={0.7} style={{ marginLeft: "-30px", cursor: "pointer" }} onClick={() => this.setState({ showPassword: !this.state.showPassword })} />}
                                          </div>
                                          <div className="text-start text-xs mt-3">
                                            <strong>Password Strength:</strong>
                                            <ul>
                                              {this.state.isError && <li className="text-red-600">
                                                - Password not match
                                              </li>}
                                              <li className={this.state.password.length >= 8 ? 'text-green-200' : 'text-gray-700'}>
                                                - At least 8 characters
                                              </li>
                                              <li className={this.state.isUpperCase ? 'text-green-200' : 'text-gray-700'}>
                                                - Contains more than 1 uppercase letters
                                              </li>
                                              <li className={this.state.isLowerCase ? 'text-green-200' : 'text-gray-700'}>
                                                - Contains more than 1 lowercase letters
                                              </li>
                                              <li className={this.state.isNumber ? 'text-green-200' : 'text-gray-700'}>
                                                - Contains more than 1 numbers
                                              </li>
                                              <li className={this.state.isSymbols ? 'text-green-200' : 'text-gray-700'}>
                                                - Contains more than 1 symbols
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                      <Button disabled={this.state.isError || !this.state.isUpperCase || !this.state.isLowerCase
                                        || !this.state.isNumber || this.state.password == "" || this.state.confirmPassword == ""
                                        || !this.state.isSymbols} className="bg-green-200 p-2 rounded-lg text-white hover:shadow-lg" onClick={() => this.updatePassword()}>Change Password</Button>
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
              </tr>
            </table>
          </td>
        </tr>
      </table>
      <ToastContainer />
    </div>);
  }
};

async function resetPassword(userDTO) {
  return fetch(Constants.API_URL + "/user/reset/password", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(userDTO),
  })
    .then((data) => data.json())
    .catch((error) => {
      return {};
    });
}

export default function PasswordComponent() {
  let { token } = useParams();

  return (
    <div className="App">
      <PasswordFunctionClass validateToken={token} />
    </div>
  );
}