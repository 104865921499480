import React from "react";
import { connect } from "react-redux";
import { withRouter } from "../router";
import { ToastContainer, toast } from 'react-toastify';
import { bindActionCreators } from "@reduxjs/toolkit";
import * as commanActionCreator from '../reducers/commanReduces'
import Navbar from "./subComponents/Navbar";
import { format } from 'date-fns'
import { DataIsEmptyWithoutButton, InputFieldWithEdit } from "./utilComponents/inputFieldAndButtons";
import { MdArrowBackIos, MdStayCurrentLandscape, MdStayCurrentPortrait } from "react-icons/md";
import { Button } from "react-bootstrap";
import RssIcon from "../appIcons/RssIcon";
import debounce from 'lodash.debounce';
import { createApp, fetchAppData, getRssLinkData } from "../services/appServices";
import RssPreview from "./subComponents/rssPreview";
import { updateMedia } from "../services/mediaService";

class RssDetailsAppComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isNameEdit: false,
            isLandscape: true,
            appName: "News app",
            idDisabled: true,
            isUpdate: false,
            duration: 10,
            rssNewsList: [],
            rssNewUrl: ""
        }
    }
    async componentDidMount() {
        this.props.counterSlice.startLoading();
        if (this.props.router.params.appId) {
            let appInfo = await fetchAppData(this.props.router.params.appId);
            this.setState({
                appInfo: appInfo.body,
                appName: appInfo.body.name,
                duration: appInfo.body.timer,
                rssNewUrl: appInfo.body.link,
                isUpdate: true,
            });
            this.searchSheetData(appInfo.body.link);
        }
        this.props.counterSlice.stopLoading();
    }
    searchSheetData = debounce(async (sheetUrl) => {
        if (sheetUrl.trim() != "") {
            this.props.counterSlice.startLoading();
            let rssNewsData = await getRssLinkData(sheetUrl.trim());
            this.props.counterSlice.stopLoading();

            let newsList = [];
            if (rssNewsData !== "Error") {
                for (let i = 0; i < rssNewsData.children[0].children.length; i++) {
                    let items = rssNewsData.children[0].children[i];
                    if (items.name === "item") {
                        let newsInfo = {};
                        for (let j = 0; j < items.children.length; j++) {
                            let innerdetails = items.children[j];
                            if (innerdetails.name === "title")
                                newsInfo.title = innerdetails.value;

                            if (innerdetails.name === "pubDate")
                                newsInfo.publishDate = innerdetails.value;

                            if (innerdetails.name === "description") {
                                newsInfo.description = innerdetails.value.replace(/<\/?p>/g, '');
                            }

                            if (innerdetails.name === "media:content")
                                newsInfo.url = innerdetails.attributes.url;
                        }
                        newsList.push(newsInfo);
                    }
                }
            } else {
                toast.error('News not found.', {
                    position: "top-right",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    theme: "colored",
                    progress: undefined,
                });
            }
            this.setState({ rssNewsList: newsList });
        }
    }, 500);

    createAppService = async () => {
        if (this.state.appName.trim() == "") {
            toast.error('App name should not be empty.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
            return;
        }
        this.props.counterSlice.startLoading();
        let appDTO = {
            name: this.state.appName.trim(),
            timer: this.state.duration,
            link: this.state.rssNewUrl,
            appType: "RSS",
            duration:"00:00:50"
        }
        let response = await createApp(appDTO);
        if (response.success) {

            toast.success('Rss app saved successfully.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
            this.props.router.navigate("/apps");

        } else {
            toast.error(response.message, {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        }
        this.props.counterSlice.stopLoading();
    }

    updateAppService = async () => {
        if (this.state.appName.trim() == "") {
            toast.error('App name should not be empty.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
            return;
        }
        this.props.counterSlice.startLoading();
        let appDTO = {
            _id: this.state.appInfo._id,
            name: this.state.appName.trim(),
            link: this.state.rssNewUrl,
            timer: this.state.duration,
        }
        let response = await updateMedia(appDTO);
        if (response.success) {

            toast.success('Rss app updated successfully.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        } else {
            toast.error('Error while updating app.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
                progress: undefined,
            });
        }
        this.props.counterSlice.stopLoading();
    }

    render() {
        const userRole = JSON.parse(localStorage.getItem("roles"));

        let screenSize = { height: "410px", width: "717px" };
        if (!this.state.isLandscape) {
            screenSize = { width: "55vh", height: "68vh" };
        }
        return (
            <div>
                <Navbar router={this.props.router} counterSlice={this.props.counterSlice} />
                <div className="flex bg-white border-b-1 border-slate-300 w-full min-h-min	">
                    <div className="flex-none p-4 border-1"
                        onClick={() => this.props.router.navigate(-1)}>
                        <MdArrowBackIos size={20} />
                    </div>
                    <div className="self-center bg-dark-team rounded p-2 m-2">
                        <RssIcon style="fill-white" />
                    </div>
                    <div className="px-2 py-3 flex flex-col self-center grow">
                        <InputFieldWithEdit
                            isEdit={this.state.isNameEdit}
                            isRead={userRole.app["editName"].read}

                            onKeyDown={(e) => {
                                if (this.state.appName.trim() !== "" && (e.key === "Enter" || e.key === "Tab" || e.key === undefined)) {
                                    this.setState({ isNameEdit: false });
                                } else this.setState({ isNameEdit: true });
                            }}
                            inputValue={this.state.appName}
                            placeholder="Enter Rss App Name"
                            onInputchange={(e) => {userRole.app["editName"].write && this.setState({ appName: e.target.value }); }}
                            onEditClick={() => {userRole.app["editName"].write && this.setState({ isNameEdit: !this.state.isNameEdit }) }} />
                        {this.state.isUpdate ? <span className="order-last text-sm text-gray-450">{format(new Date(), 'dd/MM/yyyy HH:mm')}, modified by {this.state.appInfo != "" && this.state.appInfo.modifiedBy && <span>{this.state.appInfo.modifiedBy.firstName} {this.state.appInfo.modifiedBy.lastName}</span>} </span> :
                            <span className="order-last text-sm text-gray-450">{format(new Date(), 'dd/MM/yyyy HH:mm')}</span>}
                    </div>
                    <div className="flex gap-4 self-center" >
                        <Button className="bg-darkBlue text-white rounded p-2 mr-4"
                            disabled={(this.state.rssNewsList.length == 0 || this.state.rssNewUrl.trim() === "")}
                            onClick={this.state.isUpdate ? this.updateAppService : this.createAppService}>{this.state.isUpdate ? "Update" : "Save"}</Button>
                    </div>
                </div>
                <div className="flex" style={{ height: "82vh" }}>
                    <div className="bg-white h-full w-1/3 px-12 pt-2 gap-4 flex flex-col">
                        <div className="flex mr-4">
                            <label className="self-center flex-1">{"RSS URL *"}</label>
                            <div className="flex flex-1 gap-2">
                                <input type="url" id="inputo"
                                    className="border-1 text-center rounded p-2 text-black my-select w-60"
                                    value={this.state.rssNewUrl}
                                    onChange={(e) => {
                                        this.setState({ rssNewUrl: e.target.value })
                                        this.searchSheetData(e.target.value.trim())
                                    }}
                                    style={{ backgroundColor: "inherit", color: "white" }}
                                    placeholder="URL" />
                            </div>
                        </div>
                        <div className="flex mr-4">
                            <label className="self-center flex-1">{"Duration *"}</label>
                            <div className="flex gap-2">
                                <input type="number" id="inputo"
                                    max={50}
                                    min={5}
                                    className="border-1 text-center rounded p-2 text-black my-select w-60"
                                    value={this.state.duration} onChange={(e) => e.target.value >= 5 && e.target.value <= 50 && this.setState({ duration: e.target.value })}
                                    style={{ backgroundColor: "inherit", color: "white" }}
                                    placeholder="eg. 10" />
                            </div>
                        </div>

                    </div>
                    <div className="flex-1">
                        <div className="flex content-end m-4">
                            <button className="p-2 flex bg-black text-white rounded-lg font-bold border-solid border-1" onClick={() => {
                                this.setState({ isLandscape: !this.state.isLandscape })
                            }}>
                                {this.state.isLandscape ? <MdStayCurrentLandscape className="self-center font-bold mr-1 " size={25} /> :
                                    <MdStayCurrentPortrait className="self-center font-bold mr-1 " size={25} />}
                            </button>
                        </div>
                        <div className="flex justify-center">
                            <div className="bg-cover flex bg-white items-center justify-center object-cover border-black border-4 shadow overflow-hidden" style={screenSize}>
                                {this.state.rssNewsList.length > 0 ? <RssPreview rssNewsList={this.state.rssNewsList} duration={this.state.duration} /> :
                                    <DataIsEmptyWithoutButton url={process.env.PUBLIC_URL + "/blankScreenIcon.svg"}
                                        title="App instance preview"
                                        body1="Edit and save the configuration to preview this app instance" />}
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </div >)
    };
}

const mapStateToProps = (state) => ({
    isLoading: state.counter.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
    counterSlice: bindActionCreators(commanActionCreator, dispatch)
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RssDetailsAppComponent));
