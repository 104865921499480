import React from 'react';

const Monitoring = (props) => {
  let { style } = props;
  return (
  
    <svg className={style} width="24" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.8493 9.93287C11.7784 9.93287 11.7108 9.90084 11.666 9.8441C11.6132 9.77776 11.6006 9.68808 11.6328 9.60938L15.2364 0.841332C15.286 0.721454 15.4229 0.665175 15.5418 0.713675C18.6435 1.98978 20.9054 4.83484 21.4448 8.13927C21.4546 8.20104 21.44 8.26372 21.4037 8.31405C21.3673 8.36438 21.3125 8.3987 21.2512 8.40831L11.8868 9.92967C11.8742 9.93195 11.8616 9.93287 11.8493 9.93287ZM15.5793 1.23894L12.225 9.40028L20.942 7.98416C20.3777 5.0142 18.3493 2.46289 15.5793 1.23894Z" fill="#C4C4C4" strokeWidth="0.2"/>
<path d="M11.8493 9.93287C11.7784 9.93287 11.7108 9.90084 11.666 9.8441C11.6132 9.77776 11.6006 9.68808 11.6328 9.60938L15.2364 0.841332C15.286 0.721454 15.4229 0.665175 15.5418 0.713675C18.6435 1.98978 20.9054 4.83484 21.4448 8.13927C21.4546 8.20104 21.44 8.26372 21.4037 8.31405C21.3673 8.36438 21.3125 8.3987 21.2512 8.40831L11.8868 9.92967C11.8742 9.93195 11.8616 9.93287 11.8493 9.93287ZM15.5793 1.23894L12.225 9.40028L20.942 7.98416C20.3777 5.0142 18.3493 2.46289 15.5793 1.23894Z" stroke="#C4C4C4" strokeWidth="0.2"/>
<path d="M9.7249 20.3038C4.36261 20.3038 0 15.9369 0 10.5694C0 5.20183 4.36261 0.834961 9.7249 0.834961C10.9991 0.834961 12.2415 1.07975 13.4172 1.56292C13.4745 1.58672 13.5202 1.63201 13.5442 1.68967C13.5682 1.74686 13.5682 1.81137 13.5447 1.86903L9.98316 10.5355L14.3961 18.8432C14.4566 18.9572 14.4136 19.0986 14.2998 19.1599C12.9006 19.908 11.3186 20.3038 9.7249 20.3038ZM9.7249 1.30349C4.62064 1.30349 0.468075 5.46034 0.468075 10.5694C0.468075 15.6784 4.62064 19.8352 9.7249 19.8352C11.1636 19.8352 12.5923 19.4966 13.8718 18.8538L9.51829 10.6581C9.4856 10.5968 9.48195 10.5236 9.50846 10.4591L13.0215 1.91021C11.9675 1.50756 10.8594 1.30349 9.7249 1.30349Z" fill="#C4C4C4" strokeWidth="0.2"/>
<path d="M9.7249 20.3038C4.36261 20.3038 0 15.9369 0 10.5694C0 5.20183 4.36261 0.834961 9.7249 0.834961C10.9991 0.834961 12.2415 1.07975 13.4172 1.56292C13.4745 1.58672 13.5202 1.63201 13.5442 1.68967C13.5682 1.74686 13.5682 1.81137 13.5447 1.86903L9.98316 10.5355L14.3961 18.8432C14.4566 18.9572 14.4136 19.0986 14.2998 19.1599C12.9006 19.908 11.3186 20.3038 9.7249 20.3038ZM9.7249 1.30349C4.62064 1.30349 0.468075 5.46034 0.468075 10.5694C0.468075 15.6784 4.62064 19.8352 9.7249 19.8352C11.1636 19.8352 12.5923 19.4966 13.8718 18.8538L9.51829 10.6581C9.4856 10.5968 9.48195 10.5236 9.50846 10.4591L13.0215 1.91021C11.9675 1.50756 10.8594 1.30349 9.7249 1.30349Z" stroke="#C4C4C4" strokeWidth="0.2"/>
<path d="M16.7396 20.0404C16.7167 20.0404 16.6936 20.0372 16.6715 20.0303C16.6118 20.012 16.5622 19.9708 16.5329 19.9159L12.0684 11.5107C12.033 11.4444 12.0318 11.3643 12.0654 11.2966C12.0988 11.2293 12.163 11.1817 12.2375 11.1698L21.6018 9.64801C21.7286 9.62559 21.8493 9.7139 21.8703 9.8411C21.9563 10.3682 21.9999 10.8999 21.9999 11.4219C21.9999 15.0224 20.0266 18.314 16.8497 20.0129C16.8157 20.0312 16.7777 20.0404 16.7396 20.0404ZM12.635 11.5798L16.8346 19.4863C19.7382 17.8391 21.5318 14.7708 21.5318 11.4219C21.5318 11.0014 21.5021 10.5741 21.4432 10.1481L12.635 11.5798Z" fill="#C4C4C4" strokeWidth="0.2"/>
<path d="M16.7396 20.0404C16.7167 20.0404 16.6936 20.0372 16.6715 20.0303C16.6118 20.012 16.5622 19.9708 16.5329 19.9159L12.0684 11.5107C12.033 11.4444 12.0318 11.3643 12.0654 11.2966C12.0988 11.2293 12.163 11.1817 12.2375 11.1698L21.6018 9.64801C21.7286 9.62559 21.8493 9.7139 21.8703 9.8411C21.9563 10.3682 21.9999 10.8999 21.9999 11.4219C21.9999 15.0224 20.0266 18.314 16.8497 20.0129C16.8157 20.0312 16.7777 20.0404 16.7396 20.0404ZM12.635 11.5798L16.8346 19.4863C19.7382 17.8391 21.5318 14.7708 21.5318 11.4219C21.5318 11.0014 21.5021 10.5741 21.4432 10.1481L12.635 11.5798Z" stroke="#C4C4C4" strokeWidth="0.2"/>
</svg>
)
}

export default Monitoring;

