
import React from 'react';

const Playee = (props) => {
  let { style } = props;
  return (
    <><svg className={style} width="22" height="20" viewBox="0 0 22 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.14627 14.8703L5.5 14.4519V10.3227L6.14627 9.9043L10.7298 11.9689V12.8057L6.14627 14.8703ZM6.4167 11.032V13.7417L9.42532 12.3864L6.4167 11.032Z" />
      <path d="M14.6626 19.2688H1.83707C0.824115 19.2688 0 18.4439 0 17.43V7.34375C0 6.3298 0.824115 5.50488 1.83707 5.50488H14.6626C15.6747 5.50488 16.4997 6.3298 16.4997 7.34375V17.43C16.4997 18.4439 15.6756 19.2688 14.6626 19.2688ZM1.83707 6.42248C1.32922 6.42248 0.916702 6.8354 0.916702 7.34375V17.43C0.916702 17.9383 1.32922 18.3512 1.83707 18.3512H14.6626C15.1705 18.3512 15.583 17.9383 15.583 17.43V7.34375C15.583 6.8354 15.1705 6.42248 14.6626 6.42248H1.83707Z" />
      <path d="M19.2506 16.5169H18.3339V4.59179C18.3339 4.08345 17.9214 3.67053 17.4136 3.67053H2.75V2.75293H17.4136C18.4265 2.75293 19.2506 3.57785 19.2506 4.59179V16.5169Z" />
      <path d="M22.0006 13.764H21.0839V1.83886C21.0839 1.33052 20.6714 0.917597 20.1636 0.917597H5.5V0H20.1636C21.1765 0 22.0006 0.82492 22.0006 1.83886V13.764Z" />
    </svg>&nbsp;</>

  )
}

export default Playee;