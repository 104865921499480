import React from "react";
import { connect } from "react-redux";
import { withRouter } from "../../router";
import { bindActionCreators } from "@reduxjs/toolkit";
import * as commanActionCreator from '../../reducers/commanReduces'
import { fetchAppData, fetchMatchInfo, fetchSoccerMatchesService } from "../../services/appServices";
import { format } from 'date-fns'
import SoccerMatchPreview from "../subComponents/soccerMatchPreview";

class SoccerAppPublicComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            matchList: [],
            appInfo: "",
            matchDetails: "",
            duration: 1
        }
    }
    async componentDidMount() {
        if (this.props.router.params.appId) {
            this.props.counterSlice.startLoading();
            let appInfo = await fetchAppData(this.props.router.params.appId);
            if (appInfo.success) {
                this.populateInfo(appInfo.body);
            }
            this.props.counterSlice.stopLoading();
        }
    }
    populateInfo = async (appData) => {
        let matchList = await fetchSoccerMatchesService(appData.countryCode, appData.leagueCode);
        if (matchList && matchList.data && matchList.data.length > 0) {
            let matchListData = matchList.data.filter((data) => data.time.finish == null);
            this.setState({ matchList: matchListData, duration: appData.soccerSlideduration });
        }
    }

    render() {
        return (
            <div className="flex justify-center bg-gray-800 h-screen w-screen"  >
                {this.state.matchList.length > 0 ? <div className="h-screen w-screen">
                    <SoccerMatchPreview soccerMatchList={this.state.matchList} duration={this.state.duration} />
                </div> :
                    <div className="h-full w-full flex text-center text-white">
                        <spna className="font-semibold text-6xl">End Of Season </spna>
                    </div>}
            </div>
        )
    };
}

const mapStateToProps = (state) => ({
    isLoading: state.counter.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
    counterSlice: bindActionCreators(commanActionCreator, dispatch)
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SoccerAppPublicComponent));
