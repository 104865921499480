import React from "react";
import { Form } from "react-bootstrap";
import { MdSearch } from "react-icons/md";
import { FaRegCheckSquare, FaFolder, FaFilm, FaLink } from "react-icons/fa";
import Link from "../../appIcons/Link";
import { AppIcon, DataIsEmpty } from "../utilComponents/inputFieldAndButtons";
import { format } from "date-fns";

const ChannelAddContent = (props) => {

    let { mediaList, selectedMediaFile, routeTo, type, searchMedia, onMediasearch } = props;
    return (
        <div>
            <div className="flex mt-2">
                <div className="grow px-2 relative ">
                    <MdSearch opacity={0.5} size={25} className="absolute self-center m-2" />
                    <Form.Control
                        style={{ fontFamily: "Poppins", fontSize: "16px" }}
                        size="lg"
                        type="search"
                        placeholder="Search"
                        className="me-2 px-5 text-sm"
                        aria-label="Search"
                        value={searchMedia}
                        onChange={onMediasearch}
                    />
                </div>
            </div>
            <div className="overflow-y-auto" style={{ height: "60vh" }}>
                {mediaList.length == 0 && <DataIsEmpty url={process.env.PUBLIC_URL + "/mediaEmpty.svg"}
                    title="Add your content"
                    body2="This is where you can upload all the content you want to show on your screens."
                    customFunction={routeTo}
                    buttonName={"Add " + type}
                />}
                {mediaList.map((m, i) => {
                    return (<div key={i} className={"flex px-3 py-1 cursor-pointer m-2 hover:shadow-md rounded-lg " + (!m.isSelected ? "bg-white" : "bg-gray-200")} onClick={() => selectedMediaFile(m, i)}>
                        {(m.type === "LINK" || m.type === "APP") ? <div className="flex flex-1 self-center gap-3">
                            {m.type === "APP" ?
                                <div className={"flex rounded-lg cursor-pointer bg-dark-team border-solid border-1 p-1"}>
                                    <AppIcon style={"self-center h-8 w-8 "+(m.appType === "MEET_TEAM"?"":"fill-white")} type={m.appType} />
                                </div> :
                                <div className={"rounded-lg cursor-pointer self-center border-solid border-1 p-2"}>
                                    <Link className="self-center" />
                                </div>}
                            <div className="flex self-center flex-col gap-1">
                                <span className="text-gray-500 flex-auto text-sm">{m.name}</span>
                                <span className="text-gray-400 text-xs">{format(new Date(m.createdOn), 'dd/MM/yyyy HH:mm')}</span>
                            </div>
                        </div> : <div className="flex flex-1 gap-2">
                            {m.type === "FOLDER" ?
                                <img src="../folder.svg" className="h-10 w-10 self-center" />
                                :
                                m.contentType.includes("video") ? <img src="../film.svg" className="h-6 w-6 self-center" /> :
                                    <img className="rounded-2xl border-solid self-center border-1 w-9 h-9" src={m.url} alt="loadding..." />
                            }  <div className="flex flex-col gap-1 self-center">
                                <div className="w-10 xl:w-52 lg:w-40 md:w-32 inline-block truncate text-black text-sm text-gray-500"><span>{m.name}</span></div>
                                <span className="text-gray-400 text-xs">{format(new Date(m.createdOn), 'dd/MM/yyyy HH:mm')}</span>
                            </div>
                        </div>}
                        <div className="basis-1/4 self-center"><span className="text-sm">{m.size}</span></div>
                        <div className="self-center basis-4 text-gray-400">{m.isSelected && <FaRegCheckSquare />}</div>
                    </div>
                    )
                })}
            </div>
        </div>
    );
};

export default ChannelAddContent;
