import React from "react";
import { Form } from "react-bootstrap";

import { MdSearch } from "react-icons/md";

const SearchWithButton = (props) => {
  let {icon, title, placeholder, onChangeSearch, buttonName, onButtonClick, isRead } = props;
  return (
    <div className="flex shadow-lg px-16 py-3 text-3xl	">
      <div className="flex">
        {icon}
      <h1 className="self-center mr-5 text-black font-medium text-lg">
        {title}
        </h1>
      </div>
      <div className="grow px-2 flex relative">
        <MdSearch opacity={0.5} className="absolute self-center ml-2" />
        <Form.Control
          size="lg"
          type="search"
          placeholder={placeholder}
          className="px-5 text-base"
          aria-label="Search"
          onChange={onChangeSearch}
        />
      </div>
      {isRead && <div className="flex-none self-center">
        <button
          type="button"
          className={`text-base  ml-3 px-3 py-2 btn-prime text-white  rounded-lg`}
          onClick={onButtonClick}
        >{buttonName}
        </button>
      </div>}

    </div>
  );
};
export default SearchWithButton;
